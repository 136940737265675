import React from 'react';
import IconButton from '../IconButton';
import Clear from '../icons/Clear';

interface CloseButtonProps {
  onClick: () => void;
  style?: any;
}

export function CloseButton(props: CloseButtonProps) {
  return (
    <IconButton
      type="button"
      onClick={props.onClick}
      style={
        props.style ? props.style :
        {
        position: "absolute",
        right: 0,
        top: 0,
        margin: 0,
      }}
    >
      <Clear />
    </IconButton>
  )
}
