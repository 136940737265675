import React, { Component } from "react";
import LoadingButton from "../../../LoadingButton";
import { observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { LoadingIndicator } from "../../../../util/loading";
import LoadingIcon from "../../../icons/Loading";
import { Seperator } from "../../SplitDialog";
import { TokenAvailableResponse } from "../../../../sdk/physitrack_pb";

interface Props {
  tokenAndData: TokenAvailableResponse.AsObject;
  buttonLabel: string;
}

@observer
export class PhysitrackButton extends Component<Props> {
  @observable
  private indicator = new LoadingIndicator();
  

  pushClient = async () => {
    await this.indicator.while(async () => {
    });
  };

  render() {
    const { tokenAndData, buttonLabel } = this.props;    
    const patient = tokenAndData.clientPhysitrack;

    return (
      <>
        {tokenAndData.isAvailable && (
          <>
          <Seperator style={{height:"0px"}}/>
            <form
              action={tokenAndData.signInAndPushClientUrl}
              target="_blank"
            >
                <input type="text" hidden={true} name="data[token]" value={tokenAndData.token} readOnly></input>
                <input type="text" hidden={true} name="data[patient][id]" value={patient!.externalId} readOnly></input>
                <input type="text" hidden={true} name="data[patient][email]" value={patient!.email} readOnly></input>
                <input type="text" hidden={true} name="data[patient][title]" value={patient!.gender} readOnly></input>
                <input type="text" hidden={true} name="data[patient][first_name]" value={patient!.firstName} readOnly></input>
                <input type="text" hidden={true} name="data[patient][last_name]" value={patient!.lastName} readOnly></input>
                <input type="text" hidden={true} name="data[patient][birth_year]" value={patient!.yearOfBirth} readOnly></input>
                <input type="text" hidden={true} name="data[patient][diagnosis_code]" value={patient!.diagnosisCode} readOnly></input>
                <input type="text" hidden={true} name="data[patient][mobile_phone]" value={patient!.mobilePhone} readOnly></input>
                  <LoadingButton
                    loading={this.indicator.isLoading()}
                    disabled={this.indicator.isLoading()}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    {this.indicator.isLoading() ? (
                      <LoadingIcon width={16} height={16} color="#FFF" />
                    ) : (
                      buttonLabel
                    )}
                  </LoadingButton>
            </form>
            <Seperator style={{height:"0px"}}/>
          </>
        )}
      </>
    );
  }
}
