/** @jsx jsx */
import { Component, Fragment } from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";

import { colors } from "./../util/consts";

export default class Avatar extends Component {
  state = {
    imgLoaded: false,
    imgWidth: "auto",
    imgHeight: "auto"
  };

  // This is required if the user remove's their image.
  static getDerivedStateFromProps(props, state) {
    if (!props.image) {
      return { imgLoaded: false };
    }
    return null;
  }

  imageLoaded = (e) => {
    if (e.target.nativeWidth > e.target.nativeHeight) {
      this.setState({ imgLoaded: true, imgWidth: "100%" });
    } else {
      this.setState({ imgLoaded: true, imgHeight: "100%" });
    }
  };

  render() {
    const { imgLoaded } = this.state;
    const { image, letters, size, lfrac } = this.props;

    return (
      <Container size={size} imgLoaded={imgLoaded}>
        {imgLoaded ? null : (
          <Fragment>
            <Letter size={(lfrac || 0.37) * size}>
              {!!letters && letters.length > 0 ? letters[0] : ""}
            </Letter>
            <Letter size={(lfrac || 0.37) * size}>
              {!!letters && letters.length > 1 ? letters[1] : ""}
            </Letter>
          </Fragment>
        )}
        {!image ? null : (
          <img
            css={css`
              visibility: ${!imgLoaded ? "hidden" : "visible"};
              width: 54px;
              height: 54px;
            `}
            onLoad={this.imageLoaded}
            src={image}
            alt="avatar"
          />
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  background: ${({ imgLoaded }) => (!imgLoaded ? colors.primary.main : "none")};
  color: #fff;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
`;

const Letter = styled.div`
  width: ${({ imgWidth }) => imgWidth};
  height: ${({ imgHeight }) => imgHeight};
  font-size: ${({ size }) => size}px;
  line-height: ${({ size }) => size}px;
`;
