import styled from "@emotion/styled";

import { colors } from "./../../util/consts";

const Label = styled.label`
  display: inline-block;
  margin-bottom: 6px;
  color: ${colors.blackText.highEmphasis};
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

export default Label;
