/** @jsx jsx */
import { PureComponent } from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";

import { Link } from "react-router-dom";

// utils
import { colors, breakpoints, hexToRgba } from "./../util/consts";
import typography from "./../util/typography";

// components
import ButtonBase from "./../components/ButtonBase";
import Divider from "./../components/Divider";

// icons
import Logo from "./../components/icons/Logo";
import FacebookIcon from "./../components/icons/Facebook";
import LinkedInIcon from "./../components/icons/LinkedIn";

export default class Footer extends PureComponent {

  render() {
    return (
      <Container>
        <FooterContent>
          <div>
            <FooterLinksContainer>
              <FooterLinksColumns>
                <FooterLinks>
                  <Subtitle>Lyfe</Subtitle>
                  <UnorderedList>
                    {/* <ListElement>
                      <FadingLink to="/">Press</FadingLink>
                    </ListElement> */}
                    {/* <ListElement>
                      <FadingLink to="/">Policies</FadingLink>
                    </ListElement> */}
                    <ListElement>
                      <FadingA href="mailto:info@booklyfe.com">Help</FadingA>
                    </ListElement>
                    <ListElement>
                      <FadingLink to="/terms-of-use">Terms</FadingLink>
                    </ListElement>
                    <ListElement>
                      <FadingLink to="/privacy-policy">Privacy</FadingLink>
                    </ListElement>
                  </UnorderedList>
                </FooterLinks>
              </FooterLinksColumns>
              <FooterLinksColumns>
                <FooterLinks>
                  <Subtitle>Discover</Subtitle>
                  <UnorderedList>
                    <ListElement>
                      <FadingLink to="/providers">Explore</FadingLink>
                    </ListElement>
                    <ListElement>
                      <FadingLink to="/users/edit">Profile</FadingLink>
                    </ListElement>
                    {/* <ListElement>
                      <FadingLink to="/">Blog</FadingLink>
                    </ListElement> */}
                    {/* <ListElement>
                      <FadingLink to="/">Events</FadingLink>
                    </ListElement> */}
                    <ListElement>
                      <FadingLink to="/tutorials">Video Tutorials</FadingLink>
                    </ListElement>
                    <ListElement>
                      <FadingLink to="/physitrack">Physitrack</FadingLink>
                    </ListElement>
                    <ListElement>
                      <FadingLink to="/hubspot">Hubspot</FadingLink>
                    </ListElement>
                  </UnorderedList>
                </FooterLinks>
              </FooterLinksColumns>
              <FooterLinksColumns>
                <FooterLinks>
                  <Subtitle>List</Subtitle>
                  <UnorderedList>
                    <ListElement>
                      <FadingLink to="/listyourbusiness">Why List</FadingLink>
                    </ListElement>
                    <ListElement>
                      <FadingLink to="/faqs">FAQs</FadingLink>
                    </ListElement>
                    <ListElement>
                      <FadingA href="tel:1300005933">Contact</FadingA>
                    </ListElement>
                  </UnorderedList>
                </FooterLinks>
              </FooterLinksColumns>
              <FooterLinksColumns>
                <FooterLinks>
                  <a href="https://www.facebook.com/booklyfe/">
                    <FacebookIcon height="20" width="20" />
                  </a>
                  <a href="https://www.linkedin.com/company/lyfe-group/?viewAsMember=true">
                    <LinkedInIcon height="20" width="20" />
                  </a>
                  {/* <InstagramIcon height="20" width="20" /> */}
                </FooterLinks>
              </FooterLinksColumns>
            </FooterLinksContainer>
            <Divider style={{ marginTop: 28, marginBottom: 16 }} />
            <FooterBottomContainer>
              <FooterBCLeft>
                <Button
                  css={css`
                    margin-right: 12px;
                  `}
                >
                  <ButtonPadding>English</ButtonPadding>
                </Button>
                <Button>
                  <ButtonPadding>AUD</ButtonPadding>
                </Button>
              </FooterBCLeft>
              <FooterBCLeft>
                <SubtitleOther>Copyright lyfe pty ltd</SubtitleOther>
                <Logo
                  height="24"
                  width="42.82"
                  css={css`
                    ${breakpoints["phone-only"]} {
                      display: none;
                    }
                  `}
                />
              </FooterBCLeft>
            </FooterBottomContainer>
          </div>
        </FooterContent>
      </Container>
    );
  }
}

const Button = styled(ButtonBase)`
  transition: border-color 0.2s ease;
  color: ${hexToRgba(colors.primary.main, 0.38)};
  background-color: transparent;
  border: 1px solid ${hexToRgba(colors.primary.main, 0.38)};

  &:hover:enabled {
    border-color: ${hexToRgba(colors.primary.main, 0.12)};
  }

  &:focus {
    border-color: ${hexToRgba(colors.primary.main, 0.12)};
  }

  padding: 6px 16px;
  font-size: 13.82px;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 18px;

  &:disabled {
    color: ${colors.blackText.lowEmphasis};
  }
`;

const Container = styled.footer`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const FooterContent = styled.div`
  margin: 0 auto;
  padding: 48px 24px;
  max-width: 1080px;
`;

const FooterBottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FooterBCLeft = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonPadding = styled.span`
  font-size: 10.8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 18px;

  ${breakpoints["tablet-up"]} {
    margin-left: 12px;
    margin-right: 12px;
  }
`;

const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FooterLinksColumns = styled.div`
  display: flex;
  flex: 0 1 0;
`;

const FooterLinks = styled.div`
  display: block;
`;

const Subtitle = styled.div`
  ${typography.subtitle2};
  font-weight: bold;
  marign-bottom: 8px;
`;

const SubtitleOther = styled.span`
  ${typography.subtitle2};
  color: ${colors.surfaceText.lowEmphasis};
  font-weight: normal;

  ${breakpoints["tablet-up"]} {
    margin-right: 12px;
  }
`;

const FadingLink = styled(Link)`
  ${typography.body2};
  text-decoration: none;
  opacity: 0.38;
  transition: opacity 200ms ease-in;
  outline: none;

  &:hover {
    opacity: 1;
  }

  &:focus {
    opacity: 1;
  }
`;

const FadingA = styled.a`
  ${typography.body2};
  text-decoration: none;
  opacity: 0.38;
  transition: opacity 200ms ease-in;
  outline: none;

  &:hover {
    opacity: 1;
  }

  &:focus {
    opacity: 1;
  }
`;

const UnorderedList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListElement = styled.li`
  margin: 3px 0;
  white-space: nowrap;
`;
