/** @jsx jsx */
import { Component, Fragment, createRef } from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import * as fns from "date-fns";
import { withToastManager } from "react-toast-notifications";

import * as ReduxDialogs from "./../../redux/features/dialogs";
import * as ResourceActions from "./../../redux/features/resources/thunkactions";
import { parseFormError } from "./../../util/form";
import { colors, breakpoints } from "./../../util/consts";

// Custom components
import Dialog from "./Dialog";
import Section from "./../../components/form/Section";
import Label from "./../../components/form/Label";
import Input from "./../../components/form/Input";
import Error from "./../../components/form/Error";
import DayTimePickerInput from "./../../components/form/DayTimePickerInput";
import DayTimePickerRangeInput from "./../../components/form/DayTimeRangePickerInput";
import {
  LocationOfferingSelector,
  SpecificLocationClientSelector
} from "./../../components/form/ResourceSelector";
import Button from "./../../components/Button";
import CheckRadioBox from "./../../components/form/CheckRadioBox";
import IconButton from "./../../components/IconButton";
import ClearIcon from "./../../components/icons/Clear";
import { Row } from "../layout/flexbox/Row";
import { Gutter } from "../layout/flexbox/FlexProps";
import { bookingStore } from "./../../stores/booking-store";
import { dateToTimestamp, intToTimestamp } from "./../../util/time";
import { rebookDataStore } from "../../mobx/stores/RebookDataStore";
import apiService from "../../redux/services/api";

/**
 * NOTES: currently using 2 buttons instead of a set of radio buttons
 */

class ScheduleQuickBook extends Component {
  modalRef = createRef();

  state = {
    modalHeight: 0
  };

  async componentDidMount() {
    this.props.setStatus({ Type: "Appointment" });
    window.requestAnimationFrame(this.updateModalHeight);

    if (rebookDataStore.isRebooking) {
      const res = await apiService.performRequest({
        method: "get",
        path: `/api/v1/locations/` + rebookDataStore.locationId + `/clients`
      });

      const rebookedClient = res.Payload.find((client) => client.ID === rebookDataStore.clientId);

      let endTime = fns.parse(this.props.values.StartTime, "HH:mm:ss", new Date());
      endTime = fns.addSeconds(endTime, rebookDataStore.duration.seconds);

      this.props.handleChange({
        target: { name: "Service", value: rebookDataStore.offering }
      });
      this.props.handleChange({
        target: { name: "Client", value: rebookedClient }
      });
      this.props.handleChange({
        target: { name: "EndTime", value: fns.format(endTime, "HH:mm:00") }
      });
    }
  }

  updateModalHeight = () => {
    const newHeight = this.modalRef.current ? this.modalRef.current.offsetHeight : 0;
    if (newHeight !== this.state.modalHeight) {
      this.setState({
        modalHeight: this.modalRef.current ? this.modalRef.current.offsetHeight : 0
      });
    }
  };

  getModalStyle = () => {
    const { modalHeight } = this.state;
    const { top, left, width, height } = this.props.data.stuff.cardStyle;
    const { innerWidth, innerHeight } = window;

    const right = Math.abs(left + width - innerWidth);
    const bottom = Math.abs(top + height - innerHeight);
    const isRightOfCard = right > left;
    const isBelowCard = bottom > top;

    const leftRight = css`
      ${isRightOfCard ? `left: ${width + 17}px` : `right: ${width + 17}px`};
    `;

    const minSpaceFromEdge = 50;
    const spaceToBottom = -(top + modalHeight - innerHeight);
    const spaceToTop = -(bottom + modalHeight - innerHeight);

    const topBottom = css`
      ${isBelowCard
        ? `top: ${spaceToBottom < minSpaceFromEdge ? -(minSpaceFromEdge - spaceToBottom) : 0}px`
        : `bottom: ${spaceToTop < minSpaceFromEdge ? -(minSpaceFromEdge - spaceToTop) : 0}px`};
    `;

    const styles = css`
      ${breakpoints["tablet-up"]} {
        width: 554px;
        ${leftRight};
        ${topBottom};
      }
    `;

    return styles;
  };

  handleServiceSelect = (offering) => {
    const { values, setFieldValue } = this.props;
    if (values.StartTime) {
      const bd = new Date();
      const stime = fns.parse(values.StartTime, "HH:mm:00", bd);
      if (fns.isDate(stime)) {
        const etime = fns.addMinutes(stime, offering.ServiceDuration);
        setFieldValue("EndTime", fns.format(etime, "HH:mm:00"));
      }
    }
  };

  closeDialog = () => {
    const { dispatch, dialogId } = this.props;
    dispatch(ReduxDialogs.actions.close(dialogId));
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      dispatch,
      data
    } = this.props;

    return (
      <Dialog dialogId={this.props.dialogId} noContent>
        <Block style={data.stuff.cardStyle}>
          <Container css={this.getModalStyle()} ref={this.modalRef} aria-label="quick-book-modal">
            <IconButton
              css={css`
                position: absolute !important;
                top: 2px;
                right: 0px;
                z-index: 12;

                ${breakpoints["tablet-up"]} {
                  display: none;
                }
              `}
              onClick={this.closeDialog}
              aria-labelledby="close-modal"
            >
              <ClearIcon fill={colors.primary.main} size="small" />
              <HideVisually id="close-modal">Close</HideVisually>
            </IconButton>
            <Form onSubmit={handleSubmit}>
              <Section style={{ display: "flex" }}>
                <Button
                  variant={values.Type === "Appointment" ? "contained" : "outlined"}
                  onClick={() => handleChange({ target: { name: "Type", value: "Appointment" } })}
                  style={{ marginRight: 12, flex: "1 1 50px" }}
                  type="button"
                >
                  APPOINTMENT
                </Button>
                <Button
                  variant={values.Type === "TimeOff" ? "contained" : "outlined"}
                  onClick={() => handleChange({ target: { name: "Type", value: "TimeOff" } })}
                  style={{ marginLeft: 12, flex: "1 1 50px" }}
                  type="button"
                >
                  TIME OFF
                </Button>
              </Section>
              {values.Type === "Appointment" && (
                <Fragment>
                  <Section>
                    <Label>Group Booking?</Label>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <CheckRadioBox
                          name="ServiceType"
                          value="Single"
                          type="radio"
                          checked={values.ServiceType === "Single"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.ServiceType && errors.ServiceType}
                        >
                          No
                        </CheckRadioBox>
                      </div>
                      <div style={{ width: "50%" }}>
                        <CheckRadioBox
                          name="ServiceType"
                          value="Group"
                          type="radio"
                          checked={values.ServiceType === "Group"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.ServiceType && errors.ServiceType}
                        >
                          Yes
                        </CheckRadioBox>
                      </div>
                    </div>
                  </Section>
                  <Section>
                    <Label htmlFor="quickbook-service">Service</Label>
                    <LocationOfferingSelector
                      id="quickbook-service"
                      name="Service"
                      placeholder="Select a provided service"
                      location={data.stuff.provider.Location}
                      onlyServices
                      value={values.Service}
                      onChange={handleChange}
                      onSelect={this.handleServiceSelect}
                      onBlur={handleBlur}
                      error={touched.Service && errors.Service}
                    />
                    {!!errors.Service && touched.Service && <Error>{errors.Service}</Error>}
                  </Section>
                  <Section>
                    <Label htmlFor="quickbook-datetime">Date &amp; Time</Label>
                    <DayTimePickerRangeInput
                      id="quickbook-datetime"
                      interval={5}
                      placeholder="Select the time"
                      dayName="Date"
                      dayValue={values.Date}
                      startName="StartTime"
                      startValue={values.StartTime}
                      endName="EndTime"
                      endValue={values.EndTime}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        (touched.Date && errors.Date) ||
                        (touched.StartTime && errors.StartTime) ||
                        (touched.EndTime && errors.EndTime)
                      }
                    />
                    {!!errors.Date && touched.Date && <Error>{errors.Date}</Error>}
                    {!!errors.StartTime && touched.StartTime && <Error>{errors.StartTime}</Error>}
                    {!!errors.EndTime && touched.EndTime && <Error>{errors.EndTime}</Error>}
                  </Section>
                  {values.ServiceType === "Single" && (
                    <Section>
                      <Label htmlFor="quickbook-client">Client</Label>
                      <Row alignItems="center" gutter={Gutter.small}>
                        <SpecificLocationClientSelector
                          id="quickbook-client"
                          name="Client"
                          placeholder="Select client"
                          location={data.stuff.provider.Location}
                          org={data.stuff.provider.Location.Org}
                          value={values.Client}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.Client && errors.Client}
                        />
                        {values.Client && (
                          <Button
                            variant="contained"
                            color="secondary"
                            type="button"
                            onClick={() =>
                              dispatch(
                                ReduxDialogs.actions.open(
                                  ReduxDialogs.DialogType.ClientActionDialog,
                                  {
                                    item: values.Client,
                                    initialTab: "Details"
                                  }
                                )
                              )
                            }
                          >
                            Edit
                          </Button>
                        )}
                      </Row>
                      {!!errors.Client && touched.Client && <Error>{errors.Client}</Error>}
                    </Section>
                  )}
                  {values.ServiceType === "Group" && (
                    <Section>
                      <Label htmlFor="newbook-maxgroupsize">Maximum Group Size</Label>
                      <Input
                        id="newbook-maxgroupsize"
                        name="MaxGroupSize"
                        type="number"
                        min={1}
                        max={100}
                        placeholder="Item code"
                        value={values.MaxGroupSize}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.MaxGroupSize && errors.MaxGroupSize}
                      />
                      {!!errors.MaxGroupSize && touched.MaxGroupSize && (
                        <Error>{errors.MaxGroupSize}</Error>
                      )}
                    </Section>
                  )}
                </Fragment>
              )}
              {values.Type === "TimeOff" && (
                <Fragment>
                  <Section>
                    <Label htmlFor="quick-start">Start Date and Time</Label>
                    <DayTimePickerInput
                      id="quick-start"
                      name="FromDate"
                      step={900}
                      value={values.FromDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.FromDate && errors.FromDate}
                    />
                    {!!errors.FromDate && touched.FromDate && <Error>{errors.FromDate}</Error>}
                  </Section>
                  <Section>
                    <Label htmlFor="quick-end">End Date and Time</Label>
                    <DayTimePickerInput
                      id="quick-end"
                      name="ToDate"
                      step={900}
                      value={values.ToDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.ToDate && errors.ToDate}
                    />
                    {!!errors.ToDate && touched.ToDate && <Error>{errors.ToDate}</Error>}
                  </Section>
                </Fragment>
              )}
              <ButtonContainer>
                <Button
                  css={css`
                    width: 149px;
                  `}
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  SAVE
                </Button>
              </ButtonContainer>
            </Form>
          </Container>
        </Block>
      </Dialog>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: (props) => ({
    Type: "Appointment",
    ServiceType: "Single",
    Service: null,
    Client: null,
    Date: props.data.stuff.date || new Date(),
    StartTime: props.data.stuff.startTime || "",
    EndTime: props.data.stuff.endTime || "",
    FromDate: fns.parse(props.data.stuff.startTime, "HH:mm:ss", props.data.stuff.date),
    ToDate: fns.parse(props.data.stuff.endTime, "HH:mm:ss", props.data.stuff.date),
    MaxGroupSize: 5
  }),
  validationSchema: Yup.lazy((v) => {
    if (v.Type === "Appointment" && v.ServiceType === "Single") {
      return Yup.object().shape({
        Service: Yup.object()
          .nullable(true)
          .required("A service is required"),
        Client: Yup.object()
          .nullable(true)
          .required("A client is required"),
        Date: Yup.date().required("Day is required"),
        StartTime: Yup.string().required("Start time is required"),
        EndTime: Yup.string().required("End time is required")
      });
    } else if (v.Type === "Appointment") {
      return Yup.object().shape({
        Service: Yup.object().required("A service is required"),
        Date: Yup.date().required("Day is required"),
        StartTime: Yup.string().required("Start time is required"),
        EndTime: Yup.string().required("End time is required"),
        MaxGroupSize: Yup.number()
          .min(1, "Must be one or more.")
          .max(100, "Must be less than one hundred.")
          .required("End time is required")
      });
    } else {
      return Yup.object().shape({
        FromDate: Yup.date().required("From date is required"),
        ToDate: Yup.date().required("To date is required")
      });
    }
  }),
  handleSubmit: async (values, { setSubmitting, status, setFieldError, props }) => {
    const { dispatch, dialogId } = props;
    if (values.Type === "Appointment") {
      try {
        // Create the booking.
        const newBooking = {
          $Metadata: { Type: "LocBooking" },
          Type: values.ServiceType,
          Provider: props.data.stuff.provider.User.ID,
          StartDate: fns.format(values.Date, "yyyy-MM-dd"),
          EndDate: fns.format(values.Date, "yyyy-MM-dd"),
          StartTime: values.StartTime,
          EndTime: values.EndTime,
          MaxGroupSize: values.ServiceType === "Single" ? null : values.MaxGroupSize,
          Clients: values.ServiceType === "Single" ? [values.Client.ID] : [],
          Parent: rebookDataStore.isRebooking ? rebookDataStore.bookingID : null,
          Offerings: [
            {
              Offering: values.Service.ID,
              Client: values.ServiceType === "Single" ? values.Client.ID : null,
              Count: 1,
              FundType: "None"
            }
          ]
        };
        const locId = props.data.stuff.provider.Location.ID;
        await dispatch(
          ResourceActions.action(newBooking, "Create", {
            Location: locId
          })
        );

        await rebookDataStore.reset();

        props.data.cb && props.data.cb();
        dispatch(ReduxDialogs.actions.close(dialogId));
        // The startDate and the endDate should be the date from the above and it should be in UTC because it send the string "yyyy-MM-dd" without timezone
        const queryDateInt = Math.round(
          Date.parse(fns.format(values.Date, "yyyy-MM-dd") + "T00:00:00+00:00") / 1000
        );
        bookingStore.loadByLocation(
          locId,
          intToTimestamp(queryDateInt),
          intToTimestamp(queryDateInt)
        );
      } catch (err) {
        parseFormError(err, values, setFieldError, props);
      }
    } else {
      try {
        // Create the booking.
        const newBooking = {
          $Metadata: { Type: "LocBooking" },
          Type: "TimeOff",
          Provider: props.data.stuff.provider.User.ID,
          StartDate: fns.format(values.FromDate, "yyyy-MM-dd"),
          EndDate: fns.format(values.ToDate, "yyyy-MM-dd"),
          StartTime: fns.format(values.FromDate, "HH:mm:00"),
          EndTime: fns.format(values.ToDate, "HH:mm:00"),
          Clients: [],
          Offerings: []
        };
        await dispatch(
          ResourceActions.action(newBooking, "Create", {
            Location: props.data.stuff.provider.Location.ID
          })
        );
        props.data.cb && props.data.cb();
        dispatch(ReduxDialogs.actions.close(dialogId));
      } catch (err) {
        parseFormError(err, values, setFieldError, props);
      }
    }

    setSubmitting(false);
  },
  displayName: "NewBooking"
});

export default compose(connect(), withToastManager, formikEnhancer)(ScheduleQuickBook);

const Block = styled.div`
  position: absolute;
  background-color: ${colors.surface.light};
  border-radius: 4px;
`;

const Container = styled.div`
  background-color: ${colors.surface.light};

  ${breakpoints["phone-only"]} {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }

  ${breakpoints["tablet-up"]} {
    position: absolute;
    border-radius: 4px;
  }
`;

const Form = styled.form`
  padding: 64px 24px;

  ${breakpoints["tablet-up"]} {
    padding: 54px 64px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HideVisually = styled.span`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`;
