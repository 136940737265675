import React, { Component, Fragment } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { BeatLoader } from "react-spinners";

// Utils
import { breakpoints, colors } from "./../../../util/consts";
import typography from "./../../../util/typography";

// MobX
import { signUpDataStore } from "./../../../mobx/stores/SignUpDataStore";

// Components
import PagesContainer, { PageGroup, Page } from "./../../../components/elements/PagesContainer";

// Pages
import General from "./General";
import Desc from "./Desc";
import Market from "./Market";
import Subscription from "./Subscription";
import Payment from "./Payment";
import PaymentNoAccount from "./PaymentNoAcc";
import FundPool from "./FundPool";
import SignUpOrg from './../../dialogs/AccountPageDialog/SignUpOrg'
import VerifyOrg from "./../../dialogs/AccountPageDialog/VerifyOrg";
import ChangeOrg from "./../../dialogs/AccountPageDialog/ChangeOrg"
import { rpc, metadata } from '../../../grpc';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';

export default class OrgCreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      expanded: false, 
      isLoggedIn: false, 
      indicator: false,
    };
  }

  async componentDidMount(){
    const req = new Empty();
    const res = await rpc.user.isUserLoggedIn(req, metadata());
    
    this.setState({isLoggedIn: res.toObject().isLoggedIn, indicator: true});
  }

  render() {
    return (
      <>
      { !this.state.indicator ? 
      <BeatLoader
        css={OverrideSpinner}
        size={15}
        color={colors.tertiary.main}
        loading={!this.state.indicator}
      /> :
      this.state.isLoggedIn ?
      <PagesContainer
        sidePanel={true}
        queryp="page"
        entryBlacklist={["desc", "market", "subscription",  "payment", "services", "fundpool"]}
        blockNotSeen={true}>
        <PageGroup label="Set Up">
          <Page name="general" label="General" component={General} />
          <Page name="desc" label="Description" component={Desc} />
          <Page name="subscription" label="Subscription" component={Subscription} />
          <Page name="payment" label="Payment Details" component={Payment} />
        </PageGroup>
        <PageGroup label="Fund Pool">
          <Page name="fundpool" showInTree={false} label="Fund Pool" component={FundPool} />
        </PageGroup>
      </PagesContainer> :  
          <PagesContainer
          sidePanel={true}
          queryp="page"
          entryBlacklist={["desc", "market", "subscription",  "payment", "services", "fundpool"]}
          blockNotSeen={true}>
          <PageGroup label="Registration">
          <Page name="SignUp" label="Information" component={SignUpOrg} />
          <Page name="Verify" label="Verification" component={VerifyOrg} />
          {/* <Page name="change" label="Change" component={ChangeOrg} /> */}
          </PageGroup>
          <PageGroup label="Set Up">
            <Page name="desc" label="Description" component={Desc} />
            <Page name="subscription" label="Subscription" component={Subscription} />
            <Page name="payment" label="Payment Details" component={PaymentNoAccount} />
          </PageGroup>
          <PageGroup label="Fund Pool">
            <Page name="fundpool" showInTree={false} label="Fund Pool" component={FundPool} />
          </PageGroup>
          </PagesContainer> 
       }
       </>
    );
  }
}

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  ${breakpoints["phone-only"]} {
    justify-content: space-evenly;
  }
`;

export const FormWide = styled.form`
  width: 100%;
  max-width: 800px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const ChoiceButton = styled.button`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: none;
  margin: 50px 50px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const HeaderTitle = styled.span`
  ${typography.heading5};
`;

export const HeaderSubtitle = styled.span`
  ${typography.body2};
  color: ${colors.surfaceText.medEmphasis};
  margin-top: 12px;
`;

export const ButtonText = styled.span`
  margin-top: 10px;
  font-size: 17px;
  letter-spacing: 0.25px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${colors.surfaceText.highEmphasis};
`;

export const ErrorText = styled.div`
  color: red;
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1em;
`;

export const OverrideSpinner = css`
display: block;
margin: 0 auto;
border-color: red;
`;
