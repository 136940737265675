import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { metadata, rpc } from "./../../grpc";
import {
  CreditCard,
  DeleteCreditCardRequest,
  ListCreditCardsRequest,
  SetPrimaryCardRequest
} from "./../../sdk/funds_pb";
import { TabContainer, TabHeader } from "../dialogs/AppointmentDialog";
import LargeDottedButton from "../LargeDottedButton";
import { CardRow } from "./CardRow";
import { NewCardForm } from "./NewCardForm";
import { Label } from "../elements/Label";
import { BookingClient } from "./../../sdk/bookings_pb";
import { ToastManager } from "react-toast-notifications";
import { toDate } from "./../../util/timestamp";
import { toastStore } from "./../../stores/toast-store";

interface Props {
  clientId: string;
  required?: boolean;
  selected?: CreditCard.AsObject["id"];
  onCardCreated?: () => void;
  onCardClick?: (card: CreditCard.AsObject) => void;
  dispatch?: any;
  toastManager?: ToastManager;
}

@observer
export class Cards extends React.Component<Props> {
  @observable
  private adding = false;

  @observable
  private cards = new Array<CreditCard.AsObject>();

  async componentDidMount() {
    await this.load();
  }

  private getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }

  private sortCardByLastUsed(): CreditCard.AsObject[] {
    return this.cards.sort((a: CreditCard.AsObject, b: CreditCard.AsObject) => {
      if (a.lastUsedDate && b.lastUsedDate) {
        return this.getTime(toDate(a.lastUsedDate!)) - this.getTime(toDate(b.lastUsedDate!));
      }
      return 0;
    });
  }

  private async load() {
    const { onCardCreated, onCardClick } = this.props;
    try {
      const req = new ListCreditCardsRequest();
      req.setOwner(this.props.clientId);
      req.setOwnerType(CreditCard.OwnerType.CLIENT);
      const res = await rpc.clientCards.list(req, metadata());
      this.cards = res.toObject().cardsList;
      this.cards = this.sortCardByLastUsed().reverse();
      // select the primary card, or the last used card
      if (!this.props.selected && this.cards.length > 0 && onCardClick) {
        if (this.cards.filter((card) => card.isPrimary === true).length > 0) {
          onCardClick(this.cards.filter((card) => card.isPrimary === true)[0]);
        } else {
          onCardClick(this.cards[0]);
        }
      }
      // Reload booking store
      if (onCardCreated) {
        onCardCreated();
      }
    } catch (err) {
      toastStore.grpcToastError(err);
    }
  }

  private async setPrimary(card: CreditCard.AsObject) {
    const { toastManager } = this.props;
    try {
      const req = new SetPrimaryCardRequest();
      req.setCardId(card.id);
      await rpc.clientCards.setPrimaryCard(req, metadata());
      await this.load();
      toastManager!.add("new credit card successfully set as primary", { appearance: "success" });
    } catch (err) {
      toastStore.grpcToastError(err);
    }
  }

  private async removeCard(card: CreditCard.AsObject) {
    const { toastManager } = this.props;
    try {
      const req = new DeleteCreditCardRequest();
      req.setCardId(card.id);
      await rpc.clientCards.delete(req, metadata());
      await this.load();
      toastManager!.add("credit card removed successfully", { appearance: "success" });
    } catch (err) {
      toastStore.grpcToastError(err);
    }
  }

  render() {
    return (
      <TabContainer>
        <TabHeader>
          <Label required={this.props.required}>Client's Cards</Label>
        </TabHeader>

        {this.cards.map((card) => (
          <CardRow
            key={card.id}
            card={card}
            selected={card.id === this.props.selected}
            onSetPrimary={() => this.setPrimary(card)}
            onRemove={() => this.removeCard(card)}
            onClick={() => {
              if (this.props.onCardClick) {
                this.props.onCardClick(card);
              }
            }}
            isUserCC={card.ownerType === CreditCard.OwnerType.USER ? true : false}
          />
        ))}

        {this.adding && (
          <NewCardForm
            ownerId={this.props.clientId}
            ownerType={CreditCard.OwnerType.CLIENT}
            onCardCreated={() => this.load()}
            onRequestClose={() => (this.adding = false)}
          />
        )}

        {!this.adding && (
          <LargeDottedButton onClick={() => (this.adding = true)}>ADD NEW CARD</LargeDottedButton>
        )}
      </TabContainer>
    );
  }
}
