import css from '@emotion/css';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import CreditCardInput from 'react-credit-card-input';
import * as Yup from "yup";
import Error from './../../components/form/Error';
import { createForm } from './../../forms/forms';
import { rpc, metadata } from './../../grpc';
import { CreateCreditCardRequest, CreateTokenRequest, RedeemTokenRequest, CreditCard } from './../../sdk/funds_pb';
import { webDosh, WebDoshCard, parseExpiry } from './../../thirdparty/webdosh';
import { LoadingIndicator } from './../../util/loading';
import { AccordianForm, ButtonContainer, AccordianContainer, NewHeader, ButtonHead, NoSpacingIconButton } from '../elements/AccordianElements';
import Input from '../form/Input';
import Label from '../form/Label';
import Section from '../form/Section';
import LoadingButton from '../LoadingButton';
import { testCreditCard, testCVC, testCardExpiry } from './../../util/validators';
import { CloseButton } from '../elements/CloseButton';
import { cardNumberToBrand } from './../../util/funds';
import { bookingStore } from './../../stores/booking-store';
import { toastStore } from './../../stores/toast-store';

interface Props {
  ownerId: string;
  ownerType: CreditCard.OwnerType;
  onCardCreated: (card: CreditCard.AsObject) => void;
  onRequestClose?: () => void;
}

const schema = Yup.object<WebDoshCard>({
  number: Yup.string().required().test('is-creditcard', 'must be a valid credit card number', testCreditCard),
  cvc: Yup.string().required().test('is-cvc', 'must be a valid cvc number', testCVC),
  expiry: Yup.string().required().test('is-expiry', 'must use the format MM/YY e.g. 01/12', testCardExpiry),
  holder: Yup.string().required(),
});

@observer
export class NewCardForm extends React.Component<Props> {

  @observable
  private indicator = new LoadingIndicator();

  onSubmit = async (card: WebDoshCard) => {
    await this.indicator.while(async () => {
      
      try {
        // create a client token that we can use to call webdosh
        const ctoken = await rpc.clientCards.createToken(new CreateTokenRequest(), metadata());

        // create a temporary card token
        const ttoken = await webDosh.createTemporaryToken(card, ctoken.getEndpoint(), ctoken.getToken());

        // exchange for a permanent token
        const redeem = new RedeemTokenRequest();
        redeem.setToken(ttoken.temporary_token);
        const rtoken = await rpc.clientCards.redeemToken(redeem, metadata());

        // create the card in booklyfe
        const expiry = parseExpiry(card.expiry);
        const newCardReq = new CreateCreditCardRequest();
        newCardReq.setLast4(card.number.slice(card.number.length - 4));
        newCardReq.setOwner(this.props.ownerId);
        newCardReq.setOwnerType(this.props.ownerType);
        newCardReq.setExpirationMonth(expiry.month);
        newCardReq.setExpirationYear(expiry.year);
        newCardReq.setToken(rtoken.getToken());
        newCardReq.setBrand(cardNumberToBrand(card.number));
        const newCardRes = await rpc.clientCards.create(newCardReq, metadata());
        // close
        this.props.onCardCreated(newCardRes.toObject())
        if (this.props.onRequestClose) {
          this.props.onRequestClose();
        }
      } catch(err){
        toastStore.grpcToastError(err);
        if (this.props.onRequestClose) {
          this.props.onRequestClose();
        }
      }
      
    });
  }

  render() {
    const Form = createForm<WebDoshCard>();
    return (
      <AccordianContainer>
        {this.props.onRequestClose &&
          <CloseButton onClick={this.props.onRequestClose} />
        }
        <NewHeader>
          <ButtonHead>New Card</ButtonHead>
        </NewHeader>
        <Form schema={schema} onSubmit={this.onSubmit} component={AccordianForm}>
          {({ fields, updateField, errors }) =>
            <>
              <Section>
                <Label>Card Name</Label>
                <Input
                  name="CardName"
                  type="text"
                  placeholder="Please input card name"
                  value={fields.holder}
                  onChange={(event: any) => updateField({ holder: event.currentTarget.value })}
                />
                {!!errors.holder && (
                  <Error>{errors.holder}</Error>
                )}
              </Section>
              <Section>
                <CreditCardInput
                  cardNumberInputProps={{
                    name: "Number",
                    value: fields.number,
                    onChange: (event: any) => updateField({ number: event.currentTarget.value}),
                  }}
                  cardExpiryInputProps={{
                    name: "Expiry",
                    value: fields.expiry,
                    onChange: (event: any) => updateField({ expiry: event.currentTarget.value }),
                  }}
                  cardCVCInputProps={{
                    name: "CVC",
                    value: fields.cvc,
                    onChange: (event: any) => updateField({ cvc: event.currentTarget.value }),
                  }}
                  containerStyle={{
                    width: "100%"
                  }}
                  fieldStyle={css`
                    font-size: 15.8px;
                    -webkit-letter-spacing: 0.15px;
                    -moz-letter-spacing: 0.15px;
                    -ms-letter-spacing: 0.15px;
                    letter-spacing: 0.15px;
                    text-align: left;
                    height: 44px;
                    width: 100%;
                    padding: 10px 16px;
                    border: 1px solid #d2d5e3;
                    border-radius: 4px;
                    outline: none;
                    color: rgba(44, 46, 60, 1);
                    -webkit-transition: border-color 0.2s ease;
                    transition: border-color 0.2s ease;
                  `}
                />
                {!!errors.number &&
                  <Error>{errors.number}</Error>
                }
                {!!errors.expiry &&
                  <Error>{errors.expiry}</Error>
                }
                {!!errors.cvc && (
                  <Error>{errors.cvc}</Error>
                )}
              </Section>
              <ButtonContainer>
                <LoadingButton
                  style={{
                    minWidth: 150
                  }}
                  loading={this.indicator.isLoading()}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Save
                </LoadingButton>
              </ButtonContainer>
            </>
          }
        </Form>
      </AccordianContainer>
    );
  }
}
