import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

export function dateToTimestamp(date: Date): Timestamp{
    var timeInt: number = Math.round(date.getTime() / 1000);
    return intToTimestamp(timeInt)
}

// this function will return the 12:00 AM of the passed in Date
export function setMidnightOfDate(date: Date): void {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
}

export function intToTimestamp(timeInt: number): Timestamp{
    var timestamp = new Timestamp()
    timestamp.setSeconds(timeInt)
    timestamp.setNanos(0)
    return timestamp   
}
