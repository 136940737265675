import { EntityStore } from './entity-store';
import { rpc, metadata } from '../grpc';
import { AccountRow, GetAccountTableRequest, ListAccountTableResponse, FiltersAccountTable } from '../sdk/accounts_table_pb';
import { observable, action } from 'mobx';
import { AccItemType } from '../sdk/account_items_pb';
class AccTableStore extends EntityStore<AccountRow.AsObject> {

  @observable
  private pageCount = 0;
  
  @observable
  private pageIndex = 0;
  
  @observable
  private loading = false;

  @observable
  private accType: Array<AccItemType> = [AccItemType.ACC_ITEM_TYPE_UNSPECIFIED];

  async loadFromLocation(
    filters: FiltersAccountTable,
    pageSize: number,
    pageIndex: number,
    ): Promise<ListAccountTableResponse.AsObject> {
    return new Promise(async (resolve, reject) => {
      const req = new GetAccountTableRequest();
      req.setPageSize(pageSize);
      req.setPageNo(pageIndex);
      req.setFilters(filters);
      try{
        const res = await rpc.accountTable.getBookings(req, metadata());
        const response = new ListAccountTableResponse().toObject();
        this.deleteAll();
        res.toObject().accountrowsList.map( row => {
          this.add(row);
          if (row.locationId === filters.getLocationId()){
            response.accountrowsList.push(row);
          }
        });
        response.totalRowCount = res.getTotalRowCount();
        resolve(response);
      }
      catch(e){
        this.deleteAll();
        reject(e);
      }
    });
  }

  async loadFromOrganisation(
    filters: FiltersAccountTable,
    pageSize: number,
    pageIndex: number,
    ): Promise<ListAccountTableResponse.AsObject> {
    return new Promise(async (resolve, reject) => {
      const req = new GetAccountTableRequest();
      req.setPageSize(pageSize);
      req.setPageNo(pageIndex);
      req.setFilters(filters);
      try{
        const res = await rpc.accountTable.getLyfePaymentsAndBookings(req, metadata());
        const response = new ListAccountTableResponse().toObject();
        this.deleteAll();
        res.toObject().accountrowsList.map( row => {
          this.add(row);
          if (row.organisationId === filters.getOrganisationId()){
            response.accountrowsList.push(row);
          }
        });
        response.totalRowCount = res.getTotalRowCount();
        resolve(response);
      }
      catch(e){
        this.deleteAll();
        reject(e);
      }
    });
  }

  async loadFromBooking(bookingId: string): Promise<AccountRow.AsObject> {
    return new Promise(async (resolve, reject) => {
      const req = new GetAccountTableRequest();
      const filters = new FiltersAccountTable();
      filters.setBookingId(bookingId);
      req.setFilters(filters);
      try{
        const res = await rpc.accountTable.getBookings(req, metadata());
        this.add(res.toObject().accountrowsList[0]);
        resolve(this.get(bookingId));
      }
      catch(e){
        reject(e);
      }
    });
  }

  getPageCount() {
    return this.pageCount;
  }

  @action
  setPageCount(newCount: number) {
    this.pageCount = newCount;
  }

  getPageIndex() {
    return this.pageIndex;
  }

  @action
  setPageIndex(newIndex: number) {
    this.pageIndex = newIndex;
  }

  getLoading() {
    return this.loading;
  }

  @action
  setLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  getAccType() {
    return this.accType;
  }

  @action
  setAccType(accType: AccItemType) {
    this.accType = [accType];
  }

  @action
  addAccType(accType: AccItemType) {
    this.accType.push(accType);
  }

}

export const accTableStore = new AccTableStore();


