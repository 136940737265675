import React, { Component } from "react";
import styled from "@emotion/styled";
import { colors } from "./../../util/consts";

const stdTypog = `
  font-size: 15.8px;
  letter-spacing: 0.15px;
`;

const TextAreaStyled = styled.textarea`
  ${({ typo })=>typo||stdTypog}
  text-align: left;
  min-height: 44px;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid ${colors.primary[100]};
  border-radius: 4px;
  outline: none;
  line-height: 1.15;
  color: ${colors.surfaceText.highEmphasis};
  background: #fff;
  resize: none;
  &:disabled {
    ${({ regularText })=>(!regularText ?
      "opacity: 0.6;" : `
      background-color: white;
      border: none;
      padding: 0;
    `)}
  }
  &:hover {
    background: #fff;
  }
  &:focus {
    border-color: ${colors.primary.main};
  }

  &::placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::placeholder-shown {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-webkit-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-ms-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  transition: border-color 0.2s ease;
`;


const CheckDiv = styled.div`
  ${({ typo })=>typo||stdTypog}
  poisition: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0px;
  visibility: hidden;
  overflow: hidden;
  padding: 0px 16px;
  line-height: 1.15;
  text-align: left;
  pointer-events: none;
  &:disabled {
    background: #fff;
    border: none;
    padding: 0;
  }
`;

export default class TextArea extends Component {
  ref = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      height: !props.disabled ? 200 : 25
    };
  }

  componentDidMount() {
    this.updateSize(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value || prevProps.disabled !== this.props.disabled) {
      this.updateSize(this.props);
    }
  }

  updateSize(props) {
    if (
      this.ref.current &&
      this.state.height !== this.ref.current.scrollHeight
    ) {
      requestAnimationFrame(()=>this.setState({ height: Math.max(this.ref.current.scrollHeight, !props.disabled ? 200 : 25) }));
    }
  }

  render() {
    const { typo, disabled, maxHeight, regularText } = this.props;
    const mh = maxHeight > 0 ? maxHeight : 9999999;
    return (
      <React.Fragment>
        <TextAreaStyled
          {...this.props}
          style={{ ...this.props.style, height: Math.min(this.state.height, mh)+((!disabled || !regularText) ? 25 : 5) }}
        />
        <CheckDiv ref={this.ref} disabled={this.props.disabled} typo={typo}>
          {(this.props.value||"").split("\n").map((v, k)=>
            <React.Fragment key={k}>
              <span>{v}</span>
              <br/>
            </React.Fragment>
          )}
        </CheckDiv>
      </React.Fragment>
    );
  }
}
