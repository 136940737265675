import React from "react";
import styled from "@emotion/styled";
import $ from 'jquery';

// Utils
import { breakpoints, colors, hexToRgba } from "./../../../util/consts";

// Components
import Button from "./../../../components/Button";
import NewPassword from "./NewPassword"
import ReSendInvitation from "./ReSendInvitation"
import PagesContainer, { PageGroup, Page } from "./../../../components/elements/PagesContainer";
import CreditCardRedirect from "./CreditCardRedirect"
import ScrollableAnchor from 'react-scrollable-anchor'

// Redux
import apiService from "./../../../redux/services/api";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import * as GlobalActions from "./../../../redux/features/global/actions";

//grpc
import { rpc, metadata } from "../../../grpc";
import { ClickedURLRequest, ClickedURLResponse } from '../../../sdk/user_invite_pb';
import { SendEmailRequest, SendEmailResponse } from '../../../sdk/user_invite_pb';


class EmailSignUp extends React.Component {

  constructor(props){
    super(props);
  }



  
  render(){
    return (
      <Container id='menu'>
        <FullScreenSection >
          <PagesContainer
          sidePanel={false}
          queryp="page"
          blockNotSeen={false}
          extraProps={{'historys': this.props.history}}
          >
          <PageGroup label="Set Up">
              <Page name="resend"  label="resend" component={ReSendInvitation} />
              <Page name="password"  label="password" component={NewPassword} />
              <Page name="ccards"  label="ccards" component={CreditCardRedirect} />
          </PageGroup>  
        </PagesContainer>
        </FullScreenSection>
      </Container>
    );
  }
  
}

export default EmailSignUp;

const Container = styled.main`
    width: 100%;
`;

const FullScreenSection = styled.div`
  margin: auto;
  width: 80%;
  height: calc(100vh - 80vh);
  // min-height: 400px;
  min-height: 900px;
  margin-top: 20px;

  @media only screen and (min-width: 768px) {
    padding-top: 30px;
    margin: auto;
    width: 50%;
    height: calc(100vh - 40px);
    max-height: 900px;
    display: flex;
    border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
}
`;


