import React, { PureComponent } from "react";

// importing components
import ClientAccordian from "./ClientAccordian";
import LargeDottedButton from "./../../../components/LargeDottedButton";
// import { AddButton } from "./../../../components/elements/AccordianElements";

import { TabContainer, TabHeader } from "./index";

export default class Clients extends PureComponent {
  state = {
    adding: false
  };
  addCard = (e) => {
    this.setState({ adding: true });
  };
  closeNew = () => {
    this.setState({ adding: false });
  };

  render() {
    const { booking, setClient } = this.props;
    const allowNew = booking.Status === "Created" && booking.Clients.length < booking.MaxGroupSize;
    return (
      <TabContainer>
        <TabHeader>Group Clients</TabHeader>
        {(booking.Clients || []).map((cl) => (
          <ClientAccordian
            key={cl.Client.ID}
            id={cl.Client.ID}
            client={cl}
            booking={booking}
            setClient={setClient}
          />
        ))}
        {this.state.adding || !allowNew ? null : (
          <LargeDottedButton onClick={this.addCard}>ADD CLIENT</LargeDottedButton>
        )}
        {!this.state.adding ? null : (
          <ClientAccordian
            key="newcl"
            id="newcl"
            client={null}
            booking={booking}
            closeNew={this.closeNew}
            setClient={setClient}
          />
        )}
      </TabContainer>
    );
  }
}
