import { createAction } from "typesafe-actions";

export interface GlobalSetUserContext {
  OrgID: string;
  LocID: string;
}

// Actions
export const setUserContext = createAction("Global/SetUserContext", (resolve) => {
  return (orgID: string, locID: string) => resolve({ OrgID: orgID, LocID: locID } as GlobalSetUserContext);
});
