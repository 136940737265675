import * as React from 'react';
import styled from "@emotion/styled";
import { FlexProps, Gutter } from './FlexProps';

export const FlexBox = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};

  margin-left: -${({ gutter }) => gutterToSize(gutter)}px;
  margin-right: -${({ gutter }) => gutterToSize(gutter)}px;

  > * {
    margin: ${({ gutter }) => gutterToSize(gutter)}px;
  }
`;

function gutterToSize(gutter?: Gutter) {
  switch (gutter) {
    case undefined:
    case Gutter.none:
      return 0;
    case Gutter.small:
      return 5;
    case Gutter.medium:
      return 10;
    case Gutter.large:
      return 20;
    default:
      console.warn('unknown gutter value', gutter);
      return 0;
  }
}
