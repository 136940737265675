import { TestContext } from 'yup';

// Helper function to create a promise that returns the data in
// a set amount of time.
export function delay<T>(data: T, timeMS: number): Promise<T> {
  return new Promise((resolve) => setTimeout(() => resolve(data), timeMS));
}

// Helper function for id generation (128bit hex encoded).
const ALNUM_CHARS: string = "0123456789ABCDEF";
export function generateID(): string {
  const ar = new Uint8Array(16);
  if (window.crypto.getRandomValues) {
    window.crypto.getRandomValues(ar);
  } else {
    for (let i = 0; i < 16; i++) {
      ar[i] = Math.floor(255 * Math.random());
    }
  }
  let id = "";
  for (let i = 0; i < 16; i++) {
    const lhb = ar[i] & 0xf;
    const uhb = (ar[i] >> 4) & 0xf;
    id += ALNUM_CHARS[lhb] + ALNUM_CHARS[uhb];
  }
  return id;
}

export function fileChunk(file: File, chunkSize: number, from: number = 0): Promise<Blob | null> {
  const totalSize = file.size; // Size of the file in bytes.
  let totalRead = 0; // Number of bytes read from the file.
  return new Promise((resolve) => {
    if (from < totalSize) {
      const blob = file.slice(from, Math.min(totalRead + chunkSize, totalSize));
      totalRead = blob.size;
      resolve(blob);
    } else {
      resolve(null);
    }
  });
}

// converts string of format "09:00" to [9, 0], string to [numbers]
export function splitTime(timeString: string) {
  const [hour, minute] = timeString.split(":");

  const parsedHour = parseInt(hour, 10);
  const parsedMinute = parseInt(minute, 10);
  if (isNaN(parsedHour)) {
    throw new Error("minute is not a number.");
  } else if (isNaN(parsedMinute)) {
    throw new Error("hour is not a number.");
  }

  return [parsedHour, parsedMinute];
}

export function formatTime(timeString: string) {
  const [hours, minutes] = splitTime(timeString);
  let hoursTHT = Math.floor(hours % 12);
  hoursTHT = hoursTHT === 0 ? 12 : hoursTHT;
  const suffix = hours >= 12 ? "pm" : "am";
  const min = minutes < 10 ? "0" + minutes : minutes;
  return hoursTHT.toString() + ":" + min + suffix;
}

export function toPriceString(val: number, isInCents: boolean) {
  if (!isInCents) {
    const dollars = Math.floor(val);
    const cents = Math.round(val * 100) % 100;
    return `${dollars}.${cents < 10 ? "0" : ""}${cents}`;
  } else {
    const cs = val % 100;
    const dl = (val - cs) / 100;
    return `${dl}.${cs < 10 ? "0" : ""}${cs}`;
  }
}

export function subVarToPercentage(v: number): string {
  const varC = v % 100;
  const varT = Math.floor(v / 100);
  return `${varT}.${varC < 10 ? "0" : ""}${varC}%`;
}

export function isOfType(child: any, type: any): boolean {
  if (!child) { return false; }
  if (process.env.NODE_ENV === "production") {
    return child.type === type;
  } else {
    if (typeof child.type === 'undefined'){ return false; }
    return child.type.prototype instanceof type;
  }
}

export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

export function getYearFromTwo(aCen: number): number {
  const curYear = new Date().getFullYear();
  const fromBegCen = curYear % 100;
  const begCen = curYear - fromBegCen;

  if (aCen < fromBegCen) {
    return begCen + 100 + aCen;
  } else {
    return begCen + aCen;
  }
}

// Check if we are on a mobile device.
export function isMobileDevice(): boolean {
  return (
    typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1
  );
}

const abnWeights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
export function validABN(abn: string): boolean {
  if (abn === "" || abn === null || abn === undefined) {
    return true;
  }
  try {
    const reVal = abn.replace(/\s+/g, "");
    if (reVal.length !== 11) {
      return false;
    }
    let sum = 0;
    for (let i = 0; i < reVal.length; i++) {
      const dig = parseInt(reVal[i]);
      const val = i === 0 ? dig - 1 : dig;
      sum += val * abnWeights[i];
    }
    if (sum % 89 !== 0) {
      return false;
    }
  } catch (err) {
    return false;
  }
  return true;
}
