/** @jsx jsx */
import { Component } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { css, jsx } from "@emotion/core";

// Utils
import {
  colors,
  breakpoints
  // hexToRgba
} from "../../../util/consts";
import typography from "../../../util/typography";

// Components
import ButtonBase from "../../ButtonBase";
 
interface Props {
  resource: any
}

export class ProviderSidebar extends Component<Props> {

  render() {
    return (
      <SidebarContainer>
        <LinkButton as={Link} to={"/bookings/locs/" + this.props.resource.ID} target="_blank">
          <SidebarButtonText
            css={css`
              font-weight: 600;
              text-align: center;
            `}
          >
            Book Appointment
          </SidebarButtonText>
        </LinkButton>
      </SidebarContainer>
    );
  }
}

const SidebarContainer = styled.aside`
  position: sticky;
  background-color: ${colors.surface.light};
  flex-shrink: 0;

  ${breakpoints["tablet-and-down"]} {
    height: 57px;
    flex-basis: 100%;
    bottom: 0;
    border-top: 1px solid #f3f5fd;
    margin: 0 -24px -24px;
  }

  ${breakpoints["laptop-up"]} {
    // height: 501px;
    height: 42px;
    flex-basis: 351px;
    top: 93px;
    border: 1px solid #f3f5fd;
    border-radius: 4px;
    box-shadow: 0 1px 6px 0 rgba(44, 46, 60, 0.25);
  }
`;

const SidebarButtonText = styled.span`
  ${typography.caption};
  color: ${colors.whiteText.highEmphasis};
  line-height: 18px;
  text-transform: capitalize;
`;

const LinkButton = styled(ButtonBase)`
  height: 40px;
  width: 100%;
  // border-radius: 0 0 3px 3px;
  border-radius: 3px;
  background-color: ${colors.primary.main};
  color: ${colors.whiteText.highEmphasis};
  padding: 11px;
  text-decoration: none;
`;
