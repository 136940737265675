import React, { Component } from "react";
import styled from "@emotion/styled";
import { Link, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { format, addWeeks, isSameDay, addDays } from "date-fns";

// redux
import { getResource, getResourceList } from "./../../../redux/features/resources/helpers";

// Utils
import { colors, breakpoints } from "./../../../util/consts";
import typography from "./../../../util/typography";

// Components
import ResourceListFetch from "./../../../components/elements/ResourceListFetch";
import Button from "./../../../components/Button";
import Card from "./Card";
import KeyboardArrowRight from "./../../../components/icons/KeyboardArrowRight";
import SearchInput from "./../../../components/elements/Header/SearchInput";
// import ResourceRender from "./../../../components/elements/ResourceRender";
// import ResourceListRender from "./../../../components/elements/ResourceListRender";
// import CardWithTitle from "./CardWT";
// import WelcomeBlock from "../WelcomeBlock";

// Misc
import image0 from "./../../../static/rawpixel-797129-unsplash.jpg";
import imageTop from "./../../../static/booklye-woman-title-image.jpg";
import gpIcon from "./../../../static/icons/files_icons8-stethoscope-50.svg";
import physioIcon from "./../../../static/icons/files_icons8-physical-therapy-50.svg";
import psychologistIcon from "./../../../static/icons/files_icons8-brain-50.svg";
import dentistIcon from "./../../../static/icons/files_icons8-tooth-50.svg";
import chiropractor from "./../../../static/icons/files_icons8-posture-50.svg";
import { bookingStore } from "./../../../stores/booking-store";

const icons = [
  { serviceName: "GP", iconUrl: gpIcon },
  { serviceName: "Physio", iconUrl: physioIcon },
  { serviceName: "Psychologist", iconUrl: psychologistIcon },
  { serviceName: "Dentist", iconUrl: dentistIcon },
  { serviceName: "Chiropractor", iconUrl: chiropractor }
];

class Landing extends Component {
  state = {
    lat: undefined,
    lng: undefined
  };

  componentDidMount() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    }
  }

  handleBannerButtonClick = () => {
    this.props.history.push("/listyourbusiness");
  };

  render() {
    const { lat, lng } = this.state;
    const { auth, userContext, listData } = this.props;
    const isLoggedIn = auth && auth.Type === "user" && auth.UserID;

    if (isLoggedIn && userContext.LocID) {
      return <Redirect to={{ pathname: `/admin/locs/${userContext.LocID}/dashboard` }} />;
    } else if (isLoggedIn && userContext.OrgID) {
      return <Redirect to={{ pathname: `/admin/orgs/${userContext.OrgID}/locations` }} />;
    }

    const newList = listData.map((resource) => {
      const { FreeTimes } = resource;

      const MAX_NUM_SESSIONS = 7;

      const Filter = FreeTimes.reduce(
        (accumulator, currentValue) => {
          const numRemainingSpaces = MAX_NUM_SESSIONS - accumulator.numSessions;

          if (numRemainingSpaces > 0 && currentValue.Times.length > 0) {
            const newSessions = {
              Date: currentValue.Date,
              Times: currentValue.Times.slice(0, numRemainingSpaces)
            };

            const returnValue = {
              ...accumulator,
              sessions: [...accumulator.sessions, newSessions],
              numSessions: accumulator.numSessions + newSessions.Times.length,
              totalSessions: accumulator.totalSessions + currentValue.Times.length
            };

            if (numRemainingSpaces === MAX_NUM_SESSIONS) {
              returnValue.NextAvailDate = new Date(currentValue.Date + " " + currentValue.Times[0]);
              returnValue.NextAvailString = isSameDay(new Date(currentValue.Date), new Date()) // eslint-disable-line no-nested-ternary
                ? "Today"
                : isSameDay(new Date(currentValue.Date), addDays(new Date(), 1))
                ? "Tomorrow"
                : "Next 7 days";
            }

            return returnValue;
          }

          return {
            ...accumulator,
            totalSessions: accumulator.totalSessions + currentValue.Times.length
          };
        },
        {
          sessions: [],
          numSessions: 0,
          totalSessions: 0,
          NextAvailDate: null,
          NextAvailString: ""
        }
      );

      return {
        ...resource,
        Filter
      };
    });

    return (
      <main style={{ maxWidth: "100vw" }}>
        {/* {!isLoggedIn && <WelcomeBlock />} */}
        <Content>
          {/* The minus margin-top is override the default attribute in the Block component */}
          <Block style={{ marginTop: "-65px" }}>
            <TopBannerLeft>
              <TopBannerLeftWrapper>
                <TopBannerLargeTitle style={{ marginBottom: "5px" }}>
                  Book a doctor, physio, &nbsp; &nbsp;
                </TopBannerLargeTitle>
                <TopBannerLargeTitle>psychologist and more...</TopBannerLargeTitle>
                <TopBannerSmallTitleDiv>
                  <TopBannerSmallTitle>
                    Over 100,000 appointments to choose from, Australia-wide
                  </TopBannerSmallTitle>
                </TopBannerSmallTitleDiv>
                <div style={{ marginLeft: "-24px" }}>
                  <Route component={SearchInput} />
                </div>
                <SearchIconsContainer>
                  {icons.map((i, index) => (
                    // we can use index as key props because the icons is const and never changed
                    <ServiceIcons key={index} serviceName={i.serviceName} iconUrl={i.iconUrl} history={this.props.history}></ServiceIcons>
                  ))}
                </SearchIconsContainer>
              </TopBannerLeftWrapper>
            </TopBannerLeft>

            <TopBannerRight>
              <TopBannerRightImage />
            </TopBannerRight>
          </Block>
          <Block>
            <Banner>
              <Image />
              <BannerContent>
                <BannerTitle>
                  Connect with clients, grow and manage your business more effectively
                </BannerTitle>
                <Button variant="contained" onClick={this.handleBannerButtonClick}>
                  <ButtonTextSpacing>List your business</ButtonTextSpacing>
                </Button>
              </BannerContent>
            </Banner>
          </Block>
          <Block>
            <div>
              <BlockTitle>Providers near you</BlockTitle>
              <BlockSubtitle>Explore and find your local providers</BlockSubtitle>
            </div>
            <CardContainer>
              <ResourceListFetch
                noLoginReq={true}
                type="LandingPageLocations"
                listctx={{
                  Lat: lat,
                  Lng: lng,
                  StartDate: format(new Date(), "yyyy-MM-dd"),
                  EndDate: format(addWeeks(new Date(), 1), "yyyy-MM-dd"),
                  Limit: 20
                }}
                force
              />
              {newList.length > 0 ? (
                newList.map(
                  (resource) =>
                    resource &&
                    typeof resource !== "undefined" && (
                      <Card resource={resource} key={resource.ID} />
                    )
                )
              ) : (
                <BlockSubtitle style={{ margin: 5 }}>Loading...</BlockSubtitle>
              )}
              {/* <ResourceListRender
                type="LandingPageLocations"
                listctx={{ lat, lng }}
                compSuccess={this.renderCardsList}
              /> */}
            </CardContainer>
            <BlockMore to="/providers">
              Explore more providers <KeyboardArrowRight fill={colors.secondary.main} />
            </BlockMore>
          </Block>
          {/* <Block>
            <div
              css={css`
                margin-bottom: 15px;
              `}
            >
              <BlockTitle>Top providers</BlockTitle>
              <BlockSubtitle>Explore the top providers for your needs</BlockSubtitle>
            </div>
            <BlockContent>
              <TitleCardContainer>
                {images2.map((i) => (
                  <CardWithTitle card={i} size="large" key={i.image} />
                ))}
              </TitleCardContainer>
            </BlockContent>
          </Block>
          <Block>
            <div
              css={css`
                margin-bottom: 15px;
              `}
            >
              <BlockTitle>Providers for wellness</BlockTitle>
            </div>
            <BlockContent>
              <TitleCardContainer>
                {images3.map((i) => (
                  <CardWithTitle card={i} key={i.image} />
                ))}
              </TitleCardContainer>
            </BlockContent>
          </Block> */}
        </Content>
      </main>
    );
  }
}

function mapStateToProps(state, props) {
  const { auth, lists, resources, global } = state;
  const list = getResourceList(lists, "LandingPageLocations", { Limit: 20 });
  return {
    auth,
    userContext: global.UserContext,
    list,
    listData: (list.IDs || []).map((lid) => getResource(resources, "Location", lid, true))
  };
}

// (state) => ({
// auth: state.auth,
// userContext: state.global.UserContext
// })

class ServiceIcons extends Component {
  handleClick = () => {
   const { serviceName } = this.props;
   this.props.history.push(`/providers?search=${serviceName}`);
  }
  render() {
    const { iconUrl, serviceName } = this.props;
    return (
      <ServerIconContainer onClick={this.handleClick}>
        <img src={iconUrl} style={{ heigh: "30px", width: "30px" }}></img>
        <span style={{ fontSize: "14px", marginTop: "8px" }}>{serviceName}</span>
      </ServerIconContainer>
    );
  }
}

export default connect(mapStateToProps)(Landing);

const Banner = styled.div`
  width: 100%;
  height: 385px;
  padding: 16px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 4px;

  ${breakpoints["tablet-up"]} {
    padding: 60px;
  }
`;

const ServerIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  cursor: pointer;
`;

const TopBannerLeftWrapper = styled.div`
  marginRight: "70px"
  ${breakpoints["phone-only"]}{
    marginRight: 0px;
  }
`;

const TopBannerLeft = styled.div`
  width: 50%;
  float: left;
  height: 810px;
  margin-bottom: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${breakpoints["phone-only"]}{
    width: 100%;
    height: 100%;
  }
`;

const TopBannerRight = styled.div`
  width: 50%;
  float: left;
  height: 810px;
  margin-bottom: 65px;

  ${breakpoints["phone-only"]}{
    display: none;
  }
`;

const TopBannerLargeTitle = styled.h2`
  ${typography.heading4};
  color: ${colors.blackText.highEmphasis};
  font-size: 40.39px;
  font-weight: normal;
`;

const TopBannerSmallTitleDiv = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;
const SearchIconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 30px;
`;
const TopBannerSmallTitle = styled.span`
  ${typography.heading6};
  ${typography.lighter};
`;

const TopBannerRightImage = styled.div`
  background-image: url(${imageTop});
  z-index: -1;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
`;

const BannerContent = styled.div`
  max-width: 435.5px;
`;

const BannerTitle = styled.h2`
  ${typography.heading4};
  color: ${colors.whiteText.highEmphasis};
  font-weight: normal;
  margin-bottom: 24px;
`;

const ButtonTextSpacing = styled.span`
  margin: 0 12px;
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(${image0});
  background-position: center;
  background-size: cover;
  filter: contrast(60%);
  border-radius: 4px;
`;

const Content = styled.div`
  padding: 0 24px;
  margin: 0 auto;
  max-width: 1440px;
`;

const Block = styled.div`
  margin-top: 65px;
  margin-bottom: 65px;
`;

// const BlockContent = styled.div`
//   ${breakpoipsychologistnts["tablet-and-down"]} {
//     margin-left: -24px;
//     margin-right: -24px;
//     overflow-x: scroll;
//   }
// `;

const CardContainer = styled.div`
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
  flex-wrap: wrap;
`;

// const TitleCardContainer = styled.div`
//   display: flex;
//   margin: 0 14px;

//   ${breakpoints["laptop-up"]} {
//     margin-left: -10px;
//     margin-right: -10px;
//   }
// `;

const BlockTitle = styled.h3`
  ${typography.heading6};
  font-weight: bold;
`;

const BlockSubtitle = styled.span`
  ${typography.subtitle1};
  ${typography.lighter};
`;

const BlockMore = styled(Link)`
  ${typography.subtitle1};
  color: ${colors.secondary.main};
  text-decoration: none;
  font-weight: bold;
  display: flex;
  padding-top: 20px;
`;
