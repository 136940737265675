import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const sizeProps = {
  small: {
    width: "20",
    height: "20",
  },
  medium: {
    width: "24",
    height: "24",
  },
  large: {
    width: "28",
    height: "28",
  }
};

export default class KeyboardArrowLeft extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"])
  }

  static defaultProps = {
    size: "medium"
  }

  render() {
    const { size, ...otherProps } = this.props;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...sizeProps[size]} {...otherProps}>
        <path d="M14.71 15.88L10.83 12l3.88-3.88c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L8.71 11.3c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .38-.39.39-1.03 0-1.42z"/>
      </svg>
    );
  }
}
