import React, { Component } from "react";
import styled from "@emotion/styled";
import Downshift from "downshift";
import debounce from "lodash.throttle";

import typography from "./../../util/typography";
import { colors, hexToRgba, breakpoints } from "./../../util/consts";

import Input from "./../../components/form/Input";
import ResourceListRender from "./../../components/elements/ResourceListRender";
import ResourceListFetch from "./../../components/elements/ResourceListFetch";
import ResourceRender from "./../../components/elements/ResourceRender";

import SearchIcon from "./../../components/icons/Search";

// We assume the items do not change.
export default class ResourceSearcher extends Component {
  inputRef = React.createRef();

  constructor(props) {
    super(props);
    this.lastItems = null;
    this.state = {
      curListSearch: ""
    };
  }
  handleDownshiftSelect = (selection, downshift) => {
    const { onSelect } = this.props;
    if (selection !== null) {
      onSelect&&onSelect(selection);
      if (this.inputRef.current) {
        this.inputRef.current.value = "";
      }
      downshift.clearSelection();
    }
  };
  handleInputChange = debounce((value) => {
    this.setState({ curListSearch: value });
  }, 250);
  render() {
    const { curListSearch } = this.state;
    const {
      id,
      name,
      placeholder,
      disabled,
      listType,
      resourceType,
      listctx,
      component,
      itemToString
    } = this.props;
    return (
      <Downshift
        onSelect={this.handleDownshiftSelect}
        itemToString={itemToString}
      >
        {({
          getInputProps,
          getItemProps,
          getRootProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          openMenu
        }) => {
          const listCtx = { ...listctx, Search: curListSearch||undefined };
          const { onChange, ...otherInputProps } = getInputProps();
          return (
            <Container isOpen={isOpen} disabled={disabled} {...getRootProps()}>
              <ResourceListFetch type={listType} listctx={listCtx} force />
              <InputContainer>
                <Input
                  ref={this.inputRef}
                  type="text"
                  id={id}
                  name={name}
                  placeholder={placeholder}
                  disabled={disabled}
                  {...otherInputProps}
                  onChange={(e)=>{
                    this.handleInputChange(e.target.value);
                    onChange(e);
                  }}
                  onFocus={() => openMenu()}
                />
                <InputIconContainer>
                  <SearchIcon size="medium" />
                </InputIconContainer>
              </InputContainer>
              {isOpen && (
                <DropContainer {...getMenuProps()}>
                  <ResourceListRender
                    type={listType}
                    listctx={listCtx}
                    compSuccess={({ list: ll }) =>
                      (ll.IDs || []).slice(0, 5).map((nid, i) => (
                        <ResourceRender
                          key={nid}
                          type={resourceType}
                          ids={nid}
                          denorm={true}
                          compSuccess={
                            (rprops)=>
                              <DropItem
                                key={nid}
                                {...getItemProps({ i, item: rprops.resource })}
                                highlighted={highlightedIndex === i}
                              >
                                {React.createElement(component, rprops)}
                              </DropItem>
                          }
                        />
                      ))
                    }
                  />
                </DropContainer>
              )}
            </Container>
          );
        }}
      </Downshift>
    );
  }
}

const fundMedicareSearcher = React.memo(({ resource: mcs }) =>
  <React.Fragment>
    <DropItemHeader>Service {mcs.Code}: </DropItemHeader>
    <DropItemText>{mcs.Description}</DropItemText>
  </React.Fragment>
);

export const FundMedicareServiceSearcher = React.memo(
  ({ location, showHidden, ...otherProps }) => (
    <ResourceSearcher
      {...otherProps}
      listType="AUMedicareServiceSelector"
      resourceType="AUMedicareService"
      listctx={{ Name: otherProps.name }}
      component={fundMedicareSearcher}
      itemToString={(mcs)=>mcs&&mcs.Code}
      stringToID={(v)=>v}
    />
  )
);

const Container = styled.div`
  position: relative;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 450px;
  border: 1px solid ${colors.primary[100]};
  border-radius: 4px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  height: 44px;
  display: flex;
  align-items: center;
  &:focus-within {
    box-shadow: 0 2px 4px 0 rgba(44, 46, 60, 0.15);
  }
  ${breakpoints["laptop-up"]} {
    flex-grow: 0;
  }
  ${({ isOpen }) => (!isOpen ? "" : "border-radius: 4px 4px 0px 0px")};
  ${({ disabled }) => (!disabled ? "" : "border: none; padding: 0")};
`;

const DropContainer = styled.ul`
  position: absolute;
  top: calc(100% + 1px);
  left: -1px;
  max-height: 260px;
  overflow-y: scroll;
  width: calc(100% + 2px);
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border-left: 1px solid ${colors.primary[100]};
  border-right: 1px solid ${colors.primary[100]};
  border-bottom: 1px solid ${colors.primary[100]};
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0 2px 4px 0 rgba(44, 46, 60, 0.15);
  z-index: 5;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 44px;
`;

const InputIconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  opacity: 0.3;
`;

const DropItem = styled.li`
  padding: 14px;
  align-items: center;
  background-color: ${({ highlighted }) =>
    (!highlighted ? "none" : hexToRgba(colors.primary.main, 0.05))};
  cursor: pointer;
`;

const DropItemHeader = styled.span`
  ${typography.overline};
  letter-spacing: inherit;
  color: ${colors.secondary[800]};
  font-weight: bold;
`;

const DropItemText = styled.span`
  ${typography.overline};
  letter-spacing: inherit;
`;
