/** @jsx jsx */
import { Component } from "react";
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";

// Utils
import { colors } from "./../util/consts";
import typography from "./../util/typography";

// Components
import ButtonBase from "./../components/ButtonBase";

class LargeDottedButton extends Component {
  render() {
    const { children, ...otherProps } = this.props;
    return (
      <ButtonBase
        css={css`
          width: 100%;
          height: 71px;
          border: 1px dashed ${colors.surfaceText.lowEmphasis};
          border-radius: 4px;
          background-color: transparent;
        `}
        {...otherProps}
      >
        <Text>{children}</Text>
      </ButtonBase>
    );
  }
}

export default LargeDottedButton;

const Text = styled.span`
  ${typography.subtitle2};
  font-weight: 600;
  letter-spacing: 0.22px;
  color: ${colors.surfaceText.lowEmphasis};
  text-transform: uppercase;
`;
