import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

import * as ResourceActions from "./../../redux/features/resources/thunkactions";
import * as ReduxDialogs from "./../../redux/features/dialogs";
import { parseFormError } from "./../../util/form";

import { colors, hexToRgba } from "./../../util/consts";
import typography from "./../../util/typography";

// Utils
import { breakpoints } from "./../../util/consts";

import Dialog from "./Dialog";
import ButtonBase from "./../../components/ButtonBase";

import LoadingIcon from "./../../components/icons/Loading";

class BatchChargeDialog extends React.Component {
  state = {
    processed: -1,
    count: 0
  };

  errorCount = 0;

  successCount = 0;

  processCharges = async () => {
    const { data: { booking, cb }, dispatch } = this.props;
    const clients = booking.Clients.filter((v)=>
      v.Status !== "Cancelled" && v.PaymentStatus !== "Completed"
    );
    try {
      if (booking.Status === "Created") {
        await dispatch(
          ResourceActions.action(booking, "Action", {
            Action: "StartProcessing"
          })
        );
      }
    } catch (err) {
      parseFormError(err, {}, () => {}, this.props);
      cb(false, 0);
      this.closeDialog();
      return;
    }
    this.setState({ processed: 0, count: clients.length });
    for (let i = 0; i < clients.length; i++) {
      const cl = clients[i];
      try {
        await dispatch(
          ResourceActions.action(booking, "ActionClient", {
            Client: cl.Client.ID,
            Action: "ProcessPayment"
          })
        );
        this.successCount++;
      } catch (err) {
        this.errorCount++;
      }
      this.setState({ processed: this.successCount+this.errorCount });
    }
    try {
      await dispatch(ResourceActions.action(booking, "Fetch"));
    } catch (err) {
      parseFormError(err, {}, () => {}, this.props);
      cb(false, 0);
      this.closeDialog();
      return;
    }
    cb(true, this.errorCount);
    this.closeDialog();
  };

  closeDialog = () => {
    const { dispatch, dialogId } = this.props;
    dispatch(ReduxDialogs.actions.close(dialogId));
  };

  componentDidMount() {
    this.processCharges();
  }

  render() {
    const { processed, count } = this.state;
    const { data } = this.props;
    return (
      <Dialog
        dialogId={this.props.dialogId}
        trigger={data.trigger}
        overideCloseDialog={this.closeDialog}
        noClose
      >
        <Container>
          <LoadingIcon width={30} height={30} color={colors.primary.main} />
          <InfoText>
            {processed < 0 ? "Initialising..." : `Processed ${processed}/${count} Clients`}
          </InfoText>

          <ButtonContainer>
            <LargeBottomButton isPrimary={true} onClick={this.closeDialog}>
              <LargeButtonText>Close</LargeButtonText>
            </LargeBottomButton>
          </ButtonContainer>
        </Container>
      </Dialog>
    );
  }
}

const Container = styled.div`
  position: relative;
  padding: 24px;
  padding-bottom: 74px;

  ${breakpoints["tablet-up"]} {
    padding: 42px 90px;
    padding-bottom: 82px;
    max-width: 700px;
  }

  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
`;

const InfoText = styled.span`
  ${typography.subtitle1};
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: ${colors.primary.main};
  margin-top: 20px;
`;

const LargeButtonText = styled.span`
  ${typography.heading6};
  font-weight: 600;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: ${colors.whiteText.highEmphasis};
`;

const LargeBottomButton = styled(ButtonBase)`
  flex: 1 0 100px;
  height: 100%;
  background-color: ${({ isPrimary }) => (isPrimary ? colors.primary.main : colors.secondary.main)};
  transition: opacity 0.2s ease;

  &:hover:enabled {
    opacity: 0.95;
  }

  &:focus {
    opacity: 0.95;
  }

  &:disabled {
    background-color: ${hexToRgba(colors.secondary.main, 0.12)};
  }
`;

export default compose(
  connect(),
  withToastManager
)(BatchChargeDialog);
