import React, { PureComponent } from "react";
import {
  AccordianContainer,
  ButtonHead,
  NewHeader,
  NoSpacingIconButton
} from "./../../../components/elements/AccordianElements";
import ClearIcon from "./../../../components/icons/Clear";
import LargeDottedButton from "./../../../components/LargeDottedButton";
import { Booking, BookingClient, LineItem, GetBookingRequest, BookingStatus } from "./../../../sdk/bookings_pb";
import { allLineItems, isGroupBooking } from "./../../../util/bookings";
import { colors } from "./../../../util/consts";
import { BookingLineItem } from "../BookingLineItem";
import { TabContainer, TabHeader } from "./index";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import { NewLineItemForm } from "../NewLineItemForm";
import { ToastManager } from "react-toast-notifications";
import { bookingStore } from "../../../stores/booking-store";

interface Props {
  booking: Booking.AsObject;
  client?: BookingClient.AsObject;
  toastManager?: ToastManager;
}

@observer
export default class Offerings extends PureComponent<Props> {
  @observable
  formOpen = false;

  async componentDidMount() {
    await bookingStore.load(this.props.booking.id);
  }

  render() {
    const { booking, client, toastManager } = this.props;
    const editable = booking.status === BookingStatus.BOOKING_CREATED;

    let lineItems = new Array<LineItem.AsObject>();
    if (isGroupBooking(booking)) {
      // if it's a group booking then we show line items
      // depending on if a client has been selected or not
      lineItems = client
        ? client.offeringsList.map((off) => off.lineItem!)
        : booking.offeringsList.map((off) => off.lineItem!);
    } else {
      // otherwise we always show all items
      lineItems = allLineItems(booking, client);
    }

    return (
      <TabContainer>
        <TabHeader>Products &amp; Services</TabHeader>
        <AccordianContainer>
          {lineItems.map((item, i) => (
            <BookingLineItem key={i} booking={booking} item={item} />
          ))}
        </AccordianContainer>

        {editable && this.formOpen && (
          <AccordianContainer>
            <NewLineItemForm
              booking={booking}
              client={client}
              onRequestClose={() => (this.formOpen = false)}

            />
          </AccordianContainer>
        )}

        {editable && !this.formOpen && (
          <LargeDottedButton onClick={() => (this.formOpen = true)}>
            ADD PRODUCT OR SERVICE
          </LargeDottedButton>
        )}
      </TabContainer>
    );
  }
}
