import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";

// redux
import { getResource, getResourceList } from "./../../redux/features/resources/helpers";

// utils
import { breakpoints } from "./../../util/consts";
import typography from "./../../util/typography";
import ResourceListFetch from "../elements/ResourceListFetch";

// components
import AppointmentsCard from "./../../components/AppointmentsCard";

// import image from "./../../static/rawpixel-777267-unsplash.jpg";

export class Appointments extends Component {
  render() {
    const { auth, appointments } = this.props;

    const now = Date.now();
    const [pastApts, upcomingApts] = appointments.reduce(
      (acc, cur) => {
        const [pa, fa] = acc;
        const isAptInPast = new Date(cur.EndDateTimeTZ).getTime() < now;
        return isAptInPast ? [[...pa, cur], fa] : [pa, [...fa, cur]];
      },
      [[], []]
    );

    return (
      <Container>
        <ResourceListFetch type="UserBookings" listctx={{ User: auth.UserID }} force />
        {upcomingApts.length === 0 && pastApts.length === 0 && (
          <>
            <Title>No Appointments</Title>
            <Subtitle>After you've booked an appointment they will show up here</Subtitle>
          </>
        )}
        {upcomingApts.length > 0 && (
          <>
            <Title>Upcoming Appointments</Title>
            <Subtitle>View &amp; edit your upcoming appointments</Subtitle>
            <CardsContainer>
              {upcomingApts.map((apt) => (
                <AppointmentsCard key={apt.ID} resource={apt} />
              ))}
            </CardsContainer>
          </>
        )}
        {pastApts.length > 0 && (
          <>
            <Title>Past Appointments</Title>
            <Subtitle>View your past appointments</Subtitle>
            <CardsContainer>
              {pastApts.map((apt) => (
                <AppointmentsCard key={apt.ID} resource={apt} />
              ))}
            </CardsContainer>
          </>
        )}
      </Container>
    );
  }
}

export default connect((state) => {
  const { auth, lists } = state;
  const list = getResourceList(lists, "UserBookings", { User: auth.UserID });
  return {
    auth,
    appointments: list.IDs.map((id) => getResource(state.resources, "UserBooking", id, true))
  };
})(Appointments);

const Container = styled.div`
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    padding: 48px;
  }

  ${breakpoints["laptop-up"]} {
    padding: 48px 96px;
  }
`;

const Title = styled.h2`
  ${typography.heading6};
  font-weight: 700;
  line-height: 27px;
`;

const Subtitle = styled.span`
  ${typography.subtitle1};
  letter-spacing: 0.5px;
  line-height: 28px;
  opacity: 0.6;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 32px 0;
  margin: -10px;
`;
