import React, { Component } from "react";

// components
import CardAccordian from "./CardAccordian";
import LargeDottedButton from "./../../../components/LargeDottedButton";
import Header from "./../../../components/form/Header";
import SectionsContainer from "./../../../components/form/SectionsContainer";

export default class Cards extends Component {
  state = {
    adding: false
  };
  addCard = (e) => {
    this.setState({ adding: true });
  };
  closeNew = () => {
    this.setState({ adding: false });
  };
  render() {
    const { user } = this.props;
    return (
      <React.Fragment>
        <Header
          title="Credit cards"
          subtitle="Please modify your primary payment methods below"
        />
        <SectionsContainer></SectionsContainer>
        {(user.CreditCards||[]).map((v) => (
          <CardAccordian key={v.ID} id={v.ID} user={user} ccard={v} />
        ))}
        {!this.state.adding ? null : (
          <CardAccordian
            key="newcard"
            id="newcard"
            user={user}
            ccard={null}
            closeNew={this.closeNew}
          />
        )}
        {this.state.adding ? null : (
          <LargeDottedButton onClick={this.addCard}>ADD NEW CARD</LargeDottedButton>
        )}
      </React.Fragment>
    );
  }
}
