import { compose, createStore, applyMiddleware, Middleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createEpicMiddleware } from "redux-observable";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./root-reducer";
// import rootEpic from "./root-epic";
import services from "./../../redux/services";
import migrations from "./migrations";

export const epicMiddleware = createEpicMiddleware({ dependencies: services });

const persistConfig = {
  key: "root",
  version: 0,
  storage,
  whitelist: ["auth", "user", "global"],
  blacklist: ["routing", "dialogs"],
  migrate: createMigrate(
    migrations,
    { debug: process.env.NODE_ENV === "development" },
  ),
}

const composeEnhancers = (
  process.env.NODE_ENV === "development" &&
  window &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) || compose;

// export store singleton instance
export default function configureStore(initialState?: object) {
  const middlewares: Middleware[] = [
    thunkMiddleware,
    epicMiddleware,
    services.apiService.reduxMiddleware
  ];
  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares)
  );
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, initialState!, enhancer);
  const persistor = persistStore(store)
  // epicMiddleware.run(rootEpic);

  if (process.env.NODE_ENV === "development") {
    if(module.hot) {
      module.hot.accept("./root-reducer", () =>
        store.replaceReducer(require("./root-reducer").default)
      );

      // module.hot.accept('./root-epic', () => {
      //   epicMiddleware.run(require('./root-epic').default);
      // });
    }
  }

  return { store, persistor };
}


