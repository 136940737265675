import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Global, css } from "@emotion/core";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import * as ReduxDialogs from "./../../redux/features/dialogs";

import ActionDialog from "./ActionDialog";
import InfoDialog from "./InfoDialog";
import AccountDialog from "./AccountDialog";
import QuickBookDialog from "./QuickBookDialog";
import { SplitDialog } from "./SplitDialog";
import NewBookingDialog from "./NewBookingDialog";
import { AppointmentDialog } from "./AppointmentDialog/AppointmentDialog";
import ClientActionDialog from "./ClientActionDialog";
import StaffActionDialog from "./StaffActionDialog";
import OfferingDialog from "./OfferingDialog";
import AddStaffDialog from "./AddStaffDialog";
import AddClientDialog from "./AddClientDialog";
import WelcomeDialog from "./WelcomeDialog";
import CancelDialog from "./CancelDialog";
import RejectDialog from "./RejectDialog";
import BatchChargeDialog from "./BatchChargeDialog";
import TimeOffDialog from "./TimeOffDialog";
import ConsumerAptDialog from "./ConsumerAptDialog";
import ExportTransactionsDialog from "./ExportTransactionsDialog";
import PullServicesDialog from "./PullServicesDialog";
import ClientPaymentMethodsDialog from "./ClientPaymentMethodsDialog";
import { createPortal } from "react-dom";

const dialogComponentMap = {
  [ReduxDialogs.DialogType.ActionDialog]: ActionDialog,
  [ReduxDialogs.DialogType.InfoDialog]: InfoDialog,
  [ReduxDialogs.DialogType.AccountDialog]: AccountDialog,
  [ReduxDialogs.DialogType.QuickBookDialog]: QuickBookDialog,
  [ReduxDialogs.DialogType.SplitDialog]: SplitDialog,
  [ReduxDialogs.DialogType.NewBookingDialog]: NewBookingDialog,
  [ReduxDialogs.DialogType.AppointmentDialog]: AppointmentDialog,
  [ReduxDialogs.DialogType.ClientActionDialog]: ClientActionDialog,
  [ReduxDialogs.DialogType.StaffActionDialog]: StaffActionDialog,
  [ReduxDialogs.DialogType.OfferingDialog]: OfferingDialog,
  [ReduxDialogs.DialogType.AddStaffDialog]: AddStaffDialog,
  [ReduxDialogs.DialogType.AddClientDialog]: AddClientDialog,
  [ReduxDialogs.DialogType.WelcomeDialog]: WelcomeDialog,
  [ReduxDialogs.DialogType.CancelDialog]: CancelDialog,
  [ReduxDialogs.DialogType.RejectDialog]: RejectDialog,
  [ReduxDialogs.DialogType.BatchChargeDialog]: BatchChargeDialog,
  [ReduxDialogs.DialogType.TimeOffDialog]: TimeOffDialog,
  [ReduxDialogs.DialogType.ConsumerAptDialog]: ConsumerAptDialog,
  [ReduxDialogs.DialogType.ExportTransactionsDialog]: ExportTransactionsDialog,
  [ReduxDialogs.DialogType.PullServicesDialog]: PullServicesDialog,
  [ReduxDialogs.DialogType.ClientPaymentMethodsDialog]: ClientPaymentMethodsDialog,
};

class Dialogs extends Component {
  render() {
    return createPortal(
      <Fragment>
        <Global
          styles={css`
            .dlg-fade-enter {
              opacity: 0.01;
            }
            .dlg-fade-enter-active {
              opacity: 1;
              transition: opacity 500ms ease-out;
            }
            .dlg-fade-exit {
              opacity: 1;
            }
            .dlg-fade-exit-active {
              opacity: 0.01;
              transition: opacity 500ms ease-out;
            }
          `}
        />
        <TransitionGroup>
          {this.props.dialogs.active.map((dd) => (
            <CSSTransition key={dd.id} classNames="dlg-fade" timeout={{ enter: 500, exit: 500 }}>
              {React.createElement(dialogComponentMap[dd.type], {
                dialogId: dd.id,
                data: dd.data
              })}
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Fragment>,
      document.getElementById("root")
    );
  }
}

function mapStateToProps(state, props) {
  const { dialogs } = state;
  return {
    dialogs
  };
}

export default connect(mapStateToProps)(Dialogs);
