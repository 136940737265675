import React from "react";
import styled from "@emotion/styled";
import $ from 'jquery';


// Utils
import { breakpoints, colors, hexToRgba } from "./../../../util/consts";
import typography from "./../../../util/typography";

// Images
import bannerImage from "./../../../static/lyfesalesbanner.jpg";
import firstSectionImage from "./../../../static/mybb.png";
import secondSectionImage from "./../../../static/ppfa.png";
import thirdSectionImage from "./../../../static/tsffc.png";
import forthSectionImage from "./../../../static/funds-logos.png";
import fifthSectionImage from "./../../../static/insights.jpg";

// Components
import Button from "./../../../components/Button";
import CheckIcon from "./../../../components/icons/Check";


import ScrollableAnchor from 'react-scrollable-anchor'


const TickSection = React.memo(({ label }) => (
  <Tick>
    <TickContainer>
      <CheckIcon color={colors.secondary.main} />
    </TickContainer>
    <TickContent>{label}</TickContent>
  </Tick>
));

const Tick = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
`;

const TickContainer = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid ${hexToRgba(colors.primary.main, 0.38)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  flex: 0 0 auto;
`;

const TickContent = styled.div`
  ${typography.subtitle1};
  letter-spacing: 0.5px;
  line-height: 28px;
  flex: 1 1 auto;
`;

const ListYourBusiness = React.memo((props) => {

  const NavigateToCreateOrg = () => {
    props.history.push("/createorg");
  };

  const handleScrollTo = (id) => {
    function isMobile() {
      if(window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
   }
    var x = document.getElementById(id);
    x.scrollIntoView({
      behavior: 'auto',
      block: 'start'
    })
    
    if (isMobile() == false){
      var y = function(){window.scrollTo({top: 0, behavior: 'smooth'});}
      setTimeout(y, 0);
    }
  }

  const handleScrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  
  $("body").width(100)

  return (
    <Container id='menu'>
      <FullScreenSection >
      <ScrollToTop  onClick={() => { handleScrollToTop() }}>⬆</ScrollToTop>
        <NavMenu >
            {/* <Logo src={'https://www.hubspot.com/hubfs/assets/hubspot.com/style-guide/brand-guidelines/guidelines_the-logo.svg'} ></Logo> */}
            <h1>Hubspot Integration Guide</h1>
            <NavGroup>
            <NavLink onClick={() => { handleScrollTo('step1') }}> Track users booking through the booking system</NavLink>
            </NavGroup>
        </NavMenu>
        <ContentContainer>
            <ContentCard>
                <Step1/>
            </ContentCard>
        </ContentContainer>
      </FullScreenSection>
    </Container>
  );
});

const Step1 = () =>{
    return (
        <Step  id='step1'>
        <Logo src={'https://ctvtweb-ehfkypij0t3oqxmnh1.netdna-ssl.com/wp-content/uploads/2019/02/HubSpot-logo.jpg'}></Logo>
        <span style={{display:'flex'}}><h1 style={{color:colors.secondary.main}}>{"Track new clients with contact details in HubSpot to unlock sales, marketing and lead management capabilities and communications"}</h1></span>
        <p> <strong>1.</strong> From your HubSpot Homepage, navigate to the Marketing tab and click on “Lead Capture” and then followed by “Forms” </p>
        <Image src={'https://storage.googleapis.com/booklyfe-public/documentation/Figure1.png'}></Image>
        <p><strong>2.</strong>Click on the “Create form” button on the top right</p>
        <Image src={'https://storage.googleapis.com/booklyfe-public/documentation/Figure2.png'}></Image>
        <p><strong>3.</strong>Select “Regular Form” and the click “Next” on the top right of the screen</p>
        <Image src={'https://storage.googleapis.com/booklyfe-public/documentation/Figure3.png'}></Image>
        <p><strong>4.</strong>Select “Blank template” followed by the “Start” button on the top right</p>
        <Image src={'https://storage.googleapis.com/booklyfe-public/documentation/Figure4.png'}></Image>
        <p><strong>5.</strong>Filter through the existing form fields or create custom ones until you have the following fields (the order does not matter):
            <ul>
            <li>First Name</li>
            <li>Last Name</li>
            <li>Email</li>
            <li>Phone Number</li>
            <li>Date of Birth</li>
            <li>Location</li>
            </ul>
</p>
<Image src={'https://storage.googleapis.com/booklyfe-public/documentation/Figure5.png'}></Image>
        <p><strong>6.</strong>A custom field is required for the location data so drag a “Single-line text” into the form and  in the label simple enter “Location” followed by the “Next >” key and “Create” </p>
        <Image src={'https://storage.googleapis.com/booklyfe-public/documentation/Figure6.png'}></Image>
        <p><strong>7.</strong>After entering the location field, click the “Submit” button on the top right of the page and save down the “FormId” and “PortalId”</p>
        <Image src={'https://storage.googleapis.com/booklyfe-public/documentation/Figure7.png'}></Image>
        <p><strong>8.</strong>Copy the “portalId” and “formId” and paste it into the business profile page highlighted in Step 11</p>
        <Image src={'https://storage.googleapis.com/booklyfe-public/documentation/Figure8.png'}></Image>
        <p><strong>9.</strong>If you missed the previous screen you can access the ID Codes again by clicking “Actions” on the form and pressing “Share form”</p>
        <Image src={'https://storage.googleapis.com/booklyfe-public/documentation/Figure9.png'}></Image>
        <p><strong>10.</strong>You can alternatively, get the PortalId and FormId from the URL bar of the form</p>
        <Image src={'https://storage.googleapis.com/booklyfe-public/documentation/Figure10.png'}></Image>
        <p><strong>11.</strong>Navigate to your business profile on Booklyfe to add the copied IDs into the relevant fields</p>
        <Image src={'https://storage.googleapis.com/booklyfe-public/documentation/Figure11.png'}></Image>
        <p><strong>12.</strong>Click "Save" to ensure the IDs are saved</p>
        </Step>
    )
}





export default ListYourBusiness;

const Container = styled.main`
    width: 100%;
`;

const FullScreenSection = styled.div`
width: 100%;
  @media only screen and (min-width: 768px) {
    width: 100%;
    height: calc(100vh - 40px);
    max-height: 900px;
    display: flex;
    border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
}
`;

const DiscountSection = styled.div`
@media only screen and (min-width: 768px) {
  position: absolute;
  bottom:0px;
  width: 25%;
  padding: 20px;
  padding-left: 0px;
  div{
    padding-left: 5px;
  }
}
`

const NavMenu = styled.div`
border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
background-color: ${hexToRgba(colors.secondary.main, 1)};
color: white;
padding: 5px;
 h1{
      font-size: 20px;
  }
  img{
    margin-left: 30%;
  }
  @media only screen and (min-width: 768px) {
    width: 30%;
  height: calc(100vh - 40px);
  max-height: 900px;
  display: block;
  border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
  background-color: ${hexToRgba(colors.secondary.main, 1)};
  color: white;
  padding: 20px;
}
`;

const NavLink = styled.a`
  display: block;
  text-decoration: none;
  color: white;
  padding-top: 10px;
  border-bottom: solid ${hexToRgba(colors.secondary[500], 1)} 1px;
  padding-bottom: 5px;
  &:hover {
      background-color: #c0c0c070;
      text-shadow: 2px 2px 5px silver;
  }
`;

const ScrollToTop = styled.button`
  display: block;
  position: fixed;
  right: 10px;
  bottom: 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background:  ${hexToRgba(colors.secondary.main, 1)};
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  box-shadow: 2px 2px #00000036;
  @media only screen and (min-width: 768px) {
    visibility: hidden;
}
`

const NavGroup = styled.div`
`

const Logo = styled.img`
  width: 150px;
  background-color: white;
  margin-left: 40%;
  `

const Step = styled.div`
  h1{
      font-size: 20px;
  }
@media only screen and (min-width: 768px) {
}
`;

const Image = styled.img`
width: 300px;
justify-content: center;
display:flex;
margin:auto;
@media only screen and (min-width: 768px) {
    width: 650px;
    justify-content: center;
    display:flex;
    margin:auto;
}

`

const ContentContainer = styled.div`
  @media only screen and (min-width: 768px) {
    width: 70%;
    height: 100%;
    max-height: 900px;
    display: block;
    background-color: #0000000d;
    border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
}
`;

const ContentCard = styled.div`
padding: 5px;
  @media only screen and (min-width: 768px) {
    width: 100%
    height: 100%;
    margin: 20px;
    margin-bottom: 0px;
    padding: 20px;
    display: block;
    background-color: #ffffff;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
    overflow: scroll;
    height: -webkit-fill-available;
}
  
`;
