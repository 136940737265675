import { observable } from "mobx";

export class SignUpDataStore {
    @observable
    private _loggedIn: Boolean = false;

    @observable
    private _signUpButton: Boolean = false;

    /**
     * Getter tag
     * @return {Boolean}
     */
    public get loggedIn(): Boolean {
      return this._loggedIn;
    }

        /**
     * Getter tag
     * @return {Boolean}
     */
    public get signUpButton(): Boolean {
      return this._signUpButton;
    }
  
    /**
     * Setter startFrom
     * @param {Boolean} value
     */
    
    public set loggedIn(value: Boolean) {
      this._loggedIn = value;
    }

        /**
     * Setter startFrom
     * @param {Boolean} value
     */
    
    public set signUpButton(value: Boolean) {
      this._signUpButton = value;
    }
  
  
  }
  
  export const signUpDataStore = new SignUpDataStore();
  