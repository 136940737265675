/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { Component } from "react";
import { withFormik } from "formik";
import CreditCardInput from "react-credit-card-input";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import { withRouter } from "react-router";
import { compose } from "recompose";
import * as Yup from "yup";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import { parseFormError } from "./../../../util/form";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import styled from "@emotion/styled";
import { colors } from "./../../../util/consts";

// Redux
// import apiService from "./../../../redux/services/api";
// Components
import Form from "./../../../components/form/Form";
import Header from "./../../../components/form/Header";
import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import Section from "./../../../components/form/Section";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import LoadingButton from "./../../../components/LoadingButton";
import Button from "./../../../components/Button";
import { validABN } from "./../../../util";

// Utils
import { findPlaceDetails, getAddressResourceFromPlaceDetails } from "./../../../util/address";

import * as GlobalActions from "./../../../redux/features/global/actions";

import { getYearFromTwo } from "./../../../util";
import { getCardToken, getCardType } from "./../../../util/ccard";
import { SetAffiliateCodeRequest, SetAffiliateCodeResponse } from '../../../sdk/affiliate_code_pb';
import { AffiliateCodeRequest } from '../../../sdk/affiliate_code_pb';
import { rpc, metadata } from '../../../grpc';
import { toastStore } from '../../../stores/toast-store';


class OrgCreateAccount_Payment extends Component {
  componentWillUnmount() {
    this.props.setData({ ...this.props.values });
  }

  handleValidateCode = async(value) => {
    const { setFieldValue, setFieldError } = this.props;
    const name ='Affiliate';
    const text = value.currentTarget.value
    setFieldValue(name, text, true);
    try{
    const reqAffiliate = new AffiliateCodeRequest();

    reqAffiliate.setAffiliateCode(text.trim());
    const resAffiliate = await rpc.affiliateService.get(reqAffiliate, metadata());
    const affiliateCodeExists = resAffiliate.toObject().exists;
    if (!affiliateCodeExists){
      setFieldError(name, "Not a valid code");
    }
    } catch {
      setFieldError(name, "Not a valid code");
    }
  }

  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;

    const handleGoBack = (e)=>{
      e.preventDefault();
     this.props.setPage("subscription")
    }

    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <HoverDiv>
          <Col1> <Header
          title={pageData.Subscription !="Starter-Kit" ? "Payment Details" : "Payment Details (optional)"}
          subtitle="Please add a credit card for subscription fee billing or if you require bulk billing, HICAPS or DVA claiming"
          /> </Col1>
          <Col2><BlockSubtitle>?</BlockSubtitle><TooltipText> Your card will not be charged until after the 30 day free trail. You can cancel at anytime before 30 days and you will not be charged. </TooltipText></Col2>
        </HoverDiv>
        <SectionsContainer>
          <Section>
            <Label htmlFor="createorg-pd-cname">Card Name</Label>
            <Input
              id="createorg-pd-cname"
              name="CreditCard_Name"
              type="text"
              placeholder="Name"
              autoFocus={true}
              value={values.CreditCard_Name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.CreditCard_Name && errors.CreditCard_Name}
            />
            {!!errors.CreditCard_Name && touched.CreditCard_Name && (
              <Error>{errors.CreditCard_Name}</Error>
            )}
          </Section>
          <Section>
            <CreditCardInput
              cardNumberInputProps={{
                name: "CreditCard_Number",
                value: values.CreditCard_Number,
                onChange: handleChange,
                onBlur: handleBlur
              }}
              cardExpiryInputProps={{
                name: "CreditCard_Expiry",
                value: values.CreditCard_Expiry,
                onChange: handleChange,
                onBlur: handleBlur
              }}
              cardCVCInputProps={{
                name: "CreditCard_CVC",
                value: values.CreditCard_CVC,
                onChange: handleChange,
                onBlur: handleBlur
              }}
              containerStyle={{
                width: "100%"
              }}
              fieldStyle={css`
                font-size: 15.8px;
                -webkit-letter-spacing: 0.15px;
                -moz-letter-spacing: 0.15px;
                -ms-letter-spacing: 0.15px;
                letter-spacing: 0.15px;
                text-align: left;
                height: 44px;
                width: 100%;
                padding: 10px 16px;
                border: 1px solid #d2d5e3;
                border-radius: 4px;
                outline: none;
                color: rgba(44, 46, 60, 1);
                -webkit-transition: border-color 0.2s ease;
                transition: border-color 0.2s ease;
              `}
            />
            {!!errors.CreditCard_Number && touched.CreditCard_Number && (
              <Error>{errors.CreditCard_Number}</Error>
            )}
            {!!errors.CreditCard_Expiry && touched.CreditCard_Expiry && (
              <Error>{errors.CreditCard_Expiry}</Error>
            )}
            {!!errors.CreditCard_CVC && touched.CreditCard_CVC && (
              <Error>{errors.CreditCard_CVC}</Error>
            )}
          </Section> 
        </SectionsContainer>
        <Header title="Bank Account" subtitle="In order to recieve payments from your customers, please fill out your business' bank account details" />
        <SectionsContainer>
          <Section>
            <Label htmlFor="createorg-bd-aname">Account Name</Label>
            <Input
              id="createorg-bd-aname"
              name="BankDetailsAU_AccountName"
              type="text"
              placeholder="Name"
              value={values.BankDetailsAU_AccountName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.BankDetailsAU_AccountName && errors.BankDetailsAU_AccountName}
            />
            {!!errors.BankDetailsAU_AccountName && touched.BankDetailsAU_AccountName && (
              <Error>{errors.BankDetailsAU_AccountName}</Error>
            )}
          </Section>
          <Section>
            <Label htmlFor="createorg-bd-bsb">BSB</Label>
            <Input
              id="createorg-bd-bsb"
              name="BankDetailsAU_AccountBSB"
              type="text"
              placeholder="Name"
              value={values.BankDetailsAU_AccountBSB}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.BankDetailsAU_AccountBSB && errors.BankDetailsAU_AccountBSB}
            />
            {!!errors.BankDetailsAU_AccountBSB && touched.BankDetailsAU_AccountBSB && (
              <Error>{errors.BankDetailsAU_AccountBSB}</Error>
            )}
          </Section>
          <Section>
            <Label htmlFor="createorg-bd-anum">Account Number</Label>
            <Input
              id="createorg-bd-anum"
              name="BankDetailsAU_AccountNumber"
              type="text"
              placeholder="Name"
              value={values.BankDetailsAU_AccountNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.BankDetailsAU_AccountNumber && errors.BankDetailsAU_AccountNumber}
            />
            {!!errors.BankDetailsAU_AccountNumber && touched.BankDetailsAU_AccountNumber && (
              <Error>{errors.BankDetailsAU_AccountNumber}</Error>
            )}
          </Section>
        </SectionsContainer>
        <Header
          title="Tax Information"
          subtitle="Please fill out the tax information relating to your business"
        />
        <SectionsContainer>
          <Section>
            <Label htmlFor="createorg-td-ename">Entity Name</Label>
            <Input
              id="createorg-td-ename"
              name="TaxDetailsAU_EntityName"
              type="text"
              placeholder="Name"
              value={values.TaxDetailsAU_EntityName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.TaxDetailsAU_EntityName && errors.TaxDetailsAU_EntityName}
            />
            {!!errors.TaxDetailsAU_EntityName && touched.TaxDetailsAU_EntityName && (
              <Error>{errors.TaxDetailsAU_EntityName}</Error>
            )}
          </Section>
          <Section>
            <Label htmlFor="createorg-td-abn">ABN</Label>
            <Input
              id="createorg-td-abn"
              name="TaxDetailsAU_ABN"
              type="text"
              placeholder="Name"
              value={values.TaxDetailsAU_ABN}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.TaxDetailsAU_ABN && errors.TaxDetailsAU_ABN}
            />
            {!!errors.TaxDetailsAU_ABN && touched.TaxDetailsAU_ABN && (
              <Error>{errors.TaxDetailsAU_ABN}</Error>
            )}
          </Section>
          <Header title="Affiliate Code" subtitle="If you recieved a referal please enter your it here"/>
        <Section>
          <br/>
            <Label htmlFor="createorg-affiliate"></Label>
            <Input
              id="createorg-affiliate"
              name="Affiliate"
              type="text"
              placeholder="Enter affiliate code"
              value={values.Affiliate}
              onChange={this.handleValidateCode}
              error={touched.Affiliate && errors.Affiliate}
            />
            {!!errors.Affiliate && <Error>{errors.Affiliate}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
        <Button
            style={{ width: 150, marginRight: "5px", backgroundColor: "rgba(44,46,60,0.12)", color:"rgba(0,0,0,0.38)"}}
            type="button"
            onClick={(handleGoBack)}
          >
            Go Back
          </Button>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.TaxDetailsAU_EntityName}
          >
            Continue
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const TooltipText = styled.span`
position: absolute;
visibility: hidden;
width: 240px;
background-color: #555;
padding: 10px;
color: #fff;
text-align: center;
border-radius: 6px;
z-index: 10;
transition: opacity .6s;
margin-left: 2px;
margin-top: -5px;
`
const BlockSubtitle = styled.div`
  color: ${colors.surfaceText.medEmphasis};
  font-size: 20.82px;
  letter-spacing: 0.25px;
  line-height: 13px;
  margin-left: 6px;
  &:hover ${TooltipText} {
    visibility: visible;
  }
`;

const HoverDiv = styled.div`
  display: flex;
    &:hover ${TooltipText} {
      visibility: visible;
    }
`;

const Col1 = styled.div`
  width: 90%;
`;

const Col2 = styled.div`
  width: 10%;
`;
const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData }) => ({
    CreditCard_Name: pageData.CreditCard_Name || "",
    CreditCard_Number: pageData.CreditCard_Number || "",
    CreditCard_Expiry: pageData.CreditCard_Expiry || "",
    CreditCard_CVC: pageData.CreditCard_CVC || "",
    TaxDetailsAU_EntityName: pageData.TaxDetailsAU_EntityName || "",
    TaxDetailsAU_ABN: pageData.TaxDetailsAU_ABN || "",
    BankDetailsAU_AccountName: pageData.BankDetailsAU_AccountName || "",
    BankDetailsAU_AccountBSB: pageData.BankDetailsAU_AccountBSB || "",
    BankDetailsAU_AccountNumber: pageData.BankDetailsAU_AccountNumber || "",
    Affiliate: pageData.Affiliate || ""
  }),
  validationSchema: ({pageData})=> Yup.object().shape({
    CreditCard_Name: pageData.Subscription != "Starter-Kit" ? Yup.string().required("Name is required.") : Yup.string(),
    CreditCard_Number: pageData.Subscription != "Starter-Kit" ? Yup.string().required("Is required.") : Yup.string(),
    CreditCard_Expiry: pageData.Subscription != "Starter-Kit" ? Yup.string().required("Is required.") : Yup.string(),
    CreditCard_CVC: pageData.Subscription != "Starter-Kit" ? Yup.string().required("Is required.") : Yup.string(),
    TaxDetailsAU_EntityName: pageData.Subscription != "Starter-Kit" ? Yup.string().required("Entity name is required.") : Yup.string(),
    Affiliate: Yup.string().required(""),
    TaxDetailsAU_ABN: Yup.string()
      .test("ABN Check", "Invalid ABN Number", validABN)
      .required("ABN is required."),
    BankDetailsAU_AccountName: Yup.string().required("Account name is required."),
    BankDetailsAU_AccountBSB: Yup.string()
      .matches(/^\s*[0-9]{6}\s*$/, "BSB must be 6 digits")
      .required("BSB is required"),
    BankDetailsAU_AccountNumber: Yup.string()
      .matches(/^\s*[0-9]+\s*$/, "Account number must be made up of digits")
      .required("Account number is required")
  }),
    handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      const { pageData, dispatch } = props;

      const expVals = values.CreditCard_Expiry.split("/");

      // Find and create the address.
      let addrID;
      const prDet = await findPlaceDetails(pageData.Address, "Address");
      const prAddRes = {
        OwnerType: "Organisation",
        ...getAddressResourceFromPlaceDetails(prDet, values.Address)
      };
      const prRes = await props.dispatch(ResourceActions.action(prAddRes, "Create", {}));
      addrID = prRes.Payload.ID;
      // Create the organisation.
      const org = {
        $Metadata: { Type: "Organisation" },
        Name: pageData.Name.trim(), // replace
        Description: pageData.Description.trim(),
        ContactEmail: pageData.ContactEmail.trim(), // replace
        ContactPhone: pageData.ContactPhone.trim(), // replace
        Address: addrID, // replace
        Logo: pageData.Logo,
        Banner: pageData.Banner,
        BankAccountDetails: {
          AccountName: values.BankDetailsAU_AccountName.trim(),
          BSB: values.BankDetailsAU_AccountBSB.trim(),
          AccountNumber: values.BankDetailsAU_AccountNumber.trim()
        },
        TaxDetails: {
          EntityName: values.TaxDetailsAU_EntityName.trim(),
          ABN: values.TaxDetailsAU_ABN.replace(/\s+/g, "").trim()
        },
        SubscriptionType: pageData.Subscription,
        Settings: {
          RequireBookingApproval: true,
          NotificationDaysBefore: 1,
          PenaltyFeeFixed: 0,
          PenaltyFeeVar: 1000,
          PenaltyTimePeriod: 48,
          ClientPaysCCFee: true,
          ThirdPartyTACs: ""
        },
        Integrations: {}
      };

      const orgCreate = await props.dispatch(ResourceActions.action(org, "Create", {}));
      //if optional dont create a new card
      if(values.CreditCard_Number != ""){
        const newCardToken = await getCardToken(
          values.CreditCard_Number,
          values.CreditCard_Name,
          values.CreditCard_CVC,
          parseInt(expVals[0].trim()),
          getYearFromTwo(parseInt(expVals[1].trim()))
        );

      const newCard = {
        $Metadata: { Type: "CreditCard" },
        OwnerType: "Organisation",
        Owner: orgCreate.Payload.ID,
        Token: newCardToken,
        Type: getCardType(values.CreditCard_Number),
        Last4: values.CreditCard_Number.slice(values.CreditCard_Number.length - 4),
        IsPrimary: true,
        ExpiryMonth: parseInt(expVals[0].trim()),
        ExpiryYear: getYearFromTwo(parseInt(expVals[1].trim())),
        Merchant: "WebDosh"
      };
      await dispatch(ResourceActions.action(newCard, "Create", {}));}

      await props.dispatch(
        ResourceActions.listAction(
          { $Metadata: { List: "AuthUserOrgs", Context: {} } },
          false,
          "Fetch"
        )
      );


        
      // add affiliate code to db
      const reqAffiliate = new AffiliateCodeRequest();
    reqAffiliate.setAffiliateCode(values.Affiliate.trim());
    const resAffiliate = await rpc.affiliateService.get(reqAffiliate, metadata());
    const affiliateCodeExists = resAffiliate.toObject().exists;
    if (!affiliateCodeExists){
      setFieldError("Affiliate", "Not a valid code");
      throw apiService.errorProcess(
        new APIError(HTTPMethod.Get, 'affiliateService Grpc', 
        {
          Status: `Error`, 
          StatusCode: 400, 
          Errors: [
            {UserMessage: "Not a valid code"}
          ]
        }
      ));
    }

  //check if affiliate code successful or not
      if (values.Affiliate && values.Affiliate !== ""){
        const reqSetAffiliateOrg = new SetAffiliateCodeRequest();
        reqSetAffiliateOrg.setAffiliateCode(values.Affiliate.trim());
        reqSetAffiliateOrg.setOrgId(orgCreate.Payload.ID);
        const resSetAffiliateOrg = await rpc.affiliateService.setOrgCode(reqSetAffiliateOrg, metadata());
        
        switch (resSetAffiliateOrg.getAffiliateCodeStatus()){
          case SetAffiliateCodeResponse.Status.APPLIED_SUCCESSFULLY:
            toastStore.success('Affiliate code applied successfully');
            break;
          default:
            toastStore.error('Affiliate code not applied');
            break;
        }      
      }

      dispatch(GlobalActions.setUserContext(orgCreate.Payload.ID, ""));
      props.toastManager.add("Organisation Created!", { appearance: "success" });
      props.setPage("fundpool", { orgCreatedID: orgCreate.Payload.ID });
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "OrgCreateAccountPayment"
});

export default compose(
  connect((state) => ({})),
  withToastManager,
  formikEnhancer
)(OrgCreateAccount_Payment);
