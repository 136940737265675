import { RouteComponentProps, withRouter } from 'react-router-dom';
import React, { Component } from "react";

interface PrevProps {
  location: number
}

interface Props {
  location: number
}

class ScrollToTop extends React.Component<Props & RouteComponentProps<any>> {
    componentDidUpdate(prevProps: PrevProps) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0)
      }
    }
  
    render() {
      return this.props.children
    }
  }

  export default withRouter(ScrollToTop);