import { EntityStore } from "./entity-store";
import { rpc, metadata } from "./../grpc";
import { ThirdPartyInvoice, ListThirdPartyInvoiceRequest } from "./../sdk/third_party_invoices_pb";
import { toastStore } from "./toast-store";

class ThirdPartyInvStore extends EntityStore<ThirdPartyInvoice.AsObject> {
  async load(bookingId: string, clientId?: string) {
    const req = new ListThirdPartyInvoiceRequest();
    req.setBookingId(bookingId);
    if (clientId) {
      req.setClientId(clientId);
    }
    try {
      const res = await rpc.thirdPartyInvoices.list(req, metadata());
      res.toObject().invoicesList.map((tpi: ThirdPartyInvoice.AsObject) => this.add(tpi));
    } catch (err) {
      toastStore.grpcToastError(err);
    }
  }
}

export const thirdPartyInvStore = new ThirdPartyInvStore();
