import { Booking, BookingClient, LineItem, BookingStatus, BookingType } from './../sdk/bookings_pb';
import { Money } from './../sdk/money_pb';
import { add, mul, moneyZero } from './money';
import { addSeconds } from 'date-fns/esm';
import { toDate } from './timestamp';
import { FundType } from './../sdk/offerings_pb';
import { clientFundStore } from './../stores/client_fund-store';
import { AccountItem, AccItemStatus, AccItemType } from './../sdk/account_items_pb';
import { accItemStore } from './../stores/acc_item-store';
import { ISOCurrencyName } from '../sdk/currencies_pb';


export function isGroupBooking(booking: Booking.AsObject) {
  // return booking.clientsList.length > 1;
  return booking.type === BookingType.BOOKING_GROUP;
}

export function bookingStartTime(booking: Booking.AsObject): Date {
  return toDate(booking.startTime!);
}

export function bookingEndTime(booking: Booking.AsObject): Date {
  return addSeconds(bookingStartTime(booking), booking.duration!.seconds);
}

export function calculateAmountRebate(booking: Booking.AsObject, client?: BookingClient.AsObject): Money.AsObject {
  const rebateAmounts = allLineItems(booking, client)
    .filter(item => !!item.rebate)
    .map(item => item.rebate!.amount!);
  const currencyCode = rebateAmounts[0] ? rebateAmounts[0].currencyCode : ISOCurrencyName.XXX
  return mul(add(currencyCode, ...rebateAmounts), -1, false);
}

export function calculateTaxRebate(booking: Booking.AsObject, client?: BookingClient.AsObject): Money.AsObject {
  const rebateTaxes = allLineItems(booking, client)
  .filter(item => !!item.rebate)
  .map(item => item.rebate!.tax!);
  const currencyCode = rebateTaxes[0] ? rebateTaxes[0].currencyCode : ISOCurrencyName.XXX
  return mul(add(currencyCode, ...rebateTaxes),-1,false);
}

export function calculateTotalRebate(booking: Booking.AsObject, client?: BookingClient.AsObject) {
  const rebate = calculateAmountRebate(booking, client);
  const currencyCode = rebate.currencyCode ? rebate.currencyCode : ISOCurrencyName.XXX
  return add(currencyCode, rebate, calculateTaxRebate(booking, client));
}

export function calculateLyfeFee(booking: Booking.AsObject, client?: BookingClient.AsObject): Money.AsObject {

  const fees = allLineItems(booking, client)
    .filter(item => item.fundType != FundType.THIRD_PARTY_INVOICE)
    .map(item => item.fee!.amount!);

  const taxes = allLineItems(booking, client)
    .filter(item =>
      item.fundType != FundType.THIRD_PARTY_INVOICE)
    .map(item => item.fee!)
    .filter(fee => !!fee.tax)
    .map(fee => fee.tax!);
  
  const rebate = calculateTotalRebate(booking, client);
  if (fees.length > 0){
    const currencyCode = fees[0] ? fees[0].currencyCode : ISOCurrencyName.XXX
    const total = add(currencyCode, ...fees, ...taxes, rebate);
    return mul(total, parseFloat(booking.lyfeFee), true);
  }
  return moneyZero(ISOCurrencyName.XXX);
}

export function calculateSubtotal(booking: Booking.AsObject, client?: BookingClient.AsObject): Money.AsObject {
  // get all the line items for the booking
  // then map the LineItems into their Fee Amount.
  // The Fee Amount is the cost before tax.
  // Then reduce all the Fee Amounts into a sum
  const fees = allLineItems(booking, client) 
    .map(item => {
      return item.fee!.amount!
    });
  const rebate = calculateAmountRebate(booking, client);
  const currencyCode = fees[0] ? fees[0].currencyCode : ISOCurrencyName.XXX
  return add(currencyCode, ...fees, rebate);
}

export function calculateTotalGST(booking: Booking.AsObject, client?: BookingClient.AsObject): Money.AsObject {
  const taxes = allLineItems(booking, client)
    // .filter(item =>  item.rebate!.amount == moneyZero())  
    .map(item => item.fee!)
    .filter(fee => !!fee.tax)
    .map(fee => fee.tax!);
    const currencyCode = taxes[0] ? taxes[0].currencyCode : ISOCurrencyName.XXX
  return add(currencyCode, ...taxes, calculateTaxRebate(booking, client));
}

export function calculateTotal(booking: Booking.AsObject, client?: BookingClient.AsObject): Money.AsObject {
  var subtotal: Money.AsObject = calculateSubtotal(booking, client)
  var gst: Money.AsObject = calculateTotalGST(booking, client)
  var lyfeFee: Money.AsObject = calculateLyfeFee(booking, client)
  return add(
    subtotal.currencyCode,
    subtotal,
    gst,
    lyfeFee
  )
}

// return all the line items that are relevant for a booking.
// if a client is passed in then the client's offerings will also be
// in the returned list.
export function allLineItems(booking: Booking.AsObject, client?: BookingClient.AsObject): LineItem.AsObject[] {
  
  let bookingOfferings = new Array<LineItem.AsObject>();
  if (isGroupBooking && !client){
    booking.clientsList.forEach( client => {
      booking.offeringsList
      .map(offering => {
        bookingOfferings.push(offering.lineItem!);
      });
    });
  } else {
    bookingOfferings = booking.offeringsList.map(off => off.lineItem!);
  }

  let clientOfferings = new Array<LineItem.AsObject>();
  if (client) {
    clientOfferings = client.offeringsList.map(off => off.lineItem!);
  } else {
    clientOfferings = booking.clientsList
    .flatMap(client => client.offeringsList)
    .map(offering => offering.lineItem!);
  }

  return [
    ...bookingOfferings,
    ...clientOfferings,
  ];
}

export interface FundRequirements {
  required: FundType[];
  missing: FundType[];
}

/**
 * This method returns a list of the funds that are required for a given
 * booking client and a list of the funds that the client is currently missing
 */
export function requiredFundsForBooking(booking: Booking.AsObject, client: BookingClient.AsObject, accountItems: AccountItem.AsObject[]): FundRequirements {
  const currentFunds = clientFundStore.get(client.clientId);
  const fundsRequired = new Set<FundType>();
  const missingFunds = new Set<FundType>();

  // if booking is cancelled, add credit card required
  if (booking.status == BookingStatus.BOOKING_CANCELLED) {
    fundsRequired.add(FundType.CREDIT_CARD);
    // if not credit card active selected, add to missing funds
    if (client.activeCardId === "") {
      missingFunds.add(FundType.CREDIT_CARD);
    }

  } else {
    for (const item of allLineItems(booking, client)) {
      fundsRequired.add(item.fundType);
      missingFunds.add(item.fundType);
      // When medicare std, credit card is required
      if (item.fundType == FundType.MEDICARE_STANDARD) {
        fundsRequired.add(FundType.CREDIT_CARD);
      }
      // if current funds have number, remove this fund type from missing funds list
      if (currentFunds) {
        if (item.fundType == FundType.MEDICARE_STANDARD || item.fundType == FundType.MEDICARE_BULK) {
          if (currentFunds!.medicare!.number !== "") { missingFunds.delete(item.fundType) };
        }
        if (item.fundType == FundType.DVA_STANDARD || item.fundType == FundType.DVA_ALLIED) {
          if (currentFunds!.dva!.disability !== "") { missingFunds.delete(item.fundType) };
        }
        if (item.fundType == FundType.HICAPS) {
          // assume only one of the medipass id and mobile is required
          if (currentFunds!.hicaps!.medipassMemberId !== "" || currentFunds!.hicaps!.mobile !== "") { missingFunds.delete(item.fundType) };
        }

        if (item.fundType === FundType.THIRD_PARTY_INVOICE) {
          if (accountItems.length > 0){
            missingFunds.delete(item.fundType);
          }
        }
      }
    }
    // check if medicare std or credit card have and active card already, if so delete from missing fund type list
    if ((fundsRequired.has(FundType.MEDICARE_STANDARD) || fundsRequired.has(FundType.CREDIT_CARD))
      && client.activeCardId !== ""
    ) {
      missingFunds.delete(FundType.CREDIT_CARD);
    }
  }

  // if an account item has been completed, remove it from required funds
  if (accountItems.length > 0){
    accountItems.filter(ai => ai.status == AccItemStatus.ACC_ITEM_COMPLETED)
    .map(ai => {
      missingFunds.delete(ai.fundType);
      fundsRequired.delete(ai.fundType);
    }); 
  }

  return {
    required: Array.from(fundsRequired.values()),
    missing: Array.from(missingFunds.values()), 
  }
}


export function clientPaymentStatusToText(status: BookingClient.PaymentStatus): string {
  switch (status) {
    case BookingClient.PaymentStatus.COMPLETE:
      return 'Complete';
    case BookingClient.PaymentStatus.PROCESSING:
      return 'Processing';
    case BookingClient.PaymentStatus.PENDING:
      return 'Pending';
    default:
      return 'Status unspecified';
  }
}

export function renderBookingClientStatus(booking: Booking.AsObject) {
  switch (booking.type) {
    case BookingType.BOOKING_GROUP:
      return renderBookingStatus(booking, undefined);
    case BookingType.BOOKING_SINGLE:
      return renderBookingStatus(booking, booking.clientsList[0]);
    default:
      return booking.status;
  }
}

export function renderBookingStatus(booking: Booking.AsObject, client?: BookingClient.AsObject) {
  switch (booking.status) { 
    case BookingStatus.BOOKING_CREATED:
      if (client && client.status === BookingClient.Status.APPROVED) {
        return "Approved";
      } else if (client) {
        return "Waiting For Approval";
      } else {
        return "Created";
      }
    case BookingStatus.BOOKING_PROCESSING:
      return renderProcessingStatus(booking);
    case BookingStatus.BOOKING_CANCELLED:
      if (client && client.status === BookingClient.Status.REJECTED) {
        return "Rejected";
      } else {
        return "Cancelled";
      }
    case BookingStatus.BOOKING_COMPLETED:
      return "Completed";
    default:
      return "Unknown";
  }
}

function renderProcessingStatus(booking: Booking.AsObject) {
  
  const accountItems = accItemStore.all().filter((ai) => {return ai.booking!.id === booking.id});

  if (accountItems.findIndex((v) => v.status === AccItemStatus.ACC_ITEM_ERROR && v.type === AccItemType.ACC_ITEM_BOOKING_PAYMENT)>=0) {
    return "Charge failed";
  } else if (accountItems.findIndex((v) => v.status === AccItemStatus.ACC_ITEM_ERROR && v.type === AccItemType.ACC_ITEM_FUND) >= 0) {
    return "Fund failed";
  } else if (accountItems.findIndex((v) => v.status === AccItemStatus.ACC_ITEM_ERROR) >= 0) {
    return "Error";
  } else {
    return "Processing";
  }
}

export function bookingStatusToText(status: BookingStatus) {
  switch(status){
    case BookingStatus.BOOKING_CREATED:
      return "Created";
    case BookingStatus.BOOKING_PROCESSING:
      return "Processing";
    case BookingStatus.BOOKING_COMPLETED:
      return "Completed";
    case BookingStatus.BOOKING_CANCELLED:
      return "Cancelled";
    default:
      return "Unknown";
  }
}

export function bookingTypeToDTO(bkType: string): BookingType {
  switch (bkType) {
    case "Group":
      return BookingType.BOOKING_GROUP;
    case "Single":
      return BookingType.BOOKING_SINGLE;
    case "Time Off":
      return BookingType.BOOKING_TIME_OFF
    default:
      return BookingType.BOOKING_TYPE_UNSPECIFIED;
  }
}

export function bookingTypeToText(bkType: BookingType): string {
  switch (bkType) {
    case BookingType.BOOKING_GROUP:
      return "Group";
    case BookingType.BOOKING_SINGLE:
      return "Single";
    case BookingType.BOOKING_TIME_OFF:
      return "Time Off";
    default:
      return "Unknown";
  }
}
