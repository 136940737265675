import React, { Component } from "react";
import styled from "@emotion/styled";
import Ink from "./npmoverrides/react-ink";

const Button = styled.button`
  display: inline-block;
  position: relative;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export default class ButtonBase extends Component {
  state = {
    touched: false
  };

  handleKeyDown = (event) => {
    if (this.props.handleKeyUp) {
      this.props.handleKeyUp(event);
    }

    if (event.keyCode === 13) {
      this.setState({ touched: true }, () => this.setState({ touched: false }));
    }
  };

  render() {
    const { children, innerRef, ...otherProps } = this.props;
    return (
      <Button {...otherProps} onKeyDown={this.handleKeyDown} ref={innerRef}>
        {children}
        {!this.props.disabled && (
          <Ink opacity={0.1} hasTouch={false} touched={this.state.touched} />
        )}
      </Button>
    );
  }
}
