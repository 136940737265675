import styled from "@emotion/styled";

import typography from "./../../util/typography";
import { colors } from "./../../util/consts";

const Info = styled.div`
  ${typography.caption};
  display: inline-block;
  margin-bottom: 6px;
  color: ${colors.blackText.medEmphasis};
`;

export default Info;
