import React, { PureComponent } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as dfns from "date-fns";

import Input from "./Input";

import { isMobileDevice } from "./../../util";

export default class StyledDayPickerInput extends PureComponent {
  static defaultProps = {
    input: Input
  };

  handleChange = (res) => {
    const { name, onChange } = this.props;
    if (isMobileDevice()) {
      onChange({ target: { name, value: res.target.value } });
    } else {
      onChange({ target: { name, value: dfns.format(res, "yyyy-MM-dd") } });
    }
  };
  // This is required to get IOS clear button working.
  onNativeInput(e) {
    const target = e.nativeEvent.target;
    setTimeout(() => {
      target.defaultValue = "";
    }, 0);
  }
  render() {
    const { name, value, placeholder, disabled, onChange, input: Cmpt, ...otherProps } = this.props;
    if (isMobileDevice()) {
      return (
        <Input
          type="date"
          value={value}
          // onChange={this.handleChange}
          onInput={this.onNativeInput}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          onChange={onChange}
          {...otherProps}
        />
      );
    } else {
      return (
        <DatePicker
          selected={
            !value ? null : dfns.parse(value, "yyyy-MM-dd", new Date(1980, 1, 1, 12, 0, 0, 0))
          }
          onChange={this.handleChange}
          dateFormat="d/M/yyyy"
          customInput={<Cmpt type="text" {...otherProps} />}
          placeholderText={placeholder}
          disabled={disabled}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          popperPlacement="top-end"
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "scrollParent"
            }
          }}
        />
      );
    }
  }
}
