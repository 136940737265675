import React from "react";
import styled from "@emotion/styled";

// Utils
import { breakpoints, colors, hexToRgba } from "./../../../util/consts";
import typography from "./../../../util/typography";

// Images
import bannerImage from "./../../../static/lyfesalesbanner.jpg";
import firstSectionImage from "./../../../static/mybb.png";
import secondSectionImage from "./../../../static/ppfa.png";
import thirdSectionImage from "./../../../static/tsffc.png";
import forthSectionImage from "./../../../static/funds-logos.png";
import fifthSectionImage from "./../../../static/insights.jpg";

// Components
import Button from "./../../../components/Button";
import CheckIcon from "./../../../components/icons/Check";

import Card, { InverseCard } from "./Card";

const TickSection = React.memo(({ label }) => (
  <Tick>
    <TickContainer>
      <CheckIcon color={colors.secondary.main} />
    </TickContainer>
    <TickContent>{label}</TickContent>
  </Tick>
));

const Tick = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
`;

const TickContainer = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid ${hexToRgba(colors.primary.main, 0.38)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  flex: 0 0 auto;
`;

const TickContent = styled.div`
  ${typography.subtitle1};
  letter-spacing: 0.5px;
  line-height: 28px;
  flex: 1 1 auto;
`;

const ListYourBusiness = React.memo((props) => {
  const NavigateToCreateOrg = () => {
    props.history.push("/createorg");
  };
  const NavigateToDriftMe = () => {
    window.location.replace("https://drift.me/dallas3/meeting");
  };
  
  return (
    <Container>
      <FullScreenSection>
        <FSLeft>
          <FSLeftContent>
            <SectionTitle>Freedom through business automation!</SectionTitle>
            <SectionSubtitle style={{ margin: "40px 0" }}>
              Your time is your business. Using Lyfe to streamline bookings, payments and admin will free up several hours per week so you can use it more effectively.
            </SectionSubtitle>
            <Button
              variant="contained"
              color="secondary"
              style={{ padding: "17px 36px", borderRadius: 25 }}
              onClick={NavigateToCreateOrg}
            >
              List your business
            </Button>
          </FSLeftContent>
        </FSLeft>
        <FSRight />
      </FullScreenSection>
      <Content>
        <Section>
          <ImageHalf image={firstSectionImage} />
          <Half>
            <HalfContent>
              <SectionTitle>Simplify your schedule</SectionTitle>
              <SectionSubtitle style={{ margin: "24px 0" }}>
                Manage your client’s bookings and allow them to create after hour appointments using
                the most powerful and intuitive scheduling software available.
              </SectionSubtitle>
              <TickSection label="Custom &amp; group bookings" />
              <TickSection label="Automated SMS appointment reminders" />
              <TickSection label="Integrate bookings into your website" />
              <TickSection label="Reduce cancellations" />
              <TickSection label="Attract new clients with our lead-generation package" />
            </HalfContent>
          </Half>
        </Section>
        <Section>
          <Half>
            <HalfContent>
              <SectionTitle>Process payments from anywhere</SectionTitle>
              <SectionSubtitle style={{ margin: "24px 0" }}>
                Process payments online using your PC or mobile device. No need for a physical
                payment terminal or the fees that come with it.
              </SectionSubtitle>
              <TickSection label="Cut the cord with cloud-based payments" />
              <TickSection label="Optional Auto-Payments" />
              <TickSection label="Competitive rates" />
              <TickSection label="Cancellation Payments" />
              <TickSection label="Process remote Telehealth payments" />
            </HalfContent>
          </Half>
          <ImageHalf image={secondSectionImage} />
        </Section>
        <Section>
          <ImageHalf image={thirdSectionImage} />
          <Half>
            <HalfContent>
              <SectionTitle>Time-saving features that let you focus on your clients</SectionTitle>
              <SectionSubtitle style={{ margin: "24px 0" }}>
                Automate otherwise manual tasks to free up hours. Also market to new clients though
                our powerful consumer application and marketing solution.
              </SectionSubtitle>
              <TickSection label="Automated Invoicing" />
              <TickSection label="3rd Party Invoicing" />
              <TickSection label="Re-engage past clients" />
            </HalfContent>
          </Half>
        </Section>
        <Section>
          <Half>
            <HalfContent>
              <SectionTitle>Navigate Private and Public Healthcare</SectionTitle>
              <SectionSubtitle style={{ margin: "24px 0" }}>
                We make it easier than ever before to seamlessly switch between Credit Card,
                Medicare, Hicaps, and Bulk Billing.
              </SectionSubtitle>
              <TickSection label="Speed the payment and rebate process" />
              <TickSection label="You and your clients get paid quicker" />
              <TickSection label="Less headache for your client equals added value for your business" />
            </HalfContent>
          </Half>
          <ImageHalf image={forthSectionImage} />
        </Section>

        <Section>
          <ImageHalf image={fifthSectionImage} />
          <Half>
            <HalfContent>
              <SectionTitle>Business performance insight &amp; reporting</SectionTitle>
              <SectionSubtitle style={{ margin: "24px 0" }}>
                In-depth business analytics uncover what’s already working well and what parts of your business need attention.
              </SectionSubtitle>
              <TickSection label="Clear, detailed and easy to use" />
              <TickSection label="Revenue, client and referral analytics" />
              <TickSection label="Custom tagging" />
              <TickSection label="Marketing insights" />
              <TickSection label="Lead quality" />
              <TickSection label="Much more…" />
            </HalfContent>
          </Half>
        </Section>
      </Content>
      <QuoteSection>
        <QuoteContent>
          <Quote>
            <em>
              “For us, it’s the best way to keep track of our numbers and grow &amp; retain our
              client base.”
            </em>
          </Quote>
          <QuotePerson>Katie – Positive Health Psychology</QuotePerson>
        </QuoteContent>
      </QuoteSection>
      <FinalSection id="packages">
        <FinalContent>
          <SectionTitle style={{ marginBottom: 12, textAlign: "center" }}>
            More features, more power, more bookings
          </SectionTitle>
          <SectionSubtitle style={{ textAlign: "center" }}>
            Find the perfect plan for you and your needs. 
            <br />
            Try it subscription free for 30 days
            <br/>
            <strong>If you don’t love Lyfe you can cancel anytime within 3 months and we will refund your subscription fees!</strong>
          </SectionSubtitle>
          <CardsContainer style={{ marginTop: 96}}>
            <CardsContent>
            <Card
                teamSize="Promo Pack"
                price="0"
                numProviders="3"
                transactionFee="2.40"
                onClick={NavigateToCreateOrg}
              />
              <Card
                teamSize="Solo"
                price="19"
                numProviders="1"
                transactionFee="2.00"
                onClick={NavigateToCreateOrg}
              />
              <Card
                teamSize="Team"
                price="32"
                numProviders="2-5"
                transactionFee="1.90"
                onClick={NavigateToCreateOrg}
              />
              <Card
                teamSize="Medium"
                price="64"
                numProviders="6-10"
                transactionFee="1.75"
                onClick={NavigateToCreateOrg}
              />
              <Card
                teamSize="Large"
                price="110"
                numProviders="11-15"
                transactionFee="1.50"
                onClick={NavigateToCreateOrg}
              />
              <InverseCard onClick={NavigateToDriftMe} />
            </CardsContent>
          </CardsContainer>
        </FinalContent>
      </FinalSection>
    </Container>
  );
});

export default ListYourBusiness;

const Container = styled.main``;

const FullScreenSection = styled.div`
  width: 100%;
  height: calc(100vh - 69px);
  max-height: 900px;
  display: flex;
  border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
`;

const FSLeft = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoints["phone-only"]} {
    width: 100%;
  }
`;

const FSRight = styled.div`
  width: 50%;
  height: 100%;
  background: url(${bannerImage});
  background-position: center;
  background-size: cover;

  ${breakpoints["phone-only"]} {
    display: none;
  }
`;

const FSLeftContent = styled.div`
  padding: 20px;
  max-width: 520px;
`;

const Content = styled.div`
  padding: 0 24px;
  margin: 120px auto;
  max-width: 1440px;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${breakpoints["tablet-up"]} {
    max-height: 900px;
  }
`;

const Half = styled.div`
  width: 50%;
  height: calc(100vh - 69px);
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoints["phone-only"]} {
    width: 100%;
    order: 2;
  }

  ${breakpoints["tablet-up"]} {
    max-height: 900px;
  }
`;

const HalfContent = styled.div`
  max-width: 400px;
`;

const ImageHalf = styled.div`
  width: 50%;
  height: calc(100vh - 69px);
  background: url(${({ image }) => image}) no-repeat;
  background-position: center;
  background-size: contain;

  ${breakpoints["phone-only"]} {
    width: 100%;
    order: 1;
    width: calc(100% + 48px);
    background-size: cover;
    margin: 24px -24px;
  }

  ${breakpoints["tablet-up"]} {
    max-height: 900px;
  }

  @media (max-width: 743px) {
    background-size: contain;
    height: 120vw;
  }
`;

const SectionTitle = styled.h2`
  color: #2c2e3c;
  font-size: 40.39px;
  font-weight: 400;
  line-height: 60px;
  margin: 0;

  @media (max-width: 743px) {
    line-height: 1.2;
  }
`;

const SectionSubtitle = styled.div`
  ${typography.subtitle1};
  letter-spacing: 0.5px;
  line-height: 28px;
  opacity: 0.68;
`;

const QuoteSection = styled.div`
  background-color: ${colors.secondary.main};
  padding: 72px 24px;

  ${breakpoints["tablet-up"]} {
    padding: 88px 24px;
  }

  ${breakpoints["laptop-up"]} {
    padding: 120px 24px;
  }
`;

const QuoteContent = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${breakpoints["tablet-up"]} {
    max-width: 900px;
  }
`;

const Quote = styled.div`
  color: ${colors.whiteText.highEmphasis};
  font-size: 33.57px;
  line-height: 46px;
  letter-spacing: 0.5px;
  text-align: center;

  ${breakpoints["tablet-up"]} {
    font-size: 40.39px;
    line-height: 68px;
  }

  ${breakpoints["laptop-up"]} {
    font-size: 49.8px;
    line-height: 80px;
  }
`;

const QuotePerson = styled.div`
  ${typography.subtitle1};
  color: ${colors.whiteText.medEmphasis};
  letter-spacing: 0.5px;
`;

const FinalSection = styled.div`
  margin: 0 auto;
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    padding: 48px 24px;
  }

  ${breakpoints["laptop-up"]} {
    padding: 195px 40px;
  }
`;

const FinalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardsContainer = styled.div`
  overflow-x: auto;
  margin-left: -24px;
  margin-right: -24px;
  width: calc(100% + 48px);
  max-width: 100vw;
  margin-top: 96px;
  @media (min-width: 1360px) {
    max-width: 1360px;
  }
`;

const CardsContent = styled.div`
  display: flex;
`;
