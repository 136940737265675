export async function readFileBytes(file: File): Promise<Uint8Array> {
  return new Promise<Uint8Array>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function() {
      const result = reader.result as ArrayBuffer;
      resolve(new Uint8Array(result));
    }
    reader.onerror = function() {
      reject(new Error('failed to read file'));
    }
    reader.onabort = function() {
      reject(new Error("file read aborted"));
    }
    reader.readAsArrayBuffer(file);
  });
}
