/** @jsx jsx */
import { memo, Component, Fragment } from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import { connect } from "react-redux";
import { Switch, Route } from "react-router";
import { Link } from "react-router-dom";

// Utils
import { colors, hexToRgba } from "./../../../util/consts";
import { getResource } from "./../../../redux/features/resources/helpers";
import typography from "./../../../util/typography";

function mapStateToProps(state, props) {
  const {
    match: {
      params: { oid, lid, pid }
    }
  } = props;

  const Location = getResource(state.resources, "Location", { ID: lid });

  const getOrgFromLocation = Boolean(typeof Location !== "undefined" && Location.ID);

  const Organisation = getResource(state.resources, "Organisation", {
    ID: getOrgFromLocation ? Location.Org : oid
  });

  const Provider = getResource(state.resources, "User", { ID: pid });

  return {
    Organisation,
    Location,
    Provider
  };
}

const RegularRoute = connect(mapStateToProps)(
  memo(({ Organisation, Location, Provider, location }) => {
    const splitPath = location.pathname.split("/");
    const page = splitPath[splitPath.length - 1];
    const hasPage = page !== "locations" && page !== "dashboard";

    const hasLocation = typeof Location.ID !== "undefined";
    const hasProvider = typeof Provider.ID !== "undefined";

    return (
      <Fragment>
        <Link
          to={"/admin/orgs/" + Organisation.ID + "/locations"}
          css={css`
            text-decoration: none;
            flex: 0 0 auto;
          `}
        >
          <Text isActive={!hasPage && !hasLocation}>{Organisation.Name}</Text>
        </Link>
        {hasLocation && (
          <Fragment>
            <Divider />
            <Link
              to={"/admin/locs/" + Location.ID + "/dashboard"}
              css={css`
                text-decoration: none;
                flex: 0 0 auto;
              `}
            >
              <Text isActive={!hasPage}>{Location.Name}</Text>
            </Link>
          </Fragment>
        )}
        {hasPage && Location && Location.ID && (
          <Fragment>
            <Divider />
            {hasProvider ? (
              <Fragment>
                <Link
                  to={"/admin/locs/" + Location.ID + "/appointments"}
                  css={css`
                    text-decoration: none;
                    flex: 0 0 auto;
                  `}
                >
                  <Text>Appointments</Text>
                </Link>
              </Fragment>
            ) : (
              <Fragment>
                <Text
                  isActive={true}
                  css={css`
                    text-transform: capitalize;
                  `}
                >
                  {page === "createloc" ? "Create Location" : page}
                </Text>
              </Fragment>
            )}
          </Fragment>
        )}
        {hasProvider && (
          <Fragment>
            <Divider />
            <Text
              isActive={true}
              css={css`
                text-transform: capitalize;
              `}
            >
              {Provider.FirstName} {Provider.LastName}
            </Text>
          </Fragment>
        )}
      </Fragment>
    );
  })
);

const CreateOrgRoute = memo(() => (
  <Text
    isActive={true}
    css={css`
      text-transform: capitalize;
    `}
  >
    Create Organisation
  </Text>
));

const EditUserRoute = memo(() => (
  <Text
    isActive={true}
    css={css`
      text-transform: capitalize;
    `}
  >
    Edit Account
  </Text>
));

class Breadcrumbs extends Component {
  render() {
    return (
      <Container>
        <Content>
          <Divider />
          <Switch>
            <Route path="/terms-of-use" render={() => null} />
            <Route path="/privacy-policy" render={() => null} />
            <Route path="/admin/orgs/:oid" component={RegularRoute} />
            <Route path="/admin/locs/:lid/providers/:pid" component={RegularRoute} />
            <Route path="/admin/locs/:lid" component={RegularRoute} />
            <Route path="/admin/insight/orgs/:oid/loc/:lid/insights" component={RegularRoute} />
            <Route path="/createorg" component={CreateOrgRoute} />
            <Route path="/users/edit" component={EditUserRoute} />
          </Switch>
        </Content>
      </Container>
    );
  }
}

export default connect(mapStateToProps)(Breadcrumbs);

const Container = styled.div`
  margin-left: 24px;
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1;
`;

const Content = styled.div`
  height: 24px;
  display: flex;
  align-items: center;

  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none !important;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 100%;
  margin: 0 20px;
  background-color: ${hexToRgba(colors.primary.main, 0.1)};
  flex: 0 0 auto;

  &:first-of-type {
    margin-left: 0;
  }
`;

const Text = styled.span`
  ${typography.body1};
  font-weight: 600;
  letter-spacing: 0.27px;
  color: ${({ isActive }) =>
    (isActive ? colors.surfaceText.highEmphasis : colors.surfaceText.lowEmphasis)};
  flex: 0 0 auto;

  ${({ isActive }) =>
    !isActive &&
    `
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
      color: ${colors.secondary.main};
    }
  `}
`;
