import { Component } from "react";
import { connect } from "react-redux";

import apiService from "./../../redux/services/api";

import { getResource, resourceIsFetching } from "./../../redux/features/resources/helpers";

import { generateID } from "./../../util";

import * as ResourceActions from "./../../redux/features/resources/thunkactions";

import { shallowCompare } from "./ResourceListFetch";

class ResourceFetch extends Component {
  actionID = null;

  componentDidMount() {
    this.getResource();
  }
  componentWillUnmount() {
    if (this.actionID !== null) {
      apiService.cancelRequest(this.actionID);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { resource, extraData } = this.props;
    if (
      prevProps.type !== this.props.type ||
      !shallowCompare(prevProps.ids, this.props.ids) ||
      !shallowCompare(prevProps.extraData, extraData) ||
      !resource
    ) {
      this.getResource();
    }
  }
  async getResource() {
    const {
      onSuccess,
      onError,
      force,
      resource,
      extraData,
      dispatch,
      noLoginReq = false
    } = this.props;
    // Skip if we are not logged in.
    if (!noLoginReq && !this.isLoggedIn()) {
      return;
    }
    // Skip resources that are already fetching.
    if (resourceIsFetching(resource)) {
      return;
    }
    // Do we already have data?
    if (resource.$Metadata.DataStatus === "Ok" && !force) {
      return;
    }
    // Fetch the resource.
    try {
      this.actionID = generateID();
      const res = await dispatch(
        ResourceActions.action(resource, "Fetch", extraData || {}, this.actionID)
      );
      if (typeof onSuccess === "function") {
        onSuccess(res);
      }
    } catch (err) {
      if (typeof onError === "function") {
        onError(err);
      }
    }
    this.actionID = null;
  }
  isLoggedIn() {
    const { auth } = this.props;
    return auth && auth.Type === "user" && auth.UserID;
  }
  render() {
    return null;
  }
}

function mapStateToProps(state, props) {
  const { type, ids } = props;
  const { auth, resources } = state;
  return {
    auth,
    resource: getResource(resources, type, ids)
  };
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(ResourceFetch);
