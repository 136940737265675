import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
import apiService from "./../../redux/services/api";
import * as ReduxDialogs from "./../../redux/features/dialogs";

// Utils
import { breakpoints, FundType, availableFundTypes, offFundToText } from "./../../util/consts";
import { parseFormError } from "./../../util/form";

// Custom components
import Dialog from "./Dialog";
import LoadingButton from "./../../components/LoadingButton";

import Form from "./../../components/form/Form";
import SectionsContainer from "./../../components/form/SectionsContainer";
import Section from "./../../components/form/Section";
import Header from "./../../components/form/Header";
import Label from "./../../components/form/Label";
import Input from "./../../components/form/Input";
import Error from "./../../components/form/Error";
import DayPickerInput from "./../../components/form/DayPickerInput";
import FooterContainer from "./../../components/form/FooterContainer";
import { GenderSelect } from "./../../components/form/BaseSelect";
import Select from "./../../components/form/BaseSelect";
import TagsDropdown from "./../../components/elements/Dropdown/TagsDropdown";
import MobileInput from "./../../components/form/MobileInput.tsx"

// grpc
import { rpc, metadata } from "../../grpc";
import { AddUserRequest } from '../../sdk/user_pb'
import { SendEmailRequest } from '../../sdk/user_invite_pb'
import { CreateURLRequest } from '../../sdk/user_invite_pb';


// sdk
import { ListTagRequest, ReplaceClientTagsResquest } from "./../../sdk/tag_pb";

// mobx
import { observer } from "mobx-react";
import { observable } from "mobx";
import { clientTagStore } from "./../../stores/client_tag-store";
import { toastStore } from '../../stores/toast-store';

@observer
class AddClientModal extends Component {
  @observable
  tagOptions = [];
  @observable
  selectedTags = [];

  componentDidMount = async () => {
    const resTagOptions = await this.getTags();

    // set tag options based on organisation tag
    await this.onChangeTagOptions(resTagOptions);

    // clear client tag store
    await clientTagStore.deleteAll();
  }

  getTags = async () => {
    // request
    const req = new ListTagRequest();
    req.setOrganisationId(this.props.data.org.ID);

    // response
    const res = await rpc.freeFormTag.list(req, metadata());

    return res.toObject().tagsList;
  }

  onChangeTagOptions = (tagOptions) => {
    this.tagOptions = tagOptions;
  }

  onChangeSelectedTags = (selectedTags, selectedTag, action) => {
    // change selected tags to be shown in react-select
    this.selectedTags = selectedTags;

    // change selected tags in mobx store
    if (action === "select-option") {
      clientTagStore.add("tempClientId", selectedTag);
    } else if (action === "deselect-option") {
      clientTagStore.delete("tempClientId", selectedTag);
    }
  }

  closeDialog = () => {
    const { dispatch, dialogId } = this.props;
    dispatch(ReduxDialogs.actions.close(dialogId));
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      data
    } = this.props;

    return (
      <Dialog dialogId={this.props.dialogId}>
        <Container>
          <Form
            onSubmit={
              isSubmitting
                ? (e) => {
                    e.preventDefault();
                    return false;
                  }
                : handleSubmit
            }
          >
            <Header title="New Client" />
            <SectionsContainer>
              <Section>
                <Label htmlFor="addclient-FirstName">First Name</Label>
                <Input
                  id="addclient-FirstName"
                  name="FirstName"
                  type="text"
                  placeholder="First name"
                  value={values.FirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.FirstName && errors.FirstName}
                />
                {!!errors.FirstName && touched.FirstName && <Error>{errors.FirstName}</Error>}
              </Section>
              <Section>
                <Label htmlFor="addclient-LastName">Last Name</Label>
                <Input
                  id="addclient-LastName"
                  name="LastName"
                  type="text"
                  placeholder="Last name"
                  value={values.LastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.LastName && errors.LastName}
                />
                {!!errors.LastName && touched.LastName && <Error>{errors.LastName}</Error>}
              </Section>
              <Section>
                <Label htmlFor="addclient-Email">Email</Label>
                <Input
                  id="addclient-Email"
                  name="Email"
                  type="email"
                  placeholder="example@booklyfe.com"
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Email && errors.Email}
                />
                {!!errors.Email && touched.Email && <Error>{errors.Email}</Error>}
              </Section>
              <Section>
                <Label htmlFor="addclient-Phone">Phone Number</Label>
                <MobileInput
                  onCountryChange={(e)=>{values.MobileCountryCode = e}}
                  onPhoneChange={(e)=>{values.MobileNumber = e}}
                 /> 
                {!!errors.MobileNumber && touched.MobileNumber && (
                  <Error>{errors.MobileNumber}</Error>
                )}
              </Section>
              <Section>
                <Label htmlFor="addclient-DateOfBirth">Date of Birth</Label>
                <DayPickerInput
                  id="addclient-DateOfBirth"
                  name="DateOfBirth"
                  placeholder="Select a date of birth"
                  value={values.DateOfBirth}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.DateOfBirth && errors.DateOfBirth}
                />
                {!!errors.DateOfBirth && touched.DateOfBirth && <Error>{errors.DateOfBirth}</Error>}
              </Section>
              <Section>
                <Label htmlFor="addclient-Gender">Gender</Label>
                <GenderSelect
                  id="addclient-Gender"
                  name="Gender"
                  value={values.Gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {!!errors.Gender && touched.Gender && <Error>{errors.Gender}</Error>}
              </Section>
              <Section style={{ display: "flex", flexDirection: "column" }}>
                <Label>Tags</Label>
                <TagsDropdown
                  orgId = {data.org.ID}
                  tagOptions = {this.tagOptions}
                  selectedTags = {this.selectedTags}
                  onChangeSelectedTags = {this.onChangeSelectedTags}
                  onChangeTagOptions = {this.onChangeTagOptions}
                  getTags = {this.getTags}
                  isDisabled = {false}
                  isEditingClient = {false}
                  {...this.props}
                />
              </Section>
              <Section style={{ display: "flex", flexDirection: "column" }}>
                <Label>Preferred Payment Method</Label>
                <Select
                  id="addclient-payment-method"
                  name="preferred_fund_type"
                  value={values.preferred_fund_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.preferred_fund_type && errors.preferred_fund_type}
                  style={{ width: 200 }}
                >
                  {availableFundTypes.map((v) =>
                    <option key={v} value={v}>{offFundToText(v)}</option>
                  )}
                </Select>
                {!!errors.preferred_fund_type && touched.preferred_fund_type && <Error>{errors.preferred_fund_type}</Error>}
              </Section>
            </SectionsContainer>

            <FooterContainer>
              <LoadingButton
                style={{
                  minWidth: 150
                }}
                loading={isSubmitting}
                variant="contained"
                color="secondary"
                type="submit"
              >
                Save
              </LoadingButton>
            </FooterContainer>
          </Form>
        </Container>
      </Dialog>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ data }) => ({
    FirstName: "",
    LastName: "",
    Email: "",
    MobileNumber: "",
    MobileCountryCode: "",
    DateOfBirth: "",
    Gender: "Unknown",
    preferred_fund_type: FundType.CreditCard,
  }),
  validationSchema: Yup.object().shape({
    FirstName: Yup.string().required("First name is required"),
    LastName: Yup.string().required("Last name is required"),
    Email: Yup.lazy((v)=>(
        Yup.string()
          .email("Email is invalid")
          .notRequired()
    )),
    MobileNumber: Yup.lazy((v)=>(
        Yup.string()
          .min(5, "Number is too short")
          .notRequired()
    )),
    Gender: Yup.string().required("Please enter the client's gender"),
    DateOfBirth: Yup.date().notRequired(),
    preferred_fund_type: Yup.string().required("Please choose a preferred payment method"),
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    //TODO: add proper validation for mobile and email
    try {
      // Create a client.
      const res = await apiService.performRequest({
        method: "post",
        path: "/api/v1/clients",
        data: {
          Org: props.data.org.ID,
          Location: props.data.loc.ID,
          FirstName: values.FirstName.trim(),
          LastName: values.LastName.trim(),
          Email: !values.Email ? null : values.Email.trim(),
          MobileNumber: !values.MobileNumber ? null : values.MobileNumber,
          MobileCountryCode: values.MobileNumber ? "AU" : null,
          DateOfBirth: !values.DateOfBirth ? null : values.DateOfBirth,
          Gender: values.Gender,
          FundProperties: {},
          Referrals: [],
          preferred_fund_type: values.preferred_fund_type
        }
      });
      // request
      const req = new ReplaceClientTagsResquest();
      req.setOrganisationId(res.Payload.Org);
      req.setClientId(res.Payload.ID);

      const tagListId = [];
      const clientTags = clientTagStore.get("tempClientId");
      if (clientTags) {
        clientTags.map((tagList) => { tagListId.push(tagList.id); });
        req.setTagsIdList(tagListId);
        // response
        await rpc.freeFormTag.replaceClientTags(req, metadata());
      }

      try {
      // create User Stub for Client
      let addUserResponse = {}
        const userReq = new AddUserRequest;
        userReq.setFirstName(values.FirstName.trim())
        userReq.setLastName(values.LastName.trim())
        userReq.setEmail(!values.Email ? null : values.Email.trim())
        userReq.setMobileNumber(!values.MobileNumber ? null : values.MobileNumber)
        userReq.setMobileCountryCode(values.MobileNumber ? "AU" : null)
        userReq.setGender(values.Gender)
        userReq.setDateOfBirth(!values.DateOfBirth ? null : values.DateOfBirth)
        userReq.setClientId(res.Payload.ID)
        const userRes = await rpc.user.addUser(userReq)
        addUserResponse = userRes.toObject()

      // create URL
      let addURLResponse = {}
      const urlReq = new CreateURLRequest;
      urlReq.setUserId(addUserResponse.id)
      const urlRes = await rpc.userInvite.createURL(urlReq)
      addURLResponse = urlRes.toObject()
    
      const emailReq = new SendEmailRequest;
        emailReq.setUserId(addUserResponse.id)
        emailReq.setOrgId(props.data.org.ID)
        emailReq.setUrl(addURLResponse.url)
      const emailRes = await rpc.userInvite.sendEmail(emailReq)
      } catch(err) {
        props.toastManager.add("Client connected to existing User account.", { appearance: "success" });
      }

      props.data.cb && props.data.cb(res.Payload);
      props.toastManager.add("Client added. Please set up the payment method, but you can also do it when making the payment.", { appearance: "success" });
      props.dispatch(
        ReduxDialogs.actions.swap(
          props.dialogId,
          ReduxDialogs.DialogType.ClientActionDialog,
          {
            item: res.Payload,
            initialTab: "Payment"
          }
      ));
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }

    setSubmitting(false);
  },
  displayName: "AddClient"
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(AddClientModal);

const Container = styled.div`
  width: 100vw;
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    padding: 64px 90px;
    max-width: 700px;
  }
`;
