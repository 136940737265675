import React, { PureComponent } from "react";
import * as fns from "date-fns";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

import { colors, hexToRgba } from "./../../../util/consts";
import typography from "./../../../util/typography";
import { sortOfferings } from "./../../../util/consts";

import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { parseFormError } from "./../../../util/form";

// components
import { SplitDialog, LeftContent } from "../SplitDialog";
import ResourceFetch from "./../../../components/elements/ResourceFetch";
import ResourceRender from "./../../../components/elements/ResourceRender";
import UserBlockDeprecated from "./../../../components/elements/UserBlock";
import PagesContainer, { Page } from "./../../../components/elements/PagesContainer";
import Avatar from "./../../../components/Avatar";
import ButtonBase from "./../../../components/ButtonBase";

import Entry from "./Entry";
// import Group from "./Group";
import { Payment } from "./Payment";

import { toPriceString, subVarToPercentage } from "./../../../util";
import { offFundToText } from "./../../../util/consts";

// Modal Components

export function getCurrentBookingClient(booking, groupClient) {
  if (booking.Type === "Single") {
    return booking.Clients[0];
  } else if (groupClient) {
    return booking.Clients.find((c) => c.Client.ID === groupClient);
  } else {
    return null;
  }
}

class CheckBookingProcessingP extends PureComponent {
  checkTimer = null;

  componentDidMount() {
    const { booking } = this.props;
    if (this.checkBookingProcessing(booking) && !this.checkBookingError(booking)) {
      this.setCheckTimer();
    }
  }

  componentDidUpdate(prevProps) {
    const { booking } = this.props;
    if (this.checkBookingProcessing(booking) && !this.checkBookingError(booking)) {
      this.setCheckTimer();
    } else {
      this.clearTimer();
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  checkBookingProcessing(bk) {
    return bk.Clients.filter((v) => v.PaymentStatus === "Processing").length > 0;
  }

  checkBookingError(bk) {
    return (
      bk.Clients.filter(
        (v) =>
          v.PaymentInvoices.findIndex((u) => u.Status === "Error") >= 0 ||
          v.FundInvoices.findIndex((u) => u.Status === "Error") >= 0
      ).length > 0
    );
  }

  clearTimer = () => {
    if (this.checkTimer) {
      clearInterval(this.checkTimer);
      this.checkTimer = null;
    }
  };

  setCheckTimer = () => {
    if (this.checkTimer) {
      return;
    }
    this.checkTimer = setInterval(async () => {
      const { booking, dispatch, toastManager } = this.props;
      try {
        const res = await dispatch(ResourceActions.action(booking, "Fetch"));
        if (this.checkBookingProcessing(booking) && this.checkBookingError(booking)) {
          toastManager.add("Some payments/funds failed to process.", { appearance: "error" });
          this.clearTimer();
          return;
        } else if (res.Payload.Status === "Completed") {
          toastManager.add("Payment/Funds successfully processed.", { appearance: "success" });
          this.clearTimer();
          return;
        }
      } catch (err) {
        parseFormError(err, {}, () => {}, this.props);
      }
    }, 5000);
  };

  render() {
    return null;
  }
}

const CheckBookingProcessing = compose(
  connect(),
  withToastManager
)(CheckBookingProcessingP);

export default class AppointmentDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      groupClient: props.data.client || null
    };
  }

  changeGroupClient = (client) => {
    this.setState({ groupClient: client });
  };

  clientBreakdown = React.memo(({ resource: booking, client }) => {
    if (client.Status === "Cancelled" || client.PaymentStatus !== "Pending") {
      return sortOfferings(client.Offerings).map((off) => (
        <LeftSection key={client.Client.ID + off.Offering.ID}>
          <LeftFooterText fontWeight={400}>
            {off.Offering.Name} {off.FundType === "None" ? "" : `- ${offFundToText(off.FundType)}`}
          </LeftFooterText>
          <LeftFooterText fontWeight={700}>
            {"$" + toPriceString(off.SubtotalCharge, true)}
          </LeftFooterText>
        </LeftSection>
      ));
    } else {
      return sortOfferings([...booking.GroupOfferings, ...client.Offerings]).map((off) => (
        <LeftSection key={client.Client.ID + off.Offering.ID}>
          <LeftFooterText fontWeight={400}>
            {off.Offering.Name} {off.FundType === "None" ? "" : `- ${offFundToText(off.FundType)}`}
          </LeftFooterText>
          <LeftFooterText fontWeight={700}>
            {"$" + toPriceString(off.SubtotalCharge, true)}
          </LeftFooterText>
        </LeftSection>
      ));
    }
  });

  clientTotals = React.memo(({ resource: booking }) =>
    booking.Clients.map((cl) => {
      if (cl.Status === "Cancelled") {
        return (
          <LeftSectionButton
            key={cl.Client.ID}
            onClick={() => this.setState({ groupClient: cl.Client.ID })}
          >
            <LeftSection key={booking.ID + cl.Client.ID}>
              <LeftFooterText fontWeight={400}>
                {cl.Client.FirstName} {cl.Client.LastName} (Cancelled)
              </LeftFooterText>
              <LeftFooterText fontWeight={700}>
                {"$" + toPriceString(cl.SubtotalCharge, true)}
              </LeftFooterText>
            </LeftSection>
          </LeftSectionButton>
        );
      } else if (cl.Status === "Rejected") {
        return null;
      } else {
        let extra = "";
        if (cl.PaymentStatus === "Processing") {
          if ((cl.PaymentInvoices || []).findIndex((v) => v.Status === "Error") >= 0) {
            extra = " (Card Error)";
          } else if ((cl.FundInvoices || []).findIndex((v) => v.Status === "Error")) {
            extra = " (Fund Error)";
          } else {
            extra = " (Processing)";
          }
        } else if (cl.PaymentStatus === "Completed") {
          extra = " (Paid)";
        }
        return (
          <LeftSectionButton
            key={cl.Client.ID}
            onClick={() => this.setState({ groupClient: cl.Client.ID })}
          >
            <LeftSection key={booking.ID + cl.Client.ID}>
              <LeftFooterText fontWeight={400}>
                {cl.Client.FirstName} {cl.Client.LastName}
                {extra}
              </LeftFooterText>
              <LeftFooterText fontWeight={700}>
                {"$" + toPriceString(cl.SubtotalCharge, true)}
              </LeftFooterText>
            </LeftSection>
          </LeftSectionButton>
        );
      }
    })
  );

  leftComponent = React.memo(({ resource: booking, groupClient }) => {
    const date = fns.parse(booking.StartDate, "yyyy-MM-dd", new Date());
    const stime = fns.parse(booking.StartTime, "HH:mm:ss", new Date());
    const etime = fns.parse(booking.EndTime, "HH:mm:ss", new Date());
    const client = getCurrentBookingClient(booking, groupClient);
    const ccardFee = subVarToPercentage(booking.Location.Org.SubscriptionDetails.CardVar);

    let subtotalCharge = client ? client.SubtotalCharge : booking.SubtotalCharge;
    let lyfeCreditCardFee = client ? client.LyfeCreditCardFee : booking.LyfeCreditCardFee;
    let totalTax = client ? client.TotalTax : booking.TotalTax;
    let totalCharge = client ? client.TotalCharge : booking.TotalCharge;
    const subTotalNoTax = subtotalCharge - totalTax;
    if (!booking.MayChargeCancellationFee) {
      if (booking.Status === "Cancelled" || (client && client.Status === "Cancelled")) {
        subtotalCharge = 0.0;
        lyfeCreditCardFee = 0.0;
        totalTax = 0.0;
        totalCharge = 0.0;
      }
    }

    return (
      <LeftContent style={{ padding: 0 }}>
        <LeftHeader>
          {booking.Type === "Single" ? (
            <UserBlockDeprecated type="Client" user={client.Client} alt={""} size="small" />
          ) : (
            <GBHeadContainer>
              <Avatar letters={`GB`} size={32} />
              <GBHeadTitle>Group Booking</GBHeadTitle>
            </GBHeadContainer>
          )}
        </LeftHeader>
        <Divider />
        <LeftHeader>
          <LeftHeaderTitle>{fns.format(date, "EEEE, dd/MM/yyyy")}</LeftHeaderTitle>
          <LeftHeaderBody>
            {fns.format(stime, "hh:mma")} - {fns.format(etime, "hh:mma")}
          </LeftHeaderBody>
          <LeftHeaderBody>{booking.Location.Name}</LeftHeaderBody>
          <LeftHeaderBody>
            {booking.Provider.FirstName} {booking.Provider.LastName}
          </LeftHeaderBody>
          <LeftHeaderBody>#{booking.FriendlyID}</LeftHeaderBody>
        </LeftHeader>
        <Divider />
        <LeftMain>
          {client
            ? React.createElement(this.clientBreakdown, { resource: booking, client })
            : React.createElement(this.clientTotals, { resource: booking })}
        </LeftMain>
        <Divider />
        <LeftFooter>
          <LeftSection>
            <LeftFooterText fontWeight={600}>
              {client && client.Status === "Cancelled" ? "Cancellation Fee" : "Sub Total"}
            </LeftFooterText>
            <LeftFooterText fontWeight={700}>
              ${toPriceString(subtotalCharge, true)}
            </LeftFooterText>
          </LeftSection>
          {(client ? client.FundRebate : booking.FundRebate) > 0 && (
            <LeftSection style={{ opacity: 0.7 }}>
              <LeftFooterText fontWeight={400}>Rebate (estimated)</LeftFooterText>
              <LeftFooterText fontWeight={400}>
                -${toPriceString(client ? client.FundRebate : booking.FundRebate, true)}
              </LeftFooterText>
            </LeftSection>
          )}

          {(lyfeCreditCardFee) > 0 && (
            <LeftSection>
              <LeftFooterText fontWeight={600}>
                Transaction Fee <LeftFooterSubText>({ccardFee})</LeftFooterSubText>
              </LeftFooterText>
              <LeftFooterText fontWeight={700}>
                $
                {toPriceString(lyfeCreditCardFee, true)}
              </LeftFooterText>
            </LeftSection>
          )}
        </LeftFooter>
        <Divider />
        <LeftFooter>
          { toPriceString(totalTax, true) === "0.00"? <React.Fragment/> :
          <LeftSection>
            <LeftFooterText fontWeight={600}>
              Total <LeftFooterSubText>(Excl. GST)</LeftFooterSubText>
            </LeftFooterText>
            <LeftFooterText fontWeight={700}>
              ${toPriceString(subTotalNoTax, true)}
            </LeftFooterText>
          </LeftSection>
            }
          <LeftSection>
            <LeftFooterText fontWeight={600}>
              GST <LeftFooterSubText>(incl)</LeftFooterSubText>
            </LeftFooterText>
            <LeftFooterText fontWeight={700}>
              ${toPriceString(totalTax, true)}
            </LeftFooterText>
          </LeftSection>
          <LeftSection>
            <LeftFooterText fontWeight={600}>
              Total <LeftFooterSubText>(to client)</LeftFooterSubText>
            </LeftFooterText>
            <LeftFooterText fontWeight={700}>
              ${toPriceString(totalCharge, true)}
            </LeftFooterText>
          </LeftSection>
        </LeftFooter>
      </LeftContent>
    );
  });

  rightComponent = React.memo((props) => {
    let initialPage = "entry";

    if (props.gotoPayment) {
      initialPage = "payment";
    }

    return (
      <PagesContainer
        ref={this.pagesRef}
        extraProps={props}
        initialPage={initialPage}
        initialData={{}}
      >
        <Page name="entry" component={Entry} />
        <Page name="payment" component={Payment} />
      </PagesContainer>
    );
  });

  contentComponent = React.memo((props) => (
    <React.Fragment>
      <CheckBookingProcessing booking={props.resource} />
      <SplitDialog
        data={props.data}
        dialogId={props.dialogId}
        leftRender={this.leftComponent}
        rightRender={this.rightComponent}
        forwardedProps={props}
      />
    </React.Fragment>
  ));

  render() {
    const { data } = this.props;
    return (
      <React.Fragment>
        <ResourceFetch type="LocBooking" ids={data.booking} force />
        <ResourceRender
          type="LocBooking"
          ids={data.booking}
          propsReq={["Clients", "GroupOfferings"]}
          compSuccess={this.contentComponent}
          forwardedProps={{
            ...this.props,
            ...this.state,
            setClient: this.changeGroupClient,
            gotoClient: data.client,
            gotoPayment: data.payment
          }}
          denorm={true}
        />
      </React.Fragment>
    );
  }
}

export const TabContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 100px;
`;

export const TabHeader = styled.div`
  margin-top: 30px;
  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 15.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
`;

const LeftHeader = styled.div`
  flex: 0 0 auto;
  padding: 32px 32px 22px 32px;
`;

const LeftSection = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftSectionButton = styled(ButtonBase)`
  width: 100%;
  cursor: pointer;
  background: none;

  &:hover {
    background: #fafafa;
  }
`;

const LeftMain = styled.div`
  flex: 1 0 auto;
  padding: 18px 30px;
`;

const LeftFooter = styled.div`
  flex: 0 0 auto;
  padding: 18px 30px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${hexToRgba(colors.primary.main, 0.15)};
`;

const LeftHeaderTitle = styled.div`
  ${typography.subtitle1};
  letter-spacing: 1.44px;
  line-height: 20px;
  font-weight: 600;
  margin: 3px 0;
`;

const LeftHeaderBody = styled.div`
  ${typography.body1};
  letter-spacing: 1.44px;
  line-height: 20px;
  color: ${colors.surfaceText.medEmphasis};
  margin: 3px 0;
`;

const LeftFooterText = styled.div`
  ${typography.subtitle1};
  letter-spacing: 1.44px;
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const LeftFooterSubText = styled.span`
  ${typography.overline};
  letter-spacing: 1.44px;
  font-weight: 400;
  color: ${colors.surfaceText.lowEmphasis};
`;

const GBHeadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const GBHeadTitle = styled.div`
  font-size: 13.82px;
  font-weight: normal;
  letter-spacing: 0.09px;
  line-height: 19px;
  color: #2c2e3c;
  margin: 0;
  margin-left: 10px;
`;

export const TabDetail = styled.div`
  font-weight: 400;
`;
