import React, { Component } from "react";
import LoadingIcon from "./../../../components/icons/Loading";
import { GroupOverview } from "./PaymentWizard/GroupOverview";
import { observer } from "mobx-react";
import { observable, computed, toJS } from "mobx";
import { Booking, BookingClient, ProcessBookingRequest, BookingStatus } from "./../../../sdk/bookings_pb";
import {
  LargeButtonText,
  RightBottomButtonContainer,
  LargeBottomButton,
  BackButton,
  Entry
} from "./Entry";
import { LoadingIndicator } from "./../../../util/loading";
import { ToastManager } from "react-toast-notifications";
import { rpc, metadata } from "./../../../grpc";
import { bookingStore } from "./../../../stores/booking-store";
import { Payment } from "./Payment";
import { AccountItem, AccItemStatus } from "./../../../sdk/account_items_pb";
import { requiredFundsForBooking } from "./../../../util/bookings";
import { accItemStore } from "./../../../stores/acc_item-store";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { ResourceTypes } from './../../../redux/features/resources/definitions';
import { FundType } from './../../../sdk/offerings_pb';
import { toastStore } from './../../../stores/toast-store';

interface Props {
  booking: Booking.AsObject;
  onRequestClose: () => void;
  dispatch?: any;
  resource?: any;
  toastManager?: ToastManager;
}

@observer
export class GroupPayment extends Component<Props> {
  @observable
  private indicator = new LoadingIndicator();
  @observable
  private clientSelected = new BookingClient().toObject();
  private interval: any;
  @observable
  private mounted = false;

  @computed
  get accountItems() {
    const accStoreFilter = accItemStore.all();
    return accStoreFilter.filter(
      (ai: AccountItem.AsObject) => ai.booking!.id == this.props.booking.id
    );
  }

  async componentDidMount() {
    const { booking, dispatch } = this.props;
    await accItemStore.loadByBookingClient(booking.id);
    dispatch(
      ResourceActions.action(
        { $Metadata: { Type: ResourceTypes.LocBooking } as any, ID: booking.id },
        "Fetch"
      )
    );

    this.mounted = true; // Toggle to activate refresh
    // Refresh stores every 5 seconds
    this.loadStores(booking);
  }

  componentWillUnmount() {
    // stop call execution
    this.mounted = false;
    clearInterval(this.interval);
  }

  async loadStores(booking: Booking.AsObject) {
    this.interval = setInterval(() => {
      if (
        this.mounted &&
        this.accountItems
          .filter(
            (ai) => ai.status != AccItemStatus.ACC_ITEM_ERROR && ai.fundType != FundType.THIRD_PARTY_INVOICE
          )
          .filter(
            (ai) => ai.status == AccItemStatus.ACC_ITEM_PROCESSING || ai.status == AccItemStatus.ACC_ITEM_CREATED
          ).length > 0
      ) {
        bookingStore.load(booking.id);
        accItemStore.loadByBookingClient(booking.id);
        // to update Redux store
        this.props.dispatch(
          ResourceActions.action(
            { $Metadata: { Type: ResourceTypes.LocBooking } as any, ID: booking.id },
            "Fetch"
          )
        );
      }
    }, 5000);
  }

  onBackClicked = () => {
    this.props.onRequestClose();
  };

  async processGroupBooking(booking: Booking.AsObject) {
    const { toastManager } = this.props;
    await this.indicator.while(async () => {
      try {
        const req = new ProcessBookingRequest();
        req.setBookingId(booking.id);
        const res = await rpc.bookings.processBooking(req, metadata());
        bookingStore.add(res.toObject().booking!);
        await accItemStore.loadByBookingClient(booking.id);
        this.loadStores(booking);
        toastManager!.add("Group booking processed successfully.", { appearance: "success" });
      } catch (err) {
        toastStore.grpcToastError(err);
      }
    });
  }

  checkProcessButtonDisabledGroupBooking() {
    const { booking } = this.props;
    if (booking.status !== BookingStatus.BOOKING_CREATED) {
      return true;
    }

    if (booking.clientsList.length === 0) {
      return true;
    }

    if (booking.clientsList.filter( client => client.status !== BookingClient.Status.APPROVED).length > 0) {
      return true;
    }
      
      // check all clients have required funds
      // if (requiredFundsForBooking(booking, client, this.accountItems).missing.length > 0){
      //   return true;
      // }

      // if (this.shouldShowThirdParty(client)) {
      //   if (!client.ThirdPartyInvoices || client.ThirdPartyInvoices.length === 0) {
      //     return false;
      //   }
      // }
  

    return false;
  }

  onClientSelect = (client: BookingClient.AsObject) => {
    this.clientSelected = client;
  };

  render() {
    const { booking, dispatch, resource, toastManager } = this.props;

    return this.clientSelected.paymentStatus ? (
      <Payment
        booking={booking}
        client={this.clientSelected}
        onRequestClose={() => (this.clientSelected = new BookingClient().toObject())}
        dispatch={dispatch}
        resource={resource}
        toastManager={toastManager}
      />
    ) : (
      <>
        <GroupOverview booking={booking} onClientSelect={this.onClientSelect} indicator={this.indicator}  {...this.props} />
        {booking.status === BookingStatus.BOOKING_CREATED && (
          <RightBottomButtonContainer>
            <LargeBottomButton
              disabled={this.checkProcessButtonDisabledGroupBooking()}
              onClick={() => this.processGroupBooking(this.props.booking!)}
            >
              {this.indicator.isLoading() ? (
                <LoadingIcon width={16} height={16} color="#FFF" />
              ) : (
                <LargeButtonText>Process All</LargeButtonText>
              )}
            </LargeBottomButton>
          </RightBottomButtonContainer>
        )}
        <BackButton onClick={this.onBackClicked}>Back</BackButton>
      </>
    );
  }
}
