import React from 'react';
import styled from '@emotion/styled';

export const FormSplitContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`;


export const FormSplitItem = styled.div`
  flex: 1 1 0;

  &:first-of-type {
    margin-right: 24px;
  }
`;
