import React from 'react';
import SearchInput from './form/SearchInput';
import { rpc, metadata } from './../grpc';
import { ListOfferingsRequest, Offering } from './../sdk/offerings_pb';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { chargeToText } from './../util/money';
import { durationToText } from './../util/duration';
import { throttle } from 'lodash';

interface Props {
  locationId: string;
  onSelect: (offering: Offering.AsObject | undefined) => void;
}

@observer
export class LocationOfferingSelector extends React.Component<Props> {

  @observable
  items = new Array<Offering.AsObject>();

  async componentDidMount() {
    await this.load();
  }

  private load = throttle(async (search: string = '') => {
    const req = new ListOfferingsRequest();
    req.setLocationId(this.props.locationId);
    req.setSearchFilter(search)
    const res = await rpc.offerings.list(req, metadata())
    this.items = res.toObject().offeringsList;
  }, 200)

  private label(item: Offering.AsObject) {
    return `${item.name} - ${chargeToText(item.fee!)} (${durationToText(item.duration)})`
  }

  render() {
    return (
      <SearchInput
        name="product or service"
        placeholder="Select a provided service..."
        hasClearButton
        items={this.items.map(item => ({
          label: this.label(item),
          value: item,
        }))}
        setFieldValue={(_, value) => {
          this.props.onSelect(value);
        }}
        onInputValueChange={(value) => this.load(value)}
      />
    );
  }
}
