import { createAction } from "typesafe-actions";
import DialogType from "./ids";
import { generateID } from "./../../../util";

interface DialogPayloadOpen {
  id: string;
  type: DialogType;
  data: any;
}

interface DialogPayloadSwitch extends DialogPayloadOpen {
  closeID: string;
}

interface DialogPayloadClose {
  id: string;
}

interface DialogPayloadSetData {
  id: string;
  data: any;
}

// Actions
export const open = createAction("Dialog/Open", (resolve) => {
  return (type: DialogType, data: any = {}) => resolve({
    id: generateID(),
    type,
    data,
  } as DialogPayloadOpen);
});

export const swap = createAction("Dialog/Swap", (resolve) => {
  return (closeID: string, type: DialogType, data: any = {}) => resolve({
    closeID,
    id: generateID(),
    type,
    data
  } as DialogPayloadSwitch);
});

export const close = createAction("Dialog/Close", (resolve) => {
  return (id: string) => resolve({
    id
  } as DialogPayloadClose);
});

export const setData = createAction("Dialog/SetData", (resolve) => {
  return (id: string, data: any = {}) => resolve({
    id,
    data
  } as DialogPayloadSetData);
});
