import styled from '@emotion/styled';
import * as React from 'react';
import Divider from './../../../../components/Divider';
import { Booking, BookingClient } from './../../../../sdk/bookings_pb';
import { isGroupBooking } from './../../../../util/bookings';
import { LeftContent } from '../../SplitDialog';
import { BreakdownHeader } from './BreakdownHeader';
import { ClientBreakdown } from './ClientBreakdown';
import { GroupBreakdown } from './GroupBreakdown';
import { BreakdownFooter } from './BreakdownFooter';
import { bookingStore } from './../../../../stores/booking-store';
import { toJS } from 'mobx';

interface Props {
  booking: Booking.AsObject;
  client?: BookingClient.AsObject;
}

export class BookingBreakdown extends React.Component<Props> {
  
  /* 
  This function select only one client by leaving only 1 client on the booking store.
  The back button in the UI, function restoreBookingDetails() from Entry.tsx file 
  Restores back the number of clients to the booking store.
  */
  onGroupClientClick(client: BookingClient.AsObject){
    // delete all clients from store leaving only the selected one
    bookingStore.get(client.bookingId)!.clientsList.splice(
      0, // Start removing from index
      bookingStore.get(client.bookingId)!.clientsList.length, // Remove elements count
      client // Add element
      ); 
  }

  render() {
    const { booking, client } = this.props;
    return (
      <LeftContent style={{ padding: 0 }}>
        <BreakdownHeader booking={booking} client={client} />
        <Divider />
        <LeftMain>
          {client
            ? <ClientBreakdown booking={booking} client={client} />
            : <GroupBreakdown booking={booking} onClick={this.onGroupClientClick}/>
          }
        </LeftMain>
        <Divider />
        <BreakdownFooter booking={booking} client={client} />
      </LeftContent>
    );
  }
}

const LeftMain = styled.div`
  flex: 1 0 auto;
  padding: 18px 30px;
`;

export const TabDetail = styled.div`
  font-weight: 400;
`;
