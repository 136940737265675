import React, { Component } from "react";
import Select from "react-select";

interface Props {
  options: any;
  placeholder: string;
  selectedOption: any;
  handleChange: any;
}

export default class Dropdown extends Component<Props> {
  render() {
    const { options, placeholder, selectedOption, handleChange } = this.props;

    return (
      <Select
        placeholder={placeholder}
        value={selectedOption}
        onChange={handleChange}
        options={options}
      />
    );
  }
}
