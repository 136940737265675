import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { colors } from "./../../util/consts";

class CheckRadioBox extends PureComponent {
  static propTypes = {
    types: PropTypes.oneOf(["checkbox", "radio"])
  };

  static defaultProps = {
    type: "checkbox"
  };

  handleChange = (e) => {
    const { onChange, type, name, values, value } = this.props;
    if (type === "checkbox" && values) {
      const fVals = values.filter((v) => v !== value);
      onChange({
        target: {
          name,
          value: fVals.length === values.length ? [...values, value] : fVals
        }
      });
    } else if (type === "checkbox") {
      onChange({
        target: {
          name,
          value: !value
        }
      });
    } else {
      onChange(e);
    }
  };

  render() {
    const { children, type, checked, values, value, ...otherProps } = this.props;
    const isChecked = type === "checkbox" && values ? values.indexOf(value) >= 0 : checked;
    return (
      <Label htmlFor={this.props.id} disabled={otherProps.disabled}>
        <Input
          {...otherProps}
          type={type}
          value={value}
          checked={isChecked}
          onChange={this.handleChange}
        />
        <Checkmark type={type}>
          {type === "checkbox" && isChecked &&
            <svg fill={colors.secondary.main} width="15" height="15" viewBox="0 0 24 24"><path d="M9 22l-10-10.598 2.798-2.859 7.149 7.473 13.144-14.016 2.909 2.806z"/></svg>
          }
        </Checkmark>
        {children}
      </Label>
    );
  }
}

export default CheckRadioBox;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 2px solid ${colors.primary[50]};
  border-radius: ${props => props.type === 'checkbox' ? '6px' : '50%' };
  transition: border-color 300ms ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    display: ${props => props.type === 'checkbox' ? 'none' : 'static'};
    top: 4px;
    left: 4px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 300ms ease;
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${Checkmark}::after {
    background-color: ${colors.secondary.main};
  }

  &:checked ~ ${Checkmark} {
    border-color: ${colors.secondary.main};
  }
`;

const Label = styled.label`
  display: flex;
  position: relative;
  padding-left: 33px;
  user-select: none;

  color: ${colors.blackText.highEmphasis};
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 25px;

  ${({ disabled })=>!disabled && `
    cursor: pointer;
    &:hover ${Input} ~ ${Checkmark} {
      border-color: ${colors.secondary.main};
    }
  `}
`;
