import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
// import apiService from "./../../../redux/services/api";

// Components
import Form from "./../../../components/form/Form";
import Header, { HeaderSubtitle } from "./../../../components/form/Header";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import Error from "./../../../components/form/Error";
import MediaInput from "./../../../components/form/MediaInput";
import FooterContainer from "./../../../components/form/FooterContainer";

import LoadingButton from "./../../../components/LoadingButton";
import Button from "./../../../components/Button";

import TextArea from "./../../../components/form/TextArea";

class OrgCreateAccount_Desc extends Component {
  componentWillUnmount() {
    this.props.setData({ ...this.props.values });
  }



  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      setFieldValue,
      setFieldTouched,
      setFieldError,
      pageData
    } = this.props;

    const handleGoBack = (e)=>{
      e.preventDefault();
     this.props.setPage("general")
    }

    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <Header
          title="Description"
          subtitle="This will be part of your profile when people search for you."
        />
        <SectionsContainer>
          <Section>
            <TextArea
              id="createorg-description"
              name="Description"
              type="textarea"
              placeholder='Enter your business description - Example: “At Lyfe Health we take your health services seriously. We offer premium services from the top health providers in Australia. Our services include, Physiotherapy &amp; Psychology."'
              autoFocus={true}
              value={values.Description}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={8}
              error={touched.Description && errors.Description}
            />
            {!!errors.Description && touched.Description && <Error>{errors.Description}</Error>}
          </Section>
        </SectionsContainer>
        <Header
          title="Logo"
        />
        <HeaderSubtitle>This will be displayed in your invoices and tax receipts. <br/> Ideal size for the image is 512px x 512px.</HeaderSubtitle>
        <SectionsContainer>
          <Section>
            <MediaInput
              type="avatar"
              name="Logo"
              value={values.Logo}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setFieldError={setFieldError}
            />
            {!!errors.Logo && touched.Logo && <Error>{errors.Logo}</Error>}
          </Section>
        </SectionsContainer>
        <Header
          title="Market to your clients"
        />
        <HeaderSubtitle> This photo is used as a banner and thumbnail image for your profile. It should represent who you are and not have a logo or text.
        Ideal size for the image is 1024px x 512px. If you are struggling to find an image you can get  a <a href="https://unsplash.com/search/photos/health" target="_blank">free one from Unsplash</a></HeaderSubtitle>
        <SectionsContainer>
          <Section>
            <MediaInput
              type="banner"
              name="Banner"
              value={values.Banner}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setFieldError={setFieldError}
            />
            {!!errors.Banner && touched.Banner && <Error>{errors.Banner}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
        {/* <Button
            style={{ width: 150, marginTop: "40px", marginRight: "5px", backgroundColor: "rgba(44,46,60,0.12)", color:"rgba(0,0,0,0.38)"}}
            type="button"
            onClick={(handleGoBack)}
          >
            Go Back
          </Button> */}
          <LoadingButton
            style={{ width: 150, marginTop: "40px" }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.Description && !pageData.Banner}
          >
            Continue
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData }) => ({
    Description: pageData.Description || "",
    Logo: pageData.Logo || "",
    Banner: pageData.Banner || ""

  }),
  validationSchema: Yup.object().shape({
    Description: Yup.string().required("Description is required."),
    Logo: Yup.string().required("Logo is required."),
    Banner: Yup.string().required("Banner Image is required.")
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    props.setPage("subscription", {
      Description: values.Description,
      Logo: values.Logo,
      Banner: values.Banner
    });
    setSubmitting(false);
  },
  displayName: "OrgCreateAccountDesc"
});

export default compose(
  connect((state) => ({})),
  withToastManager,
  formikEnhancer
)(OrgCreateAccount_Desc);
