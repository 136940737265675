import { APIError } from "./../redux/services/api";

export function findPlaceDetails(address, addressField) {
  return new Promise((resolve, reject)=>{
    const geocodeService = new google.maps.Geocoder();

    geocodeService.geocode(
      {
        address,
      },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(place[0]); // place_id
        } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          reject(
            new APIError(
              "get",
              "",
              {
                Status: "Bad Request",
                StatusCode: 400,
                Errors: [
                  {
                    ErrorCode: "internal_error",
                    FieldType: "body",
                    FieldName: addressField,
                    UserContext: "Internal error",
                    UserMessage: "Could not find this address on Google Maps"
                  }
                ]
              }
            )
          );
        } else {
          reject(
            new APIError(
              "get",
              "",
              {
                Status: "Bad Request",
                StatusCode: 400,
                Errors: [
                  {
                    ErrorCode: "internal_error",
                    FieldType: "body",
                    FieldName: addressField,
                    UserContext: "Internal error",
                    UserMessage: `Failed to get address inforomation from google with status ${status}`
                  }
                ]
              }
            )
          );
        }
      }
    );
  });
}

export function getAddressComponent(det, comp, short) {
  for (let i = 0; i < det.address_components.length; i++) {
    if (det.address_components[i].types.indexOf(comp) >= 0) {
      return short ? det.address_components[i].short_name : det.address_components[i].long_name;
    }
  }
  return "";
}

export function getAddressResourceFromPlaceDetails(prDet, addressField) {
  const ccode = getAddressComponent(prDet, "country", true);
  if (ccode !== "AU") {
    throw new APIError(
      "get",
      "",
      {
        Status: "Bad Request",
        StatusCode: 400,
        Errors: [
          {
            ErrorCode: "internal_error",
            FieldType: "body",
            FieldName: addressField,
            UserContext: "Internal error",
            UserMessage: `Sorry, We currently only support organisations located in Australia.`
          }
        ]
      }
    );
  }

  const sp = getAddressComponent(prDet, "subpremise", false);
  const sn = getAddressComponent(prDet, "street_number", false);
  const rt = getAddressComponent(prDet, "route", false);

  return {
    $Metadata: { Type: "Address" },
    Raw: prDet.formatted_address,
    Street1: !sp ? `${sn} ${rt}` : `${sp}/${sn} ${rt}`,
    Street2: "",
    Locality: getAddressComponent(prDet, "locality", false),
    PostCode: getAddressComponent(prDet, "postal_code", false),
    State: getAddressComponent(prDet, "administrative_area_level_1", false),
    StateCode: getAddressComponent(prDet, "administrative_area_level_1", true),
    Country: getAddressComponent(prDet, "country", false),
    CountryCode: getAddressComponent(prDet, "country", true),
    Location: { "Latitude": prDet.geometry.location.lat(), "Longitude": prDet.geometry.location.lng() },
    GooglePlaceID: prDet.place_id,
  };
}
