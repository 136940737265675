/** @jsx jsx */
import { Component } from "react";
import { css, jsx } from "@emotion/core";
import { withFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

// Utils
import { parseFormError } from "./../../../util/form";

// Redux
import apiService from "./../../../redux/services/api";

// Custom components
import Button from "./../../../components/Button";
import Header from "./../../../components/form/Header";
import Form from "./../../../components/form/Form";
import FooterContainer from "./../../../components/form/FooterContainer";
import { getAuthenticatedUser } from "./../../../components/elements/AuthGetUser";

//grpc
import { rpc, metadata } from "../../../grpc";
import { GetUserRequest } from '../../../sdk/user_pb';
import { IsURLValidRequest } from '../../../sdk/user_invite_pb';
import { ClickedURLRequest } from '../../../sdk/user_invite_pb'
import { CreateURLRequest } from '../../../sdk/user_invite_pb'
import { SendEmailRequest } from '../../../sdk/user_invite_pb'
import { toastStore } from '../../../stores/toast-store';

class ReSendInvitation extends Component {
  constructor(Props) {
    super(Props);
  }

  componentDidMount() {
    this.history = this.props.historys
    this.handleLogout()
    this.isURLValid()
  }

  handleLogout = async () => {
    const { values, setPage } = this.props;
    try {
      await apiService.userLogout();
    } catch (err) {
      parseFormError(err, values, () => { }, this.props);
    }
  };

  state = {
    userID: null,
    email: null,
    mobile: null,
  }

  async getUser(userId) {
    const req = new GetUserRequest();
    let formattedResponse = {}
    req.setUserId(userId)
    try {
      const res = await rpc.user.getUser(req, metadata())
      let clientResponse = res.toObject()
      if (clientResponse.user.status == 'Active') {
        toastStore.success('Account has already been created');
        this.history.push('/')
      }

      formattedResponse = {
        FirstName: clientResponse.user.firstName,
        LastName: clientResponse.user.lastName,
        MobileNumber: clientResponse.user.mobileNumber,
        MobileCountryCode: clientResponse.user.mobileCountryCode,
        DateOfBirth: clientResponse.user.dateOfBirth,
        Email: clientResponse.user.email
      }
    } catch (err) {
      toastStore.grpcToastError(err);
    }
    formattedResponse.Email == "" ? null : this.setState({ email: formattedResponse.Email }),
      formattedResponse.MobileNumber == "" ? null : this.setState({ mobile: formattedResponse.MobileNumber }),
      formattedResponse.MobileNumber ? "AU" : null
    this.props.pageData.ID = userId
    Object.keys(formattedResponse).map((key, i) => {
      this.props.pageData[key] = formattedResponse[key]
    })
    return formattedResponse
  }


  async isURLValid() {
    let isURLValidResponse = {}
    try {
      const req = new IsURLValidRequest();
      req.setUrl(window.location.origin + window.location.pathname);
      const res = await rpc.userInvite.isURLValid(req, metadata());
      isURLValidResponse = res.toObject();
    }
    catch (err) {
      toastStore.grpcToastError(err);
    }
    this.getUser(isURLValidResponse.userId).then(data => {
      if (isURLValidResponse.valid == true) {
        this.setURLClicked()
        this.props.pageData.userId = isURLValidResponse.UserID
        this.props.setPage("password")
      } else if (this.state.email != null || this.state.mobile != null) {
        this.setState({ userID: isURLValidResponse.UserID })
      } else {
        this.history.push('/')
      }

    })
    return isURLValidResponse
  }

  async setURLClicked() {
    let clickURLResponse = {}
    try {
      const req = new ClickedURLRequest();
      req.setUrl(window.location.origin + window.location.pathname)
      const res = await rpc.userInvite.clickedURL(req, metadata());
      clickURLResponse = res.toObject();
    } catch (err) {
      toastStore.grpcToastError(err);
    }
  }

  async reSendInvitation() {
    try {
      // create URL
      let addURLResponse = {}
      const urlReq = new CreateURLRequest();
      urlReq.setUserId(this.props.pageData.userId)
      const urlRes = await rpc.userInvite.createURL(urlReq, metadata());
      addURLResponse = urlRes.toObject()

      // send Email/sms
      const emailReq = new SendEmailRequest();
      emailReq.setUserId(this.props.pageData.userId)
      emailReq.setUrl(addURLResponse.url)
      const emailRes = await rpc.userInvite.sendEmail(emailReq, metadata());
    } catch (err) {
      toastStore.grpcToastError(err);
    }
  }

  hideemail = (email) => {
    var hiddenEmail = "";
    var i;
    for (i = 0; i < email.length; i++) {
      if (i > 2 && i < email.indexOf("@")) {
        hiddenEmail += "*";
      } else {
        hiddenEmail += email[i];
      }
    }
    return (hiddenEmail)
  }

  hidemobile = (email) => {
    var hiddenEmail = "";
    var i;
    for (i = 0; i < email.length; i++) {
      if (i > 6) {
        hiddenEmail += email[i];
      } else {
        hiddenEmail += "*";
      }
    }
    return (hiddenEmail)
  }


  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Header
          title="Oops, looks like your link has expired"
        />
        <p style={{color:'grey'}}>Click the link below to resend the inviation, alternatively click the signup button or contact your provider to resend the invitation</p>
        <FooterContainer>
          { (this.state.email || this.state.mobile) ?
          <Button
            style={{width:'300px', marginTop:'30px'}}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isSubmitting}
          >
            {this.state.email ? "Resend Email to "+ this.hideemail(this.state.email) : "Resend SMS to " + this.hidemobile(this.state.mobile)}
          </Button>
  : <div></div>}
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData }) => ({
  }),
  validationSchema: Yup.object().shape({
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      // create URL
      let addURLResponse = {}
      const urlReq = new CreateURLRequest();
      urlReq.setUserId(props.pageData.ID);
      const urlRes = await rpc.userInvite.createURL(urlReq, metadata());
      addURLResponse = urlRes.toObject();

      // send Email/sms
      let emailResponse = {}
      const emailReq = new SendEmailRequest();
      emailReq.setUserId(props.pageData.ID);
      emailReq.setUrl(addURLResponse.url);
      const emailRes = await rpc.userInvite.sendEmail(emailReq, metadata());
      emailResponse = emailRes.toObject();
      if (emailResponse.userId) {
        props.toastManager.add(`Invitation has been resent`, {
          appearance: "success"
        });
      }
    }
    catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "LoginForm"
});



export default compose(
  connect((state) => ({ authUser: getAuthenticatedUser(state) })),
  withToastManager,
  formikEnhancer
)(ReSendInvitation);