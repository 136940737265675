import { schema } from "normalizr";
import {
  resourceDefinitions,
  getIdsFromResource
} from "./definitions";

const normalizrSchemas: { [key: string]: schema.Entity } = {};

// Create the initial schema definitions.
Object.keys(resourceDefinitions).forEach((rt)=>{
  normalizrSchemas[rt] = new schema.Entity(rt, {}, {
    idAttribute: (r)=>(getIdsFromResource({ $Metadata: { Type: rt }, ...r })||[]).join("$"),
    processStrategy: !resourceDefinitions[rt].preProcess ?
                     (value)=>value :
                     resourceDefinitions[rt].preProcess,
  });
});

// Create the mapping between all the schemas.
Object.keys(resourceDefinitions).forEach((rt)=>{
  const edges = {};
  Object.keys(resourceDefinitions[rt].edges).forEach((ek)=>{
    const edge = resourceDefinitions[rt].edges[ek];
    if (Array.isArray(edge)) {
      edges[ek] = [normalizrSchemas[edge[0]]];
    } else {
      edges[ek] = normalizrSchemas[edge];
    }
  });
  normalizrSchemas[rt].define(edges);
});

export { normalizrSchemas };
