import Raven from "raven-js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { Route, Redirect } from "react-router-dom";

import ResourceRender from "./../../components/elements/ResourceRender";

import * as ReduxDialogs from "./../../redux/features/dialogs";

class AuthWrapRoute extends Component {
  componentDidMount() {
    this.checkUserActions();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.resource !== prevProps.resource) {
      this.checkUserActions();
    }
  }
  checkUserActions() {
    const { resource: authUser, dialogs } = this.props;
    // If we are already showing an account dialog don't do anything.
    if (dialogs.active.findIndex((d) => d.type === ReduxDialogs.DialogType.AccountDialog) >= 0) {
      return;
    }
    if (this.checkIfUserNeedsToResetPass()) {
      this.props.dispatch(
        ReduxDialogs.openAccount(null, "resetpasswd", { user: authUser }, () => {
          if (this.checkIfUserNeedsToResetPass() || this.checkIfUserNeedsToVerify()) {
            return false;
          } else {
            return true;
          }
        })
      );
    } else if (this.checkIfUserNeedsToVerify()) {
      this.props.dispatch(
        ReduxDialogs.openAccount(null, "verify", { user: authUser }, () => {
          if (this.checkIfUserNeedsToVerify()) {
            return false;
          } else {
            return true;
          }
        })
      );
    }
  }
  checkIfUserNeedsToResetPass() {
    const { resource: authUser } = this.props;
    if (authUser.PasswordExpiry) {
      return true;
    }
    return false;
  }
  checkIfUserNeedsToVerify() {
    const { resource: authUser } = this.props;
    if (
      authUser.Email &&
      (!authUser.ValidatedFields || authUser.ValidatedFields.indexOf("Email") < 0)
    ) {
      return true;
    } else if (
      authUser.MobileNumber &&
      (!authUser.ValidatedFields || authUser.ValidatedFields.indexOf("MobileNumber") < 0)
    ) {
      return true;
    }
    return false;
  }
  render() {
    const { component, ...rest } = this.props;
    return React.createElement(component, this.props, rest);
  }
}

const AuthWrapRouteWrapper = connect((state) => ({ dialogs: state.dialogs }))(AuthWrapRoute);

class AuthRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogginIn: !!props.redirect,
      errorCode: null
    };
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === "production") {
      console.warn("Unknown Internal Error", error);
      Raven.captureException(error, { extra: info });
    } else {
      console.error(error);
    }
    this.setState({ errorCode: Raven.lastEventId()||"unknown" });
  }

  componentDidMount() {
    const { redirect } = this.props;
    if (this.checkIfUserNeedsToLogin()) {
      this.props.dispatch(
        ReduxDialogs.openAccount(null, "", {}, () => {
          const { history } = this.props;
          if (this.checkIfUserNeedsToLogin()) {
            if (history.length > 2) {
              history.goBack();
              return true;
            } else {
              return false;
            }
          } else {
            setTimeout(() => this.setState({ isLogginIn: true }), 17);
            return true;
          }
        })
      );
    } else if (!redirect) {
      setTimeout(() => this.setState({ isLogginIn: true }), 17);
    }
  }
  checkIfUserNeedsToLogin() {
    const { auth, redirect } = this.props;
    if (redirect) {
      return false;
    }
    if (auth && auth.Type === "user" && auth.UserID) {
      return false;
    }
    return true;
  }
  render() {
    const { errorCode, isLogginIn } = this.state;
    const { component: Cmpt, redirect, auth, reqUser, ...rest } = this.props;
    
    if (errorCode) {
      return null;
    }

    return (
      <Route
        {...rest}
        render={(props) => {
          if (isLogginIn && auth && auth.Type === "user" && auth.UserID) {
            if (reqUser) {
              return (
                <ResourceRender
                  type="User"
                  ids={{ ID: auth.UserID }}
                  compSuccess={AuthWrapRouteWrapper}
                  compError={({ resource }) => (
                    <h1>Error Getting User: {resource.$Metadata.DataError.message}</h1>
                  )}
                  forwardedProps={{ ...props, component: Cmpt }}
                />
              );
            } else {
              return <Cmpt {...props} />;
            }
          } else if (redirect) {
            return <Redirect to={{ pathname: redirect, state: { from: props.location } }} />;
          } else {
            return null;
          }
        }}
      />
    );
  }
}

export default compose(
  connect((state) => ({ auth: state.auth })),
  withRouter
)(AuthRoute);
