export function getUserOrgID(){
    try{
        const store = JSON.parse(localStorage.getItem("persist:root")!);
        const global = JSON.parse(store.global);
        const orgID = global.UserContext.OrgID
        return { orgID }
    } catch (error) {
        return {}
    }    
}

export function getUserLocationID(){
    try{
        const store = JSON.parse(localStorage.getItem("persist:root")!);
        const global = JSON.parse(store.global);
        const LocID = global.UserContext.LocID
        return { LocID }
    } catch (error) {
        return {}
    }  
}

export function getUserID(){
    try{
        const store = JSON.parse(localStorage.getItem("persist:root")!);
        const auth = JSON.parse(store.auth);
        const UserID = auth.UserID
        return { UserID }
    } catch (error) {
        return {}
    }  
}

