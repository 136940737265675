import { ActionType, getType } from "typesafe-actions";
import { normalize } from "normalizr";
import * as fromActions from "./actions";
import {
  Resource,
  ResourceList,
  ResourceActionStatus,
  ResourceDataStatus,
  ResourceActionTypes
} from "./types";
import {
  ResourceTypes,
  blankResource,
  blankResourceList,
  resourceDefinitions,
  resourceListDefinitions,
  getIdsFromResource
} from "./definitions";
import { normalizrSchemas } from "./schema";

import { APIError } from "../../services/api";

// Reducer types.
export type ResourceActions = ActionType<typeof fromActions>;

export interface ResourcesReducerState {
  [key: string]: { [key: string]: Resource; };
}

export interface ResourceListsReducerState {
  [key: string]: ResourceList;
}

export const initialResourceState: ResourcesReducerState =
  Object.keys(resourceDefinitions).reduce((a, c)=>({
    ...a,
    [c]: {}
  }), {});
export const initialResourceListState: ResourceListsReducerState = {};

export interface ResourcesReduxStoreState {
  readonly resources: ResourcesReducerState;
  readonly lists: ResourceListsReducerState;
}

// Reducers.
export default {
  resources: (state: ResourcesReducerState = initialResourceState, action: ResourceActions): ResourcesReducerState => {
    switch (action.type) {
      case getType(fromActions.set): {
        const ids = getIdsFromResource(action.payload.resource);
        if (ids === null) {
          throw new Error(`invalid resource ids`);
        }
        const idS = ids.join("$");

        // Make sure we have the given resource in the type state.
        if (!action.payload.resource.$Metadata) {
          throw new Error(`invalid resource, missing metadata`);
        }

        const type = action.payload.resource.$Metadata.Type;
        if (!resourceDefinitions.hasOwnProperty(type)) {
          throw new Error(`invalid resource type ${type}`);
        }

        return {
          ...state,
          [type]: {
            ...state[type],
            [idS]: action.payload.resource
          }
        };
      }
      case getType(fromActions.actionStart): {
        const ids = getIdsFromResource(action.payload.resource);
        if (ids === null) {
          // An action to a resource without all id fields is likely to be a create
          // action so ignore.
          return state;
        }
        const idS = ids.join("$");

        // Make sure we have the given resource in the type state.
        const type = action.payload.resource.$Metadata.Type;
        const stateTypes = {
          ...state[type],
          [idS]: state[type][idS] || blankResource(type)
        }

        stateTypes[idS].$Metadata.Actions.push({
          ID: action.payload.actionID,
          Type: action.payload.actionType,
          At: new Date(),
          Status: ResourceActionStatus.Pending,
          Error: null,
          Data: action.payload.actionData,
        });

        return {
          ...state,
          [type]: stateTypes
        };
      }
      case getType(fromActions.actionSucceeded): {
        const type = action.payload.resource.$Metadata.Type;

        // Try and extract the ids from either the initial resource or returned
        // payload.
        let wasCreated = false;
        let ids = getIdsFromResource(action.payload.resource);
        if (ids === null) {
          ids = getIdsFromResource({
            $Metadata: action.payload.resource.$Metadata,
            ...action.payload.response.Payload
          });
          wasCreated = true;
        }
        if (ids === null) {
          throw new Error(`Cannot determine ids of resource type ${type} for action ${action.payload.actionID}`);
        }
        const idS = ids.join("$");

        let curResource: Resource;
        if (wasCreated) {
          curResource = blankResource(type);
          curResource.$Metadata.Actions.push({
            ID: action.payload.actionID,
            Type: action.payload.actionType,
            At: new Date(),
            Status: ResourceActionStatus.Pending,
            Error: null,
            Data: action.payload.actionData,
          });
        } else {
          curResource = state[type][idS];
        }

        // Make sure we have the given resource and action in the type state.
        const { $Metadata, ...curResourceData } = curResource;
        if (!$Metadata) {
          throw new Error(`Expected $Metadata in object ${idS} of type ${type}`);
        }

        if (!action.payload.response.Payload) {
          // Remove the resource.
          const { [idS]: _, ...otherResources } = state[type];
          return {
            ...state,
            [type]: otherResources
          };
        } else {
          // Create the new metadata with the action status set to ok.
          const aIdx = $Metadata.Actions.findIndex((v)=>v.ID===action.payload.actionID);
          if (aIdx < 0) {
            throw new Error(`Cannot find action ${action.payload.actionID} in object ${idS} of type ${type}`);
          }

          const newActions = [...$Metadata.Actions];
          newActions[aIdx].Status = ResourceActionStatus.Ok;

          // Process the new resource.
          const actFn = resourceDefinitions[type].actions[action.payload.actionType];
          const { processResponse } = actFn(action.payload.actionData, curResourceData, $Metadata);
          const newResource = !processResponse ?
                              ({ ...curResourceData, ...action.payload.response.Payload }) :
                              processResponse(curResourceData, action.payload.response.Payload);

          // Denormalize into other resources.
          const normData = normalize(newResource, normalizrSchemas[type]);

          const curTime = new Date();
          const newState = { ...state };
          Object.keys(normData.entities).forEach((tk)=>{
            const ents = normData.entities[tk];
            const tdef = resourceDefinitions[ResourceTypes[tk]];

            newState[tk] = { ...newState[tk] };
            Object.keys(ents).forEach((ek: string)=>{
              if (!newState[tk][ek]) {
                newState[tk][ek] = blankResource(ResourceTypes[tk]);
              }

              newState[tk][ek].$Metadata = {
                ...newState[tk][ek].$Metadata,
                DataStatus: ResourceDataStatus.Ok,
                DataLastSynced: curTime,
                DataError: null
              };

              if (tk === type && ek === idS) {
                newState[tk][ek].$Metadata.Actions = newActions;
              }

              newState[tk][ek] = {
                ...newState[tk][ek],
                ...(!tdef.preProcess ? ents[ek] : tdef.preProcess(ents[ek], ResourceTypes[tk]))
              };
            });
          })

          return newState;
        }
      }
      case getType(fromActions.actionFailed): {
        const ids = getIdsFromResource(action.payload.resource);
        if (ids === null) {
          // An action to a resource without all id fields is likely to be a create
          // action so ignore, failure should be handled from the resulting thunk
          // promise.
          return state;
        }
        const idS = ids.join("$");

        // Make sure we have the given resource and action in the type state.
        const type = action.payload.resource.$Metadata.Type;
        const { $Metadata } = state[type][idS];
        if (!$Metadata) {
          throw new Error(`Expected $Metadata in object ${idS} of type ${type}`);
        }

        const aIdx = $Metadata.Actions.findIndex((v)=>v.ID===action.payload.actionID);
        if (aIdx < 0) {
          throw new Error(`Cannot find action ${action.payload.actionID} in object ${idS} of type ${type}`);
        }

        // Create the new metadata with the action status set to failed.
        const newActions = [...$Metadata.Actions];
        newActions[aIdx].Status = ResourceActionStatus.Error;
        newActions[aIdx].Error = action.payload.error;

        const newMetadata = {
          ...$Metadata,
          Actions: newActions
        };

        if (
          newMetadata.DataStatus === ResourceDataStatus.NoData ||
          (
            $Metadata.Actions[aIdx].Type === ResourceActionTypes.Fetch &&
            action.payload.error instanceof APIError &&
            action.payload.error.apiErrors.findIndex((ue)=>ue.ErrorCode==="request_cancelled") < 0
          )
        ) {
          newMetadata.DataStatus = ResourceDataStatus.Error;
          newMetadata.DataError = action.payload.error;
        }

        return {
          ...state,
          [type]: {
            ...state[type],
            [idS]: {
              ...state[type][idS],
              $Metadata: newMetadata
            }
          }
        };
      }
      case getType(fromActions.listActionSucceeded): {
        const { $Metadata: lmd } = action.payload.list;
        const { List: list } = lmd;
        const listDef = resourceListDefinitions[list];

        // Denormalize into other resources.
        const normData = normalize(action.payload.response.Payload, [normalizrSchemas[listDef.entity]]);
        const curTime = new Date();
        const newState = { ...state };
        Object.keys(normData.entities).forEach((tk: string)=>{
          const ents = normData.entities[tk];
          const tdef = resourceDefinitions[ResourceTypes[tk]];

          newState[tk] = { ...newState[tk] };
          Object.keys(ents).forEach((ek: string)=>{
            if (!newState[tk][ek]) {
              newState[tk][ek] = blankResource(ResourceTypes[tk]);
            }

            newState[tk][ek].$Metadata = {
              ...newState[tk][ek].$Metadata,
              DataStatus: ResourceDataStatus.Ok,
              DataLastSynced: curTime,
              DataError: null
            };

            newState[tk][ek] = {
              ...newState[tk][ek],
              ...(!tdef.preProcess ? ents[ek] : tdef.preProcess(ents[ek], list))
            };
          });
        });

        return newState;
      }
    }
    return state;
  },
  lists: (state: ResourceListsReducerState = initialResourceListState, action: ResourceActions): ResourceListsReducerState => {
    switch (action.type) {
      case getType(fromActions.actionSucceeded):
        const type = action.payload.resource.$Metadata.Type;

        // Try and extract the ids from either the initial resource or returned
        // payload.
        let ids = getIdsFromResource(action.payload.resource);
        if (ids === null) {
          return state;
        }
        const idS = ids.join("$");

        if (Object.keys(action.payload.response.Payload).length > 0) {
          return state;
        }

        // Remove deleted entities from the list.
        const newState = { ...state };
        Object.keys(newState).forEach((ldk)=>{
          if (!newState[ldk] || !newState[ldk].$Metadata) { return; }
          const listType = newState[ldk].$Metadata.List;
          const listDef = resourceListDefinitions[listType];
          if (listDef.entity !== type) {
            return;
          }

          const newIDs = newState[ldk].IDs.filter((v)=>v!==idS);

          const newSubFilteredIDs = {};
          if (newIDs.length !== newState[ldk].IDs.length) {
            Object.keys(listDef.subFilters).forEach((sf)=>{
              newSubFilteredIDs[sf] = newState[ldk].SubFilteredIDs[sf].filter((v)=>v!==idS);
            });
          }

          newState[ldk] = {
            ...newState[ldk],
            IDs: newIDs,
            SubFilteredIDs: newSubFilteredIDs,
          };
        });

        return newState;
      case getType(fromActions.listActionStart): {
        const { $Metadata } = action.payload.list;
        const { List: list } = $Metadata;

        const listDef = resourceListDefinitions[list];
        const key = !listDef.key ? "primary" : listDef.key($Metadata);
        const listid = `${list}$${key}`;

        // Make sure we have the given resource in the type state.
        const newState = {
          ...state,
          [listid]: (!action.payload.clearFirst ? state[listid] : null) || blankResourceList(list, $Metadata.Context)
        };

        newState[listid].$Metadata.Actions.push({
          ID: action.payload.actionID,
          Type: action.payload.actionType,
          At: new Date(),
          Status: ResourceActionStatus.Pending,
          Error: null,
          Data: $Metadata.Context,
        });

        return newState;
      }
      case getType(fromActions.listActionSucceeded): {
        const { $Metadata: lmd } = action.payload.list;
        const { List: list } = lmd;

        const listDef = resourceListDefinitions[list];
        const key = !listDef.key ? "primary" : listDef.key(lmd);
        const listid = `${list}$${key}`;

        // Make sure we have the given resource and action in the type state.
        const { $Metadata } = state[listid];
        if (!$Metadata) {
          throw new Error(`Expected $Metadata in list ${listid}`);
        }

        const aIdx = $Metadata.Actions.findIndex((v)=>v.ID===action.payload.actionID);
        if (aIdx < 0) {
          throw new Error(`Cannot find action ${action.payload.actionID} in list ${listid}`);
        }

        // Create the new metadata with the action status set to failed.
        const newActions = [...$Metadata.Actions];
        newActions[aIdx].Status = ResourceActionStatus.Ok;

        // Map the ids.
        const IDs: string[] = action.payload.response.Payload.map((lr: any)=>
          getIdsFromResource({ $Metadata: { Type: listDef.entity }, ...lr })
        ).filter((v: string[]|null)=>!!v).map((v: string[])=>v.join("$"));

        const SubFilteredIDs = {};
        Object.keys(listDef.subFilters).forEach((sfk)=>{
          const sf = listDef.subFilters[sfk];
          SubFilteredIDs[sfk] = IDs.filter((_, j)=>sf(action.payload.response.Payload[j]));
        });

        return {
          ...state,
          [listid]: {
            ...state[listid],
            $Metadata: {
              ...$Metadata,
              Actions: newActions,
              DataStatus: ResourceDataStatus.Ok,
              DataLastSynced: new Date(),
              DataError: null
            },
            IDs,
            SubFilteredIDs,
          }
        };
      }
      case getType(fromActions.listActionFailed): {
        const { $Metadata: lmd } = action.payload.list;
        const { List: list } = lmd;

        const listDef = resourceListDefinitions[list];
        const key = !listDef.key ? "primary" : listDef.key(lmd);
        const listid = `${list}$${key}`;

        // Make sure we have the given resource and action in the type state.
        const { $Metadata } = state[listid];
        if (!$Metadata) {
          throw new Error(`Expected $Metadata in list ${listid}`);
        }

        const aIdx = $Metadata.Actions.findIndex((v)=>v.ID===action.payload.actionID);
        if (aIdx < 0) {
          throw new Error(`Cannot find action ${action.payload.actionID} in list ${listid}`);
        }

        // Create the new metadata with the action status set to failed.
        const newActions = [...$Metadata.Actions];
        newActions[aIdx].Status = ResourceActionStatus.Error;
        newActions[aIdx].Error = action.payload.error;

        const newMetadata = {
          ...$Metadata,
          Actions: newActions
        };

        if (
          newMetadata.DataStatus === ResourceDataStatus.NoData ||
          (
            $Metadata.Actions[aIdx].Type === ResourceActionTypes.Fetch &&
            action.payload.error instanceof APIError &&
            action.payload.error.apiErrors.findIndex((ue)=>ue.ErrorCode==="request_cancelled") < 0
          )
        ) {
          newMetadata.DataStatus = ResourceDataStatus.Error;
          newMetadata.DataError = action.payload.error;
        }

        return {
          ...state,
          [listid]: {
            ...state[listid],
            $Metadata: newMetadata
          }
        };
      }
    }
    return state;
  }
}
