/** @jsx jsx */
import { Component } from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import * as fns from "date-fns";
import { withToastManager } from "react-toast-notifications";

import * as ReduxDialogs from "./../../redux/features/dialogs";
import * as ResourceActions from "./../../redux/features/resources/thunkactions";
import { parseFormError } from "./../../util/form";

import { breakpoints } from "./../../util/consts";
import typography from "./../../util/typography";

// Custom components
import Dialog from "./Dialog";
import Form from "./../../components/form/Form";
import SectionsContainer from "./../../components/form/SectionsContainer";
import Section from "./../../components/form/Section";
import Label from "./../../components/form/Label";
import Error from "./../../components/form/Error";
import DayTimePickerInput from "./../../components/form/DayTimePickerInput";
import Button from "./../../components/Button";

/**
 * NOTES: currently using 2 buttons instead of a set of radio buttons
 */

class TimeOffDialog extends Component {
  state = {
    isRemoving: false
  }

  handleRemove = async () => {
    const { isRemoving } = this.state;
    const { isSubmitting, data: { booking }, dispatch, dialogId } = this.props;

    if (isRemoving || isSubmitting) { return; }

    this.setState({ isRemoving: true });
    try {
      // Create the booking.
      await dispatch(ResourceActions.action(booking, "Remove", {}));
      dispatch(ReduxDialogs.actions.close(dialogId));
    } catch (err) {
      parseFormError(err, {}, ()=>{}, this.props);
    }
    this.setState({ isRemoving: false });
  }

  render() {
    const { isRemoving } = this.state;
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
    } = this.props;

    return (
      <Dialog dialogId={this.props.dialogId}>
        <Container aria-label="quick-book-modal">
          <Form
            onSubmit={
              (isSubmitting || isRemoving)
                ? (e) => {
                    e.preventDefault();
                    return false;
                  }
                : handleSubmit
            }
          >
            <HeaderTitle>Time off</HeaderTitle>
            <SectionsContainer>
              <Section>
                <Label htmlFor="quick-start">Start Date and Time</Label>
                <DayTimePickerInput
                  id="quick-start"
                  name="FromDate"
                  step={900}
                  value={values.FromDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.FromDate && errors.FromDate}
                />
                {!!errors.FromDate && touched.FromDate && <Error>{errors.FromDate}</Error>}
              </Section>
              <Section>
                <Label htmlFor="quick-end">End Date and Time</Label>
                <DayTimePickerInput
                  id="quick-end"
                  name="ToDate"
                  step={900}
                  value={values.ToDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ToDate && errors.ToDate}
                />
                {!!errors.ToDate && touched.ToDate && <Error>{errors.ToDate}</Error>}
              </Section>
            </SectionsContainer>
            <ButtonContainer>
              <Button
                css={css`
                  width: 149px;
                  margin-top: 20px;
                `}
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isSubmitting || isRemoving}
              >
                SAVE
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button
                css={css`
                  width: 149px;
                  margin-top: 20px;
                `}
                variant="contained"
                color="secondary"
                type="button"
                disabled={isSubmitting || isRemoving}
                onClick={this.handleRemove}
              >
                REMOVE
              </Button>
            </ButtonContainer>
          </Form>
        </Container>
      </Dialog>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: (props) => ({
    FromDate: fns.parse(`${props.data.booking.StartDate} ${props.data.booking.StartTime}`, "yyyy-MM-dd HH:mm:ss", new Date()),
    ToDate: fns.parse(`${props.data.booking.EndDate} ${props.data.booking.EndTime}`, "yyyy-MM-dd HH:mm:ss", new Date()),
  }),
  validationSchema: Yup.object().shape({
    FromDate: Yup.date().required("From date is required"),
    ToDate: Yup.date().required("To date is required")
  }),
  handleSubmit: async (values, { setSubmitting, status, setFieldError, props }) => {
    const { dispatch, dialogId, data: { booking } } = props;

    try {
      // Create the booking.
      const updBooking = {
        ...booking,
        StartDate: fns.format(values.FromDate, "yyyy-MM-dd"),
        EndDate: fns.format(values.ToDate, "yyyy-MM-dd"),
        StartTime: fns.format(values.FromDate, "HH:mm:00"),
        EndTime: fns.format(values.ToDate, "HH:mm:00")
      };
      await dispatch(ResourceActions.action(updBooking, "Update", {}));
      props.data.cb && props.data.cb();
      dispatch(ReduxDialogs.actions.close(dialogId));
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }

    setSubmitting(false);
  },
  displayName: "TimeOff"
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(TimeOffDialog);

const Container = styled.div`
  width: 100vw;
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    padding: 64px 90px;
    max-width: 700px;
  }
`;

const HeaderTitle = styled.span`
  ${typography.heading5};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
