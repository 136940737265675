import React, { Component } from "react";
import { createForm } from "./../../../../forms/forms";
import {
  NoteTemplate,
  CreateNoteTemplateRequest,
  UpdateNoteTemplateRequest
} from "../../../../sdk/note_template_pb";
import * as Yup from "yup";
import { rpc, metadata } from "../../../../grpc";
import { AccordianForm, ButtonContainer, ButtonHead } from "../../../elements/AccordianElements";
import Section from "../../../form/Section";
import TextArea from "../../../form/TextArea";
import Input from "../../../form/Input";
import Label from "../../../form/Label";
import LoadingButton from "../../../LoadingButton";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { toastStore } from "../../../../stores/toast-store";
import Error from "../../../form/Error";

const schema = Yup.object<NoteTemplate.AsObject>({
  id: Yup.string(),
  userId: Yup.string(),
  locId: Yup.string(),
  content: Yup.string().required(),
  title: Yup.string().required()
});

interface Props {
  locationId: string;
  onSaveTemplate: () => void;
  templateId?: string;
  title?: string;
  content?: string;
}

@observer
export class CreateNoteTemplate extends Component<Props> {
  @observable isSubmitting: Boolean = false;

  onSubmit = async (noteTemplate: NoteTemplate.AsObject) => {
    try {
      if (this.props.templateId) {
        // update
        const req = new UpdateNoteTemplateRequest();
        req.setId(this.props.templateId);
        req.setTitle(noteTemplate.title);
        req.setContent(noteTemplate.content);
        const res = await rpc.noteTemplate.update(req, metadata());
        toastStore.success(
          `The template ${noteTemplate.title} is edited successfully. Please select the template to apply the new template.`
        );
      } else {
        // create
        const req = new CreateNoteTemplateRequest();
        req.setTitle(noteTemplate.title);
        req.setContent(noteTemplate.content);
        req.setLocId(noteTemplate.locId);
        const res = await rpc.noteTemplate.create(req, metadata());
        toastStore.success(
          `The template ${noteTemplate.title} is added successfully. Please select the template to apply the new template.`
        );
      }
      this.props.onSaveTemplate();
    } catch (err) {
      toastStore.grpcToastError(err);
    }
  };
  render() {
    const Form = createForm<NoteTemplate.AsObject>();
    const { locationId, title, content, templateId } = this.props;
    var initialTemplate = new NoteTemplate();
    if (templateId && title && content) {
      initialTemplate.setTitle(title);
      initialTemplate.setContent(content);
    }

    return (
      <Form
        schema={schema}
        onSubmit={this.onSubmit}
        component={AccordianForm}
        initial={initialTemplate.toObject()}
      >
        {({ fields, updateField, errors }) => (
          <>
            <Section>
              <Label>Template Title</Label>
              <Input
                name="note_template_title"
                id="add_note_template_title"
                value={fields.title ? fields.title : title ? title : fields.title}
                onChange={(event: any) => {
                  updateField({
                    title: event.currentTarget.value
                  });
                }}
              />
              {!!errors.title && <Error>{errors.title}</Error>}
            </Section>

            <Section>
              <TextArea
                id={`add_note_template_content`}
                name="note_template"
                type="textarea"
                placeholder="Please add a new note template"
                autoFocus={true}
                value={fields.content ? fields.content : content ? content : fields.content}
                onChange={(event: any) => {
                  updateField({
                    content: event.currentTarget.value,
                    locId: locationId
                  });
                }}
              ></TextArea>
              {!!errors.content && <Error>{errors.content}</Error>}
            </Section>
            <ButtonContainer>
              <LoadingButton
                key="save"
                style={{
                  minWidth: 150
                }}
                variant="contained"
                color="secondary"
                type="submit"
                loading={this.isSubmitting}
              >
                {templateId ? "Save" : "Create"}
              </LoadingButton>
            </ButtonContainer>
          </>
        )}
      </Form>
    );
  }
}
