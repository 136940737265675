import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
// import apiService from "./../../../redux/services/api";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "./../../../util/form";
import { findPlaceDetails, getAddressResourceFromPlaceDetails } from "./../../../util/address";

// Components
import GMPlacesAutocomplete from "./../../../components/form/GMPlacesAutocomplete";
import MediaInput from "./../../../components/form/MediaInput";
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import Header from "./../../../components/form/Header";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import LoadingButton from "./../../../components/LoadingButton";
import DayPickerInput from "./../../../components/form/DayPickerInput";
import { GenderSelect } from "./../../../components/form/BaseSelect";

class UserEditAccount_General extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData,
      setFieldValue,
      setFieldTouched,
      setFieldError
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <Header
          title="User Information"
          subtitle="Please fill out the relevant information about yourself"
        />
        <SectionsContainer>
          <Section>
            <Label>Profile Image</Label>
            <MediaInput
              type="avatar"
              name="Photo"
              value={values.Photo}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setFieldError={setFieldError}
            />
            {!!errors.Photo && touched.Photo && <Error>{errors.Photo}</Error>}
          </Section>
          <Section>
            <Label htmlFor="edituser-firstname">First Name</Label>
            <Input
              id="edituser-firstname"
              name="FirstName"
              type="text"
              placeholder="First Name"
              autoFocus={true}
              value={values.FirstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.FirstName && errors.FirstName}
            />
            {!!errors.FirstName && touched.FirstName && <Error>{errors.FirstName}</Error>}
          </Section>
          <Section>
            <Label htmlFor="edituser-lastname">Last Name</Label>
            <Input
              id="edituser-lastname"
              name="LastName"
              type="text"
              placeholder="Last Name"
              autoFocus={true}
              value={values.LastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.LastName && errors.LastName}
            />
            {!!errors.LastName && touched.LastName && <Error>{errors.LastName}</Error>}
          </Section>
          <Section>
            <Label htmlFor="edituser-DateOfBirth">Date of Birth</Label>
            <DayPickerInput
              id="edituser-DateOfBirth"
              name="DateOfBirth"
              placeholder="Select your date of birth"
              value={values.DateOfBirth}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.DateOfBirth && touched.DateOfBirth && <Error>{errors.DateOfBirth}</Error>}
          </Section>
          <Section>
            <Label htmlFor="edituser-Gender">Gender</Label>
            <GenderSelect
              id="edituser-Gender"
              name="Gender"
              value={values.Gender}
              onChange={handleChange}
              onBlur={handleBlur}
              isUser
            />
            {!!errors.Gender && touched.Gender && <Error>{errors.Gender}</Error>}
          </Section>
          <Section>
            <Label htmlFor="edituser-address">Address</Label>
            <GMPlacesAutocomplete
              id="edituser-address"
              name="Address"
              type="text"
              placeholder="Address"
              value={values.Address}
              onChange={handleChange}
              onBlur={handleBlur}
              css={{ margin: "0px !important" }}
              error={touched.Address && errors.Address}
            />
            {!!errors.Address && touched.Address && <Error>{errors.Address}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.Name}
          >
            Save
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ user }) => ({
    Photo: user.Photo ? user.Photo.ID : "",
    FirstName: user.FirstName || "",
    LastName: user.LastName || "",
    Address: !user.Address ? "" : user.Address.Raw || "",
    DateOfBirth: user.DateOfBirth || "",
    Gender: user.Gender || ""
  }),
  validationSchema: Yup.object().shape({
    Photo: Yup.string().notRequired(),
    FirstName: Yup.string().required("Last name is required."),
    LastName: Yup.string().required("Last name is required."),
    Address: Yup.string().notRequired(),
    DateOfBirth: Yup.date().required("Please enter your date of birth"),
    Gender: Yup.string().required("Please enter your gender")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      // Find and create the address.
      if (values.Address !== "") {
        if (!props.user.Address) {
          const prDet = await findPlaceDetails(values.Address, "Address");
          const prUpdAddr = {
            OwnerType: "User",
            Owner: props.user.ID,
            ...getAddressResourceFromPlaceDetails(prDet, values.Address)
          };
          await props.dispatch(ResourceActions.action(prUpdAddr, "Create", {}));
        } else if (props.user.Address.Raw !== values.Address) {
          const prDet = await findPlaceDetails(values.Address, "Address");
          const prUpdAddr = {
            OwnerType: "User",
            Owner: props.user.ID,
            ...props.user.Address,
            ...getAddressResourceFromPlaceDetails(prDet, values.Address)
          };
          await props.dispatch(ResourceActions.action(prUpdAddr, "Update", {}));
        }
      } else if (props.user.Address) {
        await props.dispatch(ResourceActions.action(props.user.Address, "Remove", {}));
      }

      const updUser = {
        ...props.user,
        Photo: values.Photo || null,
        FirstName: values.FirstName,
        LastName: values.LastName,
        DateOfBirth: values.DateOfBirth,
        Gender: values.Gender
      };

      await props.dispatch(ResourceActions.action(updUser, "Update", {}));
      props.toastManager.add("Saved Successfully!", { appearance: "success" });
      resetForm(values);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "UserEditAccountGeneral"
});

export default compose(
  connect((state) => ({})),
  withToastManager,
  formikEnhancer
)(UserEditAccount_General);
