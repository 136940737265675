import React, { PureComponent } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import Button from "./../../components/Button";

import { getResource, getResourceList } from "./../../redux/features/resources/helpers";
import ResourceListFetch from "./../../components/elements/ResourceListFetch";
// listIsFetching

import AutoCompleteDropdown from "./../../components/form/AutoCompleteDropdown";
import ClearIcon from "./../../components/icons/Clear";

class CategoriesSelector extends PureComponent {
  selectCat = (cat) => {
    const { onChange, name, value } = this.props;
    onChange({
      target: {
        name,
        value: [...value, cat.ID]
      }
    });
  };
  removeCat = (id) => {
    const { onChange, name, value } = this.props;
    onChange({
      target: {
        name,
        value: value.filter((vid) => vid !== id)
      }
    });
  };
  findCat(id) {
    const { listData } = this.props;
    for (let i = 0; i < listData.length; i++) {
      if (listData[i].ID === id) {
        return listData[i];
      }
    }
    return null;
  }
  render() {
    const { listData, value, disabled } = this.props;
    return (
      <Container>
        <ResourceListFetch type="Categories" listctx={{}} force />
        {value.length === 0 ? null : (
          <SelectedContainer>
            {value
              .map((v) => this.findCat(v))
              .filter((v) => !!v)
              .map((v) => (
                <StyledButton
                  key={v.ID}
                  color="secondary"
                  variant="contained"
                  onClick={() => !disabled && this.removeCat(v.ID)}
                >
                  {v.Value}
                  {!v.Parent ? "" : ` (${v.Parent.Value})`}
                  {!disabled && <ClearIcon color={"#FFF"} style={{ marginLeft: 10 }} />}
                </StyledButton>
              ))}
          </SelectedContainer>
        )}
        {!disabled && (
          <AutoCompleteDropdown
            items={listData}
            filter={(it) => value.indexOf(it.ID) < 0}
            icon={(it) => it.PrimaryMedia && it.PrimaryMedia.DownloadURL}
            placeholder={"Try Pilates, Physio…"}
            onSelect={this.selectCat}
            format={(v) => (!v.Parent ? v.Value : `${v.Value} (${v.Parent.Value})`)}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  const { auth, lists, resources } = state;
  const list = getResourceList(lists, "Categories", {});
  return {
    auth,
    list,
    listData: (list.IDs || [])
      .map((lid) => getResource(resources, "Taxonomy", lid, true))
      .filter((it) => !!it.Parent)
  };
}

export default connect(mapStateToProps)(CategoriesSelector);

const Container = styled.div`
  width: 100%;
`;

const SelectedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  margin-right: 20px;
`;
