import React, { PureComponent } from "react";
import * as fns from "date-fns";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Input from "./Input";

import { isMobileDevice } from "./../../util";

export default class StyledDayTimePickerInput extends PureComponent {
  state = {
    useNative: isMobileDevice()
  };

  inputRef = React.createRef();

  handleChange = (res) => {
    const { useNative }  = this.state;
    const { name, onChange } = this.props;
    if (useNative) {
      console.info("handleChangehandleChange", res.target.value, new Date(res.target.value));
      onChange({ target: { name, value: new Date(res.target.value) } });
    } else {
      console.info("handleChangehandleChange", res);
      onChange({ target: { name, value: res } });
    }
  };

  // This is required to get IOS clear button working.
  onNativeInput(e) {
    const target = e.nativeEvent.target;
    setTimeout(() => {
      target.defaultValue = "";
    }, 0);
  }

  componentDidMount() {
    if (this.inputRef.current) {
      if (this.inputRef.current.type === "text") {
        console.info("nonative!!!!", this.inputRef.current);
        setTimeout(()=>this.setState({ useNative: false }), 0);
      }
    }
  }

  render() {
    const { useNative }  = this.state;
    const { value, onChange: _, ...otherProps } = this.props;
    if (useNative) {
      return (
        <Input
          ref={this.inputRef}
          type="datetime-local"
          value={fns.format(value, "yyyy-MM-dd'T'HH:mm")}
          onChange={this.handleChange}
          onInput={this.onNativeInput}
          {...otherProps}
        />
      );
    } else {
      return (
        <DatePicker
          selected={value}
          onChange={this.handleChange}
          dateFormat="MMMM do, yyyy h:mm aa"
          customInput={<Input type="text" {...otherProps} />}
          placeholderText={this.props.placeholder}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          popperPlacement="top-end"
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "scrollParent"
            }
          }}
        />
      );
    }
  }
}
