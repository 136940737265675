import { ActionType, getType } from "typesafe-actions";
import { AuthCreds } from "./../../../redux/services/api";
import * as fromActions from "./actions";

export type AuthActions = ActionType<typeof fromActions>;

export type AuthReducerState = AuthCreds | null

export const initialAuthState: AuthReducerState = null;

export interface AuthReduxStoreState {
  readonly auth: AuthReducerState;
}

export default {
  auth: (state: AuthReducerState = initialAuthState, action: AuthActions): AuthReducerState => {
    switch (action.type) {
      case getType(fromActions.set):
        return action.payload;
      case getType(fromActions.refresh):
        return action.payload;
      case getType(fromActions.clear):
        return null;
      case getType(fromActions.invalid):
        return null;
    }
    return state;
  }
}
