/** @jsx jsx */
import { PureComponent } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import qs from "qs";

// Utils
import { colors, breakpoints } from "./../../../util/consts";
import typography from "./../../../util/typography";

// Components
import Button from "./../../../components/Button";

const buttonOverrides = css`
  margin-right: 8px;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  width: 82px;
  text-decoration: none;
`;

class ProviderCard extends PureComponent {
  render() {
    const { resource } = this.props;
    const query = "?" + qs.stringify({ Provider: resource.User.ID });

    return (
      <CardContainer>
        <AspectRatio>
          <CardContent>
            <CardFakeProfile>
              {resource.User.FirstName[0] + resource.User.LastName[0]}
            </CardFakeProfile>
            <Title>
              {resource.User.FirstName} {resource.User.LastName}
            </Title>
            <Subtitle>
              {typeof resource.ProviderCategories[0] !== "undefined" &&
                resource.ProviderCategories.map((c, i) => (i === 0 ? c.Value : ", " + c.Value))}
            </Subtitle>
            <div>
              <Button
                css={buttonOverrides}
                variant="contained"
                color="secondary"
                as={Link}
                to={`/bookings/locs/${resource.Location.ID + query}`}
                target="_blank"
                onClick={this.onClick}
              >
                <CardButtonText>Book</CardButtonText>
              </Button>
              {/* <Button css={buttonOverrides} variant="outlined" color="primary">
                <CardButtonText>Profile</CardButtonText>
              </Button> */}
            </div>
          </CardContent>
        </AspectRatio>
      </CardContainer>
    );
  }
}

export default ProviderCard;

const CardContainer = styled.div`
  border: 1px solid #f3f5fd;
  border-radius: 4px;
  background-color: ${colors.surface.light};
  box-shadow: 0 2px 4px 0 rgba(44, 46, 60, 0.05);
  margin: 4px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 8px);

  ${breakpoints["tablet-up"]} {
    flex-basis: calc(50% - 8px);
  }

  ${breakpoints["desktop-up"]} {
    flex-basis: calc(33.33% - 8px);
  }
`;

const AspectRatio = styled.div`
  padding-top: 100%;
  position: relative;
`;

const CardContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const CardFakeProfile = styled.div`
  height: 55px;
  width: 55px;
  border-radius: 50%;
  background-color: ${colors.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  ${typography.overline};
  letter-spacing: 0.09px;
  font-weight: 600;
  color: ${colors.whiteText.highEmphasis};
`;

const Title = styled.span`
  ${typography.subtitle1};
  color: ${colors.surfaceText.highEmphasis};
  letter-spacing: 0.25px;
  font-weight: 600;
  margin-top: 17px;
  margin-bottom: 6px;
  line-height: 18px;
`;

const Subtitle = styled.span`
  color: ${colors.surfaceText.medEmphasis};
  font-size: 11.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 19px;
`;

const CardButtonText = styled.span`
  font-size: 10.8px;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
`;
