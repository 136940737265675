import styled from "@emotion/styled";

import { breakpoints } from "./../../util/consts";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  ${breakpoints["phone-only"]} {
    justify-content: space-evenly;
  }
`;

export default Form;
