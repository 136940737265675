import { ActionType, getType } from "typesafe-actions";
import * as fromActions from "./actions";
import * as fromAuthActions from "../auth/actions";

export type GlobalActions = ActionType<typeof fromActions> | ActionType<typeof fromAuthActions>;

export interface GlobalReducerState {
  UserContext: fromActions.GlobalSetUserContext;
}

export const initialGlobalState: GlobalReducerState = {
  UserContext: {
    OrgID: "",
    LocID: ""
  }
};

export interface GlobalReduxStoreState {
  readonly global: GlobalReducerState;
}

export default {
  global: (state: GlobalReducerState = initialGlobalState, action: GlobalActions): GlobalReducerState => {
    switch (action.type) {
      case getType(fromActions.setUserContext):
        return {
          UserContext: action.payload
        };
      case getType(fromAuthActions.clear):
        return initialGlobalState;
      case getType(fromAuthActions.invalid):
        return initialGlobalState;
      case getType(fromAuthActions.set):
        return initialGlobalState;
    }
    return state;
  }
}
