import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { colors } from "./../util/consts";

import Button from "./Button";

import LoadingIcon from "./../components/icons/Loading";

class LoadingButton extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool
  };

  static defaultProps = {
    loading: false
  };

  render() {
    const { loading, children, ...otherProps } = this.props;
    return (
      <Button {...otherProps} disabled={loading ? true : otherProps.disabled}>
        {!loading ? (
          children
        ) : (
          <LoadingIcon
            width={16}
            height={16}
            color={otherProps.color === "secondary" ? "#FFF" : colors.secondary.main}
          />
        )}
      </Button>
    );
  }
}

export default LoadingButton;
