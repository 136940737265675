import React, { Component } from "react";
import { connect } from "react-redux";

import { getResource } from "./../../redux/features/resources/helpers";
import ResourceFetch from "./../../components/elements/ResourceFetch";
import ResourceListFetch from "./../../components/elements/ResourceListFetch";

export function getAuthenticatedUser(state) {
  return state.auth && getResource(state.resources, "User", { ID: state.auth.UserID });
}

class AuthGetUser extends Component {
  render() {
    const { auth } = this.props;

    if (auth && auth.Type === "user" && auth.UserID) {
      return (
        <React.Fragment>
          <ResourceFetch type="User" ids={{ ID: auth.UserID }} force={true} />
          <ResourceListFetch type="AuthUserOrgs" listctx={{}} force />
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state, props) {
  const { auth } = state;
  return {
    auth
  };
}

export default connect(mapStateToProps)(AuthGetUser);
