import React, { Component } from "react";

import ResourceRender from "./../components/elements/ResourceRender";
import Avatar from "./../components/Avatar";

export default class OrgAvatar extends Component {
  renderContent = React.memo(({ resource, otherProps }) => {
    const ns = resource.Name.split(" ");
    return (
      <Avatar
        letters={ns.length > 1 ? ns[0][0] + ns[1][0] : ns[0].slice(0, 2)}
        image={!resource.Logo ? null : resource.Logo.DownloadURL}
        {...otherProps}
      />
    );
  });

  renderLoading = React.memo(({ otherProps }) => <Avatar {...otherProps} />);

  render() {
    const { orgID, ...otherProps } = this.props;

    return (
      <ResourceRender
        type="Organisation"
        ids={{ ID: orgID }}
        denorm={true}
        forwardedProps={{ otherProps }}
        compSuccess={this.renderContent}
        compLoading={this.renderLoading}
      />
    );
  }
}
