import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
import apiService from "./../../redux/services/api";
import * as ReduxDialogs from "./../../redux/features/dialogs";

// Utils
import { breakpoints } from "./../../util/consts";
import typography from "./../../util/typography";
import { parseFormError } from "./../../util/form";

// Custom components
import Dialog from "./Dialog";
import CategoriesSelector from "./../../components/form/CategoriesSelector";
import LoadingButton from "./../../components/LoadingButton";

import Form from "./../../components/form/Form";
import SectionsContainer from "./../../components/form/SectionsContainer";
import Section from "./../../components/form/Section";
import Header from "./../../components/form/Header";
import Label from "./../../components/form/Label";
import Input from "./../../components/form/Input";
import TextArea from "./../../components/form/TextArea";
import Error from "./../../components/form/Error";
import CheckRadioBox from "./../../components/form/CheckRadioBox";
import FooterContainer from "./../../components/form/FooterContainer";
import MobileInput from "./../../components/form/MobileInput"

import WeekSchedule from "./../../components/form/WeekSchedule";
import Tooltip from "../elements/Tooltip";

class AddStaffModal extends Component {
  closeDialog = () => {
    const { dispatch, dialogId } = this.props;
    dispatch(ReduxDialogs.actions.close(dialogId));
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
    } = this.props;

    return (
      <Dialog dialogId={this.props.dialogId}>
        <Container>
          <Form
            onSubmit={
              isSubmitting
                ? (e) => {
                    e.preventDefault();
                    return false;
                  }
                : handleSubmit
            }
          >
            <Header title="New Staff Member" />
            <FormSection>Staff Details</FormSection>
            <SectionsContainer>
              <Section>
                <Label htmlFor="addstaff-FirstName">First Name</Label>
                <Input
                  id="addstaff-FirstName"
                  name="FirstName"
                  type="text"
                  placeholder="First name"
                  value={values.FirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.FirstName && errors.FirstName}
                />
                {!!errors.FirstName && touched.FirstName && <Error>{errors.FirstName}</Error>}
              </Section>
              <Section>
                <Label htmlFor="addstaff-LastName">Last Name</Label>
                <Input
                  id="addstaff-LastName"
                  name="LastName"
                  type="text"
                  placeholder="Last name"
                  value={values.LastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.LastName && errors.LastName}
                />
                {!!errors.LastName && touched.LastName && <Error>{errors.LastName}</Error>}
              </Section>
              <Section>
                <Label htmlFor="addstaff-Email">Email</Label>
                <Input
                  id="addstaff-Email"
                  name="Email"
                  type="email"
                  placeholder="example@booklyfe.com"
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Email && errors.Email}
                />
                {!!errors.Email && touched.Email && <Error>{errors.Email}</Error>}
              </Section>
              <Section>
                <Label htmlFor="addstaff-Phone">Phone Number</Label>
                <MobileInput
                  onCountryChange={(e)=>{values.MobileCountryCode = e}}
                  onPhoneChange={(e)=>{values.MobileNumber = e}}
                 /> 
                {!!errors.MobileNumber && touched.MobileNumber && (
                  <Error>{errors.MobileNumber}</Error>
                )}
              </Section>
            </SectionsContainer>

            <FormSection>
              Staff Role{" "}
              <Tooltip>
                Admin can process bookings and payments at all locations. Receptionists can process
                bookings and payments at their location and providers can process bookings and
                payments for themselves only. You can assign multiple roles to allow providers more
                access. Note:The subscription package headcountis based on providers only which
                means you can have unlimited Admin and Receptionist users and it will not affect
                your subscription package.
              </Tooltip>
            </FormSection>
            {/* (?) Admin can process bookings and payments at all locations. Receptionists can process bookings and payments at their location and providers can process bookings and payments for themselves only. You can assign multiple roles to allow providers more access. Note:The subscription package headcountis based on providers only which means you can have unlimited Admin and Receptionist users and it will not affect your subscription package. */}
            <SectionsContainer>
              <Section>
                <RolesContainer>
                  <CheckRadioBox
                    id="addstaff-Roles-Admin"
                    name="Roles"
                    value="Admin"
                    type="checkbox"
                    values={values.Roles}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Roles && errors.Roles}
                  >
                    Admin
                  </CheckRadioBox>
                  <CheckRadioBox
                    id="addstaff-Roles-Receptionist"
                    name="Roles"
                    value="Receptionist"
                    type="checkbox"
                    values={values.Roles}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Roles && errors.Roles}
                  >
                    Receptionist
                  </CheckRadioBox>
                  <CheckRadioBox
                    id="addstaff-Roles-Provider"
                    name="Roles"
                    value="Provider"
                    type="checkbox"
                    values={values.Roles}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Roles && errors.Roles}
                  >
                    Provider
                  </CheckRadioBox>
                </RolesContainer>
                {!!errors.Roles && touched.Roles && <Error>{errors.Roles}</Error>}
              </Section>
            </SectionsContainer>

            {values.Roles.indexOf("Provider") >= 0 && (
              <React.Fragment>
                <FormSection>Provider Details</FormSection>
                <SectionsContainer>
                  <Section>
                    <Label htmlFor="addstaff-ProviderProfession">Profession</Label>
                    <Input
                      id="addstaff-ProviderProfession"
                      name="ProviderProfession"
                      type="text"
                      placeholder="Doctor"
                      value={values.ProviderProfession}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.ProviderProfession && errors.ProviderProfession}
                    />
                    {!!errors.ProviderProfession && touched.ProviderProfession && (
                      <Error>{errors.ProviderProfession}</Error>
                    )}
                  </Section>

                  <Section>
                    <Label htmlFor="addstaff-ProviderBio">Bio</Label>
                    <TextArea
                      id="addstaff-ProviderBio"
                      name="ProviderBio"
                      type="text"
                      placeholder="Biographics details"
                      value={values.ProviderBio}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.ProviderBio && errors.ProviderBio}
                      rows={8}
                    />
                    {!!errors.ProviderBio && touched.ProviderBio && (
                      <Error>{errors.ProviderBio}</Error>
                    )}
                  </Section>

                  <Section>
                    <Label htmlFor="addstaff-ProviderCategories">
                      Categories{" "}
                      <Tooltip>
                        If you can’t find a category that fits your purposes email
                        info@booklyfe.comand we can add it to the list.
                      </Tooltip>
                    </Label>
                    {/* (?) If you can’t find a category that fits your purposes email info@booklyfe.comand we can add it to the list. */}
                    <CategoriesSelector
                      id="addstaff-ProviderCategories"
                      name="ProviderCategories"
                      value={values.ProviderCategories}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.ProviderCategories && errors.ProviderCategories}
                    />
                    {!!errors.ProviderCategories && touched.ProviderCategories && (
                      <Error>{errors.ProviderCategories}</Error>
                    )}
                  </Section>
                </SectionsContainer>

                <FormSection>Provider Schedule</FormSection>
                <SectionsContainer>
                  <Section>
                    <WeekSchedule
                      name="ProviderSchedule"
                      value={values.ProviderSchedule}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.ProviderSchedule && errors.ProviderSchedule}
                    />
                    {!!errors.ProviderSchedule && touched.ProviderSchedule && (
                      <Error>{errors.ProviderSchedule}</Error>
                    )}
                  </Section>
                </SectionsContainer>

                <FormSection>Provider Fund Details</FormSection>
                <SectionsContainer>
                  <Section>
                    <Label htmlFor="addstaff-ProviderFund_AUMedicare">
                      Medicare Provider Number
                    </Label>
                    <Input
                      id="addstaff-ProviderFund_AUMedicare"
                      name="ProviderFund_AUMedicare"
                      type="text"
                      placeholder="000"
                      value={values.ProviderFund_AUMedicare}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.ProviderFund_AUMedicare && errors.ProviderFund_AUMedicare}
                    />
                    {!!errors.ProviderFund_AUMedicare && touched.ProviderFund_AUMedicare && (
                      <Error>{errors.ProviderFund_AUMedicare}</Error>
                    )}
                  </Section>

                  <Section>
                    <Label htmlFor="addstaff-ProviderFund_AUDVA">DVA Provider Number</Label>
                    <Input
                      id="addstaff-ProviderFund_AUDVA"
                      name="ProviderFund_AUDVA"
                      type="text"
                      placeholder="000"
                      value={values.ProviderFund_AUDVA}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.ProviderFund_AUDVA && errors.ProviderFund_AUDVA}
                    />
                    {!!errors.ProviderFund_AUDVA && touched.ProviderFund_AUDVA && (
                      <Error>{errors.ProviderFund_AUDVA}</Error>
                    )}
                  </Section>

                  <Section>
                    <Label htmlFor="addstaff-ProviderFund_AUHICAPS">
                      HICAPS/Medipass Provider Number
                    </Label>
                    <Input
                      id="addstaff-ProviderFund_AUHICAPS"
                      name="ProviderFund_AUHICAPS"
                      type="text"
                      placeholder="000"
                      value={values.ProviderFund_AUHICAPS}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.ProviderFund_AUHICAPS && errors.ProviderFund_AUHICAPS}
                    />
                    {!!errors.ProviderFund_AUHICAPS && touched.ProviderFund_AUHICAPS && (
                      <Error>{errors.ProviderFund_AUHICAPS}</Error>
                    )}
                  </Section>
                </SectionsContainer>
              </React.Fragment>
            )}

            <FooterContainer>
              <LoadingButton
                style={{
                  minWidth: 150
                }}
                loading={isSubmitting}
                variant="contained"
                color="secondary"
                type="submit"
              >
                Save
              </LoadingButton>
            </FooterContainer>
          </Form>
        </Container>
      </Dialog>
    );
  }
}


const mcareProvCoefs = [3, 5, 8, 4, 2, 1];
const mcarePLVMap = {
  "0": 0,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "A": 10,
  "B": 11,
  "C": 12,
  "D": 13,
  "E": 14,
  "F": 15,
  "G": 16,
  "H": 17,
  "J": 18,
  "K": 19,
  "L": 20,
  "M": 21,
  "N": 22,
  "P": 23,
  "Q": 24,
  "R": 25,
  "T": 26,
  "U": 27,
  "V": 28,
  "W": 29,
  "X": 30,
  "Y": 31
};

const mcareCSIdx = ["Y", "X", "W", "T", "L", "K", "J", "H", "F", "B", "A"];
export function checkMedicareProviderNo(pno) {
  if (!pno) {
    return true;
  }

  let pc = pno.trim();

  if (!/^[0-9]{5,6}[0-9ABCDEFGHJKLMNPQRTUVWXY][ABFHJKLTWXY]$/.test(pc)) {
    return false;
  }

  if (pc.length === 7) {
    pc = "0" + pc;
  }

  let val = 0;
  for (let i = 0; i < 6; i++) {
    val += mcareProvCoefs[i] * parseInt(pc[i]);
  }
  val += mcarePLVMap[pc[6]] * 6;
  return pc[7] === mcareCSIdx[val % 11];
}


const formikEnhancer = withFormik({
  mapPropsToValues: ({ data }) => ({
    FirstName: "",
    LastName: "",
    Email: "",
    MobileNumber: "",
    MobileCountryCode: "",
    Roles: [],
    ProviderProfession: "",
    ProviderBio: "",
    ProviderCategories: [],
    ProviderSchedule: data.loc.Schedule,
    ProviderFund_AUMedicare: "",
    ProviderFund_AUDVA: "",
    ProviderFund_AUHICAPS: ""
  }),
  validationSchema: Yup.object().shape({
    FirstName: Yup.string().required("First name is required"),
    LastName: Yup.string().required("Last name is required"),
    Email: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
    MobileNumber: Yup.string()
      .min(5, "Number is too short")
      .notRequired(),
    Roles: Yup.array()
      .of(Yup.string())
      .min(1, "At least one role must be specified")
      .required("Roles are required"),
    ProviderProfession: Yup.string().notRequired(),
    ProviderBio: Yup.string().notRequired(),
    ProviderCategories: Yup.array()
      .of(Yup.string())
      .notRequired(),
      ProviderFund_AUMedicare: Yup.string()
      .test("medicare", "Invalid provider number", checkMedicareProviderNo)
      .notRequired(),
    ProviderFund_AUDVA: Yup.string()
      .test("dva", "Invalid provider number", checkMedicareProviderNo)
      .notRequired(),
    ProviderFund_AUHICAPS: Yup.string().notRequired()
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      const object = {
        User: {
          FirstName: values.FirstName.trim(),
          LastName: values.LastName.trim(),
          Email: values.Email.trim()
        },
        Roles: values.Roles,
        IsActive: true
      };

      if (values.MobileNumber !== "") {
        object.User.MobileNumber = values.MobileNumber;
        object.User.MobileCountryCode = values.MobileCountryCode;
      } else {
        object.User.MobileNumber = null;
        object.User.MobileCountryCode = null;
      }

      if (values.Roles.indexOf("Provider") >= 0) {
        object.RoleProperties = {
          ProviderProfession: values.ProviderProfession.trim(),
          ProviderBio: values.ProviderBio.trim()
        };

        object.ProviderCategories = values.ProviderCategories;
        object.ProviderSchedule = values.ProviderSchedule;
        object.ProviderFundProperties = {};

        if (values.ProviderFund_AUMedicare !== "") {
          object.ProviderFundProperties.AUMedicare = {
            ProviderID: values.ProviderFund_AUMedicare.trim()
          };
        }
        if (values.ProviderFund_AUDVA !== "") {
          object.ProviderFundProperties.AUDVA = {
            ProviderID: values.ProviderFund_AUDVA.trim()
          };
        }
        if (values.ProviderFund_AUHICAPS !== "") {
          object.ProviderFundProperties.AUHICAPS = {
            ProviderID: values.ProviderFund_AUHICAPS.trim()
          };
        }
      }

      await apiService.performRequest({
        method: "post",
        path: `/api/v1/locations/${props.data.loc.ID}/users`,
        data: object
      });

      props.data.cb && props.data.cb();
      props.dispatch(ReduxDialogs.actions.close(props.dialogId));
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }

    setSubmitting(false);
  },
  displayName: "AddStaff"
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(AddStaffModal);

const Container = styled.div`
  width: 100vw;
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    padding: 64px 90px;
    max-width: 700px;
  }
`;

const FormSection = styled.div`
  ${typography.heading6};
  font-weight: 600;
  margin-top: 36px;
`;

const RolesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;
