import React, { Component } from "react";
import styled from "@emotion/styled";

import { colors, hexToRgba } from "./../util/consts";

import Dropdown from "./../components/Dropdown";

class DropdownSelect extends Component {
  state = {
    showDropdown: false
  };

  onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState((prevState) => ({ showDropdown: !prevState.showDropdown }));
    return false;
  };

  handleClickOutside = () => {
    this.setState({ showDropdown: false });
  };

  onSelect = (op) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { forwardedArg } = this.props;
    this.setState({ showDropdown: false });
    op.onClick(forwardedArg);
    return false;
  };

  render() {
    const { showDropdown } = this.state;
    const { style, value, options, children } = this.props;
    return (
      <Dropdown
        isOpen={showDropdown}
        shouldTop
        shouldCenter
        onClickOutside={this.handleClickOutside}
        style={style}
        render={({ styles, ref }) => (
          <Container style={styles} ref={ref}>
            {(options || []).map((op) => (
              <Option key={op.label} onClick={this.onSelect(op)} selected={value === op.label}>
                {op.label}
              </Option>
            ))}
          </Container>
        )}
      >
        {React.cloneElement(React.Children.only(children), { onClick: this.onClick })}
      </Dropdown>
    );
  }
}

const Container = styled.div`
  background-color: ${colors.surface.light};
  border-radius: 4px;
  border: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
  margin-top: 7px;
  min-width: 120px;

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: calc(100% + 1px);
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 7px 7px 7px;
    border-style: solid;
    border-color: ${hexToRgba(colors.primary.main, 0.1)} transparent;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 7px 7px 7px;
    border-style: solid;
    border-color: ${colors.surface.light} transparent;
  }
`;

const Option = styled.div`
  font-family: "Open Sans";
  font-size: 12.82px;
  letter-spacing: 0.12px;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  user-select: none;

  color: ${({ selected }) => (selected ? colors.secondary.main : colors.primary.main)};

  &:hover {
    background-color: rgba(44, 46, 60, 0.05);
    color: #00b1b6;
  }
`;

export default DropdownSelect;
