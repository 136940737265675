/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.proto = require('./applications_and_tokens_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.AuthApplicationsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.AuthApplicationsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.NewAuthApplicationRequest,
 *   !proto.proto.AuthApplication>}
 */
const methodDescriptor_AuthApplications_Create = new grpc.web.MethodDescriptor(
  '/proto.AuthApplications/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.NewAuthApplicationRequest,
  proto.proto.AuthApplication,
  /** @param {!proto.proto.NewAuthApplicationRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AuthApplication.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.NewAuthApplicationRequest,
 *   !proto.proto.AuthApplication>}
 */
const methodInfo_AuthApplications_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.AuthApplication,
  /** @param {!proto.proto.NewAuthApplicationRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AuthApplication.deserializeBinary
);


/**
 * @param {!proto.proto.NewAuthApplicationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.AuthApplication)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AuthApplication>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AuthApplicationsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AuthApplications/Create',
      request,
      metadata || {},
      methodDescriptor_AuthApplications_Create,
      callback);
};


/**
 * @param {!proto.proto.NewAuthApplicationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.AuthApplication>}
 *     A native promise that resolves to the response
 */
proto.proto.AuthApplicationsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AuthApplications/Create',
      request,
      metadata || {},
      methodDescriptor_AuthApplications_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.proto.ListAuthApplicationResponse>}
 */
const methodDescriptor_AuthApplications_GetAll = new grpc.web.MethodDescriptor(
  '/proto.AuthApplications/GetAll',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.proto.ListAuthApplicationResponse,
  /** @param {!proto.google.protobuf.Empty} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListAuthApplicationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.proto.ListAuthApplicationResponse>}
 */
const methodInfo_AuthApplications_GetAll = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ListAuthApplicationResponse,
  /** @param {!proto.google.protobuf.Empty} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListAuthApplicationResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ListAuthApplicationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListAuthApplicationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AuthApplicationsClient.prototype.getAll =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AuthApplications/GetAll',
      request,
      metadata || {},
      methodDescriptor_AuthApplications_GetAll,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListAuthApplicationResponse>}
 *     A native promise that resolves to the response
 */
proto.proto.AuthApplicationsPromiseClient.prototype.getAll =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AuthApplications/GetAll',
      request,
      metadata || {},
      methodDescriptor_AuthApplications_GetAll);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ApiTokensClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ApiTokensPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.NewApiTokenRequest,
 *   !proto.proto.ApiToken>}
 */
const methodDescriptor_ApiTokens_NewToken = new grpc.web.MethodDescriptor(
  '/proto.ApiTokens/NewToken',
  grpc.web.MethodType.UNARY,
  proto.proto.NewApiTokenRequest,
  proto.proto.ApiToken,
  /** @param {!proto.proto.NewApiTokenRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ApiToken.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.NewApiTokenRequest,
 *   !proto.proto.ApiToken>}
 */
const methodInfo_ApiTokens_NewToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ApiToken,
  /** @param {!proto.proto.NewApiTokenRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ApiToken.deserializeBinary
);


/**
 * @param {!proto.proto.NewApiTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ApiToken)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ApiToken>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ApiTokensClient.prototype.newToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ApiTokens/NewToken',
      request,
      metadata || {},
      methodDescriptor_ApiTokens_NewToken,
      callback);
};


/**
 * @param {!proto.proto.NewApiTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ApiToken>}
 *     A native promise that resolves to the response
 */
proto.proto.ApiTokensPromiseClient.prototype.newToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ApiTokens/NewToken',
      request,
      metadata || {},
      methodDescriptor_ApiTokens_NewToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetListTokenUserRequest,
 *   !proto.proto.ListApiTokenResponse>}
 */
const methodDescriptor_ApiTokens_GetListTokenUser = new grpc.web.MethodDescriptor(
  '/proto.ApiTokens/GetListTokenUser',
  grpc.web.MethodType.UNARY,
  proto.proto.GetListTokenUserRequest,
  proto.proto.ListApiTokenResponse,
  /** @param {!proto.proto.GetListTokenUserRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListApiTokenResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.GetListTokenUserRequest,
 *   !proto.proto.ListApiTokenResponse>}
 */
const methodInfo_ApiTokens_GetListTokenUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ListApiTokenResponse,
  /** @param {!proto.proto.GetListTokenUserRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListApiTokenResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetListTokenUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ListApiTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListApiTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ApiTokensClient.prototype.getListTokenUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ApiTokens/GetListTokenUser',
      request,
      metadata || {},
      methodDescriptor_ApiTokens_GetListTokenUser,
      callback);
};


/**
 * @param {!proto.proto.GetListTokenUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListApiTokenResponse>}
 *     A native promise that resolves to the response
 */
proto.proto.ApiTokensPromiseClient.prototype.getListTokenUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ApiTokens/GetListTokenUser',
      request,
      metadata || {},
      methodDescriptor_ApiTokens_GetListTokenUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.SetActiveTokenRequest,
 *   !proto.proto.ApiToken>}
 */
const methodDescriptor_ApiTokens_SetActive = new grpc.web.MethodDescriptor(
  '/proto.ApiTokens/SetActive',
  grpc.web.MethodType.UNARY,
  proto.proto.SetActiveTokenRequest,
  proto.proto.ApiToken,
  /** @param {!proto.proto.SetActiveTokenRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ApiToken.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.SetActiveTokenRequest,
 *   !proto.proto.ApiToken>}
 */
const methodInfo_ApiTokens_SetActive = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ApiToken,
  /** @param {!proto.proto.SetActiveTokenRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ApiToken.deserializeBinary
);


/**
 * @param {!proto.proto.SetActiveTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ApiToken)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ApiToken>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ApiTokensClient.prototype.setActive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ApiTokens/SetActive',
      request,
      metadata || {},
      methodDescriptor_ApiTokens_SetActive,
      callback);
};


/**
 * @param {!proto.proto.SetActiveTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ApiToken>}
 *     A native promise that resolves to the response
 */
proto.proto.ApiTokensPromiseClient.prototype.setActive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ApiTokens/SetActive',
      request,
      metadata || {},
      methodDescriptor_ApiTokens_SetActive);
};


module.exports = proto.proto;

