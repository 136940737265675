import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const sizeProps = {
  small: {
    width: "20",
    height: "20",
  },
  medium: {
    width: "24",
    height: "24",
  },
  large: {
    width: "28",
    height: "28",
  }
};

export default class Place extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"])
  }

  static defaultProps = {
    size: "medium"
  }

  render() {
    const { size, ...otherProps } = this.props;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...sizeProps[size]} {...otherProps}>
        <path fill="none" d="M0 0h24v24H0V0z"/>
        <path d="M12 2C8.13 2 5 5.13 5 9c0 4.17 4.42 9.92 6.24 12.11.4.48 1.13.48 1.53 0C14.58 18.92 19 13.17 19 9c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
      </svg>
    );
  }
}
