import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { format, parse, isSameDay, addDays } from "date-fns";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

// redux
import { getResource } from "./../redux/features/resources/helpers";
import { openConsumerApt } from "./../redux/features/dialogs";

// utils
import { breakpoints, colors, hexToRgba } from "./../util/consts";
import typography from "./../util/typography";

// components
import ResourceFetch from "./../components/elements/ResourceFetch";
import Divider from "./../components/Divider";

class AppointmentsCard extends Component {
  render() {
    const { resource, Location } = this.props;

    const offerings =
      resource.Type === "Single" ? resource.Clients[0].Offerings : resource.GroupOfferings;

    let image = undefined;
    if (Location && typeof Location !== "undefined") {
      if (Location.Banner) {
        image = Location.Banner.DownloadURL;
      } else if (Location.Org && Location.Org.Banner) {
        image = Location.Org.Banner.DownloadURL;
      }
    }

    let dayString = "";
    if (isSameDay(new Date(resource.StartDate), new Date())) {
      dayString = "Today ";
    } else if (isSameDay(new Date(resource.StartDate), addDays(new Date(), 1))) {
      dayString = "Tomorrow ";
    }

    return (
      <>
        <ResourceFetch
          ids={resource.Location}
          type="Location"
          extraData={{ StartDate: format(new Date(), "yyyy-MM-dd") }}
          force
        />
        <Container
          onClick={() => this.props.dispatch(openConsumerApt(undefined, { ...resource, Location }))}
        >
          <Image
            style={{
              backgroundImage: `url(${image})`
            }}
          />
          <Content>
            <CenterLeftContainer>
              <FakeProfile>
                {resource.Provider.FirstName[0]}
                {resource.Provider.LastName[0]}
              </FakeProfile>
              <ProviderName>
                {resource.Provider.FirstName} {resource.Provider.LastName}
              </ProviderName>
            </CenterLeftContainer>
            <Divider style={{ margin: "16px 0" }} />
            {Location && typeof Location !== "undefined" && (
              <>
                <div>
                  <BusinessType>
                    {(offerings || []).map((o, i) => (i > 0 ? ", " : "") + o.Offering.Name)}
                  </BusinessType>
                  <BusinessName>{Location.Name}</BusinessName>
                  <BusinessAddress>{Location.Address && Location.Address.Raw}</BusinessAddress>
                </div>
                <Divider style={{ margin: "16px 0" }} />
              </>
            )}
            <div>
              <MainText
                style={{
                  fontWeight: 600,
                  marginBottom: 6
                }}
              >
                {dayString}
                {format(parse(resource.StartTime, "HH:mm:ss", new Date()), "hh:mmaaaaa")}m
              </MainText>
              <MainText>{format(new Date(resource.StartDate), "iiii do MMMM")}</MainText>
            </div>
          </Content>
        </Container>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const { resource } = props;
  const { auth, resources } = state;

  return {
    auth,
    Location: getResource(resources, "Location", resource.Location, true)
  };
}

export default compose(
  connect(mapStateToProps),
  withRouter
)(AppointmentsCard);

const Container = styled.div`
  position: relative;
  border: 1px solid #f3f5fd;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 ${hexToRgba(colors.primary.main, 0.1)};
  margin: 10px;
  flex-basis: 100%;
  cursor: pointer;

  ${breakpoints["tablet-up"]} {
    flex-basis: calc(50% - 20px);
  }

  ${breakpoints["laptop-up"]} {
    flex-basis: calc(33.33% - 20px);
  }

  ${breakpoints["desktop-up"]} {
    flex-basis: calc(25% - 20px);
  }
`;

const Image = styled.div`
  padding-top: 66.67%;
  background-size: cover;
  background-position: center;
  position: relative;
`;

const Content = styled.div`
  padding: 16px;
`;

const CenterLeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ProviderName = styled.div`
  ${typography.subtitle1};
  font-weight: 600;
  letter-spacing: 1.54px;
  ${typography.elipse};
`;

const BusinessType = styled.div`
  ${typography.overline};
  color: ${colors.secondary.main};
  font-weight: 600;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  margin-bottom: 5px;
  ${typography.elipse};
`;
const BusinessName = styled.div`
  ${typography.subtitle1};
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 19px;
  ${typography.elipse};
`;
const BusinessAddress = styled.div`
  ${typography.body2};
  letter-spacing: 0.5px;
  line-height: 18px;
  line-height: 18px;
  opacity: 0.6;
  ${typography.elipse};
`;

const MainText = styled.div`
  ${typography.body1};
  line-height: 20px;
`;

const FakeProfile = styled.div`
  ${typography.overline};
  color: ${colors.whiteText.highEmphasis};
  letter-spacing: 0.09px;
  line-height: 32px;
  text-align: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${colors.primary.main};
  margin-right: 18px;
`;
