import { combineReducers } from "redux";

import authReducers from "./../../redux/features/auth/reducer";
import globalReducers from "./../../redux/features/global/reducer";
import resourcesReducers from "./../../redux/features/resources/reducer";
import dialogReducers from "./../../redux/features/dialogs/reducer";

export default combineReducers({
  ...authReducers,
  ...globalReducers,
  ...resourcesReducers,
  ...dialogReducers
});
