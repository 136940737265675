import React from "react";
import { Booking, BookingClient } from "./../../../../sdk/bookings_pb";
import { LeftFooterText } from "../../SplitDialog";
import ButtonBase from "./../../../../components/ButtonBase";
import styled from "@emotion/styled-base";

interface Props {
  booking: Booking.AsObject;
  onClick: (client: BookingClient.AsObject) => void;
}

export class GroupBreakdown extends React.Component<Props> {
  render() {
    const { booking, onClick } = this.props;
    return (
      <>
        {booking.clientsList.map((client) => (
          // <ButtonGroupClient key={client.clientId} onClick={() => onClick(client)}>
          //   {client.firstName + " " + client.lastName}
          // </ButtonGroupClient>
          <LeftFooterText key={client.clientId} fontWeight={400} style={{height:"30px"}}>
            {client.firstName + " " + client.lastName}
          </LeftFooterText>
        ))}
      </>
    );
  }
}

export const ButtonGroupClient = styled(ButtonBase)`
  background-color: transparent;
  display: flex;
  height: 50px;
  width: 100%;
  cursor: pointer;
  padding: 5px;

  &:hover:enabled {
    background-color: #0000001c;
  }
`;
