import React from "react";
import { observable, computed } from "mobx";
import { observer } from "mobx-react";
import { Entry, LargeButtonText } from "../Entry";
import { Booking, BookingClient, BookingStatus, BookingType } from "./../../../../sdk/bookings_pb";
import { Payment } from "../Payment";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import ResourceFetch from "./../../../../components/elements/ResourceFetch";
import ResourceRender from "./../../../../components/elements/ResourceRender";
import { GroupPayment } from '../GroupPayment';

interface Props {
  booking: Booking.AsObject;
  client?: BookingClient.AsObject;
}

@observer
class BookingWizard extends React.Component<Props> {
  @observable
  showPaymentWizard = false;

  componentDidMount() {
    const { booking, client } = this.props;
    if (
      (booking.status == BookingStatus.BOOKING_COMPLETED || booking.status == BookingStatus.BOOKING_PROCESSING) &&
      client
    ) {
      this.showPaymentWizard = true;
    }
  }

  render() {
    const { booking, client } = this.props;
    if (this.showPaymentWizard) {
      if (booking.type === BookingType.BOOKING_SINGLE){
        return (
          <Payment
            booking={booking}
            client={client!}
            onRequestClose={() => (this.showPaymentWizard = false)}
            {...this.props}
          />
        );
      }
      return (
        <GroupPayment
          booking={booking}
          onRequestClose={() => (this.showPaymentWizard = false)}
          {...this.props}
        />
      )

    }

    return (
      <Entry
        booking={booking}
        client={client}
        onPayClick={() => (this.showPaymentWizard = true)}
        {...this.props}
      />
    );
  }
}

interface ActualProps {
  booking: Booking.AsObject;
  client?: BookingClient.AsObject;
  dispatch?: any;
  resource?: any;
}

const enhancedAppDetails = compose<any, ActualProps>(connect(), withToastManager)(BookingWizard);

export default class Loader extends React.Component<ActualProps> {
  render() {
    return (
      <>
        <ResourceFetch type="LocBooking" ids={this.props.booking.id} force />
        <ResourceRender
          type="LocBooking"
          ids={this.props.booking.id}
          compSuccess={enhancedAppDetails}
          forwardedProps={this.props}
          denorm={true}
        />
      </>
    );
  }
}
