import React, { PureComponent } from "react";
import { Booking, BookingClient, BookingType } from './../../../sdk/bookings_pb';
import ClientDetails from "../ClientActionDialog/Details";
import AppDetails from "./AppDetails";
import Clients from './Clients';
import { bookingStore } from './../../../stores/booking-store';

interface Props {
  booking: Booking.AsObject;
  client?: BookingClient.AsObject;
  resource?: any;
  dispatch?: any;
  toastManager?: any;
}

export class Details extends PureComponent<Props> {
  
  onSetClient(){
    // this.props.booking.clientsList!.push(client);
    bookingStore.load(this.props.booking.id);
  }
  
  render() {
    const { booking, client, resource } = this.props;
    return (
      <React.Fragment>
        <AppDetails bookingId={booking.id} />
        {booking.type === BookingType.BOOKING_GROUP ?
          // <GroupClients booking={booking} setClient={this.onSetClient} {...this.props}/>
          <Clients booking={resource!} setClient={this.onSetClient}/>
          :
          client &&
          <ClientDetails clientId={client.clientId} bookingId={booking.id}/>
        }
      </React.Fragment>
    );
  }
}
