import { Component } from "react";
import { connect } from "react-redux";

import apiService from "./../../redux/services/api";

import { getResourceList, listIsFetching } from "./../../redux/features/resources/helpers";

import { generateID } from "./../../util";

import * as ResourceActions from "./../../redux/features/resources/thunkactions";

export function shallowCompare(o1, o2) {
  if (typeof o1 === "object" && typeof o2 === "object") {
    const k1 = Object.keys(o1);
    const k2 = Object.keys(o2);
    if (k1.length !== k2.length) {
      return false;
    }
    for (let i = 0; i < k1.length; i++) {
      if (k2.indexOf(k1[i]) < 0) {
        return false;
      }
      if (k1.indexOf(k2[i]) < 0) {
        return false;
      }
      if (o1[k1[i]] !== o2[k1[i]]) {
        return false;
      }
    }
  } else if (Array.isArray(o1) && Array.isArray(o2)) {
    if (o1.length !== o2.length) {
      return false;
    }
    for (let i = 0; i < o1.length; i++) {
      if (o1[i] !== o2[i]) {
        return false;
      }
    }
  } else if (o1 !== o2) {
    return false;
  }
  return true;
}

class ResourceListFetch extends Component {
  actionID = null;

  componentDidMount() {
    this.getList();
  }
  componentWillUnmount() {
    if (this.actionID !== null) {
      apiService.cancelRequest(this.actionID);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { type, listctx, list } = this.props;
    if (prevProps.type !== type || !shallowCompare(prevProps.listctx, listctx) || !list) {
      this.getList();
    }
  }
  async getList() {
    const {
      listctx,
      onSuccess,
      onError,
      clear,
      force,
      list,
      dispatch,
      noLoginReq = false
    } = this.props;
    // Skip if we are not logged in.
    if (!noLoginReq && !this.isLoggedIn()) {
      return;
    }
    // Skip resources that are already fetching.
    if (listIsFetching(list)) {
      return;
    }
    // Do we already have data?
    if (list.$Metadata.DataStatus === "Ok" && !force) {
      return;
    }
    // Fetch the resource.
    try {
      this.actionID = generateID();
      const res = await dispatch(
        ResourceActions.listAction(
          { ...list, $Metadata: { ...list.$Metadata, Context: listctx || {} } },
          clear,
          "Fetch",
          this.actionID
        )
      );
      if (typeof onSuccess === "function") {
        onSuccess(res);
      }
    } catch (err) {
      if (typeof onError === "function") {
        onError(err);
      }
    }
    this.actionID = null;
  }
  isLoggedIn() {
    const { auth } = this.props;
    return auth && auth.Type === "user" && auth.UserID;
  }
  render() {
    return null;
  }
}

function mapStateToProps(state, props) {
  const { listctx, type } = props;
  const { auth, lists } = state;
  return {
    auth,
    list: getResourceList(lists, type, listctx || {})
  };
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(ResourceListFetch);
