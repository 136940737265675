/** @jsx jsx */
import { Component } from "react";
import { css, jsx } from "@emotion/core";
import { withFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

// Utils
import { colors } from "./../../../util/consts";
import { parseFormError } from "./../../../util/form";

// Redux
import apiService from "./../../../redux/services/api";

// Custom components
import Button from "./../../../components/Button";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import Header from "./../../../components/form/Header";
import Form from "./../../../components/form/Form";
import FooterContainer from "./../../../components/form/FooterContainer";
import FooterText from "./../../../components/form/FooterText";

const IS_PHONE_REGEX = /^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1})?[-\s\./0-9]*$/; // eslint-disable-line no-useless-escape

class ForgotPass extends Component {
  moveToLogIn = () => {
    const { pageData, closeModal } = this.props;
    if (pageData && pageData.closeOnBack) {
      closeModal();
    } else {
      this.props.setPage("login", {});
    }
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
    } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <Header
          title="Forgot Your Password?"
          subtitle="Enter either your email or mobile number below and we will send you a new one"
        />
        <SectionsContainer>
          <Section>
            <Input
              id="login-EmailPhone"
              placeholder="example@email.com or 04********"
              type="text"
              name="EmailPhone"
              value={values.EmailPhone}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.EmailPhone && touched.EmailPhone && <Error>{errors.EmailPhone}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <Button
            css={css`
              width: 150px;
            `}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isSubmitting}
          >
            Reset
          </Button>
        </FooterContainer>
        <FooterContainer
          css={css`
            margin-top: 16px;
          `}
        >
          <FooterText>
            Remeber that password?{" "}
            <span
              css={css`
                text-decoration: none;
                color: ${colors.secondary.main};
                cursor: pointer;
              `}
              onClick={this.moveToLogIn}
            >
              Go Back
            </span>
          </FooterText>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: () => ({
    EmailPhone: ""
  }),
  validationSchema: Yup.object().shape({
    EmailPhone: Yup.string().required("Please enter your email or mobile number.")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    const EmailPhone = values.EmailPhone;
    const usePhone = IS_PHONE_REGEX.test(EmailPhone);
    try {
      await apiService.performRequest({
        method: "post",
        path: "/api/v1/resetpass",
        data: {
          Email: usePhone ? null : EmailPhone.trim(),
          MobileNumber: usePhone ? EmailPhone : null,
          MobileCountryCode: usePhone ? "AU" : null
        }
      });

      // Go back to login.
      props.toastManager.add(`New password sent.`, {
        appearance: "success"
      });
      props.setPage("login", {});
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "LoginForm"
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(ForgotPass);
