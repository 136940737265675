/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var offerings_pb = require('./offerings_pb.js')

var money_pb = require('./money_pb.js')
const proto = {};
proto.proto = require('./bookings_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.BookingsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.BookingsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ProcessBookingRequest,
 *   !proto.proto.ProcessBookingResponse>}
 */
const methodDescriptor_Bookings_ProcessBooking = new grpc.web.MethodDescriptor(
  '/proto.Bookings/ProcessBooking',
  grpc.web.MethodType.UNARY,
  proto.proto.ProcessBookingRequest,
  proto.proto.ProcessBookingResponse,
  /** @param {!proto.proto.ProcessBookingRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ProcessBookingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ProcessBookingRequest,
 *   !proto.proto.ProcessBookingResponse>}
 */
const methodInfo_Bookings_ProcessBooking = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ProcessBookingResponse,
  /** @param {!proto.proto.ProcessBookingRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ProcessBookingResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ProcessBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ProcessBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ProcessBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingsClient.prototype.processBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Bookings/ProcessBooking',
      request,
      metadata || {},
      methodDescriptor_Bookings_ProcessBooking,
      callback);
};


/**
 * @param {!proto.proto.ProcessBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ProcessBookingResponse>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingsPromiseClient.prototype.processBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Bookings/ProcessBooking',
      request,
      metadata || {},
      methodDescriptor_Bookings_ProcessBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetBookingRequest,
 *   !proto.proto.Booking>}
 */
const methodDescriptor_Bookings_Get = new grpc.web.MethodDescriptor(
  '/proto.Bookings/Get',
  grpc.web.MethodType.UNARY,
  proto.proto.GetBookingRequest,
  proto.proto.Booking,
  /** @param {!proto.proto.GetBookingRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.GetBookingRequest,
 *   !proto.proto.Booking>}
 */
const methodInfo_Bookings_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.Booking,
  /** @param {!proto.proto.GetBookingRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @param {!proto.proto.GetBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.Booking)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Booking>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingsClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Bookings/Get',
      request,
      metadata || {},
      methodDescriptor_Bookings_Get,
      callback);
};


/**
 * @param {!proto.proto.GetBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Booking>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingsPromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Bookings/Get',
      request,
      metadata || {},
      methodDescriptor_Bookings_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateBookingRequest,
 *   !proto.proto.Booking>}
 */
const methodDescriptor_Bookings_Create = new grpc.web.MethodDescriptor(
  '/proto.Bookings/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateBookingRequest,
  proto.proto.Booking,
  /** @param {!proto.proto.CreateBookingRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateBookingRequest,
 *   !proto.proto.Booking>}
 */
const methodInfo_Bookings_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.Booking,
  /** @param {!proto.proto.CreateBookingRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @param {!proto.proto.CreateBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.Booking)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Booking>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Bookings/Create',
      request,
      metadata || {},
      methodDescriptor_Bookings_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Booking>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Bookings/Create',
      request,
      metadata || {},
      methodDescriptor_Bookings_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListBookingByLocationRequest,
 *   !proto.proto.ListBookingsResponse>}
 */
const methodDescriptor_Bookings_ListByLocation = new grpc.web.MethodDescriptor(
  '/proto.Bookings/ListByLocation',
  grpc.web.MethodType.UNARY,
  proto.proto.ListBookingByLocationRequest,
  proto.proto.ListBookingsResponse,
  /** @param {!proto.proto.ListBookingByLocationRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListBookingsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ListBookingByLocationRequest,
 *   !proto.proto.ListBookingsResponse>}
 */
const methodInfo_Bookings_ListByLocation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ListBookingsResponse,
  /** @param {!proto.proto.ListBookingByLocationRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListBookingByLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ListBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingsClient.prototype.listByLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Bookings/ListByLocation',
      request,
      metadata || {},
      methodDescriptor_Bookings_ListByLocation,
      callback);
};


/**
 * @param {!proto.proto.ListBookingByLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListBookingsResponse>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingsPromiseClient.prototype.listByLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Bookings/ListByLocation',
      request,
      metadata || {},
      methodDescriptor_Bookings_ListByLocation);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.BookingClientsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.BookingClientsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateBookingClientRequest,
 *   !proto.proto.BookingClient>}
 */
const methodDescriptor_BookingClients_Create = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateBookingClientRequest,
  proto.proto.BookingClient,
  /** @param {!proto.proto.CreateBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingClient.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateBookingClientRequest,
 *   !proto.proto.BookingClient>}
 */
const methodInfo_BookingClients_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.BookingClient,
  /** @param {!proto.proto.CreateBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingClient.deserializeBinary
);


/**
 * @param {!proto.proto.CreateBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.BookingClient)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingClient>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/Create',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingClient>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/Create',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateBookingClientRequest,
 *   !proto.proto.BookingClient>}
 */
const methodDescriptor_BookingClients_Update = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/Update',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateBookingClientRequest,
  proto.proto.BookingClient,
  /** @param {!proto.proto.UpdateBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingClient.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.UpdateBookingClientRequest,
 *   !proto.proto.BookingClient>}
 */
const methodInfo_BookingClients_Update = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.BookingClient,
  /** @param {!proto.proto.UpdateBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingClient.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.BookingClient)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingClient>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/Update',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Update,
      callback);
};


/**
 * @param {!proto.proto.UpdateBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingClient>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/Update',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.PartialUpdateBookingClientRequest,
 *   !proto.proto.BookingClient>}
 */
const methodDescriptor_BookingClients_PartialUpdate = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/PartialUpdate',
  grpc.web.MethodType.UNARY,
  proto.proto.PartialUpdateBookingClientRequest,
  proto.proto.BookingClient,
  /** @param {!proto.proto.PartialUpdateBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingClient.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.PartialUpdateBookingClientRequest,
 *   !proto.proto.BookingClient>}
 */
const methodInfo_BookingClients_PartialUpdate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.BookingClient,
  /** @param {!proto.proto.PartialUpdateBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingClient.deserializeBinary
);


/**
 * @param {!proto.proto.PartialUpdateBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.BookingClient)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingClient>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.partialUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/PartialUpdate',
      request,
      metadata || {},
      methodDescriptor_BookingClients_PartialUpdate,
      callback);
};


/**
 * @param {!proto.proto.PartialUpdateBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingClient>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.partialUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/PartialUpdate',
      request,
      metadata || {},
      methodDescriptor_BookingClients_PartialUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteBookingClientRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_BookingClients_Delete = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/Delete',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteBookingClientRequest,
  google_protobuf_empty_pb.Empty,
  /** @param {!proto.proto.DeleteBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.DeleteBookingClientRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_BookingClients_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /** @param {!proto.proto.DeleteBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/Delete',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Delete,
      callback);
};


/**
 * @param {!proto.proto.DeleteBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/Delete',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CancellationChargeClientRequest,
 *   !proto.proto.ProcessBookingCancellationResponse>}
 */
const methodDescriptor_BookingClients_CancellationCharge = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/CancellationCharge',
  grpc.web.MethodType.UNARY,
  proto.proto.CancellationChargeClientRequest,
  proto.proto.ProcessBookingCancellationResponse,
  /** @param {!proto.proto.CancellationChargeClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ProcessBookingCancellationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CancellationChargeClientRequest,
 *   !proto.proto.ProcessBookingCancellationResponse>}
 */
const methodInfo_BookingClients_CancellationCharge = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ProcessBookingCancellationResponse,
  /** @param {!proto.proto.CancellationChargeClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ProcessBookingCancellationResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CancellationChargeClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ProcessBookingCancellationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ProcessBookingCancellationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.cancellationCharge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/CancellationCharge',
      request,
      metadata || {},
      methodDescriptor_BookingClients_CancellationCharge,
      callback);
};


/**
 * @param {!proto.proto.CancellationChargeClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ProcessBookingCancellationResponse>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.cancellationCharge =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/CancellationCharge',
      request,
      metadata || {},
      methodDescriptor_BookingClients_CancellationCharge);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CancelBookingClientRequest,
 *   !proto.proto.Booking>}
 */
const methodDescriptor_BookingClients_Cancel = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/Cancel',
  grpc.web.MethodType.UNARY,
  proto.proto.CancelBookingClientRequest,
  proto.proto.Booking,
  /** @param {!proto.proto.CancelBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CancelBookingClientRequest,
 *   !proto.proto.Booking>}
 */
const methodInfo_BookingClients_Cancel = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.Booking,
  /** @param {!proto.proto.CancelBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @param {!proto.proto.CancelBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.Booking)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Booking>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.cancel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/Cancel',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Cancel,
      callback);
};


/**
 * @param {!proto.proto.CancelBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Booking>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.cancel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/Cancel',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Cancel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ApproveBookingClientRequest,
 *   !proto.proto.Booking>}
 */
const methodDescriptor_BookingClients_Approve = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/Approve',
  grpc.web.MethodType.UNARY,
  proto.proto.ApproveBookingClientRequest,
  proto.proto.Booking,
  /** @param {!proto.proto.ApproveBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ApproveBookingClientRequest,
 *   !proto.proto.Booking>}
 */
const methodInfo_BookingClients_Approve = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.Booking,
  /** @param {!proto.proto.ApproveBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @param {!proto.proto.ApproveBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.Booking)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Booking>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.approve =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/Approve',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Approve,
      callback);
};


/**
 * @param {!proto.proto.ApproveBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Booking>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.approve =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/Approve',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Approve);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RejectBookingClientRequest,
 *   !proto.proto.Booking>}
 */
const methodDescriptor_BookingClients_Reject = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/Reject',
  grpc.web.MethodType.UNARY,
  proto.proto.RejectBookingClientRequest,
  proto.proto.Booking,
  /** @param {!proto.proto.RejectBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.RejectBookingClientRequest,
 *   !proto.proto.Booking>}
 */
const methodInfo_BookingClients_Reject = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.Booking,
  /** @param {!proto.proto.RejectBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @param {!proto.proto.RejectBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.Booking)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Booking>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.reject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/Reject',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Reject,
      callback);
};


/**
 * @param {!proto.proto.RejectBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Booking>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.reject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/Reject',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Reject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetLatestReferralRequest,
 *   !proto.proto.GetLatestReferralResponse>}
 */
const methodDescriptor_BookingClients_GetLatestReferral = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/GetLatestReferral',
  grpc.web.MethodType.UNARY,
  proto.proto.GetLatestReferralRequest,
  proto.proto.GetLatestReferralResponse,
  /** @param {!proto.proto.GetLatestReferralRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetLatestReferralResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.GetLatestReferralRequest,
 *   !proto.proto.GetLatestReferralResponse>}
 */
const methodInfo_BookingClients_GetLatestReferral = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.GetLatestReferralResponse,
  /** @param {!proto.proto.GetLatestReferralRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetLatestReferralResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetLatestReferralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.GetLatestReferralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GetLatestReferralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.getLatestReferral =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/GetLatestReferral',
      request,
      metadata || {},
      methodDescriptor_BookingClients_GetLatestReferral,
      callback);
};


/**
 * @param {!proto.proto.GetLatestReferralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GetLatestReferralResponse>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.getLatestReferral =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/GetLatestReferral',
      request,
      metadata || {},
      methodDescriptor_BookingClients_GetLatestReferral);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.BookingOfferingsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.BookingOfferingsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateBookingOfferingRequest,
 *   !proto.proto.BookingOffering>}
 */
const methodDescriptor_BookingOfferings_Create = new grpc.web.MethodDescriptor(
  '/proto.BookingOfferings/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateBookingOfferingRequest,
  proto.proto.BookingOffering,
  /** @param {!proto.proto.CreateBookingOfferingRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingOffering.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateBookingOfferingRequest,
 *   !proto.proto.BookingOffering>}
 */
const methodInfo_BookingOfferings_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.BookingOffering,
  /** @param {!proto.proto.CreateBookingOfferingRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingOffering.deserializeBinary
);


/**
 * @param {!proto.proto.CreateBookingOfferingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.BookingOffering)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingOffering>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingOfferingsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingOfferings/Create',
      request,
      metadata || {},
      methodDescriptor_BookingOfferings_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateBookingOfferingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingOffering>}
 *     A native promise that resolves to the response
 */
proto.proto.BookingOfferingsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingOfferings/Create',
      request,
      metadata || {},
      methodDescriptor_BookingOfferings_Create);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ClientOfferingsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ClientOfferingsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateClientOfferingRequest,
 *   !proto.proto.ClientOffering>}
 */
const methodDescriptor_ClientOfferings_Create = new grpc.web.MethodDescriptor(
  '/proto.ClientOfferings/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateClientOfferingRequest,
  proto.proto.ClientOffering,
  /** @param {!proto.proto.CreateClientOfferingRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ClientOffering.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateClientOfferingRequest,
 *   !proto.proto.ClientOffering>}
 */
const methodInfo_ClientOfferings_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ClientOffering,
  /** @param {!proto.proto.CreateClientOfferingRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ClientOffering.deserializeBinary
);


/**
 * @param {!proto.proto.CreateClientOfferingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ClientOffering)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ClientOffering>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientOfferingsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientOfferings/Create',
      request,
      metadata || {},
      methodDescriptor_ClientOfferings_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateClientOfferingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ClientOffering>}
 *     A native promise that resolves to the response
 */
proto.proto.ClientOfferingsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientOfferings/Create',
      request,
      metadata || {},
      methodDescriptor_ClientOfferings_Create);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.LineItemsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.LineItemsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateLineItemRequest,
 *   !proto.proto.Booking>}
 */
const methodDescriptor_LineItems_Update = new grpc.web.MethodDescriptor(
  '/proto.LineItems/Update',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateLineItemRequest,
  proto.proto.Booking,
  /** @param {!proto.proto.UpdateLineItemRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.UpdateLineItemRequest,
 *   !proto.proto.Booking>}
 */
const methodInfo_LineItems_Update = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.Booking,
  /** @param {!proto.proto.UpdateLineItemRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateLineItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.Booking)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Booking>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.LineItemsClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.LineItems/Update',
      request,
      metadata || {},
      methodDescriptor_LineItems_Update,
      callback);
};


/**
 * @param {!proto.proto.UpdateLineItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Booking>}
 *     A native promise that resolves to the response
 */
proto.proto.LineItemsPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.LineItems/Update',
      request,
      metadata || {},
      methodDescriptor_LineItems_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteLineItemRequest,
 *   !proto.proto.Booking>}
 */
const methodDescriptor_LineItems_Delete = new grpc.web.MethodDescriptor(
  '/proto.LineItems/Delete',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteLineItemRequest,
  proto.proto.Booking,
  /** @param {!proto.proto.DeleteLineItemRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.DeleteLineItemRequest,
 *   !proto.proto.Booking>}
 */
const methodInfo_LineItems_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.Booking,
  /** @param {!proto.proto.DeleteLineItemRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Booking.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteLineItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.Booking)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Booking>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.LineItemsClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.LineItems/Delete',
      request,
      metadata || {},
      methodDescriptor_LineItems_Delete,
      callback);
};


/**
 * @param {!proto.proto.DeleteLineItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Booking>}
 *     A native promise that resolves to the response
 */
proto.proto.LineItemsPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.LineItems/Delete',
      request,
      metadata || {},
      methodDescriptor_LineItems_Delete);
};


module.exports = proto.proto;

