import React, { Component } from "react";
import styled from "@emotion/styled";
import { withRouter } from "react-router-dom";
import Downshift from "downshift";
import qs from "qs";
import { compose } from "recompose";
import { connect } from "react-redux";

// Redux
import { getResource, getResourceList } from "./../../../redux/features/resources/helpers";

// Utils
import typography from "./../../../util/typography";
import { colors, hexToRgba } from "./../../../util/consts";

// Components
import ButtonBase from "./../../../components/ButtonBase";
import Clear from "./../../../components/icons/Clear";
import ResourceListFetch from "./../../../components/elements/ResourceListFetch";
import ResourceListRender from "./../../../components/elements/ResourceListRender";
import ResourceRender from "./../../../components/elements/ResourceRender";
import Place from "./../../../components/icons/Place";

import magnifyIcon from "./../../../static/icons/magnifying-glass.svg";

// const items = [
//   { type: "service", value: "110", label: "General Practitioner" },
//   { type: "service", value: "111", label: "Psychiatrist" },
//   { type: "service", value: "112", label: "Podiatry" },
//   { type: "service", value: "113", label: "Pathologist" },
//   { type: "service", value: "114", label: "Paediatric Surgeon" },
//   { type: "service", value: "115", label: "Paediatrician" },
//   { type: "service", value: "116", label: "Psychologist" },
//   { type: "service", value: "117", label: "Physiotherapist" }
// ];

// const places = [
//   { type: "locality", value: "120", label: "Melbourne" },
//   { type: "locality", value: "121", label: "Prahran" },
//   { type: "locality", value: "122", label: "Thornbury" },
//   { type: "locality", value: "123", label: "Northcote" },
//   { type: "locality", value: "124", label: "Carlton" },
//   { type: "locality", value: "125", label: "Glen Iris" }
// ];

class SearchInput extends Component {
  stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return changes.selectedItem && changes.selectedItem.type === "search"
          ? changes
          : {
              ...changes,
              inputValue: "",
              selectedItem: null,
              isOpen: true
            };
      default:
        return changes;
    }
  };

  handleSelect = (selection) => {
    if (selection && selection.type !== "locality") {
      const query = qs.stringify({
        ...qs.parse(this.props.location.search.substring(1)),
        [selection.type]: selection.value
      });

      this.props.history.push("/providers?" + query);
    } else if (selection) {
      const { location, lat, lng } = selection.value;
      const query = qs.stringify({
        ...qs.parse(this.props.location.search.substring(1)),
        location,
        lat,
        lng
      });

      this.props.history.push("/providers?" + query);
    }
  };

  handleClearSelected = (type) => {
    const { location, history } = this.props;

    if (type === "locality") {
      history.push(
        location.pathname +
          "?" +
          qs.stringify({
            ...qs.parse(location.search.substring(1)),
            location: undefined,
            lng: undefined,
            lat: undefined
          })
      );
    } else {
      history.push(
        location.pathname +
          "?" +
          qs.stringify({ ...qs.parse(location.search.substring(1)), [type]: undefined })
      );
    }
  };

  render() {
    const { categories } = this.props;
    const { lat, lng, location, service } = qs.parse(this.props.location.search.substring(1));
    const locality =
      typeof lat !== "undefined" && typeof lng !== "undefined" && typeof location !== "undefined"
        ? {
            lat,
            lng,
            location
          }
        : undefined;

    let x = 0;
    return (
      <Container>
        <Downshift
          stateReducer={this.stateReducer}
          onSelect={this.handleSelect}
          itemToString={(item) => (item ? item.label : "")}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            getRootProps,
            isOpen,
            inputValue,
            highlightedIndex
          }) => {
            const validCategories =
              !service &&
              categories.filter((c) => c.Value.toLowerCase().includes(inputValue.toLowerCase()));

            return (
              <SearchRoot isOpen={isOpen} {...getRootProps()}>
                {typeof locality !== "undefined" && (
                  <SelectedItem onClick={() => this.handleClearSelected("locality")}>
                    {locality.location}
                    <Clear
                      size="small"
                      fill={colors.whiteText.highEmphasis}
                      style={{ marginLeft: 8 }}
                    />
                  </SelectedItem>
                )}
                {typeof service !== "undefined" && (
                  <ResourceRender
                    type="Taxonomy"
                    ids={service}
                    compSuccess={({ resource }) => (
                      <SelectedItem onClick={() => this.handleClearSelected("service")}>
                        {resource.Value}
                        <Clear
                          size="small"
                          fill={colors.whiteText.highEmphasis}
                          style={{ marginLeft: 8 }}
                        />
                      </SelectedItem>
                    )}
                  />
                )}
                <Input
                  isOpen={isOpen}
                  {...getInputProps({
                    placeholder: 'Try "Physio"...'
                  })}
                />
                <ResourceListFetch
                  type="SearchLocalities"
                  listctx={{
                    CountryCode: "AU",
                    Limit: 10,
                    Search: inputValue
                  }}
                  noLoginReq={true}
                  force
                />
                {isOpen && (
                  <List {...getMenuProps()}>
                    {!locality && (
                      <ResourceListRender
                        type="SearchLocalities"
                        compSuccess={({ list }) =>
                          list.IDs.length > 0 &&
                          Boolean((x = list.IDs.length || true)) && (
                            <>
                              <ListSection>
                                <Itemtext>Location</Itemtext> <FakeLine />
                              </ListSection>
                              {list.IDs.map((id, index) => (
                                <ResourceRender
                                  key={id}
                                  ids={id}
                                  type="Locality"
                                  compSuccess={({ resource }) => (
                                    <ListItem
                                      {...getItemProps({
                                        index: index,
                                        item: {
                                          value: {
                                            location: resource.Locality,
                                            lat: resource.ReferencePoint.Latitude,
                                            lng: resource.ReferencePoint.Longitude
                                          },
                                          label: resource.Locality,
                                          type: "locality"
                                        },
                                        highlighted: highlightedIndex === index
                                      })}
                                    >
                                      <Place size="small" style={{ marginRight: 12 }} />
                                      <Itemtext>{resource.Locality}</Itemtext>
                                    </ListItem>
                                  )}
                                />
                              ))}
                            </>
                          )
                        }
                      />
                    )}
                    {validCategories.length > 0 && (
                      <>
                        <ListSection>
                          <Itemtext>Specialties and Categories</Itemtext> <FakeLine />
                        </ListSection>
                        {validCategories.map((c, index) => (
                          <ListItem
                            key={c.ID}
                            {...getItemProps({
                              index: index + x,
                              item: {
                                value: c.ID,
                                label: c.Value,
                                type: "service"
                              },
                              highlighted: highlightedIndex === index + x
                            })}
                          >
                            <Icon src={c.PrimaryMedia && c.PrimaryMedia.DownloadURL} />
                            <Itemtext>{c.Value}</Itemtext>
                          </ListItem>
                        ))}
                      </>
                    )}
                    {inputValue.length > 0 && (
                      <>
                        <ListSection>
                          <Itemtext>Search</Itemtext> <FakeLine />
                        </ListSection>
                        <ListItem
                          {...getItemProps({
                            index: x + validCategories.length,
                            item: { type: "search", label: inputValue, value: inputValue },
                            highlighted: highlightedIndex === x + validCategories.length
                          })}
                        >
                          <FakeIcon />
                          <Itemtext>{inputValue}</Itemtext>
                        </ListItem>
                      </>
                    )}
                  </List>
                )}
              </SearchRoot>
            );
          }}
        </Downshift>
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  const { lists, resources } = state;
  const list = getResourceList(lists, "Categories", {});
  return {
    categories: (list.IDs || [])
      .map((lid) => getResource(resources, "Taxonomy", lid, true))
      .filter((it) => !!it.Parent)
  };
}

export default compose(withRouter, connect(mapStateToProps))(SearchInput);

const Container = styled.div`
  margin-left: 24px;
  flex: 1 1 auto;
`;

const SearchRoot = styled.div`
  position: relative;
  border: 1px solid ${colors.primary[100]};
  border-radius: 4px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  height: 40px;
  display: flex;
  ${({ isOpen }) => isOpen && `border-radius: 4px 4px 0px 0px;`}

  &:focus-within {
    box-shadow: 0 2px 4px 0 ${hexToRgba(colors.primary.main, 0.15)};
  }
`;

const Input = styled.input`
  letter-spacing: 0.15px;
  text-align: left;
  width: 100%;
  padding: 10px 16px;
  color: ${colors.surfaceText.highEmphasis};
  outline: none;
  font-size: 16px;
  line-height: 17px;
  border: none;
  border-radius: 4px;
  flex: 0 1 auto;
  ${({ isOpen }) => isOpen && `border-radius: 4px 4px 0px 0px;`};
  background: url(${magnifyIcon}) no-repeat scroll 7px 11px;
  padding-left: 35px;
  background-size: 15px 15px;

  &::placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::placeholder-shown {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-webkit-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-ms-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }
`;

const SelectedItem = styled(ButtonBase)`
  height: 100%;
  background-color: ${colors.secondary.main};
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: ${colors.whiteText.highEmphasis};
  display: flex;
  align-items: center;
  padding: 0 18px;
  flex: 1 0 auto;
  border-right: 1px solid ${colors.primary[100]};

  &:first-of-type {
    border-radius: 3px 0 0 3px;
  }
`;

const List = styled.ul`
  position: absolute;
  z-index: 11;
  top: calc(100% + 1px);
  left: -1px;
  max-height: calc(100vh - 140px);
  overflow-y: scroll;
  width: calc(100% + 2px);
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border-left: 1px solid ${colors.primary[100]};
  border-right: 1px solid ${colors.primary[100]};
  border-bottom: 1px solid ${colors.primary[100]};
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0 2px 4px 0 ${hexToRgba(colors.primary.main, 0.15)};
`;

const ListSection = styled.li`
  padding: 2px 14px;
  align-items: center;
  display: flex;

  &:first-of-type {
    margin-top: 12px;
  }
`;

const FakeLine = styled.div`
  height: 1px;
  background-color: ${colors.primary[100]};
  flex: 1 1 auto;
  margin-left: 8px;
`;

const ListItem = styled.li`
  height: 40px;
  padding: 14px;
  align-items: center;
  display: flex;

  ${({ highlighted }) => highlighted && `background-color: ${colors.surface.dark};`};
`;

const FakeIcon = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin-right: 20px;
  background-color: ${colors.primary.main};
`;

const Icon = styled.img`
  height: 12px;
  width: 12px;
  margin-right: 20px;
`;

const Itemtext = styled.span`
  ${typography.overline};
  letter-spacing: inherit;
`;
