/** @jsx jsx */
import { Component } from "react";
import { css, jsx } from "@emotion/core";
import { withFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";
import styled from "@emotion/styled";

// Redux
// import apiService from "./../../../redux/services/api";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { colors } from "./../../../util/consts";
import { parseFormError } from "./../../../util/form";
import { findPlaceDetails, getAddressResourceFromPlaceDetails } from "./../../../util/address";

// Custom components
import GMPlacesAutocomplete from "./../../../components/form/GMPlacesAutocomplete";
import Button from "./../../../components/Button";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import Label from "./../../../components/form/Label";
import Error from "./../../../components/form/Error";
import Header from "./../../../components/form/Header";
import Form from "./../../../components/form/Form";
import FooterContainer from "./../../../components/form/FooterContainer";
import { GenderSelect } from "./../../../components/form/BaseSelect";

import { getAuthenticatedUser } from "./../../../components/elements/AuthGetUser";

class SignUpExtra extends Component {
  
  closeModal = () => {
    this.props.closeModal();
  };

  handleLogin = (e) => {
    e.preventDefault()
     this.props.history.push("")
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      history
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Header
          title="Extra details"
          subtitle="Please enter the following details to speed up any future bookings"
        />
        <SectionsContainer>
          <Section>
            <Label htmlFor="signupextra-Gender">Gender (Optional)</Label>
            <GenderSelect
              id="signupextra-Gender"
              name="Gender"
              value={values.Gender}
              onChange={handleChange}
              onBlur={handleBlur}
              isUser
            />
            {!!errors.Gender && touched.Gender && <Error>{errors.Gender}</Error>}
          </Section>
          <Section css={{ marginBottom: "30px !important" }}>
            <Label htmlFor="signupextra-Address">Home Address (Optional)</Label>
            <GMPlacesAutocomplete
              id="signupextra-Address"
              name="Address"
              type="text"
              placeholder="Address"
              value={values.Address}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {!!errors.Address && touched.Address && <Error>{errors.Address}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <Button
            css={css`
              width: 150px;
            `}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </Button>
          <SkipButton type="button" onClick={this.handleLogin}>
            Skip
          </SkipButton>

        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: (props) => ({
    Gender: !props.authUser.Gender ? "Unknown" : props.authUser.Gender,
    Address: !props.authUser.Address ? "" : props.authUser.Address.Raw
  }),
  validationSchema: Yup.object().shape({
    // Gender: Yup.string().required("Please enter your gender"),
    // Address: Yup.string().required("Please enter your address")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      const updUser = {
        ...props.authUser,
        Gender: values.Gender
      };
      await props.dispatch(ResourceActions.action(updUser, "Update", {}));     


      if (values.Address !== "") {
        if (!props.authUser.Address) {
          const prDet = await findPlaceDetails(values.Address, "Address");
          const prNewAddr = {
            OwnerType: "User",
            Owner: props.authUser.ID,
            ...getAddressResourceFromPlaceDetails(prDet, values.Address)
          };
          await props.dispatch(ResourceActions.action(prNewAddr, "Create", {}));
        } else if (props.authUser.Address.Raw !== values.Address) {
          const prDet = await findPlaceDetails(values.Address, "Address");
          const prUpdAddr = {
            ...props.authUser.Address,
            ...getAddressResourceFromPlaceDetails(prDet, values.Address)
          };
          await props.dispatch(ResourceActions.action(prUpdAddr, "Update", {}));
        }
      }
      props.history.push("")
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "ExtraDetailsForm"
});

const SkipButton = styled(Button)`
  margin-top: "40px";
  background-color: "rgba(44,46,60,0.12)";
  color:"rgba(0,0,0,0.38);"
`;

export default compose(
  connect((state) => ({ authUser: getAuthenticatedUser(state) })),
  withToastManager,
  formikEnhancer
)(SignUpExtra);
