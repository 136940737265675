import styled from "@emotion/styled";
import * as fns from "date-fns";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import { compose } from "recompose";
import Avatar from "./../../../components/Avatar";
import ButtonBase from "./../../../components/ButtonBase";
import LoadingIcon from "./../../../components/icons/Loading";
import { Tab, Tabs } from "./../../../components/Tabs";
import * as ReduxDialogs from "./../../../redux/features/dialogs";
import {
  Booking,
  BookingClient,
  ApproveBookingClientRequest,
  BookingStatus,
  BookingType
} from "./../../../sdk/bookings_pb";
import { bookingEndTime, bookingStartTime, renderBookingStatus } from "./../../../util/bookings";
import { colors, hexToRgba } from "./../../../util/consts";
import typography from "./../../../util/typography";
import { RightContent } from "../SplitDialog";
import Offerings from "./Offerings";
import { Details } from "./Details";
import { Notes } from "./Notes";
import { rebookDataStore } from "../../../mobx/stores/RebookDataStore";
import Popup from "reactjs-popup";
import { RebookModal } from "../../modal/RebookModal";
import { rpc, metadata } from "./../../../grpc";
import { bookingStore } from "./../../../stores/booking-store";
import { ResourceTypes } from "./../../../redux/features/resources/definitions";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { toastStore } from "./../../../stores/toast-store";
import { PhysitrackButton } from "../ClientActionDialog/Physitrack/PhysitrackButton";
import { TokenAvailableRequest, TokenAvailableResponse } from "../../../sdk/physitrack_pb";
import { ApplicationType } from "../../../sdk/applications_and_tokens_pb";

interface Props {
  booking: Booking.AsObject;
  client?: BookingClient.AsObject;
  dispatch?: any;
  toastManager?: any;
  resource?: any;
  onPayClick(): void;
}

export class Entry extends Component<Props> {
  state = {
    isLoading: false,
    tokenAndData: new TokenAvailableResponse().toObject(),
    isTelehealth: false
  };

  async componentDidMount() {
    const { booking, client } = this.props;
    const isAnyOfferingTelehealth =
      booking!.offeringsList.filter((off) => off.lineItem!.offering!.isTelehealth === true).length >
      0;
    try {
      // Get token and patient data for physitrack
      const req = new TokenAvailableRequest();
      req.setOrgid(booking.organisationId);
      req.setClientid(client!.clientId);
      req.setType(ApplicationType.PHYSITRACK_LYFE);

      const res = await rpc.physitrackV1.tokenAvailable(req, metadata());
      this.setState({ tokenAndData: res.toObject() });
      this.setState({ isTelehealth: isAnyOfferingTelehealth });
    } catch (err) {
      toastStore.grpcToastError(err);
    }
  }

  cancelBooking = () => {
    const { booking, client, dispatch } = this.props;
    if (booking.type === BookingType.BOOKING_SINGLE && client) {
      dispatch(ReduxDialogs.openCancel(null, booking.id, client.clientId, () => {}));
    } else {
      dispatch(ReduxDialogs.openCancel(null, booking.id, undefined, () => {}));
    }
  };

  approveBooking = async () => {
    const { booking, dispatch, toastManager, client } = this.props;
    this.setState({ isLoading: true });
    try {
      const req = new ApproveBookingClientRequest();
      req.setBookingId(booking.id);
      req.setClientId(client!.clientId);
      const res = await rpc.bookingClients.approve(req, metadata());
      dispatch(
        ResourceActions.action(
          { $Metadata: { Type: ResourceTypes.LocBooking } as any, ID: booking.id },
          "Fetch"
        )
      );
      toastManager!.add(`Client successfully approved`, {
        appearance: "success"
      });

      bookingStore.add(res.toObject());
    } catch (err) {
      toastStore.grpcToastError(err);
    }
    this.setState({ isLoading: false });
  };

  rejectBooking = () => {
    const { booking, client, dispatch } = this.props;
    if (!client || this.state.isLoading) {
      return;
    }
    dispatch(ReduxDialogs.openReject(null, booking, client, () => {}));
  };

  renderButtons(client?: BookingClient.AsObject) {
    const { isLoading } = this.state;
    const { booking } = this.props;

    switch (booking.status) {
      case BookingStatus.BOOKING_CREATED:
        if (client) {
          switch (client.status) {
            case BookingClient.Status.CREATED:
              return (
                <RightBottomButtonContainer>
                  <LargeBottomButton isPrimary={true} onClick={this.rejectBooking}>
                    <LargeButtonText>Reject</LargeButtonText>
                  </LargeBottomButton>
                  <LargeBottomButton onClick={this.approveBooking}>
                    {isLoading ? (
                      <LoadingIcon width={16} height={16} color="#FFF" />
                    ) : (
                      <LargeButtonText>Approve</LargeButtonText>
                    )}
                  </LargeBottomButton>
                </RightBottomButtonContainer>
              );
            case BookingClient.Status.APPROVED:
              return (
                <RightBottomButtonContainer>
                  <LargeBottomButton isPrimary={true} onClick={this.cancelBooking}>
                    <LargeButtonText>Cancel</LargeButtonText>
                  </LargeBottomButton>
                  <LargeBottomButton onClick={this.props.onPayClick}>
                    <LargeButtonText>Pay</LargeButtonText>
                  </LargeBottomButton>
                </RightBottomButtonContainer>
              );
            case BookingClient.Status.CANCELLED:
            case BookingClient.Status.REJECTED:
              if (!client) {
                return null;
              }
              return (
                <RightBottomButtonContainer>
                  <LargeBottomButton onClick={this.props.onPayClick}>
                    <LargeButtonText>Charge Cancellation Fee</LargeButtonText>
                  </LargeBottomButton>
                </RightBottomButtonContainer>
              );
          }
        } else {
          return (
            <RightBottomButtonContainer>
              <LargeBottomButton isPrimary={true} onClick={this.cancelBooking}>
                <LargeButtonText>Cancel</LargeButtonText>
              </LargeBottomButton>
              <LargeBottomButton onClick={this.props.onPayClick}>
                <LargeButtonText>Pay</LargeButtonText>
              </LargeBottomButton>
            </RightBottomButtonContainer>
          );
        }
      case BookingStatus.BOOKING_CANCELLED:
        if (!client) {
          return null;
        }
        return (
          <RightBottomButtonContainer>
            <LargeBottomButton onClick={this.props.onPayClick}>
              <LargeButtonText>Charge Cancellation Fee</LargeButtonText>
            </LargeBottomButton>
          </RightBottomButtonContainer>
        );
      case BookingStatus.BOOKING_PROCESSING:
      case BookingStatus.BOOKING_COMPLETED:
        return (
          <RightBottomButtonContainer>
            <LargeBottomButton onClick={this.props.onPayClick}>
              <LargeButtonText>Payment Details</LargeButtonText>
            </LargeBottomButton>
          </RightBottomButtonContainer>
        );
      default:
        return null;
    }
  }

  render() {
    const { booking, client } = this.props;

    const stime = bookingStartTime(booking);
    const etime = bookingEndTime(booking);
    const dur = booking.duration!.seconds * 60; // duration in minutes

    const clientLetters = !client
      ? "GB"
      : `${client.firstName.charAt(0)}${client.lastName.charAt(0)}`;

    const clientTitle = !client ? "Group Booking" : `${client.firstName} ${client.lastName}`;
    return (
      <React.Fragment>
        {/* only render the rebooking popup after making the payment, which meaning the required info(resource.clients, resource.GroupOffering) is loaded. Also work around the bug of #313 */}
        {rebookDataStore.isOpen && (
          <Popup
            modal
            open={rebookDataStore.isOpen}
            onClose={() => {
              rebookDataStore.isOpen = false;
            }}
            contentStyle={{
              width: "360px",
              height: "175px",
              borderRadius: "9px",
              borderWidth: "0px",
              margin: "auto auto auto auto"
            }}
          >
            {
              <RebookModal
                duration={this.props.booking.duration}
                startTime={this.props.booking.startTime}
                clientId={this.props.resource.Clients[0].Client.ID}
                offering={this.props.resource.GroupOfferings[0].Offering}
                bookingId={this.props.booking.id}
                dispatch={this.props.dispatch}
                toastManager={this.props.toastManager}
                locationId={this.props.booking.locationId}
                provider={this.props.booking.provider!}
              />
            }
          </Popup>
        )}

        <RightContent>
          <TitleContainer>
            <Avatar letters={clientLetters} size={100} lfrac={0.2369} />
            <div style={{ marginLeft: 28 }}>
              <TitleName>{clientTitle}</TitleName>
              <TitleTime>
                {fns.format(stime, "hh:mma")} - {fns.format(etime, "hh:mma")}
              </TitleTime>
              <TitleService>{renderBookingStatus(booking, client)}</TitleService>
              {this.state.isTelehealth && (
                <PhysitrackButton
                  tokenAndData={this.state.tokenAndData}
                  buttonLabel={"Start telehealth session in physitrack"}
                />
              )}
            </div>
          </TitleContainer>
          <Tabs>
            <Tab
              label="Products &amp; Services"
              render={() => <Offerings booking={booking} client={client} {...this.props} />}
            />
            <Tab
              label="Details"
              render={() => <Details booking={booking} client={client} {...this.props} />}
            />
            {client && (
              <Tab label="Notes" render={() => <Notes booking={booking} client={client} />} />
            )}
          </Tabs>
          <ButtonPad />
        </RightContent>
        {this.renderButtons(client)}
      </React.Fragment>
    );
  }
}

export default compose(connect(), withToastManager)(Entry as any);

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 51px;
  margin-bottom: 51px;
`;

export const TitleName = styled.div`
  ${typography.heading6};
  font-weight: 600;
  letter-spacing;
  1.44px;
  line-height: 24px;
`;

export const TitleService = styled.div`
  ${typography.body2};
  letter-spacing: 1.17px;
  line-height: 18px;
`;

export const TitleTime = styled.div`
  ${typography.body2};
  letter-spacing: 1.17px;
  line-height: 18px;
  color: ${colors.secondary.main};
`;

export const ButtonPad = styled.div`
  height: 70px;
  width: 100%;
`;

export const RightBottomButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  display: flex;
`;

export const LargeButtonText = styled.span`
  ${typography.heading6};
  font-weight: 600;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: ${colors.whiteText.highEmphasis};
`;

export const LargeBottomButton = styled(ButtonBase)`
  flex: 1 0 100px;
  height: 100%;
  background-color: ${({ isPrimary }) => (isPrimary ? colors.primary.main : colors.secondary.main)};
  transition: opacity 0.2s ease;

  &:hover:enabled {
    opacity: 0.95;
  }

  &:focus {
    opacity: 0.95;
  }

  &:disabled {
    background-color: ${hexToRgba(colors.primary.main, 0.12)};
  }
`;

export const BackButton = styled(ButtonBase)`
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  transition: opacity 0.2s ease;

  opacity: 0.6;
  color: #2c2e3c;
  font-size: 13.82px;
  letter-spacing: 0.25px;
  line-height: 20px;

  &:hover:enabled {
    opacity: 0.95;
  }

  &:focus {
    opacity: 0.95;
  }
`;
