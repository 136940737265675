import { EntityStore } from './entity-store';
import { Booking, GetBookingRequest } from './../sdk/bookings_pb';
import { rpc, metadata } from './../grpc';
import { Tag, ListTagRequest, ClientTags, ListClientTagsRequest, AddTagToClientRequest } from './../sdk/tag_pb';
import { observable, action, computed } from 'mobx';


export class ClientTagStore {

  @observable
  private TagEntities = new Map<string, Array<Tag.AsObject>>();

  @action
  add(clientId: string, tagEntity: Tag.AsObject) {
    const currentTags = this.get(clientId);

    if (currentTags){
      currentTags.push(tagEntity);
    } else {
      const newArrayTag = Array<Tag.AsObject>();
      newArrayTag.push(tagEntity);
      this.TagEntities.set(clientId, newArrayTag);    
    }
  }

  @action
  delete(clientId: string, tagEntity: Tag.AsObject) {
    const currentTags = this.get(clientId);
    if(currentTags){
      currentTags.splice(currentTags.indexOf(tagEntity), 1);
    }
  }

  @action
  deleteAll() {
    this.TagEntities.clear();
  }

  @action
  get(clientId: string): Array<Tag.AsObject> | undefined {
    return this.TagEntities.get(clientId);
  }

  // all(): Tag.AsObject[] {
  //   return Array.from(this.TagEntities.values());
  // }

  async load(clientId: string, orgId: string) {
    const req = new ListClientTagsRequest();
    req.setClientId(clientId);
    req.setOrganisationId(orgId);
    const res = await rpc.freeFormTag.listClientTags(req, metadata());

    if (res.toObject().tagsList.length === 0) {
      const newArrayTag = Array<Tag.AsObject>();
      this.TagEntities.set(clientId, newArrayTag);  
    }
    else {
      await res.toObject().tagsList.map(tag => {
          this.add(clientId, tag);
        }
      ); 
    }
  }
}

export const clientTagStore = new ClientTagStore();
