import React, { Component } from "react";
import styled from "@emotion/styled";
import { withFormik } from "formik";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

// import apiService from "./../../../redux/services/api";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

import { parseFormError } from "./../../../util/form";

// utils
import { colors } from "./../../../util/consts";
import typography from "./../../../util/typography";

// components
import Input from "./../../../components/form/Input";
import CheckRadioBox from "./../../../components/form/CheckRadioBox";
import Error from "./../../../components/form/Error";
import Form from "./../../../components/form/Form";
import Section from "./../../../components/form/Section";
import Button from "./../../../components/Button";

// icons
import LoadingIcon from "./../../../components/icons/Loading";

import { RolesContainer } from "./../../../components/elements/AccordianElements";

class OrgRoles extends Component {
  constructor(props) {
    super(props);
  }
  handleEditClick = (e) => {
    this.props.setStatus(true);
  };
  render() {
    const {
      values,
      status,
      touched,
      errors,
      handleChange,
      handleBlur,
      isSubmitting,
      handleSubmit
    } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <HeaderContainer>
          Organisation Roles
          {!isSubmitting && status && <HeaderButton type="submit">Save</HeaderButton>}
          {!isSubmitting && !status && (
            <HeaderButton onClick={this.handleEditClick}>Edit</HeaderButton>
          )}
          {isSubmitting && <LoadingIcon color="#2c2e3c" size="small" />}
        </HeaderContainer>

        <Container>
          <Section>
          <RolesContainer>
            <CheckRadioBox
              id={`addstaff-OrgRoles-Admin`}
              name="Roles"
              value="Admin"
              type="checkbox"
              values={values.Roles}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Roles && errors.Roles}
              disabled={!status}
            >
              Admin
            </CheckRadioBox>
            <CheckRadioBox
              id={`addstaff-OrgRoles-Receptionist`}
              name="Roles"
              value="Receptionist"
              type="checkbox"
              values={values.Roles}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Roles && errors.Roles}
              disabled={!status}
            >
              Receptionist
            </CheckRadioBox>
          </RolesContainer>
            {!!errors.Roles && touched.Roles && <Error>{errors.Roles}</Error>}
          </Section>
        </Container>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ orgUser }) => ({
    Roles: orgUser.Roles || []
  }),
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, setStatus, setFieldError, props }) => {
    const { orgUser, dispatch } = props;
    try {
      const updUser = {
        $Metadata: orgUser.$Metadata,
        Org: orgUser.Org.ID,
        User: {
          ID: orgUser.User.ID,
          FirstName: orgUser.User.FirstName,
          LastName: orgUser.User.LastName,
          Email: orgUser.User.Email || null,
          MobileNumber: orgUser.User.MobileNumber || null,
          MobileCountryCode: orgUser.User.MobileCountryCode || null
        },
        RoleProperties: orgUser.RoleProperties,
        Roles: values.Roles,
        IsActive: orgUser.IsActive
      };

      await dispatch(ResourceActions.action(updUser, "Update", {}));
      setStatus(false);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  }
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(OrgRoles);

export const Container = styled.div`
  border-radius: 4px;
  margin: 8px 0 30px;
  background-color: ${colors.surface.light};
  padding: 24px 32px 30px;
`;

export const HeaderContainer = styled.div`
  /* margin-top: 30px; */
  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 15.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderButton = styled(Button)`
  padding: 0;
  opacity: 0.3;
`;

export const DetailInput = styled(Input)`
  ${typography.body2};
  letter-spacing; 0.19px;
  line-height: 18px;
  opacity: 0.6;
`;
