import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import typography from "./../../../util/typography";
import { colors } from "./../../../util/consts";

export default class Complete extends Component {
  onBookAnother = (e) => {
    e.preventDefault();
    const { setPage } = this.props;
    setPage("initial", {}, true);
  }
  render() {
    const { match } = this.props;
    return (
      <Container>
        <Info>
          <Heading>Booking Submitted</Heading>
          <SubHeading>You have successfully booked the appointment.</SubHeading>
          <Desc>
            Book another appointment <StyledLink to={match.url} onClick={this.onBookAnother}>here</StyledLink>.
          </Desc>
        </Info>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 96px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 899px) {
    flex: 1 0 300px;
    padding: 0 30px;
  }
  @media (min-width: 900px) {
    max-width: 430px;
  }
`;

const Heading = styled.h1`
  ${typography.heading2};
  color: ${colors.primary.main};
`;

const SubHeading = styled.h2`
  ${typography.heading5};
  color: ${colors.primary.main};
  margin-top: 17px;
`;

const Desc = styled.h2`
  ${typography.subtitle2};
  color: ${colors.primary.main};
  opacity: 0.6;
  margin-top: 17px;
`;

const StyledLink = styled(Link)`
  ${typography.subtitle2};
  color: ${colors.secondary.main};
  opacity: 0.6;
  text-decoration: none;
  margin-top: 17px;
`;
