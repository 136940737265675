/** @jsx jsx */
import { Component, Fragment } from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import { connect } from "react-redux";
import { Link, NavLink, Switch, Route } from "react-router-dom";
import debounce from "lodash.debounce";

// mobx
import { tagDataStore } from "../../../mobx/stores/TagDataStore";
import { signUpDataStore } from "./../../../mobx/stores/SignUpDataStore";

// Redux
import * as ReduxDialogs from "./../../../redux/features/dialogs";

// Utils
import typography from "./../../../util/typography";
import { colors, hexToRgba, breakpoints } from "./../../../util/consts";

// Components
import Button from "./../../../components/Button";
import IconButton from "./../../../components/IconButton";
import LogoIcon from "./../../../components/icons/Logo";
import MenuIcon from "./../../../components/icons/Menu";

// import UserContextMenu from "./UserContextMenu";
// import OrgContextMenu from "./OrgContextMenu";
import Breadcrumbs from "./Breadcrumbs";
import SearchInput from "./SearchInput";

import UserAvatar from "./../../../components/UserAvatar";
import OrgAvatar from "./../../../components/OrgAvatar";
import KeyboardArrowUpIcon from "./../../../components/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "./../../../components/icons/KeyboardArrowDown";
import ClearIcon from "./../../../components/icons/Clear";

// Testing
import { UserMenu, OrgMenu } from "./Menus";
import Dropdown from "./../../../components/Dropdown";
import { paymentModalStore } from "./../../../mobx/stores/PaymentModalStore";
import { PaymentModal } from "./../../../components/modal/PaymentModal";
import Popup from "reactjs-popup";
import { observer } from "mobx-react";

@observer
class Header extends Component {
  state = {
    isLoggedIn: false,
    isUserMenuOpen: false,
    isOrgMenuOpen: false,
    winWidth: window.innerWidth
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    // Adding script to load livechat support
    const script = document.createElement("script");
    script.src = "https://tracking.cchat.io/lyfe/tracking.js";
    script.async = true;
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = debounce(() => {
    this.setState({ winWidth: window.innerWidth });
  }, 20);

  static getDerivedStateFromProps = (props) => {
    const { auth } = props;
    return {
      isLoggedIn: Boolean(auth && auth.Type === "user" && auth.UserID)
    };
  };

  openLoginModal = (e) => {
    const trigger = e.target;
    this.props.dispatch(ReduxDialogs.openAccount(trigger));
  };

  openSignUpModal = (e) => {
    const trigger = e.target;
    signUpDataStore._signUpButton = true
    this.props.dispatch(ReduxDialogs.openAccount(trigger));
  };

  toggleUserMenu = () => {
    this.setState((prevState) => ({
      isUserMenuOpen: !prevState.isUserMenuOpen
    }));
  };

  closeUserMenu = () => {
    this.setState({ isUserMenuOpen: false });
  };

  toggleOrgMenu = () => {
    this.setState((prevState) => ({
      isOrgMenuOpen: !prevState.isOrgMenuOpen
    }));
  };

  closeOrgMenu = () => {
    this.setState({ isOrgMenuOpen: false });
  };

  render() {
    const { isLoggedIn, isUserMenuOpen, isOrgMenuOpen, winWidth } = this.state;
    const isMobile = winWidth < 744;
    const { userContext, auth, history } = this.props;

    // get organisation ID for getting organisation's tags
    tagDataStore.organisationID = userContext.OrgID;

    return (
      <Container viewWidth={window.innerWidth}>
        {/* open payment modal after user is logged in */}
        {paymentModalStore.isOpen && (
          <Popup
            modal
            open={paymentModalStore.isOpen}
            onClose={() => {
              paymentModalStore.isOpen = false;
            }}
            contentStyle={{
              width: "360px",
              height: "175px",
              borderRadius: "9px",
              borderWidth: "0px",
              margin: "auto auto auto auto"
            }}
          >
            {<PaymentModal history={history} creditCards={null} medicareFunds={null} />}
          </Popup>
        )}

        <ContentContainer>
          {isMobile ? (
            <Dropdown
              isOpen={isUserMenuOpen}
              onClickOutside={this.closeUserMenu}
              render={({ ref }) => (
                <UserMenu
                  containerStyles={{
                    position: "fixed",
                    top: 69,
                    left: 0
                  }}
                  innerRef={ref}
                  closeMenu={this.closeUserMenu}
                />
              )}
            >
              <TransparentButton onClick={this.toggleUserMenu}>
                <LogoIcon
                  css={css`
                    flex: 0 0 auto;
                  `}
                />
                {isUserMenuOpen ? (
                  <KeyboardArrowUpIcon fill={colors.secondary.main} size="small" />
                ) : (
                  <KeyboardArrowDownIcon fill={colors.secondary.main} size="small" />
                )}
              </TransparentButton>
            </Dropdown>
          ) : (
            <Link
              to={userContext.OrgID === "" ? "/" : `/admin/orgs/${userContext.OrgID}/locations`}
            >
              <LogoIcon
                css={css`
                  flex: 0 0 auto;
                `}
              />
            </Link>
          )}
          {/* <div
            css={css`
              margin-left: 24px;
              flex: 0 1 auto;

              ${breakpoints["tablet-up"]} {
                flex-grow: 1;
              }
            `}
          > */}
          {isLoggedIn && userContext.OrgID ? (
            <Route component={Breadcrumbs} />
          ) : (
            <Switch>
              <Route exact path="/createorg" component={Breadcrumbs} />
              <Route path="/bookings/locs" />
              <Route component={SearchInput} />
            </Switch>
          )}
          {/* </div> */}
        </ContentContainer>
        <NavContainer>
          <Switch>
            <Route exact path="/createorg" render={(p) => <RightFullPageForm {...p} />} />
            <Route
              exact
              path="/admin/orgs/:oid/createloc"
              render={(p) => <RightFullPageForm {...p} />}
            />
            {!isMobile && (
              <Route
                render={(p) => (
                  <Fragment>
                    {userContext.OrgID === "" ? (
                      <Fragment>
                        <FadingLink
                          to="/listyourbusiness"
                          exact
                          activeStyle={{
                            color: colors.secondary.main,
                            opacity: 1
                          }}
                        >
                          <a style={{fontWeight: '500'}}>List your Business</a>
                        </FadingLink>
                        {isLoggedIn && (
                          <FadingLink
                            to={`/users/appointments`}
                            exact
                            activeStyle={{
                              color: colors.secondary.main,
                              opacity: 1
                            }}
                          >
                            My Appointments
                          </FadingLink>
                        )}
                      </Fragment>
                    ) : (
                      <Switch>
                        <Route path="/admin/orgs/:oid/locations" render={() => null} />
                        <Route
                          render={() => (
                            <Dropdown
                              isOpen={isOrgMenuOpen}
                              onClickOutside={this.closeOrgMenu}
                              render={({ styles, ref }) => (
                                <OrgMenu
                                  containerStyles={styles}
                                  innerRef={ref}
                                  closeMenu={this.closeOrgMenu}
                                />
                              )}
                            >
                              <IconButton onClick={this.toggleOrgMenu}>
                                <MenuIcon size="small" />
                              </IconButton>
                            </Dropdown>
                          )}
                        />
                      </Switch>
                    )}
                    {isLoggedIn ? (
                      <Dropdown
                        isOpen={isUserMenuOpen}
                        onClickOutside={this.closeUserMenu}
                        render={({ styles, ref }) => (
                          <UserMenu
                            containerStyles={styles}
                            innerRef={ref}
                            closeMenu={this.closeUserMenu}
                          />
                        )}
                      >
                        <IconButton mini onClick={this.toggleUserMenu}>
                          {userContext.OrgID === "" ? (
                            <UserAvatar userID={auth.UserID} size={32} />
                          ) : (
                            <OrgAvatar orgID={userContext.OrgID} size={32} />
                          )}
                        </IconButton>
                      </Dropdown>
                    ) : (
                      <Fragment>
                        {/* <FadingLink
                          to="/signup"
                          exact
                          activeStyle={{
                            color: colors.secondary.main,
                            opacity: 1
                          }}>  */}
                          <SignUpButtom variant="contained" color="primary" onClick={this.openSignUpModal}>
                            <ButtonText>Sign Up</ButtonText> 
                          </SignUpButtom>
                    {/* </FadingLink> */}
                      <Button variant="contained" color="secondary" onClick={this.openLoginModal}>
                        <ButtonText>Login</ButtonText>
                      </Button> 
                      </Fragment>
                    )}
                  </Fragment>
                )}
              />
            )}
          </Switch>
        </NavContainer>
        {/* {!isUserMenuOpen ? null : (
          <UserContextMenu
            closeMenu={() => this.setState({ isUserMenuOpen: false })}
            history={history}
          />
        )}
        {!isOrgMenuOpen ? null : (
          <OrgContextMenu
            closeMenu={() => this.setState({ isOrgMenuOpen: false })}
            history={history}
          />
        )} */}
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  const { auth, global } = state;
  return {
    auth,
    userContext: global.UserContext
  };
}

export default connect(mapStateToProps)(Header);

const Container = styled.div`
  max-width: 100vw;
  width: ${({ viewWidth }) => viewWidth}px;
  height: 69px;
  padding: 0 24px;
  background-color: ${colors.surface.light};
  border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 11;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${breakpoints["laptop-up"]} {
    flex: 1;
  }
`;

const NavContainer = styled.nav`
  display: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${breakpoints["laptop-up"]} {
  }
`;

const TransparentButton = styled.button`
  outline: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;

const SignUpButtom = styled(Button)`
  background-color: ${colors.primary[500]};
  margin-right: 18px;
`;

class RightFullPageForm extends Component {
  render() {
    const { history } = this.props;
    return (
      <IconButton onClick={() => history.length > 2 && history.goBack()}>
        <ClearIcon />
      </IconButton>
    );
  }
}

const ButtonText = styled.span`
  margin: 0 12px;
`;

const FadingLink = styled(NavLink)`
  ${typography.body2};
  text-decoration: none;
  margin-right: 18px;
  margin-left: 18px;
  opacity: 0.38;
  transition: opacity 200ms ease-in;
  outline: none;

  &:hover {
    opacity: 1;
  }

  &:focus {
    opacity: 1;
  }
`;
