import apiService, { APIError, HTTPMethod } from "./../redux/services/api";
import axios, { AxiosResponse } from "axios";
import qs from "qs";

function padn2(v: string): string {
  if (v.length < 2) {
    return `0${v}`;
  } else {
    return v;
  }
}

function getPaymentMethod(v: string): string {
  switch (v.replace(/\s/g, "").slice(0, 1)) {
    case "4":
        return "VISA";
    case "5":
        return "MASTERCARD";
    case "3":
        return "AMEX";
  }
  return "";
}

export function getCardType(number: string): string {
  switch (number.replace(/\s/g, "").slice(0, 1)) {
    case "4":
        return "Visa";
    case "5":
        return "Mastercard";
    case "3":
        return "Amex";
  }
  return "Other";
}

export async function getCardToken(
  number: string,
  holder: string,
  ccv: string,
  expMonth: string,
  expYear: string
): Promise<string> {
  // Get an access token.
  const atres = await apiService.performRequest({
    method: HTTPMethod.Post,
    path: "/api/v1/ccards/token",
    data: {
      Merchant: "WebDosh",
      Action: "GenerateAccessToken"
    }
  });

  const wdAccessToken = atres.Payload.Token;
  const wdEndpoint = atres.Payload.Endpoint;

  // Get a temporary token for the card.
  const ttres: AxiosResponse = await axios.request({
    method: "post",
    url: `${wdEndpoint}/post2/temporary_token/create`,
    data: qs.stringify({
      temporary_access: wdAccessToken,
      card_number: number.replace(/\s/g, ""),
      card_holder: holder.trim(),
      card_verification_code: ccv.replace(/\s/g, ""),
      card_expiry_month: padn2(`${expMonth}`.replace(/\s/g, "")),
      card_expiry_year: padn2(`${expYear}`.replace(/\s/g, "")),
      payment_method: getPaymentMethod(number),
    }),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    timeout: 30000,
    responseType: "json"
  });

  if (ttres.status === 422) {
    throw new APIError(
      HTTPMethod.Post,
      `${wdEndpoint}/post2/temporary_token/create`,
      {
        Status: "Request Error",
        StatusCode: 0,
        Errors: [{
          ErrorCode: "bad_network",
          UserContext: "Card Error",
          UserMessage: "Invalid card details."
        }]
      }
    );
  } else if (ttres.status < 200 || ttres.status > 300) {
    let resData;
    try {
      resData = JSON.stringify(ttres.data);
    } catch(e) {
      resData = ttres.data;
    }
    throw new Error(`WebDoshError: ${ttres.statusText}: ${resData}`);
    // if (status >= 500) {
    //   throw new Error(`WebDoshError: ${ttres.statusText}: ${JSON.stringify(ttres.data)}`);
    // } else {
    //   new APIError(
    //     HTTPMethod.Get,
    //     "",
    //     {
    //       Status: "Internal Error",
    //       StatusCode: 0,
    //       Errors: [
    //         {
    //           ErrorCode: "internal_error",
    //           RefID: sentryId,
    //           UserContext: "Internal error",
    //           UserMessage: "There was an internal application error (Code: " + sentryId + "). " +
    //           "Please try again later. If the problem persists, contact support."
    //         }
    //       ]
    //     }
    //   )
    // }
  }

  const wdTempToken = ttres.data.temporary_token;

  // Exchange for a permanent token.
  const ptres = await apiService.performRequest({
    method: HTTPMethod.Post,
    path: "/api/v1/ccards/token",
    data: {
      Merchant: "WebDosh",
      Action: "RedeemTemporaryToken",
      Token: wdTempToken,
    }
  });

  return ptres.Payload.Token;
}
