import { Tooltip } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';


export const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.75)',
      boxShadow: theme.shadows[2],
      fontSize: 13.2,
      maxWidth: 200,
      border: '1px solid #dadde9',
      textAlign: 'center',
    },
  }))(Tooltip);