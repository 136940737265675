import React, { PureComponent } from "react";
import hotkeys from "hotkeys-js";

class Dropdown extends PureComponent {
  dropdownRef = React.createRef();
  containerRef = React.createRef();

  componentDidMount() {
    hotkeys("esc", this.props.onClickOutside);
    document.addEventListener("mousedown", this.mousedown);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.forceUpdate();
    }
  }

  componentWillUnmount = () => {
    hotkeys.unbind("esc");
    document.removeEventListener("mousedown", this.mousedown);
  };

  mousedown = (event) => {
    const { current: container } = this.containerRef;

    if (container !== null && !container.contains(event.target)) {
      this.props.onClickOutside();
    }
  };

  getDropdownPosition = () => {
    const { mobileFullscreen, shouldCenter, shouldTop } = this.props;
    const { current: container } = this.containerRef;
    const { current: dropdown } = this.dropdownRef;
    const { innerWidth, innerHeight } = window;

    if (innerWidth < 600 && mobileFullscreen) {
      return {
        position: "fixed",
        zIndex: 12,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
      };
    }

    const base = {
      position: "absolute",
      zIndex: 12
    };
    if (!container || !dropdown) {
      return base;
    }

    const anchorRect = container.getBoundingClientRect();

    // get the distance between the container and the top
    const distanceToTop = anchorRect.top;
    const distanceToBottom = innerHeight - anchorRect.bottom;

    const topBottom = {
      top: distanceToBottom > distanceToTop || shouldTop ? anchorRect.height : "unset",
      bottom: distanceToTop > distanceToBottom && !shouldTop ? anchorRect.height : "unset"
    };

    if (shouldCenter) {
      return {
        ...base,
        ...topBottom,
        left: "50%",
        transform: "translateX(-50%)"
      };
    }

    // get distance left and right of the container
    const distanceToLeft = anchorRect.left;
    const distanceToRight = innerWidth - anchorRect.right;

    return {
      ...base,
      ...topBottom,
      left: distanceToRight > distanceToLeft ? 0 : "unset",
      right: distanceToLeft > distanceToRight ? 0 : "unset"
    };
  };

  render() {
    const { isOpen, style } = this.props;

    return (
      <div
        style={{ ...style, position: "relative", display: "inline-flex" }}
        ref={this.containerRef}
      >
        {this.props.children}
        {isOpen &&
          this.props.render({
            ref: this.dropdownRef,
            styles: this.getDropdownPosition()
          })}
      </div>
    );
  }
}

export default Dropdown;
