import { observable, computed, toJS } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import LoadingIcon from "./../../../components/icons/Loading";
import {
  Booking,
  BookingClient,
  CancellationChargeClientRequest,
  ProcessBookingRequest,
  BookingStatus
} from "./../../../sdk/bookings_pb";
import {
  allLineItems,
  requiredFundsForBooking,
  clientPaymentStatusToText,
  isGroupBooking
} from "./../../../util/bookings";
import { LoadingIndicator } from "./../../../util/loading";
import {
  BackButton,
  LargeBottomButton,
  LargeButtonText,
  RightBottomButtonContainer
} from "./Entry";
import { ClientOverview } from "./PaymentWizard/ClientOverview";
import { FundType } from "./../../../sdk/offerings_pb";
import { rpc, metadata } from "./../../../grpc";
import { bookingStore } from "./../../../stores/booking-store";
import { AccItemStatus, AccountItem, AccItemType } from "./../../../sdk/account_items_pb";
import { accItemStore } from "./../../../stores/acc_item-store";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

import { ResourceTypes } from "./../../../redux/features/resources/definitions";
import { clientFundStore } from "./../../../stores/client_fund-store";
import * as ReduxDialogs from "./../../../redux/features/dialogs";
import { ToastManager } from "react-toast-notifications";
import Popup from "reactjs-popup";
import { thirdPartyInvStore } from "./../../../stores/third_party_inv-store";
import { toastStore } from "./../../../stores/toast-store";
import { rebookDataStore } from "../../../mobx/stores/RebookDataStore";
import { RebookModal } from "../../../components/modal/RebookModal";
import Raven from 'raven-js';
interface Props {
  booking: Booking.AsObject;
  client: BookingClient.AsObject;
  onRequestClose: () => void;
  dispatch?: any;
  resource?: any;
  toastManager?: ToastManager;
}

interface State {
  popupHeight: String;
  open: boolean;
}

@observer
export class Payment extends Component<Props, State> {
  @observable
  private indicator = new LoadingIndicator();
  private interval: any;
  @observable
  private mounted = false;

  @computed
  get accountItems() {
    const accStoreFilter = accItemStore.all();
    const { booking, client } = this.props;
    if (this.props.client) {
      return accStoreFilter.filter(
        (ai: AccountItem.AsObject) =>
          ai.booking!.id == booking.id && ai.clientId == client!.clientId
      );
    } else {
      return accStoreFilter.filter(
        (ai: AccountItem.AsObject) => ai.booking!.id == this.props.booking.id
      );
    }
  }

  async componentDidMount() {
    const { booking, client, dispatch } = this.props;
    await accItemStore.loadByBookingClient(booking.id, client!.clientId);
    await clientFundStore.load(client!.clientId);

    dispatch(
      ResourceActions.action(
        { $Metadata: { Type: ResourceTypes.LocBooking } as any, ID: booking.id },
        "Fetch"
      )
    );

    this.mounted = true; // Toggle to activate refresh
    // Refresh stores every 5 seconds
    this.loadStores(booking, client!);
  }

  async loadStores(booking: Booking.AsObject, client: BookingClient.AsObject) {
    this.interval = setInterval(() => {
      if (
        this.mounted &&
        this.accountItems
          .filter(
            (ai) => ai.status != AccItemStatus.ACC_ITEM_ERROR && ai.fundType != FundType.THIRD_PARTY_INVOICE
          )
          .filter(
            (ai) => ai.status == AccItemStatus.ACC_ITEM_PROCESSING || ai.status == AccItemStatus.ACC_ITEM_CREATED
          ).length > 0
      ) {
        bookingStore.load(booking.id);
        accItemStore.loadByBookingClient(booking.id, client!.clientId);
        // to update Redux store
        this.props.dispatch(
          ResourceActions.action(
            { $Metadata: { Type: ResourceTypes.LocBooking } as any, ID: booking.id },
            "Fetch"
          )
        );
      }
    }, 5000);
  }

  componentWillUnmount() {
    // stop call execution
    this.mounted = false;
    clearInterval(this.interval);
  }

  checkClientReferrals(): Promise<boolean> {
    if (this.props.client!.activeReferralId === "") {
      return new Promise((resolve, reject) => {
        const { dispatch } = this.props;
        dispatch(
          ReduxDialogs.openAction(
            "To send this claim with a referral you must click on the referral to highlight it. Are you sure you want to send this claim without a referral?",
            null,
            null,
            (check: any, yay: any, notify: any) => resolve(yay),
            "Yes",
            "No"
          )
        );
      });
    } else {
      return Promise.resolve(true);
    }
  }

  checkProcessButtonDisabled(): boolean {
    const { booking, client } = this.props;

    var needRequiredFunds = true;
    // If it's group booking, this will check only the client selected
    if (isGroupBooking(booking)) {
      needRequiredFunds =
        requiredFundsForBooking(booking, client, this.accountItems).missing.length > 0;
    } else {
      // we can process the booking when all clients:
      booking.clientsList.every(async (client) => {
        // have all the required funds entered in the system
        needRequiredFunds =
          requiredFundsForBooking(booking, client, this.accountItems).missing.length > 0;
      });
    }
    // if any of the following is true, then booking can't be processed
    return needRequiredFunds;
  }

  async processBooking(booking: Booking.AsObject, client: BookingClient.AsObject) {
    const { toastManager } = this.props;
    for (const item of allLineItems(booking, client)) {
      switch (item.fundType) {
        case FundType.DVA_ALLIED ||
          FundType.MEDICARE_BULK ||
          FundType.MEDICARE_STANDARD ||
          FundType.HICAPS:
          if (!(await this.checkClientReferrals())) {
            return;
          }
          break;
      }
    }

    const processBookingReq = new ProcessBookingRequest();
    processBookingReq.setBookingId(booking.id);
    processBookingReq.setClientId(client!.clientId);
    await this.indicator.while(async () => {
      const res = await rpc.bookings.processBooking(processBookingReq, metadata());
      await bookingStore.add(res.toObject().booking!);
      await accItemStore.loadByBookingClient(booking.id, client.clientId);
      await thirdPartyInvStore.load(booking.id, client.clientId);
      toastManager!.add(
        `Successfully created account items for booking ${booking.friendlyId}`,
        { appearance: "success" }
      );
      this.loadStores(booking, client!);
    });
  }

  async cancellationCharge(booking: Booking.AsObject, client: BookingClient.AsObject) {
    const { toastManager } = this.props;
    const reqCancellationCharge = new CancellationChargeClientRequest();
    reqCancellationCharge.setBookingId(booking.id);
    reqCancellationCharge.setClientId(client.clientId);
    await this.indicator.while(async () => {
      await rpc.bookingClients.cancellationCharge(reqCancellationCharge, metadata());
      toastManager!.add(
        `Successfully submitted cancellation payment for client ${(client.firstName,
        " ",
        client.lastName)}`,
        { appearance: "success" }
      );
      await accItemStore.loadByBookingClient(booking.id, client.clientId);
      this.loadStores(booking, client!);
    });
  }

  onBackClicked = () => {
    this.props.onRequestClose();
  };

  render() {
    const { booking, client } = this.props;
    
    return (
      <React.Fragment>
        {booking && (
          <>
            <ClientOverview
              booking={booking}
              client={client}
              accountItems={this.accountItems}
              {...this.props}
            />
            {rebookDataStore.isOpen && (
              <Popup
                modal
                open={rebookDataStore.isOpen}
                onClose={() => {
                  rebookDataStore.isOpen = false;
                }}
                contentStyle={{
                  width: "360px",
                  height: "175px",
                  borderRadius: "9px",
                  borderWidth: "0px",
                  margin: "auto auto auto auto"
                }}
              >
                {
                  <RebookModal
                    duration={this.props.booking.duration}
                    startTime={this.props.booking.startTime}
                    bookingId={this.props.booking.id}
                    clientId={this.props.resource.Clients[0].Client.ID}
                    offering={this.props.resource.GroupOfferings.length > 0 ? this.props.resource.GroupOfferings[0].Offering : this.props.resource.Clients[0].Offerings[0].Offering}
                    dispatch={this.props.dispatch}
                    toastManager={this.props.toastManager!}
                    locationId={booking.locationId}
                    provider={booking.provider!}
                  />
                }
              </Popup>
            )}

            {client!.paymentStatus == BookingClient.PaymentStatus.PENDING &&
              this.accountItems.filter(
                (v: AccountItem.AsObject) =>
                  v.status == AccItemStatus.ACC_ITEM_PROCESSING && v.type != AccItemType.ACC_ITEM_THIRD_PARTY
              ).length == 0 && (
                <RightBottomButtonContainer>
                  <LargeBottomButton
                    disabled={this.indicator.isLoading() ? true : this.checkProcessButtonDisabled()}
                    onClick={() => {
                      if (booking.status == BookingStatus.BOOKING_CANCELLED) {
                        this.cancellationCharge(this.props.booking, client!)
                      } else {
                        this.processBooking(this.props.booking, client!)
                          .then((result) => {
                            // show the RebookModal popup when the payment request is successfully submitted
                            if (booking.status != BookingStatus.BOOKING_CANCELLED) {
                              rebookDataStore.isOpen = true;
                            }
                          })
                          .catch((err) => {
                            Raven.captureException(err);
                          });
                      }
                    }
                  }
                  >
                    {this.indicator.isLoading() ? (
                      <LoadingIcon width={16} height={16} color="#FFF" />
                    ) : (
                      <LargeButtonText>Process</LargeButtonText>
                    )}
                  </LargeBottomButton>
                </RightBottomButtonContainer>
              )}
          </>
        )}
        <BackButton onClick={this.onBackClicked}>Back</BackButton>
      </React.Fragment>
    );
  }
}
