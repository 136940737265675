import React, { Component } from "react";

import ResourceRender from "./../components/elements/ResourceRender";
import Avatar from "./../components/Avatar";

export default class UserAvatar extends Component {
  renderContent = React.memo(({ resource, otherProps }) => (
    <Avatar
      letters={`${resource.FirstName[0]}${resource.LastName[0]}`}
      image={!resource.Photo ? null : resource.Photo.DownloadURL}
      {...otherProps}
    />
  ));

  renderLoading = React.memo(({ otherProps }) => <Avatar {...otherProps} />);

  render() {
    const { userID, type, ...otherProps } = this.props;

    return (
      <ResourceRender
        type={type||"User"}
        ids={{ ID: userID }}
        forwardedProps={{ otherProps }}
        denorm={true}
        compSuccess={this.renderContent}
        compLoading={this.renderLoading}
      />
    );
  }
}
