import { createAction } from "typesafe-actions";
import { APIResponse } from "./../../../redux/services/api";
import {
  Resource,
  ResourceList
} from "./types";

interface ResourceAction {
  resource: Resource;
  actionID: string;
  actionType: string;
  actionData: any;
}

interface ResourceActionSucceeded extends ResourceAction {
  response: APIResponse;
}

interface ResourceActionFailed extends ResourceAction {
  error: Error;
}

interface ResourceListAction {
  list: ResourceList;
  clearFirst: boolean;
  actionID: string;
  actionType: string;
}

interface ResourceListActionSucceeded extends ResourceListAction {
  response: APIResponse;
}

interface ResourceListActionFailed extends ResourceListAction {
  error: Error;
}

// Resource Actions
export const actionStart = createAction("Resource/Action/Start", (resolve) => {
  return (resource: Resource, actionID: string, actionType: string, actionData: any) =>
    resolve({ resource, actionID, actionType, actionData } as ResourceAction);
});

export const actionSucceeded = createAction("Resource/Action/Succeeded", (resolve) => {
  return (resource: Resource, actionID: string, actionType: string, actionData: any, response: APIResponse) =>
    resolve({ resource, actionID, actionType, actionData, response } as ResourceActionSucceeded);
});

export const actionFailed = createAction("Resource/Action/Failed", (resolve) => {
  return (resource: Resource, actionID: string, actionType: string, actionData: any, error: Error) =>
    resolve({ resource, actionID, actionType, actionData, error } as ResourceActionFailed);
});

export const set = createAction("Resource/Set", (resolve) => {
  return (resource: Resource) => resolve({ resource } as ResourceAction);
});

// Resource List Actions.
export const listActionStart = createAction("ResourceList/Action/Start", (resolve) => {
  return (list: ResourceList, clearFirst: boolean, actionID: string, actionType: string) =>
    resolve({ list, clearFirst, actionID, actionType } as ResourceListAction);
});

export const listActionSucceeded = createAction("ResourceList/Action/Succeeded", (resolve) => {
  return (list: ResourceList, actionID: string, actionType: string, response: APIResponse) =>
    resolve({ list, actionID, actionType, response } as ResourceListActionSucceeded);
});

export const listActionFailed = createAction("ResourceList/Action/Failed", (resolve) => {
  return (list: ResourceList, actionID: string, actionType: string, error: Error) =>
    resolve({ list, actionID, actionType, error } as ResourceListActionFailed);
});
