/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var account_items_pb = require('./account_items_pb.js')
const proto = {};
proto.proto = require('./funds_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ClientHealthFundsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ClientHealthFundsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetFundsRequest,
 *   !proto.proto.HealthFunds>}
 */
const methodDescriptor_ClientHealthFunds_Get = new grpc.web.MethodDescriptor(
  '/proto.ClientHealthFunds/Get',
  grpc.web.MethodType.UNARY,
  proto.proto.GetFundsRequest,
  proto.proto.HealthFunds,
  /** @param {!proto.proto.GetFundsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.HealthFunds.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.GetFundsRequest,
 *   !proto.proto.HealthFunds>}
 */
const methodInfo_ClientHealthFunds_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.HealthFunds,
  /** @param {!proto.proto.GetFundsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.HealthFunds.deserializeBinary
);


/**
 * @param {!proto.proto.GetFundsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.HealthFunds)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.HealthFunds>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientHealthFundsClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientHealthFunds/Get',
      request,
      metadata || {},
      methodDescriptor_ClientHealthFunds_Get,
      callback);
};


/**
 * @param {!proto.proto.GetFundsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.HealthFunds>}
 *     A native promise that resolves to the response
 */
proto.proto.ClientHealthFundsPromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientHealthFunds/Get',
      request,
      metadata || {},
      methodDescriptor_ClientHealthFunds_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateFundsRequest,
 *   !proto.proto.HealthFunds>}
 */
const methodDescriptor_ClientHealthFunds_Update = new grpc.web.MethodDescriptor(
  '/proto.ClientHealthFunds/Update',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateFundsRequest,
  proto.proto.HealthFunds,
  /** @param {!proto.proto.UpdateFundsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.HealthFunds.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.UpdateFundsRequest,
 *   !proto.proto.HealthFunds>}
 */
const methodInfo_ClientHealthFunds_Update = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.HealthFunds,
  /** @param {!proto.proto.UpdateFundsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.HealthFunds.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateFundsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.HealthFunds)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.HealthFunds>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientHealthFundsClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientHealthFunds/Update',
      request,
      metadata || {},
      methodDescriptor_ClientHealthFunds_Update,
      callback);
};


/**
 * @param {!proto.proto.UpdateFundsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.HealthFunds>}
 *     A native promise that resolves to the response
 */
proto.proto.ClientHealthFundsPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientHealthFunds/Update',
      request,
      metadata || {},
      methodDescriptor_ClientHealthFunds_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CheckClaimRequest,
 *   !proto.proto.AccountItem>}
 */
const methodDescriptor_ClientHealthFunds_CheckClaim = new grpc.web.MethodDescriptor(
  '/proto.ClientHealthFunds/CheckClaim',
  grpc.web.MethodType.UNARY,
  proto.proto.CheckClaimRequest,
  account_items_pb.AccountItem,
  /** @param {!proto.proto.CheckClaimRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  account_items_pb.AccountItem.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CheckClaimRequest,
 *   !proto.proto.AccountItem>}
 */
const methodInfo_ClientHealthFunds_CheckClaim = new grpc.web.AbstractClientBase.MethodInfo(
  account_items_pb.AccountItem,
  /** @param {!proto.proto.CheckClaimRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  account_items_pb.AccountItem.deserializeBinary
);


/**
 * @param {!proto.proto.CheckClaimRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.AccountItem)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AccountItem>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientHealthFundsClient.prototype.checkClaim =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientHealthFunds/CheckClaim',
      request,
      metadata || {},
      methodDescriptor_ClientHealthFunds_CheckClaim,
      callback);
};


/**
 * @param {!proto.proto.CheckClaimRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.AccountItem>}
 *     A native promise that resolves to the response
 */
proto.proto.ClientHealthFundsPromiseClient.prototype.checkClaim =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientHealthFunds/CheckClaim',
      request,
      metadata || {},
      methodDescriptor_ClientHealthFunds_CheckClaim);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RetryClaimRequest,
 *   !proto.proto.AccountItem>}
 */
const methodDescriptor_ClientHealthFunds_RetryClaim = new grpc.web.MethodDescriptor(
  '/proto.ClientHealthFunds/RetryClaim',
  grpc.web.MethodType.UNARY,
  proto.proto.RetryClaimRequest,
  account_items_pb.AccountItem,
  /** @param {!proto.proto.RetryClaimRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  account_items_pb.AccountItem.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.RetryClaimRequest,
 *   !proto.proto.AccountItem>}
 */
const methodInfo_ClientHealthFunds_RetryClaim = new grpc.web.AbstractClientBase.MethodInfo(
  account_items_pb.AccountItem,
  /** @param {!proto.proto.RetryClaimRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  account_items_pb.AccountItem.deserializeBinary
);


/**
 * @param {!proto.proto.RetryClaimRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.AccountItem)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AccountItem>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientHealthFundsClient.prototype.retryClaim =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientHealthFunds/RetryClaim',
      request,
      metadata || {},
      methodDescriptor_ClientHealthFunds_RetryClaim,
      callback);
};


/**
 * @param {!proto.proto.RetryClaimRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.AccountItem>}
 *     A native promise that resolves to the response
 */
proto.proto.ClientHealthFundsPromiseClient.prototype.retryClaim =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientHealthFunds/RetryClaim',
      request,
      metadata || {},
      methodDescriptor_ClientHealthFunds_RetryClaim);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CancelAndChargeClientClaimRequest,
 *   !proto.proto.AccountItem>}
 */
const methodDescriptor_ClientHealthFunds_cancelAndChargeClientClaim = new grpc.web.MethodDescriptor(
  '/proto.ClientHealthFunds/cancelAndChargeClientClaim',
  grpc.web.MethodType.UNARY,
  proto.proto.CancelAndChargeClientClaimRequest,
  account_items_pb.AccountItem,
  /** @param {!proto.proto.CancelAndChargeClientClaimRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  account_items_pb.AccountItem.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CancelAndChargeClientClaimRequest,
 *   !proto.proto.AccountItem>}
 */
const methodInfo_ClientHealthFunds_cancelAndChargeClientClaim = new grpc.web.AbstractClientBase.MethodInfo(
  account_items_pb.AccountItem,
  /** @param {!proto.proto.CancelAndChargeClientClaimRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  account_items_pb.AccountItem.deserializeBinary
);


/**
 * @param {!proto.proto.CancelAndChargeClientClaimRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.AccountItem)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AccountItem>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientHealthFundsClient.prototype.cancelAndChargeClientClaim =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientHealthFunds/cancelAndChargeClientClaim',
      request,
      metadata || {},
      methodDescriptor_ClientHealthFunds_cancelAndChargeClientClaim,
      callback);
};


/**
 * @param {!proto.proto.CancelAndChargeClientClaimRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.AccountItem>}
 *     A native promise that resolves to the response
 */
proto.proto.ClientHealthFundsPromiseClient.prototype.cancelAndChargeClientClaim =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientHealthFunds/cancelAndChargeClientClaim',
      request,
      metadata || {},
      methodDescriptor_ClientHealthFunds_cancelAndChargeClientClaim);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.UserHealthFundsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.UserHealthFundsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateFundsRequest,
 *   !proto.proto.HealthFunds>}
 */
const methodDescriptor_UserHealthFunds_Update = new grpc.web.MethodDescriptor(
  '/proto.UserHealthFunds/Update',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateFundsRequest,
  proto.proto.HealthFunds,
  /** @param {!proto.proto.UpdateFundsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.HealthFunds.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.UpdateFundsRequest,
 *   !proto.proto.HealthFunds>}
 */
const methodInfo_UserHealthFunds_Update = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.HealthFunds,
  /** @param {!proto.proto.UpdateFundsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.HealthFunds.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateFundsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.HealthFunds)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.HealthFunds>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserHealthFundsClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserHealthFunds/Update',
      request,
      metadata || {},
      methodDescriptor_UserHealthFunds_Update,
      callback);
};


/**
 * @param {!proto.proto.UpdateFundsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.HealthFunds>}
 *     A native promise that resolves to the response
 */
proto.proto.UserHealthFundsPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserHealthFunds/Update',
      request,
      metadata || {},
      methodDescriptor_UserHealthFunds_Update);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ClientCreditCardsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ClientCreditCardsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListCreditCardsRequest,
 *   !proto.proto.ListCreditCardsResponse>}
 */
const methodDescriptor_ClientCreditCards_List = new grpc.web.MethodDescriptor(
  '/proto.ClientCreditCards/List',
  grpc.web.MethodType.UNARY,
  proto.proto.ListCreditCardsRequest,
  proto.proto.ListCreditCardsResponse,
  /** @param {!proto.proto.ListCreditCardsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListCreditCardsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ListCreditCardsRequest,
 *   !proto.proto.ListCreditCardsResponse>}
 */
const methodInfo_ClientCreditCards_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ListCreditCardsResponse,
  /** @param {!proto.proto.ListCreditCardsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListCreditCardsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListCreditCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ListCreditCardsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListCreditCardsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientCreditCardsClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientCreditCards/List',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_List,
      callback);
};


/**
 * @param {!proto.proto.ListCreditCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListCreditCardsResponse>}
 *     A native promise that resolves to the response
 */
proto.proto.ClientCreditCardsPromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientCreditCards/List',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateCreditCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodDescriptor_ClientCreditCards_Create = new grpc.web.MethodDescriptor(
  '/proto.ClientCreditCards/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateCreditCardRequest,
  proto.proto.CreditCard,
  /** @param {!proto.proto.CreateCreditCardRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateCreditCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodInfo_ClientCreditCards_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.CreditCard,
  /** @param {!proto.proto.CreateCreditCardRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @param {!proto.proto.CreateCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.CreditCard)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreditCard>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientCreditCardsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientCreditCards/Create',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreditCard>}
 *     A native promise that resolves to the response
 */
proto.proto.ClientCreditCardsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientCreditCards/Create',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteCreditCardRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ClientCreditCards_Delete = new grpc.web.MethodDescriptor(
  '/proto.ClientCreditCards/Delete',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteCreditCardRequest,
  google_protobuf_empty_pb.Empty,
  /** @param {!proto.proto.DeleteCreditCardRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.DeleteCreditCardRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_ClientCreditCards_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /** @param {!proto.proto.DeleteCreditCardRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientCreditCardsClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientCreditCards/Delete',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_Delete,
      callback);
};


/**
 * @param {!proto.proto.DeleteCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.proto.ClientCreditCardsPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientCreditCards/Delete',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.SetPrimaryCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodDescriptor_ClientCreditCards_SetPrimaryCard = new grpc.web.MethodDescriptor(
  '/proto.ClientCreditCards/SetPrimaryCard',
  grpc.web.MethodType.UNARY,
  proto.proto.SetPrimaryCardRequest,
  proto.proto.CreditCard,
  /** @param {!proto.proto.SetPrimaryCardRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.SetPrimaryCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodInfo_ClientCreditCards_SetPrimaryCard = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.CreditCard,
  /** @param {!proto.proto.SetPrimaryCardRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @param {!proto.proto.SetPrimaryCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.CreditCard)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreditCard>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientCreditCardsClient.prototype.setPrimaryCard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientCreditCards/SetPrimaryCard',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_SetPrimaryCard,
      callback);
};


/**
 * @param {!proto.proto.SetPrimaryCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreditCard>}
 *     A native promise that resolves to the response
 */
proto.proto.ClientCreditCardsPromiseClient.prototype.setPrimaryCard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientCreditCards/SetPrimaryCard',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_SetPrimaryCard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateTokenRequest,
 *   !proto.proto.Token>}
 */
const methodDescriptor_ClientCreditCards_CreateToken = new grpc.web.MethodDescriptor(
  '/proto.ClientCreditCards/CreateToken',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateTokenRequest,
  proto.proto.Token,
  /** @param {!proto.proto.CreateTokenRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Token.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateTokenRequest,
 *   !proto.proto.Token>}
 */
const methodInfo_ClientCreditCards_CreateToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.Token,
  /** @param {!proto.proto.CreateTokenRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Token.deserializeBinary
);


/**
 * @param {!proto.proto.CreateTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.Token)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Token>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientCreditCardsClient.prototype.createToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientCreditCards/CreateToken',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_CreateToken,
      callback);
};


/**
 * @param {!proto.proto.CreateTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Token>}
 *     A native promise that resolves to the response
 */
proto.proto.ClientCreditCardsPromiseClient.prototype.createToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientCreditCards/CreateToken',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_CreateToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RedeemTokenRequest,
 *   !proto.proto.Token>}
 */
const methodDescriptor_ClientCreditCards_RedeemToken = new grpc.web.MethodDescriptor(
  '/proto.ClientCreditCards/RedeemToken',
  grpc.web.MethodType.UNARY,
  proto.proto.RedeemTokenRequest,
  proto.proto.Token,
  /** @param {!proto.proto.RedeemTokenRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Token.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.RedeemTokenRequest,
 *   !proto.proto.Token>}
 */
const methodInfo_ClientCreditCards_RedeemToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.Token,
  /** @param {!proto.proto.RedeemTokenRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Token.deserializeBinary
);


/**
 * @param {!proto.proto.RedeemTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.Token)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Token>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientCreditCardsClient.prototype.redeemToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientCreditCards/RedeemToken',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_RedeemToken,
      callback);
};


/**
 * @param {!proto.proto.RedeemTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Token>}
 *     A native promise that resolves to the response
 */
proto.proto.ClientCreditCardsPromiseClient.prototype.redeemToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientCreditCards/RedeemToken',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_RedeemToken);
};


module.exports = proto.proto;

