import React from "react";
import { Cards } from "./../../../../components/cards/Cards";
import Referrals from "../../ClientActionDialog/Referrals";
import Funds from "../../ClientActionDialog/Funds";
import { Booking, BookingClient, UpdateBookingClientRequest, GetLatestReferralRequest, PartialUpdateBookingClientRequest, BookingStatus } from "./../../../../sdk/bookings_pb";
import { FundType } from "./../../../../sdk/offerings_pb";
import {
  allLineItems,
  requiredFundsForBooking,
  FundRequirements,
  clientPaymentStatusToText
} from "./../../../../util/bookings";
import { RightContent } from "../../SplitDialog";
import { TitleContainer, TitleName, TitleService } from "../Entry";
import { CreditCard } from "./../../../../sdk/funds_pb";
import { rpc, metadata } from "./../../../../grpc";
import { stringValue } from "./../../../../util/protowrappers";
import { LoadingIndicator } from "./../../../../util/loading";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import { bookingStore } from "./../../../../stores/booking-store";
import { ThirdPartyInvoices } from "./../../../../components/third-party-invoices/ThirdPartyInvoices";
import { AccItemStatus, AccItemType, AccountItem } from "./../../../../sdk/account_items_pb";
import Failed from "../Failed";
import { ShowInvoices } from "../ShowInvoices";
import { ToastManager } from 'react-toast-notifications';
import { toastStore } from './../../../../stores/toast-store';

interface Props {
  booking: Booking.AsObject;
  client: BookingClient.AsObject;
  resource?: any;
  accountItems: Array<AccountItem.AsObject>;
  dispatch?: any;
  toastManager?: ToastManager;
}

@observer
export class ClientOverview extends React.Component<Props> {
  @observable
  private indicator = new LoadingIndicator();

  @observable
  private activeReferral = "";

  paymentTitle() {
    const { booking, client, accountItems } = this.props;
    const prefix =
      booking.status == BookingStatus.BOOKING_CANCELLED || client.status == BookingClient.Status.CANCELLED
        ? "Cancellation "
        : "";

    switch (client.paymentStatus) {
      default:
      case BookingClient.PaymentStatus.PENDING:
        if (
          accountItems.findIndex(
            (v: AccountItem.AsObject) =>
              v.status == AccItemStatus.ACC_ITEM_PROCESSING && v.type == AccItemType.ACC_ITEM_BOOKING_PAYMENT
          ) >= 0
        ) {
          return prefix + "Payment (Created)";
        } else if (
          accountItems.findIndex(
            (v: AccountItem.AsObject) =>
              (v.status == AccItemStatus.ACC_ITEM_PROCESSING || v.status == AccItemStatus.ACC_ITEM_CREATED) &&
              v.type == AccItemType.ACC_ITEM_FUND
          ) >= 0
        ) {
          return prefix + "Payment (Created)";
        } else {
          return prefix + "Payment";
        }
      case BookingClient.PaymentStatus.PROCESSING:
        if (
          accountItems.findIndex(
            (v: AccountItem.AsObject) =>
              v.status == AccItemStatus.ACC_ITEM_ERROR && v.type === AccItemType.ACC_ITEM_BOOKING_PAYMENT
          ) >= 0
        ) {
          return prefix + "Payment (Charge Failed)";
        } else if (
          accountItems.findIndex((v: AccountItem.AsObject) => v.status == AccItemStatus.ACC_ITEM_ERROR) >= 0
        ) {
          return prefix + "Payment (Fund Failed)";
        } else {
          return prefix + "Payment (Processing)";
        }
      case BookingClient.PaymentStatus.COMPLETE:
        return prefix + "Payment (Completed)";
    }
  }

  paymentSubtitle() {
    const { booking, client, accountItems } = this.props;
    if (booking.status == BookingStatus.BOOKING_CANCELLED) {
      return "Charge client";
    }

    switch (client.paymentStatus) {
      default:
      case BookingClient.PaymentStatus.PENDING:
        if (
          accountItems.findIndex(
            (v: AccountItem.AsObject) =>
              v.status == AccItemStatus.ACC_ITEM_PROCESSING && v.type == AccItemType.ACC_ITEM_BOOKING_PAYMENT
          ) >= 0
        ) {
          return "Payment has not been processed";
        } else if (
          accountItems.findIndex(
            (v: AccountItem.AsObject) =>
              v.status == AccItemStatus.ACC_ITEM_PROCESSING && v.type == AccItemType.ACC_ITEM_FUND
          ) >= 0
        ) {
          return "Fund payment has not been processed";
        } else {
          return "Select a payment method";
        }
      case BookingClient.PaymentStatus.PROCESSING:
        if (
          accountItems.findIndex(
            (v: AccountItem.AsObject) =>
              v.status == AccItemStatus.ACC_ITEM_ERROR && v.type == AccItemType.ACC_ITEM_BOOKING_PAYMENT
          ) >= 0
        ) {
          return "There was an error charging the client's credit card";
        } else if (
          accountItems.findIndex((v: AccountItem.AsObject) => v.status == AccItemStatus.ACC_ITEM_ERROR) >= 0
        ) {
          return "There was an error charging a fund";
        } else {
          return "The payment is currently processing";
        }
      case BookingClient.PaymentStatus.COMPLETE:
        return "The client's payment has been completed";
    }
  }

  shouldShowFunds(requiredFunds: FundRequirements) {
    return (
      requiredFunds.required.includes(FundType.DVA_ALLIED) ||
      requiredFunds.required.includes(FundType.DVA_STANDARD) ||
      requiredFunds.required.includes(FundType.HICAPS) ||
      requiredFunds.required.includes(FundType.MEDICARE_STANDARD) ||
      requiredFunds.required.includes(FundType.MEDICARE_BULK)
    );
  }

  shouldShowPending() {
    const { client, accountItems } = this.props;

    if (accountItems.length > 0) {
      return (
        client.paymentStatus == BookingClient.PaymentStatus.PENDING && 
        accountItems.filter(ai => ai.fundType != FundType.THIRD_PARTY_INVOICE).length == 0
      );
    } else {
      return client.paymentStatus == BookingClient.PaymentStatus.PENDING;
    }
  }

  shouldShowProcessing() {
    const { client, accountItems } = this.props;
    return (
      (client.paymentStatus == BookingClient.PaymentStatus.PROCESSING || 
        client.paymentStatus == BookingClient.PaymentStatus.PENDING) && (
        accountItems.filter(
          (v: AccountItem.AsObject) =>
            (v.status == AccItemStatus.ACC_ITEM_PROCESSING || v.status == AccItemStatus.ACC_ITEM_CREATED || v.status == AccItemStatus.ACC_ITEM_COMPLETED) &&
            v.type != AccItemType.ACC_ITEM_THIRD_PARTY
        ).length > 0)
    );
  }

  shouldShowFailed() {
    const { accountItems } = this.props;
    return accountItems.filter((v: AccountItem.AsObject) => v.status === AccItemStatus.ACC_ITEM_ERROR).length > 0;
  }

  shouldShowCompleted() {
    const { client, accountItems } = this.props;

    return (
      client.paymentStatus == BookingClient.PaymentStatus.COMPLETE &&
      accountItems.filter(
        (v: AccountItem.AsObject) =>
          v.status == AccItemStatus.ACC_ITEM_COMPLETED && v.type != AccItemType.ACC_ITEM_THIRD_PARTY
      ).length > 0
    );
  }

  shouldShowThirdParty() {
    return allLineItems(this.props.booking, this.props.client).some(
      (item) => item.fundType === FundType.THIRD_PARTY_INVOICE
    );
  }

  componentDidMount(){
    const { booking, client } = this.props;
    const needReferrals = allLineItems(booking, client).some(ln => (
      ln.fundType === FundType.DVA_ALLIED || 
      ln.fundType === FundType.DVA_STANDARD || 
      ln.fundType === FundType.HICAPS || 
      ln.fundType === FundType.MEDICARE_BULK || 
      ln.fundType === FundType.MEDICARE_STANDARD)
      );
    if (client.paymentStatus !== BookingClient.PaymentStatus.COMPLETE && needReferrals){
      this.checkAndSelectReferral();
    } 
  }

  async checkAndSelectReferral() {
    const {client} = this.props;
    if (client.activeReferralId===""){
      try {
      const req = new GetLatestReferralRequest();
      req.setBookingId(client.bookingId);
      req.setClientId(client.clientId);
      const res = await rpc.bookingClients.getLatestReferral(req, metadata());
      if (res.toObject().latestReferralId && res.toObject().latestReferralId !== ""){
        this.onSelectReferral(res.toObject().latestReferralId);
      }
      } catch(err){
        toastStore.grpcToastError(err);
      }
    } else {
      this.activeReferral = client.activeReferralId;
    }
  }

  async setActiveCard(card: CreditCard.AsObject) {
    const {toastManager} = this.props;
    await this.indicator.while(async () => {
      try{
        const req = new PartialUpdateBookingClientRequest();
        req.setBookingId(this.props.client.bookingId);
        req.setClientId(this.props.client.clientId);
        req.setActiveCardId(stringValue(card.id));
        await rpc.bookingClients.partialUpdate(req, metadata());
        this.props.client.activeCardId = card.id;
        await bookingStore.load(this.props.booking.id);
        toastManager!.add("credit card selected", { appearance: "success" });
      } catch(err){
        toastStore.grpcToastError(err);
      }
    });
  }

  async onSelectReferral(referralId: string) {
    const { booking, client } = this.props;

    if (this.activeReferral != referralId) {
      this.activeReferral = referralId;
    } else {
      this.activeReferral = "";
    }
    await this.indicator.while(async () => {
      try{
        const req = new UpdateBookingClientRequest();
        req.setBookingId(client.bookingId);
        req.setClientId(client.clientId);
        if (this.activeReferral!= ""){
          req.setActiveReferralId(stringValue(this.activeReferral));
        }
        const newBkClient = await rpc.bookingClients.update(req, metadata());
        bookingStore.get(booking.id)!.clientsList[0] = newBkClient.toObject();
        if (newBkClient.getActiveReferralId() === ""){
          toastStore.success("referral unselected");
        } else {
          toastStore.success("referral selected");
        }
      } catch(err){
        toastStore.grpcToastError(err);
      }
    });
  }

  render() {
    const { resource, booking, client, accountItems } = this.props;
    const requiredFunds = requiredFundsForBooking(booking, client, accountItems);
    return (
      <RightContent>
        <TitleContainer style={{ flexDirection: "column" }}>
          <TitleName style={{ fontSize: 23.69 }}>{this.paymentTitle()}</TitleName>
          <TitleService style={{ fontSize: 13.82, marginTop: 6 }}>
            {this.paymentSubtitle()}
          </TitleService>
        </TitleContainer>
        
        {this.shouldShowFailed() && (
          <>
            <Failed
              {...this.props}
              invoices={accountItems.filter((inv) => inv.type != AccItemType.ACC_ITEM_THIRD_PARTY)}
            />
            {requiredFunds.required.includes(FundType.CREDIT_CARD) && (
              <Cards
                required
                clientId={client.clientId}
                selected={client.activeCardId}
                onCardClick={(card) => this.setActiveCard(card)}
                onCardCreated={() => { bookingStore.load(booking.id) }}
                {...this.props}
              />
            )}
            {this.shouldShowFunds(requiredFunds) && (
              <>
                <Funds clientId={client.clientId} />
                <Referrals
                  client={resource.Clients[0].Client}
                  bkClient={client}
                  onSelect={(provId: string) => this.onSelectReferral(provId)}
                  selRef={this.activeReferral}
                />
              </>
            )}
          </>
        )}
        
        {this.shouldShowPending() && requiredFunds.required.includes(FundType.CREDIT_CARD) && (
          <Cards
            required
            clientId={client.clientId}
            selected={client.activeCardId}
            onCardClick={(card) => this.setActiveCard(card)}
            onCardCreated={() => { bookingStore.load(booking.id) }}
            {...this.props}
          />
        )}

        {this.shouldShowFunds(requiredFunds) && this.shouldShowPending() && (
          <>
            <Funds clientId={client.clientId} />
            <Referrals
              client={resource.Clients[0].Client}
              bkClient={client}
              onSelect={(provId: string) => this.onSelectReferral(provId)}
              selRef={this.activeReferral}
            />
          </>
        )}

        {(this.shouldShowProcessing() || this.shouldShowCompleted()) && (
          <ShowInvoices
            invoices={accountItems.filter((inv) => inv.type != AccItemType.ACC_ITEM_THIRD_PARTY)}
          />
        )}
        {this.shouldShowThirdParty() && (
            <ThirdPartyInvoices
              booking={booking}
              client={client}
              required={this.shouldShowPending()}
            />
          )}

      </RightContent>
    );
  }
}
