import styled from "@emotion/styled";

import { colors } from "./../../util/consts";

const Input = styled.input<any>`
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 22px;
  text-align: left;
  height: 44px;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid ${colors.primary[100]};
  border-radius: 4px;
  outline: none;
  color: ${colors.surfaceText.highEmphasis};
  background: #fff;
  -webkit-appearance: none;

  &:disabled {
    ${({ regularText })=>(!regularText ?
      "opacity: 0.6;" : `
      background-color: white;
      border: none;
      padding: 0;
      height: 25px;
    `)}
  }

  &:focus {
    border-color: ${colors.primary.main};
  }

  &::placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::placeholder-shown {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-webkit-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-ms-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  transition: border-color 0.2s ease;
`;

export default Input;
