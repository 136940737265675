import * as React from 'react';
import { Cards } from "../../cards/Cards";
import Funds from "./Funds";
import Referrals from "./Referrals";
import ThirdPartyTemplate from "../../third-party-invoices/ThirdPartyTemplate";

interface Props {
  client: any;
}
export default class PaymentsTab extends React.Component <Props> {

 render() {
     if(this.props.client) {
    return (
    <React.Fragment>
      <Cards clientId={this.props.client.ID} />
      <Funds clientId={this.props.client.ID} />
      <Referrals client={this.props.client} />
      <ThirdPartyTemplate clientId={this.props.client.ID} />
    </React.Fragment>
    );
     }
     else{
         return(
         <React.Fragment></React.Fragment>
         )
     }
  }

}

