/** @jsx jsx */
import { Component } from "react";
import { css, jsx } from "@emotion/core";
import { withFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

// Utils
import { parseFormError } from "./../../../util/form";


// Redux
import apiService from "./../../../redux/services/api";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import * as GlobalActions from "./../../../redux/features/global/actions";

// Custom components
import Button from "./../../../components/Button";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import Header from "./../../../components/form/Header";
import Form from "./../../../components/form/Form";
import FooterContainer from "./../../../components/form/FooterContainer";
import { paymentModalStore } from "./../../../mobx/stores/PaymentModalStore";
import { getAuthenticatedUser } from "./../../../components/elements/AuthGetUser";


//grpc
import { rpc, metadata } from "../../../grpc";
import { GetUserRequest } from '../../../sdk/user_pb';
import { IsURLValidRequest, IsURLValidResponse } from '../../../sdk/user_invite_pb';

let history;

class CreditCardRedirect extends Component {
  constructor(Props) {
    super(Props);
  }

  componentDidMount() {
    this.history = this.props.historys
  }


  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Header
          title="Welcome to Lyfe!"
          subtitle={"In order to start using our services you may need to set up a credit card"}
        />
        <SectionsContainer>   
          <Button
            css={css`
              margin-right: 5px;
              width: 200px;
            `}
            variant="contained"
            color="secondary"
            type="submit"
            onClick={()=>{this.history.push('/users/edit?page=ccards')}}
            disabled={isSubmitting}
          >
            Set Up Create Credit
          </Button> 
          <Button
            css={css`
              margin-left: 5px;
              width: 150px;
            `}
            variant="contained"
            color="secondary"
            type="submit"
            onClick={()=>{this.history.push('/users/appointments')}}
            disabled={isSubmitting}
          >
            Later
          </Button> 
        </SectionsContainer>
        <FooterContainer>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: () => ({
  }),
  validationSchema: Yup.object().shape({
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    setSubmitting(false);
  },
  displayName: "LoginForm"
});

export default compose(
  connect((state)=>({ authUser: getAuthenticatedUser(state) })),
  withToastManager,
  // formikEnhancer
)(CreditCardRedirect);
