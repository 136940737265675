import { Duration } from 'google-protobuf/google/protobuf/duration_pb';

export function durationToText(duration: Duration.AsObject | undefined): string {
  if (!duration) {
    return '0min';
  }
  const seconds = duration.seconds;
  const minutes = (seconds / 60).toFixed(0);
  return `${minutes}min`;
}
