import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

import AuthLogout from "./../../components/elements/AuthLogout";

class UserLogout extends Component {
  render() {
    return (
      <Container>
        <AuthLogout />
        <h1>Logging Out</h1>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({});

export default connect(mapStateToProps)(UserLogout);

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;
