import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";

import * as ReduxDialogs from "./../../redux/features/dialogs";

import { colors } from "./../../util/consts";
import typography from "./../../util/typography";

import Dialog from "./Dialog";
import Button from "./../../components/Button";

class WelcomeDialog extends React.Component {
  handleOnClick = () => {
    const { dispatch, dialogId, data } = this.props;
    if (data && data.onClick) {
      data.onClick();
    }
    dispatch(ReduxDialogs.actions.close(dialogId));
  };

  render() {
    const { data } = this.props;
    return (
      <Dialog dialogId={this.props.dialogId} trigger={data.trigger}>
        <Container>
          <Content>
            <Title style={{ marginBottom: 20 }}>{data.title}</Title>
            <SubTitle style={{ marginBottom: 30 }}>{data.subtitle}</SubTitle>
            {data.items.map((i, index) => (
              <Body key={index}>{i}</Body>
            ))}
          </Content>
          <Button variant="contained" color="secondary" onClick={this.handleOnClick}>
            <ButtonSpacing>{data.buttonText}</ButtonSpacing>
          </Button>
        </Container>
      </Dialog>
    );
  }
}

export default connect()(WelcomeDialog);

const Container = styled.div`
  background-color: #fff;
  width: 100vw;
  max-width: 600px;
  height: 450px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  max-width: 320px;
  margin-bottom: 40px;
`;

const Title = styled.div`
  color: #2c2e3c;
  font-size: 39.69px;
  font-weight: 600;
  line-height: 54px;
  text-align: center;
`;

const SubTitle = styled.div`
  ${typography.subtitle1};
  color: ${colors.surfaceText.medEmphasis};
  text-align: center;
`;

const Body = styled.div`
  ${typography.subtitle2};
  text-align: center;
`;

const ButtonSpacing = styled.span`
  margin: 0 12px;
`;
