import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import Downshift from "downshift";
import debounce from "lodash.throttle";

import typography from "./../../util/typography";
import { colors, hexToRgba, breakpoints } from "./../../util/consts";

import * as ReduxDialogs from "./../../redux/features/dialogs";

import Input from "./../../components/form/Input";
import ResourceListRender from "./../../components/elements/ResourceListRender";
import ResourceListFetch from "./../../components/elements/ResourceListFetch";
import ResourceRender from "./../../components/elements/ResourceRender";

import IconButton from "./../../components/IconButton";
import ClearIcon from "./../../components/icons/Clear";
import AddIcon from "./../../components/icons/Add";
import EditIcon from "./../../components/icons/Edit";

import UserAvatar from "./../../components/UserAvatar";
import { toPriceString } from './../../util';

// We assume the items do not change.
export default class ResourceSelector extends Component<any, any> {
  inputRef = React.createRef<any>();
  lastItems: any;

  constructor(props: any) {
    super(props);
    this.lastItems = null;
    this.state = {
      curListSearch: ""
    };
  }

  handleEdit = async (item: any) => {
    const { onChange, onSelect, name } = this.props;
    const { onEdit } = this.props;
    const newItem = await onEdit(this.inputRef.current||null, item);
    if (newItem) {
      onChange({ target: { name, value: newItem } });
      onSelect&&onSelect(newItem);
      this.inputRef.current && this.inputRef.current.blur();
    }
  }
  handleAdd = async () => {
    const { onChange, onSelect, name } = this.props;
    const { onAdd } = this.props;
    const newItem = await onAdd(this.inputRef.current||null);
    if (newItem) {
      onChange({ target: { name, value: newItem } });
      onSelect&&onSelect(newItem);
      this.inputRef.current && this.inputRef.current.blur();
    }
  }
  clearSelection = () => {
    const { onChange, name } = this.props;
    onChange({ target: { name, value: "" } });
    setTimeout(()=>this.inputRef.current && this.inputRef.current.focus(), 17);
  };
  handleDownshiftSelect = (selection: any, downshift: any) => {
    const { onChange, onSelect, name } = this.props;
    if (selection !== null) {
      onChange({ target: { name, value: selection } });
      onSelect&&onSelect(selection);
      downshift.clearSelection();
    }
  };
  handleInputChange = debounce((value: any) => {
    this.setState({ curListSearch: value });
  }, 250);
  render() {
    const { curListSearch } = this.state;
    const {
      id,
      name,
      placeholder,
      value,
      disabled,
      listType,
      resourceType,
      listctx,
      component,
      onAdd
    } = this.props;
    return (
      <Downshift
        onSelect={this.handleDownshiftSelect}
        itemToString={(v)=>""}
      >
        {({
          getInputProps,
          getItemProps,
          getRootProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          openMenu
        }) => {
          const listCtx = { ...listctx, Search: curListSearch||undefined };
          const { onChange, ...otherInputProps } = getInputProps();
          return (
            <Container isOpen={isOpen} disabled={disabled} {...getRootProps()}>
              <ResourceListFetch type={listType} listctx={listCtx} force />
              {!value ? (
                <InputContainer>
                  <Input
                    ref={this.inputRef}
                    type="text"
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    {...otherInputProps}
                    onChange={(e: any)=>{
                      this.handleInputChange(e.target.value);
                      onChange(e);
                    }}
                    onFocus={() => openMenu()}
                  />
                  {onAdd &&
                    <InputIconButton onClick={this.handleAdd}>
                      <AddIcon />
                    </InputIconButton>}
                </InputContainer>
              ) : (
                <SelectedInputContainer disabled={disabled}>
                  {React.createElement(component, { resource: value, disabled, selected: true })}
                  {!disabled &&
                    <InputIconButton
                      onClick={this.clearSelection}
                    >
                      <ClearIcon />
                    </InputIconButton>}
                </SelectedInputContainer>
              )}
              {isOpen && (
                <DropContainer {...getMenuProps()}>
                  <ResourceListRender
                    type={listType}
                    listctx={listCtx}
                    compSuccess={({ list: ll }: any) =>
                      (ll.IDs || []).slice(0, 5).map((nid: any, i: any) => (
                        <ResourceRender
                          key={nid}
                          type={resourceType}
                          ids={nid}
                          denorm={true}
                          compSuccess={
                            (rprops: any)=>
                              <DropItem
                                key={nid}
                                {...getItemProps({ i, item: rprops.resource } as any)}
                                highlighted={highlightedIndex === i}
                              >
                                {React.createElement(component, { ...rprops, handleEdit: this.handleEdit })}
                              </DropItem>
                          }
                        />
                      ))
                    }
                  />
                </DropContainer>
              )}
            </Container>
          );
        }}
      </Downshift>
    );
  }
}

const serviceSelector = React.memo(({ resource: off, disabled, selected, handleEdit }: any) =>
  <DropItemText>
    {off.Name} - ${toPriceString(off.Fee, true)}{off.ServiceDuration && ` (${off.ServiceDuration}min)`}
    {!disabled && !selected &&
      <InputIconButton onClick={()=>handleEdit(off)}>
        <EditIcon />
      </InputIconButton>}
  </DropItemText>
);

export const LocationOfferingSelector = connect()(React.memo(
  ({ location, showHidden, onlyServices, dispatch, ...otherProps }: any) => (
    <ResourceSelector
      {...otherProps}
      listType="LocationOfferingsSelector"
      resourceType="Offering"
      listctx={{ Location: location.ID, Name: otherProps.name, Filter: onlyServices ? "Services" : undefined }}
      component={serviceSelector}
      onAdd={(t: any)=>new Promise((resolve, reject)=>{
        dispatch(ReduxDialogs.offeringDialog(null, location, resolve, "Service"));
      })}
      onEdit={(t: any, item: any)=>new Promise((resolve, reject)=>{
        dispatch(ReduxDialogs.offeringDialog(item.ID, location, resolve, "Service", true));
      })}
    />
  )
));

const providerSelector = React.memo(({ resource: prov }: any) =>
  <React.Fragment>
    <DropItemIcon><UserAvatar userID={prov.User.ID} size={20} /></DropItemIcon>
    <DropItemText>{prov.User.FirstName} {prov.User.LastName}</DropItemText>
  </React.Fragment>
);

export const LocationProviderSelector = React.memo(
  ({ location, showHidden, onlyServices, ...otherProps }: any) => (
    <ResourceSelector
      {...otherProps}
      listType="LocationProvidersSelector"
      resourceType="OrganisationUser"
      listctx={{ Location: location.ID, Name: otherProps.name, FilterRole: "Provider" }}
      component={providerSelector}
    />
  )
);

const clientSelector = React.memo(({ resource: client }: any) =>
  <React.Fragment>
    <DropItemIcon><UserAvatar type="Client" userID={client.ID} size={20} /></DropItemIcon>
    <DropItemText>{client.FirstName} {client.LastName} | {client.DateOfBirth} | {client.Email||client.MobileNumber}</DropItemText>
  </React.Fragment>
);

//this one was replaced in favour of SpecificLocationClientSelector -- deletable
// export const LocationClientSelector = connect()(React.memo(
//   ({ location, showHidden, onlyServices, dispatch, ...otherProps }: any) => (
//     <ResourceSelector
//       {...otherProps}
//       listType="LocationClientsSelector"
//       resourceType="Client"
//       listctx={{ Location: location.ID, Name: otherProps.name }}
//       component={clientSelector}
//       onAdd={(t: any)=>new Promise((resolve, reject)=>{
//         dispatch(ReduxDialogs.addClientDialog(location.Org, location, resolve));
//       })}
//     />
//   )
// ));

export const SpecificLocationClientSelector = connect()(React.memo(
  ({ org, location, showHidden, onlyServices, dispatch, ...otherProps }: any) => (
    <ResourceSelector
      {...otherProps}
      listType="OrganisationClientsPage"
      resourceType="Client"
      // listctx={{ Location: location.ID, Name: otherProps.name }}
      listctx={{ Org: org.ID, Name: otherProps.name, LocationID: location.ID }}
      component={clientSelector}
      onAdd={(t: any)=>new Promise((resolve, reject)=>{
        dispatch(ReduxDialogs.addClientDialog(location.Org, location, resolve));
      })}
    />
  )
));

const Container = styled.div<any>`
  position: relative;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 450px;
  border: 1px solid ${colors.primary[100]};
  border-radius: 4px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  height: 44px;
  display: flex;
  align-items: center;
  &:focus-within {
    box-shadow: 0 2px 4px 0 rgba(44, 46, 60, 0.15);
  }
  ${breakpoints["laptop-up"]} {
    flex-grow: 0;
  }
  ${({ isOpen }) => (!isOpen ? "" : "border-radius: 4px 4px 0px 0px")};
  ${({ disabled }) => (!disabled ? "" : "border: none; padding: 0")};
`;

const SelectedInputContainer = styled.div<any>`
  position: relative;
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 22px;
  text-align: left;
  height: 44px;
  width: 100%;
  padding: 10px 16px;
  padding-right: 60px;
  user-select: none;
  display: flex;
  align-items: center;
  ${({ disabled }) => (!disabled ? "" : "border: none; padding: 0")};
`;

const DropContainer = styled.ul`
  position: absolute;
  top: calc(100% + 1px);
  left: -1px;
  max-height: 260px;
  overflow-y: scroll;
  width: calc(100% + 2px);
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border-left: 1px solid ${colors.primary[100]};
  border-right: 1px solid ${colors.primary[100]};
  border-bottom: 1px solid ${colors.primary[100]};
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0 2px 4px 0 rgba(44, 46, 60, 0.15);
  z-index: 5;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 44px;
`;

const InputIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  padding: 10px;
  opacity: 0.5;
`;

const DropItem = styled.li<any>`
  position: relative;
  height: 40px;
  padding: 14px;
  align-items: center;
  background-color: ${({ highlighted }) =>
    (!highlighted ? "none" : hexToRgba(colors.primary.main, 0.05))};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const DropItemIcon = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const DropItemText = styled.div`
  ${typography.overline};
  letter-spacing: inherit;
`;
