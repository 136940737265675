import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import styled from "@emotion/styled";

// Components
import Dialogs from "./../../components/dialogs";
import AuthRoute from "./../../components/elements/AuthRoute";
import AuthGetUser from "./../../components/elements/AuthGetUser";

import Header from "./../../components/elements/Header";
import UserFooter from "./../../components/UserFooter";
import ResourceListFetch from "./../../components/elements/ResourceListFetch";

// Standard Pages
import Landing from "./Landing";
import ListYourBusiness from "./ListYourBusiness";
import Physitrack from "./Physitrack";
import Provider from "./Provider";
import IframeLocs from "./IframeLocs";
import IframeOrgs from "./IframeOrgs";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import FAQs from "./FAQs";
import Providers from "./Providers";
import UserAppointments from "./UserAppointments";
import UserEdit from "./UserEdit";
import UserLogout from "./UserLogout";
import SetLocInUserContext from "./SetLocInUserContext";
import OrgCreate from "./OrgCreate";
import NotFound from "./NotFound";
import Tutorials from "./Tutorials";
import SignUp from "./Signup";
import Hubspot from './Hubspot'
import EmailSignUp from './EmailSignUp'

const AdminRoutes = React.lazy(() => import("./AdminRoutes"));

class Base extends Component {
  render() {
    return (
      <Container>
        <AuthGetUser />
        <ResourceListFetch type="Categories" listctx={{}} noLoginReq={true} />
        <Switch>
          <Route path="/iframe" render={() => null} />
          <Route component={Header} />
        </Switch>
        <Route path="/admin/locs/:lid" component={SetLocInUserContext} />
        <Suspense fallback={<div>loading...</div>}>
          <Switch>
            {/* Landing */}
            <Route exact path="/" component={Landing} />
            <Route exact path="/listyourbusiness" component={ListYourBusiness} />
            <Route exact path="/physitrack" component={Physitrack} />
            <Route exact path="/hubspot" component={Hubspot} />
            <Route exact path="/signup/:token" component={EmailSignUp} />
            <Route exact path="/terms-of-use" component={TermsOfUse} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/faqs" component={FAQs} />
            <Route exact path="/tutorials" component={Tutorials} />
            <Route exact path="/providers" component={Providers} />
            <Route exact path="/providers/:pid" component={Provider} />
            <Route exact path="/bookings/orgs/:oid" component={IframeOrgs} />
            <Route exact path="/bookings/locs/:lid" component={IframeLocs} />
            <Route exact path="/iframe/orgs/:oid" component={IframeOrgs} />
            <Route exact path="/iframe/locs/:lid" component={IframeLocs} />
            {/* Users */}
            <AuthRoute exact path="/users/edit" component={UserEdit} reqUser />
            <AuthRoute exact path="/users/appointments" component={UserAppointments} />
            <AuthRoute exact path="/logout" component={UserLogout} redirect="/" />
            {/* Organisations */}
            <Route exact path="/createorg" component={OrgCreate} />
            {/* Admin Routes */}
            <Route path="/admin" component={AdminRoutes} reqUser />
            {/* Not Found */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
        <Switch>
          <Route path={["/iframe", "/bookings", "/admin"]} render={() => null} />
          <Route component={UserFooter} />
        </Switch>
        <Dialogs />
      </Container>
    );
  }
}

export default connect()(Base);

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`;
