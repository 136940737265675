const FAQProvider = [
    {
    "question": "What is Lyfe?",
    "answer": "<p>For a health & wellness business, Lyfe is like having several different types of business automation software bundled into one convenient and affordable package.</p><p>We provide the following:</p><ul><li>Booking / scheduling software. (Plus: group bookings) </li><li>Practice management software.</li><li>Cloud-payments. (No EFTPOS terminal required)</li><li>Client facing auto-messaging via SMS and email.</li><li>Comprehensive business performace and analytics.</li><li>White-label bookings.</li><li>Instant Medicare, DVA and HICAPS Private Health claiming.</li><li>Patient / client notes and templates.</li></ul><p>Everything you need to streamline and automate your business. Saving you dozens of hours each week.</p>"
  }, {
    "question": "Who is Lyfe for?",
    "answer": "Lyfe is perfect for healthcare & wellness providers that wish to benefit from a booking & CRM platform."
  }, {
    "question": "Do I need to be a healthcare provider to use Lyfe?",
    "answer": "<p>No, we also welcome fitness and wellness providers and their clients to use the platform.</p>"
  }, {
    "question": "How are the payments processed?",
    "answer": "<p>Process payments online using your PC or mobile device. No need for a physical payment terminal or the fees that come with it.</p>"
  }, {
    "question": "Do you support 3rd party invoicing?",
    "answer": "<p>Yes. The invoice will be emailed automatically to the recipient (insurance company etc) along with payment terms and bank account info for EFT.</p>"
  }, {
    "question": "Can I use private or public healthcare rebates?",
    "answer": "<p>Both. Your service provider is able to process services payments using credit card, Medicare, HICAPS Private Health, Bulk Billing, DVA.</p>"
  }, {
    "question": "Are there additional fees for using health funds?",
    "answer": "<p>A business is charged a 0.5% surcharge on-top of its transactional rate set in their subscription package. This is to pay for the health fund’s gateway/merchant fees.</p>"
  }, {
    "question": "How does my business use Bulk Billing and DVA?",
    "answer": "<p>Bulk Billing and DVA Online Provider Agreement: To apply for a Bulk Billing and/or a Department of Veteran Affairs (DVA) ID which you will then save into your Lyfe provider profile please fill out this Online Provider Agreement form. Banking Details: So that Medicare knows where to pay you for Bulk Billing and DVA claims you will need to complete an Online Claiming Banking Details form to receive funds from Medicare.</p>"
  }, {
    "question": "Business: How does my business use HICAPS private health claiming?",
    "answer": "<p>Lyfe uses Medipass as its official HICAPS private health claiming channel. If the provider is not an existing HICAPS customer, they will need to complete this form and submit to HICAPS (note this is the same form for terminal and HICAPS Go/Medipass applications, but providers using Lyfe can sign up to HICAPS Go/Medipass only, they do not need a terminal as Lyfe acts as the terminal) the provider is an existing HICAPS customer with a terminal, they can sign up online here for HICAPS Go/Medipass - https://au.digital.computershare.com/HICAPS/Produce/wizard/4b5502b8-40e6-4a8c-b90f-a4d8ab0b236b Please follow this link for instructions on using HICAPS claiming (http://help.medipass.com.au/medipass-for-providers/transactions/private-health-insurance-transactions-without-an-app-beta)</p>"
  }, {
    "question": "Are there SMS appointment reminder fees?",
    "answer": "<p>No, Lyfe absorbs the SMS fees.</p>"
  }, {
    "question": "Can I manually book an appointment for one of my clients?",
    "answer": "<p>Yes, simply click the plus sign at the top right corner of the calendar to add an appointment or click on an available time slot in the calendar view.</p>"
  }, {
    "question": "How do I integrate the bookings to my website?",
    "answer": "<p>There is a bit of code you need to copy and paste into your website to embed Lyfe booking functionality. This code can be found in your “business profile” (icon in top right corner of your screen) then click the \"Iframe\" option and copy the code from there. For WordPress its as simple as pasting the code into your websites design layer.</p>"
  }, {
    "question": "How does the app allow me to re-engage past clients?",
    "answer": "<p>In the \"Insights\" page select the clients tab. There you will be able to view and manage past clients based on their activity. Then you can download a CSV file with all your client data and upload the list into Mailchimp or a similar marketing service to reengage past clients."
  }, {
    "question": "How do I book an appointment for enterprise pricing?",
    "answer": "<p>To schedule a call with a member of our team, go to the following page and click on \"Schedule call\" in the enterprise section of our pricing grid (in blue). <a href=\"https://www.booklyfe.com/listyourbusiness\">https://www.booklyfe.com/listyourbusiness</a></p>"
  }, {
    "question": "Where are you based out of?",
    "answer": "<p>We are based out of Melbourne, Australia.</p>"
  }, {
    "question": "How does the pricing work?",
    "answer": "<p>The pricing has two components:</p>   <ul><li>A monthly subscription fee</li><li>A transactional fee between 1.5% - 2% </li></ul><p>You can choose a larger package to minimise the transaction fee if you have high volume of appointments. We also offer for a limited time a $0 subscription fee package with a 2.4% transactional fee.</p>"
  }, {
    "question": "What are the transaction fees?",
    "answer": "<p>The transaction fee is a small fee based on the service or product you sell using Lyfe payments system. The fee will be deducted from the fee  payed by your clients for your services / products. Therefore if you choose to offset the Lyfe fee you can simply increase your service / product fee by 1.5% - 2%.</p>"
  }, {
    "question": "What kind of Business Performance Insights & Reports are available in the app?",
    "answer": "<ul><li>Available appointments</li><li>Appointments booked</li><li>Appointments cancelled</li><li>Appointments cancelled and rebooked</li><li>Unpaid Invoices</li><li>New clients</li><li>Retention rate </li><li>Rebookings</li><li>Revenue graph and much, much more.</li></ul><p>Lyfe has all the capability of a dedicated business reporting platform at no additional cost to you.</p>"
  }, {
    "question": "Where can I find the business performance insights and reports?",
    "answer": "<p>You can find these in the main page of your app. If you are on a different page, you can click on the Lyfe Logo in the top left corner of the page to get back to the main page.</p>"
  }, {
    "question": "How do I set up my account?",
    "answer": "<p>Setting up your account:</p><ul><li>Step 1: Choose the subscription package that is right for your business. Don’t worry you can change it at any time.</li><li>Step 2: Create a list of services from the services page.</li><li>Step 3: Create or invite your service providers (Staff).</li><li>Step 4: Create your client list. NOTE: You can create your clients on the fly by clicking the + symbol in the client field while making a booking from the appointments page.</li></ul><p>If you need additional assistance please view our tutorial videos which are in the footer of our homepage. You can also contact Lyfe Support via 24\/7 chat or email us at info@booklyfe.com"
  }, {
    "question": "Where can I see the calendar with all my appointments?",
    "answer": "<p>You can find the calendar view under the \"Appointments\" tab.</p>"
  }, {
    "question": "How can I add a staff member?",
    "answer": "<p>Click on the \"Staff\" tab and the plus sign located in the top right corner to add a new staff member. You will then be given options as to the role of this staff member and the locations where they work.</p>"
  }, {
    "question": "How can I remove a staff member?",
    "answer": "<p>A staff member cannot be removed but they can be rendered \"inactive\". In the staff tab click on the staff members profile then the location, then select inactive.</p>"
  }, {
    "question": "Where can I see my entire client list?",
    "answer": "<p>You can see your entire list of clients by clicking on the \"Clients\" tab.</p>"
  }, {
    "question": "How can I edit a client?",
    "answer": "<p>You can see your entire list of clients by clicking on the \"Clients\" tab. Then find the client and click on the three dots following their name to edit the client.</p>"
  }, {
    "question": "Where can I see billing and invoicing information?",
    "answer": "<p>Simply click on the \"Accounts\" tab to see all invoices and financial information. Here is a short video to help you navigate our accounts tab.</p>"
  }, {
    "question": "Where can I add a service or product?",
    "answer": "<p>You can add either of these by selecting the \"Product and Services\" tab in your app."
  }, {
    "question": "Can I try out the app for free?",
    "answer": "<p>You can try Lyfe subscription free for 30 days! Sign up here: <a href=\"https://www.booklyfe.com/listyourbusiness\">https://www.booklyfe.com/listyourbusiness</a></p>"
  }, {
    "question": "Do you have a referral program?",
    "answer": "<p>If you refer another healthcare or wellness provider, we will give you one month of subscription free when they sign up.</p>"
  }, {
    "question": "Do you have video tutorials?",
    "answer": "<p>You can find all of our video tutorials here: <a href=\"https://www.booklyfe.com/tutorials\">https://www.booklyfe.com/tutorials</a></p>"
  }, {
    "question": "How can I book and appointment and process a payment?",
    "answer": "<p>Here is a short video to help you: <a href=\"https://video.drift.com/v/abBodk3swT6/\">https://video.drift.com/v/abBodk3swT6/</a></p>"
  }, {
    "question": "Can I choose my cancellation policy?",
    "answer": "<p>A provider can choose the cancellation window in the \"business profile\" then Settings page. Then choose the amount of hours a client must reschedule or cancel an appointment prior. It is always up to the provider whether or not they enforce a cancellation fee but choosing the cancellation window will notify the client as to your policy.</p>"
  }
]

export default FAQProvider