import React, { Children, Component } from "react";
import { hot } from "react-hot-loader";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { ToastProvider } from "react-toast-notifications";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Global, css } from "@emotion/core";
import ScrollToTop from "./util/scrollToTop.tsx"

import { colors } from "./util/consts";

import Base from "./components/routes/Base";
import { ToastConnector } from './toast-connector';

const placements = {
  "top-left": { top: 0, left: 0 },
  "top-center": { top: 0, left: "50%", transform: "translateX(-50%)" },
  "top-right": { top: 0, right: 0 },
  "bottom-left": { bottom: 0, left: 0 },
  "bottom-center": { bottom: 0, left: "50%", transform: "translateX(-50%)" },
  "bottom-right": { bottom: 0, right: 0 }
};

export const ToastContainer = ({ children, placement }) => (
  <div
    style={{
      boxSizing: "border-box",
      maxHeight: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      padding: 8,
      pointerEvents: Children.count(children) ? "auto" : "none",
      position: "fixed",
      zIndex: 50,
      ...placements[placement]
    }}
  >
    <TransitionGroup component={null}>{children}</TransitionGroup>
  </div>
);

class App extends Component {
  render() {
    const { store, persistor } = this.props;
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastProvider
            ref={this.onToastProviderRef}
            autoDismiss={true}
            autoDismissTimeout={10000}
            components={{ ToastContainer }}
          >
            <ToastConnector />
            <Global
              styles={css`
                *,
                *:before,
                *:after {
                  box-sizing: border-box;

                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  text-rendering: optimizeLegibility;

                  -webkit-tap-highlight-color: transparent;

                  -webkit-overflow-scrolling: touch;
                  font-family: "Open Sans", sans-serif;
                }

                body {
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  font-family: sans-serif;
                  display: inline-block;
                  min-width: 100%;
                }

                html,
                body {
                  padding: 0;
                  margin: 0;
                }

                div#root {
                  width: 100%;
                }

                .react-datepicker-wrapper {
                  display: block;
                  width: 100%;
                }

                .react-datepicker__input-container {
                  display: block;
                  width: 100%;
                }

                .react-datepicker__header--time {
                  min-height: 58.3px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .react-datepicker__current-month {
                  font-weight: 600;
                }

                .react-datepicker-time__header {
                  font-weight: 600;
                }

                .react-datepicker__time-list-item--selected {
                  background-color: ${colors.primary.main} !important;
                }

                .react-datepicker__header {
                  background-color: unset;
                }

                .react-datepicker__day {
                  color: ${colors.primary.main};
                }

                .react-datepicker__day:hover {
                  border-radius: 50%;
                }

                .react-datepicker__day--selected,
                .react-datepicker__day--keyboard-selected {
                  border-radius: 50%;
                  background-color: ${colors.primary.main};
                  color: ${colors.whiteText.highEmphasis} !important;
                }

                .react-datepicker__day--selected:hover,
                .react-datepicker__day--keyboard-selected:hover {
                  background-color: ${colors.primary[800]};
                }

                .react-datepicker__month-select,
                .react-datepicker__year-select {
                  background: transparent;
                  border: none;
                }

                .react-datepicker__day--today {
                  color: ${colors.secondary.main};
                }

                .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
                  display: none;
                }
              `}
            />
            <Router>
              <ScrollToTop>
              <Route component={Base} />
              </ScrollToTop>
            </Router>
          </ToastProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default hot(module)(App);
