import React from 'react';
import styled from "@emotion/styled";

import { colors } from "./../../util/consts";
import typography from "./../../util/typography";

interface Props {
  tip: string;
  children: React.ReactNode;
}

export class HoverToolTip extends React.Component<Props> {
  render() {
    return (
      <Container>
        {this.props.children}
        <Hover>
          <div>{this.props.tip}</div>
        </Hover>
      </Container>
    );
  }
}

const Hover = styled.div`
  display: none;
  background-color: #ffffff;
  border-radius: 4px;
  width: 200px;

  bottom: calc(100% + 8px);
  border: 1px solid #636363;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 10px;
  color: ${colors.surfaceText.highEmphasis};
  ${typography.body2};
`;

const Container = styled.span`
  position: relative;
  cursor: pointer;

  &:hover ${Hover} {
    display: block;
  }
`;
