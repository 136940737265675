import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

import * as ReduxDialogs from "./../../redux/features/dialogs";
import { parseFormError } from "./../../util/form";

import { colors, hexToRgba } from "./../../util/consts";
import typography from "./../../util/typography";

// Utils
import { breakpoints } from "./../../util/consts";

import Dialog from "./Dialog";
import ButtonBase from "./../../components/ButtonBase";
import Label from "./../../components/form/Label";
import Section from "./../../components/form/Section";
import { YearSelect, MonthSelect } from "./../../components/form/BaseSelect";

import LoadingIcon from "./../../components/icons/Loading";
import apiService from "./../../redux/services/api";

class ExportTransactionDialog extends React.Component {
  state = {
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth(),
    isLoading: false
  };

  closeDialog = () => {
    const { dispatch, dialogId } = this.props;
    dispatch(ReduxDialogs.actions.close(dialogId));
  }

  exportTransactions = async () => {
    const { data: { org, loc }, dispatch, dialogId } = this.props;
    const { year, month } = this.state;

    if (this.state.isLoading) { return; }

    this.setState({ isLoading: true });
    try {
      const res = await apiService.performRequest({
        method: "get",
        path: "/api/v1/transactions",
        query: {
          OrgID: org.ID,
          LocID: !loc ? undefined : loc.ID,
          Year: year,
          Month: month
        },
        responseType: "blob"
      });

      const fileName = loc ? `${loc.Name}-${year}-${month+1}.xlsx` : `${org.Name}-${year}-${month+1}.xlsx`;
      const objectUrl = URL.createObjectURL(res);
      const a = document.createElement("a");
      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);

      this.setState({ isLoading: false });
      dispatch(ReduxDialogs.actions.close(dialogId));
    } catch (err) {
      parseFormError(err, {}, () => {}, this.props);
      this.setState({ isLoading: false });
    }
  }

  handleYearChange = (e) => {
    this.setState({ year: e.target.value });
  }

  handleMonthChange = (e) => {
    this.setState({ month: e.target.value });
  }

  render() {
    const { isLoading, year, month } = this.state;
    const { data } = this.props;
    const curYear = (new Date()).getFullYear();
    return (
      <Dialog
        dialogId={this.props.dialogId}
        trigger={data.trigger}
        overideCloseDialog={this.closeDialog}
        noClose
      >
        <Container>
          <FormSections>
            <Section>
              <Label htmlFor="export-Year">Year</Label>
              <YearSelect
                id="export-Year"
                name="Year"
                value={year}
                onChange={this.handleYearChange}
                disabled={isLoading}
                from={curYear-100}
                to={curYear}
              />
            </Section>
            <Section style={{ marginLeft: 40 }}>
              <Label htmlFor="export-Month">Month</Label>
              <MonthSelect
                id="export-Month"
                name="Month"
                value={month}
                onChange={this.handleMonthChange}
                disabled={isLoading}
                from={0}
                to={year===curYear ? (new Date()).getMonth() : 11}
              />
            </Section>
          </FormSections>

          <ButtonContainer>
            <LargeBottomButton onClick={this.exportTransactions}>
              {isLoading ?
                <LoadingIcon
                  width={16}
                  height={16}
                  color="#FFF"
                /> :
                <LargeButtonText>Export Data</LargeButtonText>}
            </LargeBottomButton>
          </ButtonContainer>
        </Container>
      </Dialog>
    );
  }
}

const Container = styled.div`
  position: relative;
  padding: 24px;
  padding-bottom: 74px;

  ${breakpoints["tablet-up"]} {
    padding: 42px 90px;
    padding-bottom: 82px;
    max-width: 700px;
  }

  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const FormSections = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
`;

const LargeButtonText = styled.span`
  ${typography.heading6};
  font-weight: 600;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: ${colors.whiteText.highEmphasis};
`;

const LargeBottomButton = styled(ButtonBase)`
  flex: 1 0 100px;
  height: 100%;
  background-color: ${({ isPrimary })=>(isPrimary ? colors.primary.main : colors.secondary.main)};
  transition: opacity 0.2s ease;

  &:hover:enabled {
    opacity: 0.95;
  }

  &:focus {
    opacity: 0.95;
  }

  &:disabled {
    background-color: ${hexToRgba(colors.secondary.main, 0.12)};
  }
`;

export default compose(
  connect(),
  withToastManager
)(ExportTransactionDialog);
