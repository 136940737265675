// source: insight_filter_options.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.proto.GetInisghtFilterOptionsRequest', null, global);
goog.exportSymbol('proto.proto.InsightFilterOptions', null, global);
goog.exportSymbol('proto.proto.Option', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetInisghtFilterOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetInisghtFilterOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetInisghtFilterOptionsRequest.displayName = 'proto.proto.GetInisghtFilterOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.InsightFilterOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.InsightFilterOptions.repeatedFields_, null);
};
goog.inherits(proto.proto.InsightFilterOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.InsightFilterOptions.displayName = 'proto.proto.InsightFilterOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Option = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Option, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Option.displayName = 'proto.proto.Option';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetInisghtFilterOptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetInisghtFilterOptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetInisghtFilterOptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetInisghtFilterOptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    locationid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    providerid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetInisghtFilterOptionsRequest}
 */
proto.proto.GetInisghtFilterOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetInisghtFilterOptionsRequest;
  return proto.proto.GetInisghtFilterOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetInisghtFilterOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetInisghtFilterOptionsRequest}
 */
proto.proto.GetInisghtFilterOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetInisghtFilterOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetInisghtFilterOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetInisghtFilterOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetInisghtFilterOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocationid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProviderid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string orgId = 1;
 * @return {string}
 */
proto.proto.GetInisghtFilterOptionsRequest.prototype.getOrgid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetInisghtFilterOptionsRequest} returns this
 */
proto.proto.GetInisghtFilterOptionsRequest.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string locationId = 2;
 * @return {string}
 */
proto.proto.GetInisghtFilterOptionsRequest.prototype.getLocationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetInisghtFilterOptionsRequest} returns this
 */
proto.proto.GetInisghtFilterOptionsRequest.prototype.setLocationid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string providerId = 3;
 * @return {string}
 */
proto.proto.GetInisghtFilterOptionsRequest.prototype.getProviderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetInisghtFilterOptionsRequest} returns this
 */
proto.proto.GetInisghtFilterOptionsRequest.prototype.setProviderid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.InsightFilterOptions.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.InsightFilterOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.InsightFilterOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.InsightFilterOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.InsightFilterOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.proto.Option.toObject, includeInstance),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.proto.Option.toObject, includeInstance),
    providersList: jspb.Message.toObjectList(msg.getProvidersList(),
    proto.proto.Option.toObject, includeInstance),
    statesList: jspb.Message.toObjectList(msg.getStatesList(),
    proto.proto.Option.toObject, includeInstance),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.proto.Option.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.InsightFilterOptions}
 */
proto.proto.InsightFilterOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.InsightFilterOptions;
  return proto.proto.InsightFilterOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.InsightFilterOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.InsightFilterOptions}
 */
proto.proto.InsightFilterOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Option;
      reader.readMessage(value,proto.proto.Option.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    case 2:
      var value = new proto.proto.Option;
      reader.readMessage(value,proto.proto.Option.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    case 3:
      var value = new proto.proto.Option;
      reader.readMessage(value,proto.proto.Option.deserializeBinaryFromReader);
      msg.addProviders(value);
      break;
    case 4:
      var value = new proto.proto.Option;
      reader.readMessage(value,proto.proto.Option.deserializeBinaryFromReader);
      msg.addStates(value);
      break;
    case 5:
      var value = new proto.proto.Option;
      reader.readMessage(value,proto.proto.Option.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.InsightFilterOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.InsightFilterOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.InsightFilterOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.InsightFilterOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Option.serializeBinaryToWriter
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.Option.serializeBinaryToWriter
    );
  }
  f = message.getProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.proto.Option.serializeBinaryToWriter
    );
  }
  f = message.getStatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.proto.Option.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.proto.Option.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Option locations = 1;
 * @return {!Array<!proto.proto.Option>}
 */
proto.proto.InsightFilterOptions.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.proto.Option>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Option, 1));
};


/**
 * @param {!Array<!proto.proto.Option>} value
 * @return {!proto.proto.InsightFilterOptions} returns this
*/
proto.proto.InsightFilterOptions.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Option=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Option}
 */
proto.proto.InsightFilterOptions.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Option, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.InsightFilterOptions} returns this
 */
proto.proto.InsightFilterOptions.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};


/**
 * repeated Option services = 2;
 * @return {!Array<!proto.proto.Option>}
 */
proto.proto.InsightFilterOptions.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.proto.Option>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Option, 2));
};


/**
 * @param {!Array<!proto.proto.Option>} value
 * @return {!proto.proto.InsightFilterOptions} returns this
*/
proto.proto.InsightFilterOptions.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.Option=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Option}
 */
proto.proto.InsightFilterOptions.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.Option, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.InsightFilterOptions} returns this
 */
proto.proto.InsightFilterOptions.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};


/**
 * repeated Option providers = 3;
 * @return {!Array<!proto.proto.Option>}
 */
proto.proto.InsightFilterOptions.prototype.getProvidersList = function() {
  return /** @type{!Array<!proto.proto.Option>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Option, 3));
};


/**
 * @param {!Array<!proto.proto.Option>} value
 * @return {!proto.proto.InsightFilterOptions} returns this
*/
proto.proto.InsightFilterOptions.prototype.setProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.proto.Option=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Option}
 */
proto.proto.InsightFilterOptions.prototype.addProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.proto.Option, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.InsightFilterOptions} returns this
 */
proto.proto.InsightFilterOptions.prototype.clearProvidersList = function() {
  return this.setProvidersList([]);
};


/**
 * repeated Option states = 4;
 * @return {!Array<!proto.proto.Option>}
 */
proto.proto.InsightFilterOptions.prototype.getStatesList = function() {
  return /** @type{!Array<!proto.proto.Option>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Option, 4));
};


/**
 * @param {!Array<!proto.proto.Option>} value
 * @return {!proto.proto.InsightFilterOptions} returns this
*/
proto.proto.InsightFilterOptions.prototype.setStatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.proto.Option=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Option}
 */
proto.proto.InsightFilterOptions.prototype.addStates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.proto.Option, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.InsightFilterOptions} returns this
 */
proto.proto.InsightFilterOptions.prototype.clearStatesList = function() {
  return this.setStatesList([]);
};


/**
 * repeated Option tags = 5;
 * @return {!Array<!proto.proto.Option>}
 */
proto.proto.InsightFilterOptions.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.proto.Option>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Option, 5));
};


/**
 * @param {!Array<!proto.proto.Option>} value
 * @return {!proto.proto.InsightFilterOptions} returns this
*/
proto.proto.InsightFilterOptions.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.proto.Option=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Option}
 */
proto.proto.InsightFilterOptions.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.proto.Option, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.InsightFilterOptions} returns this
 */
proto.proto.InsightFilterOptions.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Option.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Option.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Option} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Option.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Option}
 */
proto.proto.Option.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Option;
  return proto.proto.Option.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Option} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Option}
 */
proto.proto.Option.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Option.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Option.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Option} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Option.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.Option.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Option} returns this
 */
proto.proto.Option.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.proto.Option.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Option} returns this
 */
proto.proto.Option.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.proto);
