/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var offerings_pb = require('./offerings_pb.js')

var bookings_pb = require('./bookings_pb.js')

var money_pb = require('./money_pb.js')
const proto = {};
proto.proto = require('./account_items_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.AccountItemsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.AccountItemsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListAccountItemsByBookingClientRequest,
 *   !proto.proto.ListAccountItemsResponse>}
 */
const methodDescriptor_AccountItems_ListByBookingClient = new grpc.web.MethodDescriptor(
  '/proto.AccountItems/ListByBookingClient',
  grpc.web.MethodType.UNARY,
  proto.proto.ListAccountItemsByBookingClientRequest,
  proto.proto.ListAccountItemsResponse,
  /** @param {!proto.proto.ListAccountItemsByBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListAccountItemsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ListAccountItemsByBookingClientRequest,
 *   !proto.proto.ListAccountItemsResponse>}
 */
const methodInfo_AccountItems_ListByBookingClient = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ListAccountItemsResponse,
  /** @param {!proto.proto.ListAccountItemsByBookingClientRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListAccountItemsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListAccountItemsByBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ListAccountItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListAccountItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AccountItemsClient.prototype.listByBookingClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AccountItems/ListByBookingClient',
      request,
      metadata || {},
      methodDescriptor_AccountItems_ListByBookingClient,
      callback);
};


/**
 * @param {!proto.proto.ListAccountItemsByBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListAccountItemsResponse>}
 *     A native promise that resolves to the response
 */
proto.proto.AccountItemsPromiseClient.prototype.listByBookingClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AccountItems/ListByBookingClient',
      request,
      metadata || {},
      methodDescriptor_AccountItems_ListByBookingClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListByLocationBookingDateRequest,
 *   !proto.proto.ListAccountItemsResponse>}
 */
const methodDescriptor_AccountItems_ListByLocationBookingDate = new grpc.web.MethodDescriptor(
  '/proto.AccountItems/ListByLocationBookingDate',
  grpc.web.MethodType.UNARY,
  proto.proto.ListByLocationBookingDateRequest,
  proto.proto.ListAccountItemsResponse,
  /** @param {!proto.proto.ListByLocationBookingDateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListAccountItemsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ListByLocationBookingDateRequest,
 *   !proto.proto.ListAccountItemsResponse>}
 */
const methodInfo_AccountItems_ListByLocationBookingDate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ListAccountItemsResponse,
  /** @param {!proto.proto.ListByLocationBookingDateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListAccountItemsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListByLocationBookingDateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ListAccountItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListAccountItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AccountItemsClient.prototype.listByLocationBookingDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AccountItems/ListByLocationBookingDate',
      request,
      metadata || {},
      methodDescriptor_AccountItems_ListByLocationBookingDate,
      callback);
};


/**
 * @param {!proto.proto.ListByLocationBookingDateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListAccountItemsResponse>}
 *     A native promise that resolves to the response
 */
proto.proto.AccountItemsPromiseClient.prototype.listByLocationBookingDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AccountItems/ListByLocationBookingDate',
      request,
      metadata || {},
      methodDescriptor_AccountItems_ListByLocationBookingDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RetryAccountItemRequest,
 *   !proto.proto.AccountItem>}
 */
const methodDescriptor_AccountItems_RetryPayment = new grpc.web.MethodDescriptor(
  '/proto.AccountItems/RetryPayment',
  grpc.web.MethodType.UNARY,
  proto.proto.RetryAccountItemRequest,
  proto.proto.AccountItem,
  /** @param {!proto.proto.RetryAccountItemRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AccountItem.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.RetryAccountItemRequest,
 *   !proto.proto.AccountItem>}
 */
const methodInfo_AccountItems_RetryPayment = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.AccountItem,
  /** @param {!proto.proto.RetryAccountItemRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AccountItem.deserializeBinary
);


/**
 * @param {!proto.proto.RetryAccountItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.AccountItem)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AccountItem>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AccountItemsClient.prototype.retryPayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AccountItems/RetryPayment',
      request,
      metadata || {},
      methodDescriptor_AccountItems_RetryPayment,
      callback);
};


/**
 * @param {!proto.proto.RetryAccountItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.AccountItem>}
 *     A native promise that resolves to the response
 */
proto.proto.AccountItemsPromiseClient.prototype.retryPayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AccountItems/RetryPayment',
      request,
      metadata || {},
      methodDescriptor_AccountItems_RetryPayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RetryInvGenRequest,
 *   !proto.proto.AccountItem>}
 */
const methodDescriptor_AccountItems_RetryInvGeneration = new grpc.web.MethodDescriptor(
  '/proto.AccountItems/RetryInvGeneration',
  grpc.web.MethodType.UNARY,
  proto.proto.RetryInvGenRequest,
  proto.proto.AccountItem,
  /** @param {!proto.proto.RetryInvGenRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AccountItem.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.RetryInvGenRequest,
 *   !proto.proto.AccountItem>}
 */
const methodInfo_AccountItems_RetryInvGeneration = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.AccountItem,
  /** @param {!proto.proto.RetryInvGenRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AccountItem.deserializeBinary
);


/**
 * @param {!proto.proto.RetryInvGenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.AccountItem)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AccountItem>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AccountItemsClient.prototype.retryInvGeneration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AccountItems/RetryInvGeneration',
      request,
      metadata || {},
      methodDescriptor_AccountItems_RetryInvGeneration,
      callback);
};


/**
 * @param {!proto.proto.RetryInvGenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.AccountItem>}
 *     A native promise that resolves to the response
 */
proto.proto.AccountItemsPromiseClient.prototype.retryInvGeneration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AccountItems/RetryInvGeneration',
      request,
      metadata || {},
      methodDescriptor_AccountItems_RetryInvGeneration);
};


module.exports = proto.proto;

