import React, { Component, Fragment } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";

// components
import Label from "./../../../components/form/Label";
import Error from "./../../../components/form/Error";
import Form from "./../../../components/form/Form";
import Section from "./../../../components/form/Section";

import { parseFormError } from "./../../../util/form";

// icons
import LoadingIcon from "./../../../components/icons/Loading";

import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

import { Container, HeaderContainer, HeaderButton, DetailInput, DetailTextArea } from "./ContactDetails";

class ProfessionalDetails extends Component {
  constructor(props) {
    super(props);
  }
  handleEditClick = (e) => {
    this.props.setStatus(true);
  };
  render() {
    const {
      values,
      touched,
      status,
      errors,
      handleChange,
      handleBlur,
      isSubmitting,
      handleSubmit
    } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <HeaderContainer>
          Professional Details
          {!isSubmitting && status && <HeaderButton type="submit">Save</HeaderButton>}
          {!isSubmitting && !status && (
            <HeaderButton onClick={this.handleEditClick}>Edit</HeaderButton>
          )}
          {isSubmitting && <LoadingIcon color="#2c2e3c" size="small" />}
        </HeaderContainer>
        <Fragment>
          <Container>
            <Section>
              <Label>Profession</Label>
              <DetailInput
                type="text"
                name="Profession"
                placeholder="Enter your Profession"
                value={values.Profession}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={!status}
                regularText
                error={errors.Profession && touched.Profession}
              />
              {!!errors.Profession && touched.Profession && <Error>{errors.Profession}</Error>}
            </Section>

            <Section>
              <Label>Biograhpy</Label>
              <DetailTextArea
                name="Bio"
                type="text"
                placeholder="Enter your Biography"
                value={values.Bio}
                onChange={handleChange}
                disabled={!status}
                maxHeight={200}
                regularText
                error={errors.Bio && touched.Bio}
              />
              {!!errors.Bio && touched.Bio && <Error>{errors.Bio}</Error>}
            </Section>
          </Container>
        </Fragment>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ orgUser }) => ({
    Profession: !orgUser.RoleProperties ? "" : orgUser.RoleProperties.ProviderProfession || "",
    Bio: !orgUser.RoleProperties ? "" : orgUser.RoleProperties.ProviderBio || ""
  }),
  validationSchema: Yup.object().shape({
    Profession: Yup.string().notRequired(),
    Bio: Yup.string().notRequired()
  }),
  enableReinitialize: true,
  handleSubmit: async (values, { setStatus, setSubmitting, setFieldError, props }) => {
    try {
      const updUser = {
        $Metadata: props.orgUser.$Metadata,
        Org: props.orgUser.Org.ID,
        User: {
          ID: props.orgUser.User.ID,
          FirstName: props.orgUser.User.FirstName,
          LastName: props.orgUser.User.LastName,
          Email: !props.orgUser.User.Email ? null : props.orgUser.User.Email,
          MobileNumber: !props.orgUser.User.MobileNumber ? null : props.orgUser.User.MobileNumber,
          MobileCountryCode: !props.orgUser.User.MobileCountryCode ? null : props.orgUser.User.MobileCountryCode
        },
        RoleProperties: {
          ProviderProfession: values.Profession,
          ProviderBio: values.Bio
        },
        Roles: props.orgUser.Roles,
        IsActive: props.orgUser.IsActive
      };

      await props.dispatch(ResourceActions.action(updUser, "Update", {}));
      setStatus(false);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  }
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(ProfessionalDetails);
