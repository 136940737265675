import styled from "@emotion/styled";

import { colors } from "./../../util/consts";
import typography from "./../../util/typography";

const FooterText = styled.span`
  ${typography.body2};
  color: ${colors.surfaceText.medEmphasis};
  letter-spacing: 0.09px;
`;

export default FooterText;
