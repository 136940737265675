import React, { Component } from "react";
import styled from "@emotion/styled";
import { withFormik } from "formik";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// import apiService from "./../../../redux/services/api";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

import { parseFormError } from "./../../../util/form";
import { findPlaceDetails, getAddressResourceFromPlaceDetails } from "./../../../util/address";

// utils
import { colors } from "./../../../util/consts";
import typography from "./../../../util/typography";

// components
import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import Error from "./../../../components/form/Error";
import Form from "./../../../components/form/Form";
import TextArea from "./../../../components/form/TextArea";
import Section from "./../../../components/form/Section";
import Button from "./../../../components/Button";
import GMPlacesAutocomplete from "./../../../components/form/GMPlacesAutocomplete";
import MobileInput from "./../../form/MobileInput"


// icons
import LoadingIcon from "./../../../components/icons/Loading";

class ContactDetails extends Component {
  constructor(props) {
    super(props);
  }
  handleEditClick = (e) => {
    this.props.setStatus(true);
  };
  render() {
    const {
      values,
      status,
      touched,
      errors,
      handleChange,
      handleBlur,
      isSubmitting,
      handleSubmit,
      orgUser
    } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <HeaderContainer>
          Contact Details
          {!isSubmitting && status && <HeaderButton type="submit">Save</HeaderButton>}
          {!isSubmitting && !status && (
            <HeaderButton onClick={this.handleEditClick}>Edit</HeaderButton>
          )}
          {isSubmitting && <LoadingIcon color="#2c2e3c" size="small" />}
        </HeaderContainer>

        <Container>
          <Section>
            <Label>First Name</Label>
            <DetailInput
              type="text"
              name="FirstName"
              value={values.FirstName}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={!status}
              regularText
              error={errors.FirstName && touched.FirstName}
            />
            {!!errors.FirstName && touched.FirstName && <Error>{errors.FirstName}</Error>}
          </Section>

          <Section>
            <Label>Last Name</Label>
            <DetailInput
              type="text"
              name="LastName"
              value={values.LastName}
              onBlur={handleBlur}
              autoFocus={true}
              onChange={handleChange}
              disabled={!status}
              regularText
              error={errors.LastName && touched.LastName}
            />
            {!!errors.LastName && touched.LastName && <Error>{errors.LastName}</Error>}
          </Section>

          <Section>
            <Label>Email Address</Label>
            <DetailInput
              type="text"
              name="Email"
              placeholder="example@booklyfe.com"
              value={values.Email}
              onBlur={handleBlur}
              autoFocus={true}
              onChange={handleChange}
              disabled={!status || orgUser.User.Status !== "Stub"}
              regularText
              error={errors.Email && touched.Email}
            />
            {!!errors.Email && touched.Email && <Error>{errors.Email}</Error>}
          </Section>

          <Section>
            <Label>Phone Number</Label>
            { !status || orgUser.User.Status !== "Stub" ?
            <DetailInput
            type="text"
            name="MobileNumber"
            value={values.MobileNumber}
            onBlur={handleBlur}
            autoFocus={true}
            onChange={handleChange}
            disabled={!status || orgUser.User.Status !== "Stub"}
            regularText
          />:         
            <MobileInput
                value={values.MobileNumber}
                disabled={!status || orgUser.User.Status !== "Stub"}
                onCountryChange={(e)=>{values.MobileCountryCode = e}}
                onPhoneChange={(e)=>{values.MobileNumber = e}}
            />}
            {!!errors.MobileNumber && touched.MobileNumber && <Error>{errors.MobileNumber}</Error>}
          </Section>

          <Section>
            <Label>Address</Label>
            <GMPlacesAutocomplete
              id="loccreate-address"
              name="Address"
              type="text"
              style = {{ opacity: "0.6", fontSize: "12.82px", border: "none" }}
              placeholder="Address"
              value={values.Address}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!status || orgUser.User.Status !== "Stub"}
              regularText
              error={touched.Address && errors.Address}
            />
            {!!errors.Address && touched.Address && <Error>{errors.Address}</Error>}
          </Section>
        </Container>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ orgUser }) => ({
    FirstName: orgUser.User.FirstName,
    LastName: orgUser.User.LastName,
    Email: orgUser.User.Email || "",
    MobileNumber: orgUser.User.MobileNumber || "",
    MobileCountryCode: "AU",
    Address: !orgUser.User.Address ? "" : orgUser.User.Address.Raw
  }),
  validationSchema: Yup.object().shape({
    FirstName: Yup.string().required("First name is required."),
    LastName: Yup.string().required("Last name is required."),
    Email: Yup.string()
      .email("Not a valid email")
      .required("Email is required."),
    MobileNumber: Yup.string().notRequired(),
    Address: Yup.string().notRequired()
  }),
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, setStatus, setFieldError, props }) => {
    const { orgUser, dispatch } = props;
    try {
      if (values.Address !== "") {
        if (!orgUser.User.Address) {
          const prDet = await findPlaceDetails(values.Address, "Address");
          const prAddRes = {
            OwnerType: "User",
            Owner: props.orgUser.User.ID,
            ...getAddressResourceFromPlaceDetails(prDet, values.Address)
          };
          await dispatch(ResourceActions.action(prAddRes, "Create", {}));
        } else if (orgUser.User.Address.Raw !== values.Address) {
          const prDet = await findPlaceDetails(values.Address, "Address");
          const prUpdAddr = {
            OwnerType: "User",
            ...orgUser.User.Address,
            ...getAddressResourceFromPlaceDetails(prDet, values.Address)
          };
          await dispatch(ResourceActions.action(prUpdAddr, "Update", {}));
        }
      } else if (orgUser.User.Address) {
        await props.dispatch(ResourceActions.action(orgUser.User.Address, "Remove", {}));
      }

      const updUser = {
        $Metadata: props.orgUser.$Metadata,
        Org: props.orgUser.Org.ID,
        User: {
          ID: props.orgUser.User.ID,
          FirstName: values.FirstName.trim(),
          LastName: values.LastName.trim(),
          Email: values.Email.trim(),
          MobileNumber: !values.MobileNumber ? null : values.MobileNumber,
          MobileCountryCode: !values.MobileNumber ? null : values.MobileCountryCode
        },
        RoleProperties: props.orgUser.RoleProperties,
        Roles: props.orgUser.Roles,
        IsActive: props.orgUser.IsActive
      };

      await dispatch(ResourceActions.action(updUser, "Update", {}));
      setStatus(false);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  }
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(ContactDetails);

export const Container = styled.div`
  border-radius: 4px;
  margin: 8px 0 30px;
  background-color: ${colors.surface.light};
  padding: 24px 32px 30px;
`;

export const HeaderContainer = styled.div`
  /* margin-top: 30px; */
  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 15.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderButton = styled(Button)`
  padding: 0;
  opacity: 0.3;
`;

export const DetailInput = styled(Input)`
  ${typography.body2};
  letter-spacing; 0.19px;
  line-height: 18px;
  opacity: 0.6;
`;

export const DetailTextArea = styled(TextArea)`
  ${typography.body2};
  letter-spacing; 0.19px;
  line-height: 18px;
  opacity: 0.6;
`;
