import React, { Component } from "react";
import * as fns from "date-fns";
import styled from "@emotion/styled";
import { withFormik } from "formik";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// import apiService from "./../../../redux/services/api";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import * as ReduxDialogs from "./../../../redux/features/dialogs";

import { parseFormError } from "./../../../util/form";

// utils
import { colors } from "./../../../util/consts";
import typography from "./../../../util/typography";

// components
import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import Error from "./../../../components/form/Error";
import Form from "./../../../components/form/Form";
import Section from "./../../../components/form/Section";
import Button from "./../../../components/Button";
import DayTimeRangePickerInput from "./../../../components/form/DayTimeRangePickerInput";
import { LocationProviderSelector } from "./../../../components/form/ResourceSelector";

// icons
import LoadingIcon from "./../../../components/icons/Loading";

import { TabContainer } from "./index";
import ResourceFetch from './../../../components/elements/ResourceFetch';
import ResourceRender from './../../../components/elements/ResourceRender';

export const DetailInput = styled(Input)`
  ${typography.body2};
  letter-spacing; 0.19px;
  line-height: 18px;
  opacity: 0.6;
`;

interface Props {
  resource: any;
  values: any;
  status: any;
  touched: any;
  errors: any;
  handleChange: any;
  handleBlur: any;
  isSubmitting: any;
  handleSubmit: any;
  setStatus: (status: boolean) => void;
}

class AppDetails extends Component<Props> {

  handleEditClick = () => {
    this.props.setStatus(true);
  };

  handleDoneClick = () => {
    this.props.setStatus(false);
  };

  render() {
    const {
      resource: booking,
      values,
      status,
      touched,
      errors,
      handleChange,
      handleBlur,
      isSubmitting,
      handleSubmit
    } = this.props;
    const mayEdit = booking.Status === "Created";
    return (
      <TabContainer>
        <Form onSubmit={handleSubmit}>
          <HeaderContainer>
            Appointment Details
            {!isSubmitting && status &&
              <HeaderButton type="submit">Save</HeaderButton>
            }
            {!isSubmitting && !status && mayEdit && (
              <HeaderButton onClick={this.handleEditClick}>Edit</HeaderButton>
            )}
            {isSubmitting && <LoadingIcon color="#2c2e3c" size="small" />}
          </HeaderContainer>

          <Container>
            <Section>
              <Label htmlFor="newbook-datetime">Date &amp; Time</Label>
              <DayTimeRangePickerInput
                id="newbook-datetime"
                interval={5}
                placeholder="Select the time"
                dayName="Date"
                dayValue={values.Date}
                startName="StartTime"
                startValue={values.StartTime}
                endName="EndTime"
                endValue={values.EndTime}
                onChange={handleChange}
                onBlur={handleBlur}
                startDisabled={!status}
                endDisabled={!status}
                regularText
                error={
                  (touched.Date && errors.Date) ||
                  (touched.StartTime && errors.StartTime) ||
                  (touched.EndTime && errors.EndTime)
                }
              />
              {!!errors.Date && touched.Date && <Error>{errors.Date}</Error>}
            </Section>
            <Section>
              <Label htmlFor="newbook-provider">Provider</Label>
              <LocationProviderSelector
                id="newbook-provider"
                name="Provider"
                placeholder="Select the provider"
                location={booking.Location}
                value={values.Provider}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!status}
                regularText
                error={touched.Provider && errors.Provider}
              />
              {!!errors.Provider && touched.Provider && <Error>{errors.Provider}</Error>}
            </Section>
            {booking.Type === "Group" && (
              <React.Fragment>
                <Section>
                  <Label htmlFor="newbook-maxgroupsize">Maximum Group Size</Label>
                  <Input
                    id="offering-maxgroupsize"
                    name="MaxGroupSize"
                    type="number"
                    min={1}
                    max={100}
                    placeholder="Item code"
                    value={values.MaxGroupSize}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!status}
                    regularText
                  />
                  {!!errors.MaxGroupSize && touched.MaxGroupSize && (
                    <Error>{errors.MaxGroupSize}</Error>
                  )}
                </Section>
              </React.Fragment>
            )}
          </Container>
        </Form>
      </TabContainer>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ resource: booking }: any) => ({
    Provider: { User: booking.Provider },
    Date: fns.parse(booking.StartDate, "yyyy-MM-dd", new Date()),
    StartTime: booking.StartTime,
    EndTime: booking.EndTime,
    MaxGroupSize: booking.MaxGroupSize||5
  }),
  validationSchema: ({ resource: booking }: any) => {
    if (booking.Type === "Single") {
      return Yup.object().shape({
        Provider: Yup.object()
          .required("A provider is required"),
        Date: Yup.date()
          .required("Day is required"),
        StartTime: Yup.string()
          .required("Start time is required"),
        EndTime: Yup.string()
          .required("End time is required")
      });
    } else {
      return Yup.object().shape({
        Provider: Yup.object()
          .required("A provider is required"),
        Date: Yup.date()
          .required("Day is required"),
        StartTime: Yup.string()
          .required("Start time is required"),
        EndTime: Yup.string()
          .required("End time is required"),
        MaxGroupSize: Yup.number()
          .min(Math.max(booking.Clients.length, 1), booking.Clients.length < 1 ? "Must be one or more." : "Must not be less than the current number of clients.")
          .max(100, "Must be less than one hundred.")
          .required("End time is required"),
      });
    }
  },
  enableReinitialize: true,
  handleSubmit: async (values: any, { setSubmitting, setStatus, setFieldError, props }: any) => {
    const { resource: booking, dispatch } = props;
    try {
      const updBooking = {
        ...booking,
        Provider: values.Provider.User.ID,
        StartDate: fns.format(values.Date, "yyyy-MM-dd"),
        EndDate: fns.format(values.Date, "yyyy-MM-dd"),
        StartTime: values.StartTime,
        EndTime: values.EndTime,
        MaxGroupSize: values.ServiceType === "Single" ? null : values.MaxGroupSize,
      };

      const openActionDialogMsg = (msg: any, checkMsg: any) => new Promise((resolve, reject) =>
        dispatch(ReduxDialogs.openAction(
          msg,
          checkMsg,
          null,
          (check: any, yay: any, notify: any)=>{
            if (check && yay) {
              dispatch(ResourceActions.action({ ...updBooking, Notify: notify }, "Update", {}))
                .then(()=>{
                  setStatus(false);
                  resolve();
                })
                .catch((err: any)=>reject(err));
            } else {
              resolve();
            }
          },
          "Yes",
          "No"
        ))
      );

      if ((updBooking.StartDate !== booking.StartDate) || (updBooking.StartTime !== booking.StartTime) || 
      (updBooking.EndDate !== booking.EndDate) || (updBooking.EndTime !== booking.EndTime)) {
        await openActionDialogMsg(
          "Are you sure you wish to reschedule this booking?",
          "Notify the client(s)?"
        );
      } else if (updBooking.Provider !== booking.Provider.ID) {
        await openActionDialogMsg(
          "Are you sure you wish to change the provider for this booking?",
          "Notify the client(s)?"
        );
      } else {
        await dispatch(ResourceActions.action(updBooking, "Update", {}));
        setStatus(false);
      }
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  }
});

const enhancedAppDetails = compose<any, { bookingId: string }>(
  connect(),
  withToastManager,
  formikEnhancer
)(AppDetails);

class Loader extends React.Component<{ bookingId: string }> {
  render() {
    return (
      <>
        <ResourceFetch type="LocBooking" ids={this.props.bookingId} force />
        <ResourceRender
          type="LocBooking"
          ids={this.props.bookingId}
          compSuccess={enhancedAppDetails}
          forwardedProps={this.props}
          denorm={true}
        />
      </>
    );
  }
}

export default Loader;

export const Container = styled.div`
  margin: 8px 0 30px;
  border-radius: 4px;
  background-color: ${colors.surface.light};
  padding: 24px 32px 30px;
`;

export const HeaderContainer = styled.div`
  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 15.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderButton = styled(Button)`
  padding: 0;
  opacity: 0.3;
`;
