import React, { Component } from "react";

// components
// import ButtonBase from "./../../../components/ButtonBase";

import CardAccordian from "./CardAccordian";
import LargeDottedButton from "./../../../components/LargeDottedButton";

// import { AddButton } from "./../../../components/elements/AccordianElements";

import { TabContainer, TabHeader, TabDetail } from "./index";

import LoadingIcon from "./../../../components/icons/Loading";

export default class Cards extends Component {
  state = {
    adding: false
  };
  addCard = (e) => {
    this.setState({ adding: true });
  };
  closeNew = () => {
    this.setState({ adding: false });
  };
  render() {
    const { client, rightMessage, rightMessageProps, selCard, onSelect, isLoading } = this.props;
    return (
      <TabContainer>
        <TabHeader>
          <div>
            Client's Cards
          </div>
          {!isLoading && rightMessage && <TabDetail {...rightMessageProps}>{rightMessage}</TabDetail>}
          {isLoading && <TabDetail><LoadingIcon width={16} height={16} color="#2c2e3c" /></TabDetail>}
        </TabHeader>
        {(client.CreditCards||[]).map((v) => (
          <CardAccordian
            key={v.ID}
            id={v.ID}
            client={client}
            ccard={v}
            selected={v.ID===selCard}
            onSelect={onSelect}
            charge={this.props.charge}
          />
        ))}
        {!this.state.adding ? null : (
          <CardAccordian
            key="newcard"
            id="newcard"
            client={client}
            ccard={null}
            closeNew={this.closeNew}
          />
        )}
        {this.state.adding ? null : (
          <LargeDottedButton onClick={this.addCard}>ADD NEW CARD</LargeDottedButton>
        )}
      </TabContainer>
    );
  }
}
