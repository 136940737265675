import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { parseFormError } from "./../../../util/form";

// utils
import { colors } from "./../../../util/consts";
import { isPhoneNumber } from "./../../../util/phone";

import IconButton from "./../../../components/IconButton";
import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import Error from "./../../../components/form/Error";
import LoadingButton from "./../../../components/LoadingButton";
import Button from "./../../../components/Button";
import Section from "./../../../components/form/Section";
import DayPickerInput from "./../../../components/form/DayPickerInput";

import Select from "./../../../components/form/BaseSelect";

import DropdownSelect from "./../../../components/DropdownSelect";

import {
  AccordianForm,
  AccordianContainer,
  // AccordianButton,
  AccordianEdit,
  NewHeader,
  Separator,
  OptionsContainer,
  ButtonHead,
  ButtonDetails,
  ButtonContainer,
  NoSpacingIconButton
} from "./../../../components/elements/AccordianElements";

// icons
import ClearIcon from "./../../../components/icons/Clear";
import Options from "./../../../components/icons/Options";

import { mapFund } from "./../../../util/consts";
import apiService from "./../../../redux/services/api";
import { clientFundStore } from "./../../../stores/client_fund-store";

export const availFunds = [
  "AUMedicare",
  "AUDVA",
  "AUHICAPS",
  // "AUVictoriaTAC"
];

class FundAccordian extends Component {
  toggleOpen = () => {
    const { values, setFieldValue } = this.props;
    setFieldValue("IsOpen", !values.IsOpen);
  };

  removeItem = async () => {
    const {  dispatch, client, fund } = this.props;
    // Adding to funds.

    try {
      const { [fund]: _, ...fProps } = client.FundProperties;
      const updClient = {
        ...client,
        Org: client.Org.ID,
        FundProperties: fProps
      };
      await dispatch(
        ResourceActions.action(updClient, "Update", {})
      );
    } catch (err) {
      parseFormError(err, {}, ()=>{}, this.props);
    }
    await clientFundStore.load(client.ID);
  }

  editOptions = [
    {
      label: "Edit",
      onClick: this.toggleOpen
    },
    {
      label: "Remove",
      onClick: this.removeItem
    }
  ];

  leftOverFunds(client) {
    return availFunds.filter((v)=>!client.FundProperties[v]);
  }

  fundDetails(client, fund) {
    switch (fund) {
    case "AUMedicare":
      return  (
        <React.Fragment>
          <ButtonDetails>{client.FundProperties.AUMedicare.Number}</ButtonDetails>
          <Separator />
          <ButtonDetails>{client.FundProperties.AUMedicare.Reference}</ButtonDetails>
        </React.Fragment>
      );
    case "AUDVA":
      return  (
        <React.Fragment>
          <ButtonDetails>{client.FundProperties.AUDVA.Number}</ButtonDetails>
          <Separator />
          <ButtonDetails>{client.FundProperties.AUDVA.Disability||"None"}</ButtonDetails>
        </React.Fragment>
      );
    case "AUHICAPS":
      return  (
        <React.Fragment>
          <ButtonDetails>{!client.FundProperties.AUHICAPS.MemberID ? "Mobile Number" : "Member ID"}</ButtonDetails>
        </React.Fragment>
      );
    default:
      return null;
    }
  }

  renderFundForm() {
    const {
      id,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      setFieldValue,
      isSubmitting
    } = this.props;

    switch (values.FundType) {
      case "AUMedicare":
        return  (
          <React.Fragment>
            <Section>
              <Label htmlFor={`addfund-${id}-AUMedicare_Number`}>Number</Label>
              <Input
                id={`addfund-${id}-AUMedicare_Number`}
                name="AUMedicare_Number"
                type="text"
                placeholder="0000 00000 0"
                value={values.AUMedicare_Number}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.AUMedicare_Number && errors.AUMedicare_Number}
              />
              {!!errors.AUMedicare_Number && touched.AUMedicare_Number && (
                <Error>{errors.AUMedicare_Number}</Error>
              )}
            </Section>
            <Section>
              <Label htmlFor={`addfund-${id}-AUMedicare_Reference`}>Reference</Label>
              <Input
                id={`addfund-${id}-AUMedicare_Reference`}
                name="AUMedicare_Reference"
                type="number"
                placeholder="0"
                value={values.AUMedicare_Reference}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.AUMedicare_Reference && errors.AUMedicare_Reference}
              />
              {!!errors.AUMedicare_Reference && touched.AUMedicare_Reference && (
                <Error>{errors.AUMedicare_Reference}</Error>
              )}
            </Section>
          </React.Fragment>
        );
      case "AUDVA":
        return  (
          <React.Fragment>
            <Section>
              <Label htmlFor={`addfund-${id}-AUDVA_Number`}>Number</Label>
              <Input
                id={`addfund-${id}-AUDVA_Number`}
                name="AUDVA_Number"
                type="text"
                placeholder="000"
                value={values.AUDVA_Number}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.AUDVA_Number && errors.AUDVA_Number}
              />
              {!!errors.AUDVA_Number && touched.AUDVA_Number && (
                <Error>{errors.AUDVA_Number}</Error>
              )}
            </Section>
            <Section>
              <Label htmlFor={`addfund-${id}-AUDVA_Disability`}>Disability</Label>
              <Input
                id={`addfund-${id}-AUDVA_Disability`}
                name="AUDVA_Disability"
                type="text"
                placeholder="000"
                value={values.AUDVA_Disability}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.AUDVA_Disability && errors.AUDVA_Disability}
              />
              {!!errors.AUDVA_Disability && touched.AUDVA_Disability && (
                <Error>{errors.AUDVA_Disability}</Error>
              )}
            </Section>
          </React.Fragment>
        );
      case "AUHICAPS":
        if (values.AUHICAPS_Search) {
          return  (
            <React.Fragment>
              <Section>
                <Label htmlFor={`addfund-${id}-AUHICAPS_EmailPhoneName`}>Email or Mobile Phone or Name</Label>
                <Input
                  id={`addfund-${id}-AUHICAPS_EmailPhoneName`}
                  name="AUHICAPS_EmailPhoneName"
                  type="text"
                  placeholder="person@example.com/04********/Joe Smith"
                  value={values.AUHICAPS_EmailPhoneName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.AUHICAPS_EmailPhoneName && errors.AUHICAPS_EmailPhoneName}
                />
                {!!errors.AUHICAPS_EmailPhoneName && touched.AUHICAPS_EmailPhoneName && (
                  <Error>{errors.AUHICAPS_EmailPhoneName}</Error>
                )}
              </Section>
              <Section>
                <Label htmlFor={`addfund-${id}-AUHICAPS_DateOfBirth`}>Date of Birth</Label>
                <DayPickerInput
                  id={`addfund-${id}-AUHICAPS_DateOfBirth`}
                  name="AUHICAPS_DateOfBirth"
                  placeholder="Select a date of birth"
                  value={values.AUHICAPS_DateOfBirth}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.AUHICAPS_DateOfBirth && errors.AUHICAPS_DateOfBirth}
                />
                {!!errors.AUHICAPS_DateOfBirth && touched.AUHICAPS_DateOfBirth && (
                  <Error>{errors.AUHICAPS_DateOfBirth}</Error>
                )}
              </Section>
              <ButtonContainer>
                <LoadingButton
                  style={{
                    minWidth: 150
                  }}
                  loading={isSubmitting}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Search
                </LoadingButton>
                <Button
                  style={{ marginTop: "10px" }}
                  variant="text"
                  color="primary"
                  type="button"
                  onClick={()=>setFieldValue("AUHICAPS_Search", false)}
                >
                  Cancel
                </Button>
              </ButtonContainer>
            </React.Fragment>
          );
        } else {
          return  (
            <React.Fragment>
              {values.AUHICAPS_MemberID !== "" ?
                <Section style={{ marginTop: "10px", position: "relative" }}>
                  <Label htmlFor={`addfund-${id}-AUHICAPS_MemberID`}>Member ID</Label>
                  <Input
                    id={`addfund-${id}-AUHICAPS_MemberID`}
                    name="AUHICAPS_MemberID"
                    type="text"
                    placeholder="Enter member ID"
                    value={values.AUHICAPS_MemberID}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.AUHICAPS_MemberID && errors.AUHICAPS_MemberID}
                    disabled={true}
                  />
                  <IIconButton onClick={()=>setFieldValue("AUHICAPS_MemberID", "")}>
                    <ClearIcon />
                  </IIconButton>
                  {!!errors.AUHICAPS_MemberID && touched.AUHICAPS_MemberID && (
                    <Error>{errors.AUHICAPS_MemberID}</Error>
                  )}
                </Section> :
                <Section style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={()=>setFieldValue("AUHICAPS_Search", true)}
                >
                  Search For Member
                </Button>
                </Section>}
              {values.AUHICAPS_MemberID === "" &&
                <Section style={{ display: "flex", justifyContent: "center" }}>
                  <Label>- OR -</Label>
                </Section>}
              <Section>
                {values.AUHICAPS_MemberID !== "" && <Label htmlFor={`addfund-${id}-AUHICAPS_MobileNumber`}>Mobile Number</Label>}
                <Input
                  id={`addfund-${id}-AUHICAPS_MobileNumber`}
                  name="AUHICAPS_MobileNumber"
                  type="text"
                  placeholder="Enter mobile number"
                  value={values.AUHICAPS_MobileNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.AUHICAPS_MobileNumber && errors.AUHICAPS_MobileNumber}
                />
                {!!errors.AUHICAPS_MobileNumber && touched.AUHICAPS_MobileNumber && (
                  <Error>{errors.AUHICAPS_MobileNumber}</Error>
                )}
              </Section>
            </React.Fragment>
          );
        }
      default:
        return null;
      }
  }

  renderContent() {
    const {
      id,
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      client,
      fund
    } = this.props;

    return (
      <AccordianForm
        id={`addfund-${id}-form`}
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        {fund ? null : (
          <Section>
            <Label>Fund</Label>
            <Select
              id={`addfund-${id}-FundType`}
              name="FundType"
              value={values.FundType}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.FundType && errors.FundType}
            >
              <option key="" value="" />
              {this.leftOverFunds(client).map((fk) => (
                <option key={fk} value={fk}>
                  {mapFund(fk)}
                </option>
              ))}
            </Select>
            {!!errors.FundType && touched.FundType && <Error>{errors.FundType}</Error>}
          </Section>
        )}

        {this.renderFundForm()}

        {!values.AUHICAPS_Search &&
          <ButtonContainer>
            <LoadingButton
              style={{
                minWidth: 150
              }}
              loading={isSubmitting}
              variant="contained"
              color="secondary"
              type="submit"
            >
              Save
            </LoadingButton>
          </ButtonContainer>}
      </AccordianForm>
    );
  }

  render() {
    const { values, client, fund, closeNew } = this.props;

    return (
      <AccordianContainer>
        {!fund ? null : (
          <AccordianEdit>
            <ButtonHead>{mapFund(fund)}</ButtonHead>
            <Separator />
            {this.fundDetails(client, fund)}
            <OptionsContainer>
              {!values.IsOpen ?
                <DropdownSelect options={this.editOptions}>
                    <NoSpacingIconButton>
                      <Options />
                    </NoSpacingIconButton>
                </DropdownSelect> :
                <NoSpacingIconButton onClick={this.toggleOpen}>
                  <ClearIcon fill={colors.primary.main} />
                </NoSpacingIconButton>}
            </OptionsContainer>
          </AccordianEdit>
        )}
        {(values.IsOpen || !fund) && (
          <React.Fragment>
            {fund ? null :
              <NewHeader>
                <ButtonHead>New Fund</ButtonHead>
                <NoSpacingIconButton onClick={closeNew}>
                  <ClearIcon fill={colors.primary.main} />
                </NoSpacingIconButton>
              </NewHeader>}
            {this.renderContent()}
          </React.Fragment>
        )}
      </AccordianContainer>
    );
  }
}

const mcareIDCoefs = [1, 3, 7, 9, 1, 3, 7, 9];
export function validateMedicareNo(mcn) {
  if (!mcn) { return true; }
  if (!/^[0-9]{10}$/.test(mcn)) {
    return false;
  }
  let val = 0;
  for (let i = 0; i < 8; i++) {
    val += mcareIDCoefs[i] * parseInt(mcn[i]);
  }
  return (val%10) === parseInt(mcn[8]);
}

export function validateDVANo(mcn) {
  if (!mcn) { return true; }
  if (!/^[NVQWST][ 0-9A-Z][0-9A-Z]{2}[0-9]{4}[A-Z]?$/.test(mcn.toUpperCase())) {
    return false;
  }
  return true;
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ client, fund }) => ({
    IsOpen: !fund,
    FundType: !client ? "" : fund,
    AUMedicare_Number: (!client.FundProperties || !client.FundProperties.AUMedicare) ?
      "" : client.FundProperties.AUMedicare.Number||"",
    AUMedicare_Reference: (!client.FundProperties || !client.FundProperties.AUMedicare) ?
      "" : client.FundProperties.AUMedicare.Reference||"",
    AUDVA_Number: (!client.FundProperties || !client.FundProperties.AUDVA) ?
      "" : client.FundProperties.AUDVA.Number||"",
    AUDVA_Disability: (!client.FundProperties || !client.FundProperties.AUDVA) ?
      "" : client.FundProperties.AUDVA.Disability||"",
    AUHICAPS_Search: false,
    AUHICAPS_EmailPhoneName: client.Email || client.MobileNumber || `${client.FirstName} ${client.LastName}`,
    AUHICAPS_DateOfBirth: client.DateOfBirth || "",
    AUHICAPS_MemberID: (!client.FundProperties || !client.FundProperties.AUHICAPS) ?
      "" : client.FundProperties.AUHICAPS.MemberID||"",
    AUHICAPS_MobileNumber: (!client.FundProperties || !client.FundProperties.AUHICAPS) ?
      "" : client.FundProperties.AUHICAPS.MobileNumber||client.MobileNumber||"",
  }),
  validationSchema: Yup.lazy((v)=>{
    switch (v.FundType) {
    case "AUMedicare":
      return Yup.object().shape({
        AUMedicare_Number: Yup.string()
          .test("medicare", "Medicare number is invalid", validateMedicareNo)
          .required("Medicare number is required"),
        AUMedicare_Reference: Yup.number()
          .required("Medicare reference is required")
      });
    case "AUDVA":
      return Yup.object().shape({
        AUDVA_Number: Yup.string()
          .test("dva", "DVA number is invalid", validateDVANo)
          .required("DVA number is required"),
        AUDVA_Disability: Yup.string()
          .notRequired(),
      });
    case "AUHICAPS":
      if (v.AUHICAPS_Search) {
        return Yup.object().shape({
          AUHICAPS_EmailPhoneName: Yup.string()
            .required("Email, mobile, or first and last names is required"),
          AUHICAPS_DateOfBirth: Yup.string()
            .required("Date of birth is required")
        });
      } else {
        return Yup.object().shape({
          AUHICAPS_MemberID: Yup.string()
            .notRequired("Mobile number is required"),
          AUHICAPS_MobileNumber: Yup.string()
            .notRequired("Mobile number is required")
        });
      }
    default:
      return Yup.object().shape({
        FundType: Yup.string().required("Fund type is required")
      });
    }
  }),
  enableReinitialize: false,
  handleSubmit: async (values, { setSubmitting, setFieldError, setFieldValue, props }) => {
    if (values.AUHICAPS_Search) {
      try {
        const { client } = props;
        const query = {
          DateOfBirth: values.AUHICAPS_DateOfBirth
        };

        if (values.AUHICAPS_EmailPhoneName.includes("@")) {
          query.Email = values.AUHICAPS_EmailPhoneName;
        } else if (isPhoneNumber(values.AUHICAPS_EmailPhoneName)) {
          query.Mobile = values.AUHICAPS_EmailPhoneName;
        } else {
          const [firstName, lastName] = values.AUHICAPS_EmailPhoneName.split(" ");
          query.FirstName = firstName;
          query.LastName = lastName;
        }

        const res = await apiService.performRequest({
          method: "get",
          path: `/api/v1/orgs/${client.Org.ID}/mpsearch`,
          query
        });

        setFieldValue("AUHICAPS_MemberID", res.Payload.MemberID);
        setFieldValue("AUHICAPS_Search", false);
        await clientFundStore.load(client.ID);
      } catch (err) {
        parseFormError(err, values, setFieldError, props);
      }
    } else {
      const { dispatch, client } = props;
      // Adding to funds.
      const fProps = {
        ...client.FundProperties
      };
      switch (values.FundType) {
        case "AUMedicare":
          fProps.AUMedicare = {
            Number: values.AUMedicare_Number.trim(),
            Reference: parseInt(values.AUMedicare_Reference),
          };
          break;
        case "AUDVA":
          fProps.AUDVA = {
            Number: values.AUDVA_Number.trim(),
            Disability: values.AUDVA_Disability.trim(),
          };
          break;
        case "AUHICAPS": {
          const mid = values.AUHICAPS_MemberID.trim();
          const mmn = values.AUHICAPS_MobileNumber;
          fProps.AUHICAPS = {
            MemberID: !mid ? null : mid,
            MobileNumber: !mmn ? null : mmn,
            MobileCountryCode: !mmn ? null : "AU",
          };
          break;
        }
      }
      try {
        const updClient = {
          ...client,
          Org: client.Org.ID,
          FundProperties: fProps
        };
        const res = await dispatch(
          ResourceActions.action(updClient, "Update", {})
        );

        switch (values.FundType) {
          case "AUMedicare":
            if (
              res.Payload.FirstName !== client.FirstName ||
              res.Payload.LastName !== client.LastName ||
              res.Payload.FundProperties.AUMedicare.Number !== fProps.AUMedicare.Number ||
              res.Payload.FundProperties.AUMedicare.Reference !== fProps.AUMedicare.Reference
            ) {
              props.toastManager.add(
                "Medicare validation successfull and some updated details for the client were supplied.",
                { appearance: "success" }
              );
            } else {
              props.toastManager.add(
                "Medicare validation successfull.",
                { appearance: "success" }
              );
            }
            break;
          case "AUDVA":
            if (
              res.Payload.FirstName !== client.FirstName ||
              res.Payload.LastName !== client.LastName ||
              res.Payload.FundProperties.AUDVA.Number !== fProps.AUDVA.Number ||
              res.Payload.FundProperties.AUDVA.Disability !== fProps.AUDVA.Disability
            ) {
              props.toastManager.add(
                "DVA validation successfull and some updated details for the client were supplied.",
                { appearance: "success" }
              );
            } else {
              props.toastManager.add(
                "DVA validation successfull.",
                { appearance: "success" }
              );
            }
            break;
          case "AUHICAPS":
            props.toastManager.add(
              "Added client HICAPS/Medipass details.",
              { appearance: "success" }
            );
            break;
        }

        !props.fund && props.closeNew();
        props.fund && setFieldValue("IsOpen", false);
        await clientFundStore.load(client.ID);
      } catch (err) {
        parseFormError(err, values, setFieldError, props);
      }
    }
    setSubmitting(false);
  },
  displayName: "Fund"
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(FundAccordian);

const IIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 30px;
  margin: 0;
  padding: 10px;
  opacity: 0.5;
`;
