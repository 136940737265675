import axios, { AxiosResponse } from 'axios';
import qs from "qs";
import { rpc } from './../grpc';
import { CreateTokenRequest, RedeemTokenRequest } from './../sdk/funds_pb';

export interface WebDoshCard {
  number: string;
  holder: string;
  cvc: string;
  expiry: string; // mm/yy e.g. 01/12
}

class WebDosh {
  async createTemporaryToken(card: WebDoshCard, endpoint: string, clientToken: string) {
    const ttres = await axios.request<TemporaryTokenResponse>({
      method: "post",
      url: `${endpoint}/post2/temporary_token/create`,
      data: qs.stringify({
        temporary_access: clientToken,
        card_number: card.number.replace(/\s/g, ""),
        card_holder: card.holder.trim(),
        card_verification_code: card.cvc.replace(/\s/g, ""),
        card_expiry_month: `${card.expiry.split('/')[0]}`.replace(/\s/g, ""), // must be 0 padded string (i think, webdosh don't have docs)
        card_expiry_year: `20${card.expiry.split('/')[1]}`.replace(/\s/g, ""),
        payment_method: getPaymentMethod(card.number),
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      timeout: 30000,
      responseType: "json"
    });
    return ttres.data;
  }
}

function getPaymentMethod(v: string): string {
  switch (v.replace(/\s/g, "").slice(0, 1)) {
    case "4":
      return "VISA";
    case "5":
      return "MASTERCARD";
    case "3":
      return "AMEX";
  }
  return "";
}

interface Expiry {
  month: number;
  year: number;
}

export function parseExpiry(expiry: string): Expiry {
  const [month, year] = expiry.split('/');
  return {
    month: parseInt(month, 10),
    year: parseInt('20' + year, 10)
  }
}

interface TemporaryTokenResponse {
  temporary_token: string;
}

export const webDosh = new WebDosh();
