import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";
import { withRouter } from "react-router";
import moment from "moment";

// Redux
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "./../../../util/form";

// Components
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import Header, {HeaderSubtitle} from "./../../../components/form/Header";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import LoadingButton from "./../../../components/LoadingButton";
import Button from "./../../../components/Button";
import Select from "./../../../components/form/BaseSelect";

import * as GlobalActions from "./../../../redux/features/global/actions";

import { getYearFromTwo } from "./../../../util";
import { getCardToken, getCardType } from "./../../../util/ccard";

class OrgCreateAccount_Subscription extends Component {
  componentWillUnmount() {
    this.props.setData({ ...this.props.values });
  }
  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;

    const handleGoBack = (e)=>{
      e.preventDefault();
     this.props.setPage("desc")
    }

    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <Header
          title="Subscription"
        />
        <HeaderSubtitle>
              Please choose the subscription you require for your business. The first month is free.
              <br/>
              The subscription fee will not be charged until {moment().add(60, 'days').format('dddd, [the] Do of MMMM YYYY')}
        </HeaderSubtitle>
        <SectionsContainer>
          <Section>
            <Select
              id="createorg-sub"
              name="Subscription"
              type="select"
              placeholder="Subscription"
              autoFocus={true}
              value={values.Subscription}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Subscription && errors.Subscription}
            >    
              <option value="Starter-Kit">Promo Pack | $0/mo. | 3 Providers | 2.4% Fee</option>           
              <option value="Solo">Solo | $19/mo. | 1 Provider | 2.0% Fee</option>
              <option value="Team">Team | $32/mo. | 5 Providers | 1.9% Fee</option>
              <option value="Medium">Medium | $64/mo. | 10 Providers | 1.75% Fee</option>
              <option value="Large">Large | $110/mo. | 15 Providers | 1.5% Fee</option>   
            </Select>
            {!!errors.Subscription && touched.Subscription && <Error>{errors.Subscription}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
        <Button
            style={{ width: 150, marginRight: "5px", backgroundColor: "rgba(44,46,60,0.12)", color:"rgba(0,0,0,0.38)"}}
            type="button"
            onClick={(handleGoBack)}
          >
            Go Back
          </Button>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={errors.Subscription && !pageData.Subscription}
          >
            Continue
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData }) => ({
    Subscription: pageData.Subscription || "Starter-Kit"
  }),
  validationSchema: Yup.object().shape({
    Subscription: Yup.string().required("Subscription is required.")
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    props.setPage("payment", {
        SubscriptionType: values.Subscription,
  });
    setSubmitting(false);
  },
  // handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
  //   try {
  //     const { pageData, dispatch } = props;

  //     const expVals = pageData.CreditCard_Expiry.split("/");
  //     const newCardToken = await getCardToken(
  //       pageData.CreditCard_Number,
  //       pageData.CreditCard_Name,
  //       pageData.CreditCard_CVC,
  //       parseInt(expVals[0].trim()),
  //       getYearFromTwo(parseInt(expVals[1].trim()))
  //     );

  //     // Create the organisation.
  //     const org = {
  //       $Metadata: { Type: "Organisation" },
  //       Name: pageData.Name.trim(),
  //       Description: pageData.Description.trim(),
  //       ContactEmail: pageData.ContactEmail.trim(),
  //       ContactPhone: pageData.ContactPhone.trim(),
  //       Address: pageData.AddressID,
  //       Logo: pageData.Logo,
  //       Banner: pageData.Banner,
  //       BankAccountDetails: {
  //         AccountName: pageData.BankDetailsAU_AccountName.trim(),
  //         BSB: pageData.BankDetailsAU_AccountBSB.trim(),
  //         AccountNumber: pageData.BankDetailsAU_AccountNumber.trim()
  //       },
  //       TaxDetails: {
  //         EntityName: pageData.TaxDetailsAU_EntityName.trim(),
  //         ABN: pageData.TaxDetailsAU_ABN.trim()
  //       },
  //       SubscriptionType: values.Subscription,
  //       Settings: {
  //         RequireBookingApproval: true,
  //         NotificationDaysBefore: 1,
  //         PenaltyFeeFixed: 0,
  //         PenaltyFeeVar: 1000,
  //         PenaltyTimePeriod: 48,
  //         ClientPaysCCFee: true,
  //         ThirdPartyTACs: ""
  //       },
  //       Integrations: {}
  //     };

  //     const orgCreate = await props.dispatch(ResourceActions.action(org, "Create", {}));

  //     const newCard = {
  //       $Metadata: { Type: "CreditCard" },
  //       OwnerType: "Organisation",
  //       Owner: orgCreate.Payload.ID,
  //       Token: newCardToken,
  //       Type: getCardType(pageData.CreditCard_Number),
  //       Last4: pageData.CreditCard_Number.slice(pageData.CreditCard_Number.length - 4),
  //       IsPrimary: true,
  //       ExpiryMonth: parseInt(expVals[0].trim()),
  //       ExpiryYear: getYearFromTwo(parseInt(expVals[1].trim())),
  //       Merchant: "WebDosh"
  //     };
  //     await dispatch(ResourceActions.action(newCard, "Create", {}));

  //     await props.dispatch(
  //       ResourceActions.listAction(
  //         { $Metadata: { List: "AuthUserOrgs", Context: {} } },
  //         false,
  //         "Fetch"
  //       )
  //     );

  //     dispatch(GlobalActions.setUserContext(orgCreate.Payload.ID, ""));
  //     props.toastManager.add("Organisation Created!", { appearance: "success" });
  //     props.setPage("fundpool", { orgCreatedID: orgCreate.Payload.ID });
  //   } catch (err) {
  //     parseFormError(err, values, setFieldError, props);
  //   }
  //   setSubmitting(false);
  // },
  displayName: "OrgCreateAccountSubscription"
});

export default compose(
  connect((state) => ({})),
  withRouter,
  withToastManager,
  formikEnhancer
)(OrgCreateAccount_Subscription);
