import styled from "@emotion/styled";

const Error = styled.div`
  margin: 0;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  color: #f00f51;
  margin-top: 6px;
  line-height: 12px;
`;

export default Error;
