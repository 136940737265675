import React, { Component } from "react";
import styled from "@emotion/styled";
import { format } from "date-fns";

import { breakpoints, hexToRgba, colors } from "./../../util/consts";
import typography from "./../../util/typography";

import Dialog from "./Dialog";
// import ButtonBase from "./../../components/ButtonBase";

class ConsumerAptDialog extends Component {
  render() {
    const { booking } = this.props.data;

    const StartDateTime = new Date(booking.StartDate + " " + booking.StartTime);
    const offerings =
      booking.Type === "Single" ? booking.Clients[0].Offerings : booking.GroupOfferings;

    const FullProvider =
      booking.Location &&
      booking.Location.Providers &&
      booking.Location.Providers.find((p) => p.User && p.User.ID === booking.Provider.ID);

    return (
      <Dialog dialogId={this.props.dialogId}>
        <Container>
          <Content>
            <Header>
              <HeaderTitle>{format(StartDateTime, "EEEE 'at' hh:mmaaaaa")}m</HeaderTitle>
              <HeaderSubtitle style={{ marginBottom: 4 }}>
                {format(StartDateTime, "dd MMMM yyyy")}
              </HeaderSubtitle>
              <HeaderSubSubtitle style={booking.Status === "Cancelled" ? { color: "#f00f51" } : {}}>
                {booking.Status}
              </HeaderSubSubtitle>
            </Header>
            <Section>
              <div>
                <SectionHeading1>{booking.Location.Name}</SectionHeading1>
                <SectionBody>{booking.Location.Address.Raw}</SectionBody>
                <SectionBody>{booking.Location.Phone}</SectionBody>
              </div>
            </Section>
            <Section>
              <div>
                <SectionHeading2>
                  {booking.Provider.FirstName} {booking.Provider.LastName}
                  {booking.Type === "Group" && " - Group Booking"}
                </SectionHeading2>
                <SectionBody>
                  {FullProvider &&
                    FullProvider.ProviderCategories &&
                    FullProvider.ProviderCategories.map((c, i) => (i > 0 ? ", " : "") + c.Value)}
                </SectionBody>
              </div>
            </Section>
            <Section>
              {(offerings || []).map((o, i) => (
                <SectionSection key={i}>
                  <div>
                    <SectionHeading2>{o.Offering.Name}</SectionHeading2>
                    <SectionBody>{o.Offering.ServiceDuration} mins</SectionBody>
                  </div>
                  {booking.Status !== "Created" && booking.Status !== "Cancelled" && (
                    <SectionHeading2 style={{ opacity: 0.6 }}>
                      ${(o.Offering.Fee / 100).toFixed(2)}
                    </SectionHeading2>
                  )}
                </SectionSection>
              ))}
            </Section>
            {booking.Status !== "Created" && booking.Status !== "Cancelled" && (
              <>
                <Section>
                  <SectionSection>
                    <div>
                      <SectionHeading2>Subtotal</SectionHeading2>
                    </div>
                    <SectionHeading2>${(booking.SubtotalCharge / 100).toFixed(2)}</SectionHeading2>
                  </SectionSection>
                  <SectionSection>
                    <div>
                      <SectionHeading2>GST (incl)</SectionHeading2>
                    </div>
                    <SectionHeading2>${(booking.SubtotalTax / 100).toFixed(2)}</SectionHeading2>
                  </SectionSection>

                  <SectionSection>
                    <div>
                      <SectionHeading2>Transaction Fee (1.20%)</SectionHeading2>
                    </div>
                    <SectionHeading2>
                      ${(booking.LyfeCreditCardFee / 100).toFixed(2)}
                    </SectionHeading2>
                  </SectionSection>

                  <SectionSection>
                    <div>
                      <SectionHeading2 style={{ fontWeight: 600 }}>Total</SectionHeading2>
                    </div>
                    <SectionHeading2 style={{ fontWeight: 600 }}>
                      ${(booking.TotalCharge / 100).toFixed(2)}
                    </SectionHeading2>
                  </SectionSection>
                </Section>
              </>
            )}
            <Section>
              <div>
                <SectionHeading2>Cancelation policy</SectionHeading2>
                <SectionBody>
                  Please note cancellations within 24 hours of the appointment may incur a
                  cancellation fee.
                </SectionBody>
              </div>
            </Section>
          </Content>
        </Container>
        {/* <FixedContainer>
          {booking.Status === "Created" || booking.Status === "Cancelled" ? (
            <>
              <FixedLeftButton>
                <FixedButtonLabel>Cancel</FixedButtonLabel>
              </FixedLeftButton>
              <FixedRightButton>
                <FixedButtonLabel>Reschedule</FixedButtonLabel>
              </FixedRightButton>
            </>
          ) : (
            <FixedLeftButton>
              <FixedButtonLabel>Rebook</FixedButtonLabel>
            </FixedLeftButton>
          )}
        </FixedContainer> */}
      </Dialog>
    );
  }
}

export default ConsumerAptDialog;

const Container = styled.div`
  position: relative;
  background-color: #fff;
  width: 100vw;
  max-width: 600px;
  padding: 24px;
  margin-bottom: 64px;

  ${breakpoints["tablet-up"]} {
    padding: 60px;
  }
`;

// const FixedContainer = styled.div`
//   position: absolute;
//   bottom: 0;
//   height: 64px;
//   left: 0;
//   width: 100%;
// `;

// const FixedLeftButton = styled(ButtonBase)`
//   background-color: ${colors.primary.main};
//   height: 100%;
//   width: 50%;
// `;

// const FixedRightButton = styled(ButtonBase)`
//   background-color: ${colors.secondary.main};
//   height: 100%;
//   width: 50%;
// `;

// const FixedButtonLabel = styled.span`
//   color: ${colors.whiteText.highEmphasis};
//   font-size: 17.82px;
//   font-weight: 600;
//   letter-spacing: 1.62px;
//   line-height: 24px;
//   text-align: center;
// `;

const Content = styled.div``;

const Header = styled.div`
  margin-bottom: 42px;
`;

const HeaderTitle = styled.div`
  ${typography.heading5};
  font-weight: 600;
`;

const HeaderSubtitle = styled.div`
  ${typography.body2};
  font-weight: 600;
  letter-spacing: 0.09px;
  color: ${colors.surfaceText.medEmphasis};
`;

const HeaderSubSubtitle = styled.div`
  ${typography.caption};
  color: ${colors.secondary.main};
`;

const Section = styled.div`
  padding-bottom: 18px;
  border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
  margin: 16px 0;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SectionSection = styled.div`
  display: flex;
  align-itmes: center;
  justify-content: space-between;

  margin: 12px 0;

  &first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SectionHeading1 = styled.div`
  ${typography.subtitle1};
  font-weight: 600;
  letter-spacing: 0.29px;
  line-height: 20px;
`;

const SectionHeading2 = styled.div`
  ${typography.subtitle2};
  letter-spacing: 0.25px;
  line-height: 20px;
`;

const SectionBody = styled.div`
  ${typography.body2};
  letter-spacing: 0.21px;
  line-height: 20px;
  color: ${hexToRgba(colors.primary.main, 0.6)};
`;
