import React, { Component } from "react";
import styled from "@emotion/styled";

import typography from "./../../util/typography";
import { colors } from "./../../util/consts";

import icon404 from "./../../static/404icon.svg";

export default class InternalError extends Component {
  render() {
    const { errorID } = this.props;
    return (
      <Container>
        <Info>
          <Heading>Oops!</Heading>
          <SubHeading>We're sorry, a system error has been encountered.</SubHeading>
          <Code>Error code: 500</Code>
          <Code>Error ID: {errorID}</Code>
          <Desc>Please try again later or contact Booklyfe support.</Desc>
        </Info>
        <Image src={icon404} />
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 96px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 899px) {
    flex: 1 0 300px;
    padding: 0 30px;
  }
  @media (min-width: 900px) {
    max-width: 430px;
  }
`;

const Heading = styled.h1`
  ${typography.heading2};
  color: ${colors.primary.main};
`;

const SubHeading = styled.h2`
  ${typography.heading5};
  color: ${colors.primary.main};
  margin-top: 17px;
`;

const Code = styled.h2`
  ${typography.subtitle1};
  color: ${colors.primary.main};
  opacity: 0.6;
  margin-top: 17px;
`;

const Desc = styled.h2`
  ${typography.subtitle2};
  color: ${colors.primary.main};
  opacity: 0.6;
  margin-top: 17px;
`;

const Image = styled.img`
  flex: 0 1 368px;

  @media (max-width: 899px) {
    margin: 40px 20px;
  }
  @media (min-width: 900px) {
    margin-left: 102px;
  }
`;
