import React, { useEffect } from 'react';
import { ToastManager, withToastManager } from 'react-toast-notifications'
import { toastStore } from './stores/toast-store';

interface Props {
  toastManager: ToastManager;
}

// This is a dummy react component that will
// link the current toast provider into a global
// mobx store so that it's accessible outside the
// react component tree
class ToastConnectorImpl extends React.Component<Props> {

  componentDidMount() {
    toastStore.setProvider(this.props.toastManager)
  }

  componentDidUpdate() {
    toastStore.setProvider(this.props.toastManager)
  }

  componentWillUnmount() {
    toastStore.setProvider(undefined);
  }

  render() {
    return null;
  }
}

export const ToastConnector = withToastManager(ToastConnectorImpl);
