import React, { Component } from "react";
import styled from "@emotion/styled";

import typography from "./../../util/typography";
import NewTab from "../../components/icons/NewTab"

export default class Tutorials extends Component {
  render() {
    return (
      <Container>
        <Header>
          <Title>Lyfe Tutorials</Title>
        </Header>
        <Content>
        <Body>
            <Subtitle
              target="_blank"
              href="https://video.drift.com/v/abi6HDsffq7/"
            >
              <strong> Physitrack Integration</strong> <NewTab/>
            </Subtitle>
            <Vimeo src="https://player.vimeo.com/video/424014178" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></Vimeo>

          </Body>
          <Body>
            <Subtitle
              target="_blank"
              href="https://video.drift.com/v/abi6HDsffq7/"
            >
              <strong>Setup Service &amp; Provider</strong> <NewTab/>
            </Subtitle>
            <IFrame src="https://video.drift.com/v/abi6HDsffq7/"></IFrame>

          </Body>
          <Body>
            <Subtitle
              target="_blank"
              href="https://video.drift.com/v/abK3Aibnjq7/"
            >
              <strong>Telehealth</strong><NewTab/>
            </Subtitle>
            <IFrame src="https://video.drift.com/v/abK3Aibnjq7/"></IFrame>
          </Body>
          <Body>
            <Subtitle
              target="_blank"
              href="https://video.drift.com/v/ab4qGrg2jq7/"
            >
              <strong>Settings</strong><NewTab/>
            </Subtitle>
            <IFrame src="https://video.drift.com/v/ab4qGrg2jq7/"></IFrame>
          </Body>
          <Body>
            <Subtitle
              target="_blank"
              href="https://video.drift.com/v/abys3yjJjq7/"
            >
              <strong>Create a booking and process payment</strong><NewTab/>
            </Subtitle>
            <IFrame src="https://video.drift.com/v/abys3yjJjq7/"></IFrame>
          </Body>
          <Body>
            <Subtitle
              target="_blank"
              href="https://video.drift.com/v/abUbE5qIjq7/"
            >
              <strong>Client Notes and Settings</strong><NewTab/>
            </Subtitle>
            <IFrame src="https://video.drift.com/v/abUbE5qIjq7/"></IFrame>
          </Body>
          <Body>
            <Subtitle
              target="_blank"
              href="https://video.drift.com/v/abbiRjV9jq7/"
            >
              <strong>Business Insights and Reporting</strong><NewTab/>
            </Subtitle>
            <IFrame src="https://video.drift.com/v/abbiRjV9jq7/"></IFrame>
          </Body>
        </Content>
      </Container>
    );
  }
}

const Container = styled.div`
  max-width: 826px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Header = styled.div`
  padding: 50px 0;
`;

const Subtitle = styled.a`
text-decoration-color: black;
color: black;
`
const IFrame = styled.iframe`
height: 200px;
width: 80%;
@media (min-width: 700px) {
  width: 100%;
}
`

const Vimeo = styled.iframe`
width: 100%;
height: 180px;
border: none;

@media (min-width: 700px) {
  width: 100%;
  height: 450px;
  border: none;
}
`

const Content = styled.div`
  padding: 30px 0;
`;

const Title = styled.h2`
  ${typography.heading3};
`;

const Body = styled.p`
  ${typography.body1};
  margin-bottom: 24px;
`;
