import React, { Component } from "react";
import styled from "@emotion/styled";
import { colors } from "./../../util/consts";

import LoadingIcon from "./../../components/icons/Loading";

export default class LoadingPage extends Component {
  render() {
    return (
      <Container>
        <LoadingIcon
          width={48}
          height={48}
          color={colors.secondary.main}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 96px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
