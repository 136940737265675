const onlyNumbers = /^[0-9]*$/.compile();

export function testCreditCard(value: string): boolean {
  if (typeof(value) !== 'string') {
    return false;
  }
  return onlyNumbers.test(value);
}

export function testCVC(value: string): boolean {
  if (typeof(value) !== 'string') {
    return false;
  }
  return onlyNumbers.test(value) && value.length === 3;
}

// matches 01/12 i.e. mm/yy
const cardExpiry = /^\d\d\/\d\d$/.compile()

export function testCardExpiry(value: string): boolean {
  if (typeof(value) !== 'string') {
    return false;
  }
  return cardExpiry.test(value);
}
