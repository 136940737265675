import React, { Component } from "react";
import styled from "@emotion/styled";
import Downshift from "downshift";

import typography from "./../../util/typography";
import { colors, hexToRgba, breakpoints } from "./../../util/consts";

// We assume the items do not change.
export default class AutoCompleteDropdown extends Component {
  inputRef = React.createRef();

  constructor(props) {
    super(props);
    this.lastItems = null;
  }
  handleDownshiftSelect = (selection, downshift) => {
    const { onSelect } = this.props;
    if (selection !== null) {
      onSelect(selection);
      downshift.clearSelection();
      this.inputRef.current && this.inputRef.current.blur();
    }
  };
  render() {
    const { placeholder, items, filter, icon, format } = this.props;
    if (this.lastItems !== items) {
      this.lowerValues = items.map((it) => it.Value.toLowerCase());
    }

    return (
      <Downshift
        onSelect={this.handleDownshiftSelect}
        itemToString={(item) => (item ? item.Value : "")}
      >
        {({
          clearSelection,
          getInputProps,
          getItemProps,
          getRootProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          openMenu
        }) => {
          const lowerInputValue = inputValue.toLowerCase();

          return (
            <Container isOpen={isOpen} {...getRootProps()}>
              <Input
                ref={this.inputRef}
                placeholder={placeholder}
                {...getInputProps()}
                onFocus={() => openMenu()}
              />
              {isOpen && (
                <DropContainer {...getMenuProps()}>
                  {items
                    .filter(
                      (item, i) =>
                        (!inputValue || this.lowerValues[i].includes(lowerInputValue)) &&
                        filter(item)
                    )
                    .map((item, i) => (
                      <DropItem
                        key={item.ID}
                        highlighted={highlightedIndex === i}
                        {...getItemProps({ i, item })}
                      >
                        {!icon ? null : <DropItemIcon src={icon(item)} />}
                        <DropItemText>{!format ? item.Value : format(item)}</DropItemText>
                      </DropItem>
                    ))}
                </DropContainer>
              )}
            </Container>
          );
        }}
      </Downshift>
    );
  }
}

const Container = styled.div`
  position: relative;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 450px;
  border: 1px solid ${colors.primary[100]};
  border-radius: 4px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  height: 44px;
  display: flex;
  &:focus-within {
    box-shadow: 0 2px 4px 0 rgba(44, 46, 60, 0.15);
  }
  ${breakpoints["laptop-up"]} {
    flex-grow: 0;
  }
  ${({ isOpen }) => (!isOpen ? "" : "border-radius: 4px 4px 0px 0px")};
`;

const Input = styled.input`
  letter-spacing: 0.15px;
  text-align: left;
  width: 100%;
  padding: 10px 16px;
  color: ${colors.surfaceText.highEmphasis};
  outline: none;
  font-size: 12px;
  line-height: 22px;
  border: none;
  border-radius: 4px;
  flex: 0 1 auto;

  &::placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::placeholder-shown {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-webkit-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-ms-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }
`;

const DropContainer = styled.ul`
  position: absolute;
  top: calc(100% + 1px);
  left: -1px;
  max-height: 260px;
  overflow-y: scroll;
  width: calc(100% + 2px);
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border-left: 1px solid ${colors.primary[100]};
  border-right: 1px solid ${colors.primary[100]};
  border-bottom: 1px solid ${colors.primary[100]};
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0 2px 4px 0 rgba(44, 46, 60, 0.15);
  z-index: 5;
`;

const DropItem = styled.li`
  height: 40px;
  padding: 14px;
  align-items: center;
  background-color: ${({ highlighted }) =>
    (!highlighted ? "none" : hexToRgba(colors.primary.main, 0.05))};
  display: flex;
  cursor: pointer;
`;

const DropItemIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 20px;
`;

const DropItemText = styled.span`
  ${typography.overline};
  letter-spacing: inherit;
`;
