/** @jsx jsx */
import { memo } from "react";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

// Utils
import { colors } from "./../../../util/consts";
import typography from "./../../../util/typography";

// Components
import Button from "./../../../components/Button";
import LocationCityIcon from "./../../../components/icons/LocationCity";

const Card = memo(({ teamSize, price, numProviders, transactionFee, onClick }) => (
  <Container>
    <Content>
      <TeamSize>{teamSize}</TeamSize>
      <div style={{ display: "flex" }}>
        <DollarSign css={{ display: "flex" }}>$</DollarSign>
        <Price>{price}</Price>
        <PerMonth css={{ display: "flex", alignItems: "flex-end" }}>/mo.</PerMonth>
      </div>
      <span css={{ display: "flex", flexDirection: "column" }}>
        <Text>
          <strong>{numProviders}</strong> Provider
          {numProviders !== "1" && "s"}
        </Text>
        <Text>
          <strong>{transactionFee}%</strong> Transactional fee
        </Text>
      </span>
      <Button
        onClick={onClick}
        variant="contained"
        css={{ padding: "17px", width: "100%", borderRadius: 25 }}
      >
        Get Started
      </Button>
    </Content>
  </Container>
));

export default Card;

export const InverseCard = memo(({ onClick }) => (
  <Container>
    <Content css={{ backgroundColor: colors.secondary.main }}>
      <TeamSize css={{ color: colors.whiteText.highEmphasis }}>Enterprise</TeamSize>
      <div style={{ display: "flex" }}>
        <LocationCityIcon width={89} height={89} fill={colors.surface.light} />
      </div>
      <span css={{ display: "flex", flexDirection: "column" }}>
        <Text css={{ color: colors.whiteText.highEmphasis }}>
          <strong>15+</strong> Providers
        </Text>
        <Text css={{ color: colors.whiteText.highEmphasis }}>White label apps</Text>
      </span>
      <Button
        onClick={onClick}
        variant="inverted"
        color="secondary"
        css={{ padding: "17px", width: "100%", borderRadius: 25 }}
      >
        Schedule Call
      </Button>
    </Content>
  </Container>
));

const Container = styled.div`
  height: 450px;
  flex: 1;
  padding: 6px;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 33px 20px;
  border-radius: 4px;
  background-color: ${({ inverse }) => (inverse ? colors.secondary.main : colors.surface.light)};
  box-shadow: 0 2px 10px 0 rgba(44, 46, 60, 0.05);
`;

const TeamSize = styled.span`
  ${typography.heading6};
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 27px;
  text-transform: uppercase;
`;

const DollarSign = styled.span`
  font-size: 29.8px;
  letter-spacing: 0.5px;
  line-height: 41px;
  color: ${colors.surfaceText.lowEmphasis};
`;

const Price = styled.span`
  font-size: 64px;
  letter-spacing: -.05em;
  line-height: 1em;
  color: ${colors.primary.main};
  margin-right: .5rem
`;

const PerMonth = styled.span`
  font-size: 15.8px;
  letter-spacing: 0.5px;
  line-height: 41px;
  color: ${colors.surfaceText.lowEmphasis};
`;

const Text = styled.span`
  ${typography.subtitle1};
  letter-spacing: 0.5px;
  line-height: 22px;
  text-align: center;
`;
