import { CSSProperties } from 'react';

export enum Gutter {
  none,
  small,
  medium,
  large,
}

export interface FlexProps {
  children?: React.ReactNode;
  id?: React.HTMLAttributes<HTMLElement>['id'];
  style?: React.HTMLAttributes<HTMLElement>['style'];
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  flexBasis?: CSSProperties['flexBasis'];
  flexGrow?: CSSProperties['flexGrow'];
  flexShrink?:  CSSProperties['flexShrink'];
  flexDirection?: CSSProperties['flexDirection'];
  flexWrap?: CSSProperties['flexWrap'];
  gutter?: Gutter;
  ref?: React.RefObject<any>;
}
