import React, { Component } from "react";
import { createForm } from "../../../../forms/forms";
import {
  ApiToken,
  AuthApplication,
  ApplicationType,
  NewApiTokenRequest
} from "../../../../sdk/applications_and_tokens_pb";
import * as Yup from "yup";
import { observer } from "mobx-react";
import { observable } from "mobx";
import Error from "../../../form/Error";
import LoadingIcon from "../../../icons/Loading";
import { DetailInput } from "../../ClientActionDialog/Details";
import { LoadingIndicator } from "../../../../util/loading";
import styled from "styled-components";
import { breakpoints, colors, hexToRgba } from "../../../../util/consts";
import { Select } from "../../../../components/form/Select";
import { ApiTypeToInternal } from "./IntegratedApps";
import Label from "../../../../components/form/Label";
import { Separator } from "../../../../components/elements/AccordianElements";
import ButtonBase from "../../../../components/ButtonBase";
import { rpc, metadata } from '../../../../grpc';
import { toastStore } from '../../../../stores/toast-store';
import LoadingButton from "./../../../../components/LoadingButton";

interface Props {
  orgId: string;
  userId: string;
  authApplications: Array<AuthApplication.AsObject>;
  handleEditClick: ()=>void;
  listApiTokens: Array<ApiToken.AsObject>;
}

const schema = Yup.object<ApiToken.AsObject>({
  type: Yup.mixed().notOneOf([ApplicationType.UNKNOWN],"Select one option"),
  orgId: Yup.string(),
  userId: Yup.string(),
  isActive: Yup.boolean(),
  token: Yup.string()
});

interface SelectOptions {
  label: string;
  value: number;
}

@observer
export class NewApp extends Component<Props> {
  @observable
  private indicator = new LoadingIndicator();
  @observable
  private selectedAppType = ApplicationType.UNKNOWN;

  onSubmit = async (fields: ApiToken.AsObject) => {
    const { orgId, userId, handleEditClick, listApiTokens } = this.props;
    await this.indicator.while(async () => {
      try{
        const req = new NewApiTokenRequest();
        req.setOrgId(orgId);
        req.setUserId(userId);
        req.setType(this.selectedAppType);
        if(fields.token){
          req.setToken(fields.token);
        }
      
        const res = await rpc.apiTokenService.newToken(req, metadata())
        handleEditClick();
        listApiTokens.push(res.toObject());
        toastStore.success("Token saved/generated successfully");
      } catch (err){
        toastStore.grpcToastError(err);
        handleEditClick();
      }
      });
  };

  render() {
    const { authApplications, orgId, userId } = this.props;
    const Form = createForm<ApiToken.AsObject>();

    // Create first select option
    const authAppOptions = Array<SelectOptions>();

    authAppOptions.push({
      label: "--select one--",
      value: ApplicationType.UNKNOWN
    });

    // Add authorized app options
    authApplications.map((app) =>
      authAppOptions.push({
        label: ApiTypeToInternal(app.type),
        value: app.type
      })
    );

    return (
      <>
        <Label>Add new Application</Label>
        <Form
          schema={schema}
          onSubmit={this.onSubmit}
          component={FormStyle}
          initial={new ApiToken().toObject()}
        >
          {({ fields, updateField, errors }) => (
            <>
            <div>
              <Select
                value={this.selectedAppType}
                values={authAppOptions}
                onChange={(option) => {
                  this.selectedAppType = option.value;
                  updateField({ type: this.selectedAppType });
                  updateField({ orgId: orgId });
                  updateField({ userId: userId });
                }}
                style={{ height: "30px", padding: "0", fontSize: "12.8px", width: "120px" }}
              ></Select>
              { !!errors.type && <Error>{errors.type}</Error> }
            </div>
              <Separator style={{ display: "flex", height: "10px" }} />
              <DetailInput
                id={`new-AppType`}
                name="AppType"
                type="text"
                placeholder="Paste a token or generate one"
                onChange={(e: any) => { updateField({ token: e.currentTarget.value }); }}
                regularText
                style={{ height: "30px", fontSize: "12.8px" }}
              />
              <Separator style={{ display: "flex", height: "10px" }}/>
              <LoadingButton
                loading={this.indicator.isLoading()}
                disabled={this.indicator.isLoading()}
                style={{ width: "200px", height: "30px", padding: 0 }}
                variant="contained"
                color="secondary"
                type="submit"
              >
                {this.indicator.isLoading() ? (
                    <LoadingIcon width={16} height={16} color="#FFF" />
                  ) : fields.token ? (
                    "Save"
                  ) : (
                    "Generate"
                  )}
              </LoadingButton>
            </>
          )}
        </Form>
        <br/><a href={'/physitrack'} style={{fontSize:'10px'}}>For more information visit the Physitrack documentation by clicking here</a>

      </>
    );
  }
}

const FormStyle = styled.form`
  display: flex;
  align-items: flex-left;
  flex-direction: ${() => {
    if (window.innerWidth > 800) {
      return "row";
    } else {
      return "column";
    }
  }};

  width: 100%;
  min-height: 100%;

  ${breakpoints["phone-only"]} {
    justify-content: space-evenly;
  }
`;

const FormButton = styled(ButtonBase)`
  height: 100%;
  background-color: ${({ isPrimary }) => (isPrimary ? colors.primary.main : colors.secondary.main)};
  transition: opacity 0.2s ease;

  &:hover:enabled {
    opacity: 0.95;
  }

  &:focus {
    opacity: 0.95;
  }

  &:disabled {
    background-color: ${hexToRgba(colors.primary.main, 0.12)};
  }
`;
