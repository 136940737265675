import * as grpcWeb from "grpc-web";

import { MediaServicePromiseClient } from "./sdk/media_grpc_web_pb";
import {
  BookingsPromiseClient,
  BookingClientsPromiseClient,
  BookingOfferingsPromiseClient,
  ClientOfferingsPromiseClient,
  LineItemsPromiseClient
} from "./sdk/bookings_grpc_web_pb";
import {
  ClientHealthFundsPromiseClient,
  UserHealthFundsPromiseClient,
  ClientCreditCardsPromiseClient
} from "./sdk/funds_grpc_web_pb";
import { OfferingsPromiseClient } from "./sdk/offerings_grpc_web_pb";
import { ThirdPartyInvoicesPromiseClient } from "./sdk/third_party_invoices_grpc_web_pb";
import { NotesPromiseClient } from "./sdk/notes_grpc_web_pb";
import { AccountItemsPromiseClient } from "./sdk/account_items_grpc_web_pb";
import { HistoryThirdPartiesPromiseClient } from "./sdk/history_third_parties_grpc_web_pb";
import { InsightMetricsServicePromiseClient } from "./sdk/insight_metrics_grpc_web_pb";
import { InsightFilterOptionsServicePromiseClient } from "./sdk/insight_filter_options_grpc_web_pb";
import { RevenueTableServicePromiseClient } from "./sdk/revenue_table_grpc_web_pb";
import { InsightRevenueServicePromiseClient } from "./sdk/insight_revenue_grpc_web_pb";
import { InsightClientDataServicePromiseClient } from "./sdk/insight_client_grpc_web_pb";
import { ClientTableServicePromiseClient } from "./sdk/client_table_grpc_web_pb";
import { InsightReferralServicePromiseClient } from "./sdk/insight_referral_grpc_web_pb";
import { ReferralTableServicePromiseClient } from "./sdk/referral_table_grpc_web_pb";
import { TagServicePromiseClient } from "./sdk/tag_grpc_web_pb";
import { NoteTemplateServicePromiseClient } from "./sdk/note_template_grpc_web_pb";
import { ClientServicePromiseClient } from './sdk/clients_grpc_web_pb';
import { ThirdPartyInvoiceTemplateServicePromiseClient } from './sdk/third_party_invoice_templates_grpc_web_pb';
import { AccountTableServicePromiseClient } from './sdk/accounts_table_grpc_web_pb';
import { AffiliateServicePromiseClient } from './sdk/affiliate_code_grpc_web_pb';
import { ApiTokensPromiseClient, AuthApplicationsPromiseClient } from './sdk/applications_and_tokens_grpc_web_pb';
import { PhysitrackApiV1PromiseClient, PhysitrackApiV2PromiseClient } from './sdk/physitrack_grpc_web_pb';
import { UserServicePromiseClient } from './sdk/user_grpc_web_pb';
import { HubspotApiV1PromiseClient} from './sdk/hubspot_grpc_web_pb';
import { UserInviteServicePromiseClient} from './sdk/user_invite_grpc_web_pb';
import { CurrenciesServiceClient, CurrenciesServicePromiseClient } from './sdk/currencies_grpc_web_pb';

const server = `${window.location.origin}/api`;

export const rpc = {
  bookings: new BookingsPromiseClient(server),
  bookingClients: new BookingClientsPromiseClient(server),
  bookingOfferings: new BookingOfferingsPromiseClient(server),
  clientOfferings: new ClientOfferingsPromiseClient(server),
  clientFunds: new ClientHealthFundsPromiseClient(server),
  userFunds: new UserHealthFundsPromiseClient(server),
  clientCards: new ClientCreditCardsPromiseClient(server),
  historyThirdParties: new HistoryThirdPartiesPromiseClient(server),
  media: new MediaServicePromiseClient(server),
  offerings: new OfferingsPromiseClient(server),
  lineItems: new LineItemsPromiseClient(server),
  thirdPartyInvoices: new ThirdPartyInvoicesPromiseClient(server),
  notes: new NotesPromiseClient(server),
  accountItems: new AccountItemsPromiseClient(server),
  insightMetrics: new InsightMetricsServicePromiseClient(server),
  insightFilterOptions: new InsightFilterOptionsServicePromiseClient(server),
  revenueTable: new RevenueTableServicePromiseClient(server),
  insightRevenue: new InsightRevenueServicePromiseClient(server),
  insightClient: new InsightClientDataServicePromiseClient(server),
  clientTable: new ClientTableServicePromiseClient(server),
  insightReferral: new InsightReferralServicePromiseClient(server),
  referralTable: new ReferralTableServicePromiseClient(server),
  freeFormTag: new TagServicePromiseClient(server),
  noteTemplate: new NoteTemplateServicePromiseClient(server),
  thirdPartyInvoiceTemplate: new ThirdPartyInvoiceTemplateServicePromiseClient(server),
  clientService: new ClientServicePromiseClient(server),
  accountTable: new AccountTableServicePromiseClient(server),
  affiliateService: new AffiliateServicePromiseClient(server),
  authApplicationService: new AuthApplicationsPromiseClient(server),
  apiTokenService: new ApiTokensPromiseClient(server),
  physitrackV1: new PhysitrackApiV1PromiseClient(server),
  physitrackV2: new PhysitrackApiV2PromiseClient(server),
  user: new UserServicePromiseClient(server),
  hubspotV1: new HubspotApiV1PromiseClient(server),
  userInvite: new UserInviteServicePromiseClient(server),
  currencies: new CurrenciesServicePromiseClient(server),
};

// https://github.com/SafetyCulture/grpc-web-devtools
const devtools = (window as any).__GRPCWEB_DEVTOOLS__;
if (devtools) {
  devtools(Object.values(rpc));
}

export function metadata(): grpcWeb.Metadata {
  try {
    const store = JSON.parse(localStorage.getItem("persist:root")!);
    const auth = JSON.parse(store.auth);
    const authorization = auth.Token;
    return { authorization };
  } catch (error) {
    console.error("failed to create rpc metadata. using empty metadata instead", error);
    return {};
  }
}
