// source: third_party_invoices.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var money_pb = require('./money_pb.js');
goog.object.extend(proto, money_pb);
var account_items_pb = require('./account_items_pb.js');
goog.object.extend(proto, account_items_pb);
var media_pb = require('./media_pb.js');
goog.object.extend(proto, media_pb);
goog.exportSymbol('proto.proto.CreateThirdPartyInvoiceRequest', null, global);
goog.exportSymbol('proto.proto.DeleteThirdPartyInvoiceRequest', null, global);
goog.exportSymbol('proto.proto.ListThirdPartyInvoiceRequest', null, global);
goog.exportSymbol('proto.proto.ListThirdPartyInvoiceResponse', null, global);
goog.exportSymbol('proto.proto.SetPaidInvoiceRequest', null, global);
goog.exportSymbol('proto.proto.TaxType', null, global);
goog.exportSymbol('proto.proto.ThirdPartyInvoice', null, global);
goog.exportSymbol('proto.proto.ThirdPartyInvoice.Status', null, global);
goog.exportSymbol('proto.proto.ThirdPartyInvoiceDetails', null, global);
goog.exportSymbol('proto.proto.ThirdPartyInvoiceLineItem', null, global);
goog.exportSymbol('proto.proto.UpdateThirdPartyInvoiceRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ThirdPartyInvoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ThirdPartyInvoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ThirdPartyInvoice.displayName = 'proto.proto.ThirdPartyInvoice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ThirdPartyInvoiceDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ThirdPartyInvoiceDetails.repeatedFields_, null);
};
goog.inherits(proto.proto.ThirdPartyInvoiceDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ThirdPartyInvoiceDetails.displayName = 'proto.proto.ThirdPartyInvoiceDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ThirdPartyInvoiceLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ThirdPartyInvoiceLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ThirdPartyInvoiceLineItem.displayName = 'proto.proto.ThirdPartyInvoiceLineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListThirdPartyInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListThirdPartyInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListThirdPartyInvoiceRequest.displayName = 'proto.proto.ListThirdPartyInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListThirdPartyInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListThirdPartyInvoiceResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListThirdPartyInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListThirdPartyInvoiceResponse.displayName = 'proto.proto.ListThirdPartyInvoiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateThirdPartyInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateThirdPartyInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateThirdPartyInvoiceRequest.displayName = 'proto.proto.CreateThirdPartyInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateThirdPartyInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateThirdPartyInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateThirdPartyInvoiceRequest.displayName = 'proto.proto.UpdateThirdPartyInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteThirdPartyInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteThirdPartyInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteThirdPartyInvoiceRequest.displayName = 'proto.proto.DeleteThirdPartyInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SetPaidInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SetPaidInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SetPaidInvoiceRequest.displayName = 'proto.proto.SetPaidInvoiceRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ThirdPartyInvoice.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ThirdPartyInvoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ThirdPartyInvoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ThirdPartyInvoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    details: (f = msg.getDetails()) && proto.proto.ThirdPartyInvoiceDetails.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    statusMessage: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastEmailAt: (f = msg.getLastEmailAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    media: (f = msg.getMedia()) && media_pb.Media.toObject(includeInstance, f),
    accountItem: (f = msg.getAccountItem()) && account_items_pb.AccountItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ThirdPartyInvoice}
 */
proto.proto.ThirdPartyInvoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ThirdPartyInvoice;
  return proto.proto.ThirdPartyInvoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ThirdPartyInvoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ThirdPartyInvoice}
 */
proto.proto.ThirdPartyInvoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.proto.ThirdPartyInvoiceDetails;
      reader.readMessage(value,proto.proto.ThirdPartyInvoiceDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    case 3:
      var value = /** @type {!proto.proto.ThirdPartyInvoice.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusMessage(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastEmailAt(value);
      break;
    case 6:
      var value = new media_pb.Media;
      reader.readMessage(value,media_pb.Media.deserializeBinaryFromReader);
      msg.setMedia(value);
      break;
    case 7:
      var value = new account_items_pb.AccountItem;
      reader.readMessage(value,account_items_pb.AccountItem.deserializeBinaryFromReader);
      msg.setAccountItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ThirdPartyInvoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ThirdPartyInvoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ThirdPartyInvoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ThirdPartyInvoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ThirdPartyInvoiceDetails.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStatusMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastEmailAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMedia();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      media_pb.Media.serializeBinaryToWriter
    );
  }
  f = message.getAccountItem();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      account_items_pb.AccountItem.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.proto.ThirdPartyInvoice.Status = {
  STATUS_UNSPECIFIED: 0,
  WAITING: 1,
  SENT: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.ThirdPartyInvoice.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoice} returns this
 */
proto.proto.ThirdPartyInvoice.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ThirdPartyInvoiceDetails details = 2;
 * @return {?proto.proto.ThirdPartyInvoiceDetails}
 */
proto.proto.ThirdPartyInvoice.prototype.getDetails = function() {
  return /** @type{?proto.proto.ThirdPartyInvoiceDetails} */ (
    jspb.Message.getWrapperField(this, proto.proto.ThirdPartyInvoiceDetails, 2));
};


/**
 * @param {?proto.proto.ThirdPartyInvoiceDetails|undefined} value
 * @return {!proto.proto.ThirdPartyInvoice} returns this
*/
proto.proto.ThirdPartyInvoice.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ThirdPartyInvoice} returns this
 */
proto.proto.ThirdPartyInvoice.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ThirdPartyInvoice.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {!proto.proto.ThirdPartyInvoice.Status}
 */
proto.proto.ThirdPartyInvoice.prototype.getStatus = function() {
  return /** @type {!proto.proto.ThirdPartyInvoice.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.proto.ThirdPartyInvoice.Status} value
 * @return {!proto.proto.ThirdPartyInvoice} returns this
 */
proto.proto.ThirdPartyInvoice.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string status_message = 4;
 * @return {string}
 */
proto.proto.ThirdPartyInvoice.prototype.getStatusMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoice} returns this
 */
proto.proto.ThirdPartyInvoice.prototype.setStatusMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp last_email_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.ThirdPartyInvoice.prototype.getLastEmailAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.ThirdPartyInvoice} returns this
*/
proto.proto.ThirdPartyInvoice.prototype.setLastEmailAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ThirdPartyInvoice} returns this
 */
proto.proto.ThirdPartyInvoice.prototype.clearLastEmailAt = function() {
  return this.setLastEmailAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ThirdPartyInvoice.prototype.hasLastEmailAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Media media = 6;
 * @return {?proto.proto.Media}
 */
proto.proto.ThirdPartyInvoice.prototype.getMedia = function() {
  return /** @type{?proto.proto.Media} */ (
    jspb.Message.getWrapperField(this, media_pb.Media, 6));
};


/**
 * @param {?proto.proto.Media|undefined} value
 * @return {!proto.proto.ThirdPartyInvoice} returns this
*/
proto.proto.ThirdPartyInvoice.prototype.setMedia = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ThirdPartyInvoice} returns this
 */
proto.proto.ThirdPartyInvoice.prototype.clearMedia = function() {
  return this.setMedia(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ThirdPartyInvoice.prototype.hasMedia = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AccountItem account_item = 7;
 * @return {?proto.proto.AccountItem}
 */
proto.proto.ThirdPartyInvoice.prototype.getAccountItem = function() {
  return /** @type{?proto.proto.AccountItem} */ (
    jspb.Message.getWrapperField(this, account_items_pb.AccountItem, 7));
};


/**
 * @param {?proto.proto.AccountItem|undefined} value
 * @return {!proto.proto.ThirdPartyInvoice} returns this
*/
proto.proto.ThirdPartyInvoice.prototype.setAccountItem = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ThirdPartyInvoice} returns this
 */
proto.proto.ThirdPartyInvoice.prototype.clearAccountItem = function() {
  return this.setAccountItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ThirdPartyInvoice.prototype.hasAccountItem = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ThirdPartyInvoiceDetails.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ThirdPartyInvoiceDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ThirdPartyInvoiceDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ThirdPartyInvoiceDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyAbn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    companyEmailAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    providerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    claimId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.proto.ThirdPartyInvoiceLineItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ThirdPartyInvoiceDetails}
 */
proto.proto.ThirdPartyInvoiceDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ThirdPartyInvoiceDetails;
  return proto.proto.ThirdPartyInvoiceDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ThirdPartyInvoiceDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ThirdPartyInvoiceDetails}
 */
proto.proto.ThirdPartyInvoiceDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyAbn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyEmailAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClaimId(value);
      break;
    case 7:
      var value = new proto.proto.ThirdPartyInvoiceLineItem;
      reader.readMessage(value,proto.proto.ThirdPartyInvoiceLineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ThirdPartyInvoiceDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ThirdPartyInvoiceDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ThirdPartyInvoiceDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyAbn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProviderId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getClaimId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.proto.ThirdPartyInvoiceLineItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_abn = 2;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.getCompanyAbn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceDetails} returns this
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.setCompanyAbn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string company_email_address = 3;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.getCompanyEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceDetails} returns this
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.setCompanyEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string company_name = 4;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceDetails} returns this
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string provider_id = 5;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.getProviderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceDetails} returns this
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string claim_id = 6;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.getClaimId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceDetails} returns this
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.setClaimId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated ThirdPartyInvoiceLineItem line_items = 7;
 * @return {!Array<!proto.proto.ThirdPartyInvoiceLineItem>}
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.proto.ThirdPartyInvoiceLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ThirdPartyInvoiceLineItem, 7));
};


/**
 * @param {!Array<!proto.proto.ThirdPartyInvoiceLineItem>} value
 * @return {!proto.proto.ThirdPartyInvoiceDetails} returns this
*/
proto.proto.ThirdPartyInvoiceDetails.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.proto.ThirdPartyInvoiceLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ThirdPartyInvoiceLineItem}
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.proto.ThirdPartyInvoiceLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ThirdPartyInvoiceDetails} returns this
 */
proto.proto.ThirdPartyInvoiceDetails.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ThirdPartyInvoiceLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ThirdPartyInvoiceLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ThirdPartyInvoiceLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ThirdPartyInvoiceLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    charge: (f = msg.getCharge()) && money_pb.Charge.toObject(includeInstance, f),
    taxType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ThirdPartyInvoiceLineItem}
 */
proto.proto.ThirdPartyInvoiceLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ThirdPartyInvoiceLineItem;
  return proto.proto.ThirdPartyInvoiceLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ThirdPartyInvoiceLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ThirdPartyInvoiceLineItem}
 */
proto.proto.ThirdPartyInvoiceLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new money_pb.Charge;
      reader.readMessage(value,money_pb.Charge.deserializeBinaryFromReader);
      msg.setCharge(value);
      break;
    case 4:
      var value = /** @type {!proto.proto.TaxType} */ (reader.readEnum());
      msg.setTaxType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ThirdPartyInvoiceLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ThirdPartyInvoiceLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ThirdPartyInvoiceLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ThirdPartyInvoiceLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCharge();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      money_pb.Charge.serializeBinaryToWriter
    );
  }
  f = message.getTaxType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceLineItem.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceLineItem} returns this
 */
proto.proto.ThirdPartyInvoiceLineItem.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceLineItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceLineItem} returns this
 */
proto.proto.ThirdPartyInvoiceLineItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Charge charge = 3;
 * @return {?proto.proto.Charge}
 */
proto.proto.ThirdPartyInvoiceLineItem.prototype.getCharge = function() {
  return /** @type{?proto.proto.Charge} */ (
    jspb.Message.getWrapperField(this, money_pb.Charge, 3));
};


/**
 * @param {?proto.proto.Charge|undefined} value
 * @return {!proto.proto.ThirdPartyInvoiceLineItem} returns this
*/
proto.proto.ThirdPartyInvoiceLineItem.prototype.setCharge = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ThirdPartyInvoiceLineItem} returns this
 */
proto.proto.ThirdPartyInvoiceLineItem.prototype.clearCharge = function() {
  return this.setCharge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ThirdPartyInvoiceLineItem.prototype.hasCharge = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TaxType tax_type = 4;
 * @return {!proto.proto.TaxType}
 */
proto.proto.ThirdPartyInvoiceLineItem.prototype.getTaxType = function() {
  return /** @type {!proto.proto.TaxType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.proto.TaxType} value
 * @return {!proto.proto.ThirdPartyInvoiceLineItem} returns this
 */
proto.proto.ThirdPartyInvoiceLineItem.prototype.setTaxType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListThirdPartyInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListThirdPartyInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListThirdPartyInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListThirdPartyInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListThirdPartyInvoiceRequest}
 */
proto.proto.ListThirdPartyInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListThirdPartyInvoiceRequest;
  return proto.proto.ListThirdPartyInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListThirdPartyInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListThirdPartyInvoiceRequest}
 */
proto.proto.ListThirdPartyInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListThirdPartyInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListThirdPartyInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListThirdPartyInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListThirdPartyInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string booking_id = 1;
 * @return {string}
 */
proto.proto.ListThirdPartyInvoiceRequest.prototype.getBookingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListThirdPartyInvoiceRequest} returns this
 */
proto.proto.ListThirdPartyInvoiceRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_id = 2;
 * @return {string}
 */
proto.proto.ListThirdPartyInvoiceRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListThirdPartyInvoiceRequest} returns this
 */
proto.proto.ListThirdPartyInvoiceRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListThirdPartyInvoiceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListThirdPartyInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListThirdPartyInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListThirdPartyInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListThirdPartyInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.proto.ThirdPartyInvoice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListThirdPartyInvoiceResponse}
 */
proto.proto.ListThirdPartyInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListThirdPartyInvoiceResponse;
  return proto.proto.ListThirdPartyInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListThirdPartyInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListThirdPartyInvoiceResponse}
 */
proto.proto.ListThirdPartyInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ThirdPartyInvoice;
      reader.readMessage(value,proto.proto.ThirdPartyInvoice.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListThirdPartyInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListThirdPartyInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListThirdPartyInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListThirdPartyInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.ThirdPartyInvoice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ThirdPartyInvoice invoices = 1;
 * @return {!Array<!proto.proto.ThirdPartyInvoice>}
 */
proto.proto.ListThirdPartyInvoiceResponse.prototype.getInvoicesList = function() {
  return /** @type{!Array<!proto.proto.ThirdPartyInvoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ThirdPartyInvoice, 1));
};


/**
 * @param {!Array<!proto.proto.ThirdPartyInvoice>} value
 * @return {!proto.proto.ListThirdPartyInvoiceResponse} returns this
*/
proto.proto.ListThirdPartyInvoiceResponse.prototype.setInvoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.ThirdPartyInvoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ThirdPartyInvoice}
 */
proto.proto.ListThirdPartyInvoiceResponse.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.ThirdPartyInvoice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListThirdPartyInvoiceResponse} returns this
 */
proto.proto.ListThirdPartyInvoiceResponse.prototype.clearInvoicesList = function() {
  return this.setInvoicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateThirdPartyInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateThirdPartyInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateThirdPartyInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateThirdPartyInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    details: (f = msg.getDetails()) && proto.proto.ThirdPartyInvoiceDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateThirdPartyInvoiceRequest}
 */
proto.proto.CreateThirdPartyInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateThirdPartyInvoiceRequest;
  return proto.proto.CreateThirdPartyInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateThirdPartyInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateThirdPartyInvoiceRequest}
 */
proto.proto.CreateThirdPartyInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 3:
      var value = new proto.proto.ThirdPartyInvoiceDetails;
      reader.readMessage(value,proto.proto.ThirdPartyInvoiceDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateThirdPartyInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateThirdPartyInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateThirdPartyInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateThirdPartyInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.ThirdPartyInvoiceDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string booking_id = 1;
 * @return {string}
 */
proto.proto.CreateThirdPartyInvoiceRequest.prototype.getBookingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateThirdPartyInvoiceRequest} returns this
 */
proto.proto.CreateThirdPartyInvoiceRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_id = 2;
 * @return {string}
 */
proto.proto.CreateThirdPartyInvoiceRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateThirdPartyInvoiceRequest} returns this
 */
proto.proto.CreateThirdPartyInvoiceRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ThirdPartyInvoiceDetails details = 3;
 * @return {?proto.proto.ThirdPartyInvoiceDetails}
 */
proto.proto.CreateThirdPartyInvoiceRequest.prototype.getDetails = function() {
  return /** @type{?proto.proto.ThirdPartyInvoiceDetails} */ (
    jspb.Message.getWrapperField(this, proto.proto.ThirdPartyInvoiceDetails, 3));
};


/**
 * @param {?proto.proto.ThirdPartyInvoiceDetails|undefined} value
 * @return {!proto.proto.CreateThirdPartyInvoiceRequest} returns this
*/
proto.proto.CreateThirdPartyInvoiceRequest.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CreateThirdPartyInvoiceRequest} returns this
 */
proto.proto.CreateThirdPartyInvoiceRequest.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CreateThirdPartyInvoiceRequest.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateThirdPartyInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateThirdPartyInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateThirdPartyInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateThirdPartyInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdPartyInvoiceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bookingId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    details: (f = msg.getDetails()) && proto.proto.ThirdPartyInvoiceDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateThirdPartyInvoiceRequest}
 */
proto.proto.UpdateThirdPartyInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateThirdPartyInvoiceRequest;
  return proto.proto.UpdateThirdPartyInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateThirdPartyInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateThirdPartyInvoiceRequest}
 */
proto.proto.UpdateThirdPartyInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdPartyInvoiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 4:
      var value = new proto.proto.ThirdPartyInvoiceDetails;
      reader.readMessage(value,proto.proto.ThirdPartyInvoiceDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateThirdPartyInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateThirdPartyInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateThirdPartyInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateThirdPartyInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdPartyInvoiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBookingId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.ThirdPartyInvoiceDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string third_party_invoice_id = 1;
 * @return {string}
 */
proto.proto.UpdateThirdPartyInvoiceRequest.prototype.getThirdPartyInvoiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateThirdPartyInvoiceRequest} returns this
 */
proto.proto.UpdateThirdPartyInvoiceRequest.prototype.setThirdPartyInvoiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string booking_id = 2;
 * @return {string}
 */
proto.proto.UpdateThirdPartyInvoiceRequest.prototype.getBookingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateThirdPartyInvoiceRequest} returns this
 */
proto.proto.UpdateThirdPartyInvoiceRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string client_id = 3;
 * @return {string}
 */
proto.proto.UpdateThirdPartyInvoiceRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateThirdPartyInvoiceRequest} returns this
 */
proto.proto.UpdateThirdPartyInvoiceRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ThirdPartyInvoiceDetails details = 4;
 * @return {?proto.proto.ThirdPartyInvoiceDetails}
 */
proto.proto.UpdateThirdPartyInvoiceRequest.prototype.getDetails = function() {
  return /** @type{?proto.proto.ThirdPartyInvoiceDetails} */ (
    jspb.Message.getWrapperField(this, proto.proto.ThirdPartyInvoiceDetails, 4));
};


/**
 * @param {?proto.proto.ThirdPartyInvoiceDetails|undefined} value
 * @return {!proto.proto.UpdateThirdPartyInvoiceRequest} returns this
*/
proto.proto.UpdateThirdPartyInvoiceRequest.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpdateThirdPartyInvoiceRequest} returns this
 */
proto.proto.UpdateThirdPartyInvoiceRequest.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpdateThirdPartyInvoiceRequest.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteThirdPartyInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteThirdPartyInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteThirdPartyInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteThirdPartyInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdPartyInvoiceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteThirdPartyInvoiceRequest}
 */
proto.proto.DeleteThirdPartyInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteThirdPartyInvoiceRequest;
  return proto.proto.DeleteThirdPartyInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteThirdPartyInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteThirdPartyInvoiceRequest}
 */
proto.proto.DeleteThirdPartyInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdPartyInvoiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteThirdPartyInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteThirdPartyInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteThirdPartyInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteThirdPartyInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdPartyInvoiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string third_party_invoice_id = 1;
 * @return {string}
 */
proto.proto.DeleteThirdPartyInvoiceRequest.prototype.getThirdPartyInvoiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DeleteThirdPartyInvoiceRequest} returns this
 */
proto.proto.DeleteThirdPartyInvoiceRequest.prototype.setThirdPartyInvoiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SetPaidInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SetPaidInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SetPaidInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SetPaidInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdPartyInvoiceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isPaid: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    details: (f = msg.getDetails()) && proto.proto.ThirdPartyInvoiceDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SetPaidInvoiceRequest}
 */
proto.proto.SetPaidInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SetPaidInvoiceRequest;
  return proto.proto.SetPaidInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SetPaidInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SetPaidInvoiceRequest}
 */
proto.proto.SetPaidInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdPartyInvoiceId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPaid(value);
      break;
    case 3:
      var value = new proto.proto.ThirdPartyInvoiceDetails;
      reader.readMessage(value,proto.proto.ThirdPartyInvoiceDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SetPaidInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SetPaidInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SetPaidInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SetPaidInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdPartyInvoiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsPaid();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.ThirdPartyInvoiceDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string third_party_invoice_id = 1;
 * @return {string}
 */
proto.proto.SetPaidInvoiceRequest.prototype.getThirdPartyInvoiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SetPaidInvoiceRequest} returns this
 */
proto.proto.SetPaidInvoiceRequest.prototype.setThirdPartyInvoiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_paid = 2;
 * @return {boolean}
 */
proto.proto.SetPaidInvoiceRequest.prototype.getIsPaid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SetPaidInvoiceRequest} returns this
 */
proto.proto.SetPaidInvoiceRequest.prototype.setIsPaid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional ThirdPartyInvoiceDetails details = 3;
 * @return {?proto.proto.ThirdPartyInvoiceDetails}
 */
proto.proto.SetPaidInvoiceRequest.prototype.getDetails = function() {
  return /** @type{?proto.proto.ThirdPartyInvoiceDetails} */ (
    jspb.Message.getWrapperField(this, proto.proto.ThirdPartyInvoiceDetails, 3));
};


/**
 * @param {?proto.proto.ThirdPartyInvoiceDetails|undefined} value
 * @return {!proto.proto.SetPaidInvoiceRequest} returns this
*/
proto.proto.SetPaidInvoiceRequest.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SetPaidInvoiceRequest} returns this
 */
proto.proto.SetPaidInvoiceRequest.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SetPaidInvoiceRequest.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.proto.TaxType = {
  INCLUDED: 0,
  EXCLUDED: 1,
  EXEMPT: 2,
  UNSPECIFIED: 3
};

goog.object.extend(exports, proto.proto);
