import { Money, Charge } from './../sdk/money_pb';
import { TaxType } from './../sdk/third_party_invoices_pb';
import { ISOCurrencyName } from '../sdk/currencies_pb';
import { CcyIsoNameToString } from '../components/routes/OrgEdit/Settings';

export function moneyZero(ccyIsoName: ISOCurrencyName): Money.AsObject {
  return {
    currencyCode: ccyIsoName,
    units: 0,
    nanos: 0,
  };
}

export function chargeZero(ccyIsoName: ISOCurrencyName): Charge.AsObject {
  return {
    amount: moneyZero(ccyIsoName),
    tax: moneyZero(ccyIsoName),
  };
}

export function moneyToText(money: Money.AsObject, showCurr: boolean): string {
  if (showCurr){
    return `${CcyIsoNameToString(money.currencyCode)} $${ moneyToFloat(money).toFixed(2)}`;
  }
  return `$${ moneyToFloat(money).toFixed(2)}`;
}

export function chargeToText(charge: Charge.AsObject): string {
  const total = add(charge.amount!.currencyCode, charge.amount!, charge.tax!);
  return moneyToText(total, false);
}

export function mul(a: Money.AsObject, b: number, percentage: boolean): Money.AsObject {
  const moneyA = moneyToFloat(a);
  var mul = (moneyA * b);
  if (percentage){
    mul = mul/100
  }
  return moneyFromFloat(a.currencyCode, mul);
}

export function add(ccyIsoName: ISOCurrencyName, ...values: Money.AsObject[]): Money.AsObject {
  return values.reduce((prev, curr) => addImpl(prev, curr), moneyZero(ccyIsoName));
}

function addImpl(a: Money.AsObject, b: Money.AsObject): Money.AsObject {
  const sum = moneyToFloat(a) + moneyToFloat(b);
  return moneyFromFloat(a.currencyCode, sum);
}

export function moneyFromFloat(ccyIsoName: ISOCurrencyName, float: number): Money.AsObject {
  const units = Math.floor(float);
  // We round to only two digit number (for infinite or larger decimal places)
  // Then we do Math.pow to 9 from the round up function
  // Finally, we do a another Math floor to avoid new wrong decimal digits
  const nanos = Math.floor(parseFloat((float - units).toFixed(2)) * Math.pow(10, 9));
  return {
    currencyCode: ccyIsoName,
    units,
    nanos,
  };
}

export function taxTypeToString(taxType: TaxType): string {
  switch (taxType) {
    case TaxType.INCLUDED:
      return "Included";
    case TaxType.EXCLUDED:
      return "Excluded";
    case TaxType.EXEMPT:
      return "Exempt";
    default:
      return "unknown";
  }
}

export function chargeFromFloat(ccyIsoName: ISOCurrencyName, float: number, taxType: TaxType): Charge.AsObject {
  let amount = 0;
  let tax = 0;
  switch (taxType) {
    case TaxType.INCLUDED:
      amount = float / 1.1;
      tax = float - amount;
      break;
    case TaxType.EXCLUDED:
      amount = float;
      tax = float * 0.1; // 10% GST
      break;
    case TaxType.EXEMPT:
      amount = float;
      tax = 0;
      break;
    default:
      amount = float;
      tax = 0;
      break;
  }

  return {
    amount: moneyFromFloat(ccyIsoName, amount),
    tax: moneyFromFloat(ccyIsoName, tax),
  }
}

export function chargeToFloat(charge: Charge.AsObject, desiredTaxType: TaxType): number {
  switch (desiredTaxType) {
    case TaxType.INCLUDED:
      return moneyToFloat(add(charge.amount!.currencyCode, charge.amount!, charge.tax!))
    case TaxType.EXCLUDED:
      return moneyToFloat(add(charge.amount!.currencyCode, charge.amount!));
    case TaxType.EXEMPT:
      return moneyToFloat(add(charge.amount!.currencyCode, charge.amount!));
    default:
      return moneyToFloat(add(charge.amount!.currencyCode, charge.amount!, charge.tax!));
  }
}

export function moneyToFloat(money: Money.AsObject): number {
  const units = money.units;
  const nanos = money.nanos;
  return units + nanos * Math.pow(10, -9);
}

export function moneyToProto(money: Money.AsObject): Money {
  const moneyMsg = new Money();
  moneyMsg.setCurrencyCode(money.currencyCode);
  moneyMsg.setUnits(money.units);
  moneyMsg.setNanos(money.nanos);
  return moneyMsg;
}

export function chargeToProto(charge: Charge.AsObject): Charge {
  const chargeMsg = new Charge();
  chargeMsg.setAmount(moneyToProto(charge.amount!));
  chargeMsg.setTax(moneyToProto(charge.tax!));
  return chargeMsg;
}
