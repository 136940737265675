import { observable } from 'mobx';
import * as grpc from 'grpc-web';
import { toastStore } from './../stores/toast-store';

type Action<T> = () => Promise<T>;

export class LoadingIndicator {

  @observable
  private count = 0;

  isLoading() {
    return this.count > 0;
  }

  async while<T>(callback: Action<T>): Promise<T> {
    this.count += 1;
    try {
      return await callback();
    } catch (error) {
      if (isRpcError(error)) {
        toastStore.grpcToastError(error);
      }
      throw error;
    } finally {
      this.count -= 1;
    }
  }
}

function isRpcError(value: any): value is grpc.Error {
  if (typeof(value) !== 'object') {
    return false;
  }
  if (typeof(value.code) === 'number' && value.code >= 0 && value.code <= 16) {
    if (typeof(value.message) === 'string') {
      return true;
    }
  }
  return false;
}
