/** @jsx jsx */
import { Component } from "react";
import { css, jsx } from "@emotion/core";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";

// Utils
import { colors } from "./../../../util/consts";
import { parseFormError } from "./../../../util/form";

import { getAuthenticatedUser } from "./../../../components/elements/AuthGetUser";

import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Redux
import apiService from "./../../../redux/services/api";

// Custom components
import Button from "./../../../components/Button";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import Header from "./../../../components/form/Header";
import Form from "./../../../components/form/Form";
import FooterContainer from "./../../../components/form/FooterContainer";
import FooterText from "./../../../components/form/FooterText";

class Change extends Component {
  handleGoback = async () => {
    this.props.setPage("Verify");
  };
  handleLogout = async () => {
    const { values, setPage } = this.props;
    try {
      await apiService.userLogout();
      setPage("login");
    } catch (err) {
      parseFormError(err, values, () => {}, this.props);
    }
  };
  render() {
    const {
      authUser,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData: { changeType }
    } = this.props;

    if (!authUser) {
      return null;
    }
    const type = changeType === "Email" ? "Email" : "Phone";
    const label = changeType === "Email" ? "email" : "phone number";
    return (
      <Form onSubmit={handleSubmit}>
        <Header title={`Change Account Details`} subtitle={`Change your ${label} below`} />
        <SectionsContainer>
          <Section>
            <Label htmlFor="change-Prop">{changeType === "Email" ? "Email" : "Phone Number"}</Label>
            <Input
              id="change-Prop"
              placeholder={`Enter your ${label}`}
              type="text"
              name="Prop"
              value={values.Prop}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.Prop && touched.Prop && <Error>{errors.Prop}</Error>}
          </Section>
          <Section>
            <Label htmlFor="change-OldPassword">Password</Label>
            <Input
              id="change-OldPassword"
              placeholder={`Enter your password`}
              type="password"
              name="OldPassword"
              value={values.OldPassword}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.OldPassword && touched.OldPassword && <Error>{errors.OldPassword}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <Button
            css={css`
              width: 150px;
            `}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </FooterContainer>
        <FooterContainer
          css={css`
            margin-top: 32px;
          `}
        >
          <FooterText>
            Is your {type === "Email" ? "email" : "phone number"} correct?{" "}
            <span
              css={css`
                text-decoration: none;
                color: ${colors.secondary.main};
                cursor: pointer;
              `}
              onClick={this.handleGoback}
            >
              Go Back
            </span>
          </FooterText>
        </FooterContainer>
        <FooterContainer
          css={css`
            margin-top: 16px;
          `}
        >
          <FooterText>
            Want to escape?{" "}
            <span
              css={css`
                text-decoration: none;
                color: ${colors.secondary.main};
                cursor: pointer;
              `}
              onClick={this.handleLogout}
            >
              Logout
            </span>
          </FooterText>
        </FooterContainer>
      </Form>
    );
  }
}
const formikEnhancer = withFormik({
  mapPropsToValues: (props) => ({
    Prop:
      props.pageData.changeType === "Email" ? props.authUser.Email : props.authUser.MobileNumber,
    OldPassword: ""
  }),
  validationSchema: (props) => {
    if (props.pageData.changeType === "Email") {
      return Yup.object().shape({
        Prop: Yup.string()
          .email("Please enter a valid email")
          .required("Email is required"),
        OldPassword: Yup.string().required("Password is required")
      });
    } else {
      return Yup.object().shape({
        Prop: Yup.string().required("Phone number is required"),
        OldPassword: Yup.string().required("Password is required")
      });
    }
  },
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    const { authUser, toastManager, pageData } = props;
    try {
      const updUser = {
        ...authUser,
        OldPassword: values.OldPassword
      };

      if (pageData.changeType === "Email") {
        updUser.Email = values.Prop.trim();
      } else {
        updUser.MobileNumber = values.Prop.trim();
        updUser.MobileCountryCode = "AU";
      }

      await props.dispatch(ResourceActions.action(updUser, "Update", {}));
      await apiService.performRequest({
        method: "post",
        path: `/api/v1/users/${authUser.ID}/validate`,
        data: {
          Type: pageData.changeType === "Email" ? "Email" : "MobileNumber",
          Token: null
        }
      });

      toastManager.add(`${pageData.changeType} updated and verification code sent.`, {
        appearance: "success"
      });

      props.setPage("verify");
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "ChangeForm"
});
export default compose(
  connect((state) => ({ authUser: getAuthenticatedUser(state) })),
  withToastManager,
  formikEnhancer
)(Change);
