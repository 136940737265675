import React from "react";
import { ThirdPartyInvoice } from "./../../sdk/third_party_invoices_pb";
import {
  AccordianContainer,
  AccordianEdit,
  ButtonHead,
  Separator,
  ButtonDetails,
  OptionsContainer,
  NoSpacingIconButton
} from "../elements/AccordianElements";
import { HoverToolTip } from "../elements/HoverToolTip";
import Email from "../icons/Email";
import Options from "../icons/Options";
import { tpiStatusToText, tpiTotalCharge } from "./../../util/third-party-invoices";
import { aiStatusToText } from "./../../util/account-items";
import { AccItemStatus } from "./../../sdk/account_items_pb";
import { observer } from "mobx-react";
import { observable, toJS, computed } from "mobx";
import { Booking, BookingClient } from "./../../sdk/bookings_pb";
import { EditThirdPartyForm } from "./EditThirdPartyForm";
import { CloseButton } from "../elements/CloseButton";
import { moneyToText } from "./../../util/money";
import DropdownSelect from "../DropdownSelect";
import { tpiTemplateStore } from "../../stores/tpi_template-store";
import { ThirdPartyInvoiceTemplate } from "../../sdk/third_party_invoice_templates_pb";
import { ThirdPartyTemplateEditForm } from "./ThirdPartyTemplateEditForm";

interface Props {
  client: any;
  template: ThirdPartyInvoiceTemplate.AsObject;
  onRemove?: () => void;
}

@observer
export class ThirdPartyTemplateRow extends React.Component<Props> {
  @observable
  private isEditing = false;

  private editOptions() {
    return [this.edit, this.remove];
  }

  edit = {
    label: "Edit",
    onClick: () => {
      this.isEditing = true;
    }
  };

  remove = {
    label: "Remove",
    onClick: () => {
      this.props.onRemove!();
    }
  };

  render() {
    const { template } = this.props;
    return (
      <AccordianContainer>
        <AccordianEdit>
          <ButtonHead style={{ width: "25%" }}>{template.entityName}</ButtonHead>
          {this.isEditing ? (
            <CloseButton
              style={{
                position: "relative",
                padding: "2px"
              }}
              onClick={() => (this.isEditing = false)}
            />
          ) : (
            <OptionsContainer>
              <DropdownSelect options={this.editOptions()}>
                <NoSpacingIconButton>
                  <Options />
                </NoSpacingIconButton>
              </DropdownSelect>
            </OptionsContainer>
          )}
        </AccordianEdit>
        {this.isEditing && (
          <ThirdPartyTemplateEditForm
            client={this.props.client}
            template={template}
            onRequestClose={() => {
              this.isEditing = false;
            }}
          />
        )}
      </AccordianContainer>
    );
  }
}
