import apiService from "./../redux/services/api";

export function parseFormError(err, values, setFieldError, props) {
  const apiErr = apiService.errorProcess(err);

  // Check for field errors.
  if (apiErr.statusCode === 400) {
    let fieldRefd = false;
    for (let i = 0; i < apiErr.apiErrors.length; i++) {
      if (apiErr.apiErrors[i].FieldType === "body") {
        if (values.hasOwnProperty(apiErr.apiErrors[i].FieldName)) {
          setFieldError(apiErr.apiErrors[i].FieldName, apiErr.apiErrors[i].UserMessage);
          fieldRefd = true;
        }
      }
    }

    if (fieldRefd) {
      props.toastManager.add(`There are errors in the form. Please correct them and try again.`, {
        appearance: "error"
      });
      return;
    }
  }

  // Otherwise display a generate error dialog.
  props.toastManager.add(apiErr.userMessages, {
    appearance: "error"
  });
}
