import React, { Component } from "react";

import NotFound from "./../../components/routes/NotFound";
import NotAllowed from "./../../components/routes/NotAllowed";
import InternalError from "./../../components/routes/InternalError";
import UnknownError from "./../../components/routes/UnknownError";

import apiService from "./../../redux/services/api";

export default class ResourcePageError extends Component {
  render() {
    const { resource, list, errorOveride } = this.props;
    if (errorOveride) {
      return <UnknownError errorCode={errorOveride} />;
    } else {
      const data = resource || list;
      const apiError = apiService.errorProcess(data.$Metadata.DataError);
      const firstError = apiError.apiErrors[0];
      if (firstError.ErrorCode === "entity_not_found") {
        return <NotFound />;
      } else if (firstError.ErrorCode === "request_forbidden") {
        return <NotAllowed />;
      } else if (firstError.ErrorCode === "internal_error") {
        return <InternalError errorID={firstError.RefID} />;
      } else {
        return <UnknownError errorCode={firstError.ErrorCode} />;
      }
    }
  }
}
