import React, { Component } from 'react'
import * as Yup from "yup";
import { createForm } from "./../../forms/forms";
import {AccordianForm, ButtonContainer} from "./../../components/elements/AccordianElements";
import { CreateNoteRequest, Note } from "./../../sdk/notes_pb";
import SignUp from './../../components/dialogs/AccountPageDialog/SignUp'
import PagesContainer, { PageGroup, Page } from "./../../components/elements/PagesContainer";
import Dialog from "../dialogs/Dialog";
import Verify from "../dialogs/AccountPageDialog/Verify";
import SignUpExtra from "../dialogs/AccountPageDialog/SignUpExtra";

export class Signup extends Component {
    render() {
        return (
        <PagesContainer
        sidePanel={true}
        queryp="page"
        blockNotSeen={true}
        >
        <PageGroup label="Registration">
          <Page name="SignUp" label="Information" component={SignUp} />
          <Page name="Verify" label="Verification" component={Verify} />
          <Page name="SignUpExtra" label="Extra Details" history = {this.props.history} component={SignUpExtra}/>
        </PageGroup>
        </PagesContainer>
        )
    }
}

export default Signup
