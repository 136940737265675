import React from 'react';
import DropdownSelect from './../../../../components/DropdownSelect';
import { AccordianContainer, AccordianEdit, ButtonDetails, ButtonHead, NoSpacingIconButton, OptionsContainer, Separator } from './../../../../components/elements/AccordianElements';
import Options from './../../../../components/icons/Options';
import { Booking, BookingClient, ApproveBookingClientRequest, RejectBookingClientRequest, CancellationChargeClientRequest, ProcessBookingRequest } from './../../../../sdk/bookings_pb';
import { TabContainer } from '..';
import { RightContent } from '../../SplitDialog';
import { TitleContainer, TitleName, TitleService } from '../Entry';
import { rpc, metadata } from './../../../../grpc';
import { bookingStore } from './../../../../stores/booking-store';
import * as ReduxDialogs from "./../../../../redux/features/dialogs";
import { moneyToText } from './../../../../util/money';
import { calculateTotal, allLineItems } from './../../../../util/bookings';
import { FundType } from './../../../../sdk/offerings_pb';
import { LoadingIndicator } from './../../../../util/loading';
import { toastStore } from './../../../../stores/toast-store';


interface Props {
  booking: Booking.AsObject;
  indicator: LoadingIndicator;
  onClientSelect: (client:BookingClient.AsObject) => void;
  dispatch?: any;
}

export class GroupOverview extends React.Component<Props> {

  async clientApproval(client:BookingClient.AsObject) {
    try {
      const req = new ApproveBookingClientRequest();
      req.setBookingId(client.bookingId);
      req.setClientId(client.clientId);
      const res = await rpc.bookingClients.approve(req, metadata());
      bookingStore.add(res.toObject());
      toastStore.success("client approved successfully.");
    } catch(err) {
      toastStore.grpcToastError(err);
    }
  }

  async clientReject(client:BookingClient.AsObject) {
    const { booking, dispatch } = this.props;
    dispatch(ReduxDialogs.openReject(null, booking, client, () => {}));
  }

  async processBooking(client:BookingClient.AsObject) {
    const { booking, indicator } = this.props;
    for (const item of allLineItems(booking, client)) {
      switch (item.fundType) {
        case FundType.DVA_ALLIED ||
          FundType.MEDICARE_BULK ||
          FundType.MEDICARE_STANDARD ||
          FundType.HICAPS:
          if (!(await this.checkClientReferrals(client))) {
            return;
          }
          break;
      }
    }

    const processBookingReq = new ProcessBookingRequest();
    processBookingReq.setBookingId(booking.id);
    processBookingReq.setClientId(client!.clientId);
    await indicator.while(async () => {
      try {
        const res = await rpc.bookings.processBooking(processBookingReq, metadata());
        await bookingStore.add(res.toObject().booking!);
        // await accItemStore.loadByBookingClient(booking.id, client.clientId);
        toastStore.success(`Successfully created account items for booking ${booking.friendlyId}`);
        bookingStore.load(booking.id);
      } catch (err) {
        toastStore.grpcToastError(err);
        return;
      }
    });
  }

  checkClientReferrals(client:BookingClient.AsObject): Promise<boolean> {
    if (client!.activeReferralId === "") {
      return new Promise((resolve, reject) => {
        const { dispatch } = this.props;
        dispatch(
          ReduxDialogs.openAction(
            "To send this claim with a referral you must click on the referral to highlight it. Are you sure you want to send this claim without a referral?",
            null,
            null,
            (check: any, yay: any, notify: any) => resolve(yay),
            "Yes",
            "No"
          )
        );
      });
    } else {
      return Promise.resolve(true);
    }
  }

  render() {
    const { booking, onClientSelect } = this.props;
    return (
      <RightContent>
        <TitleContainer style={{ flexDirection: "column" }}>
          <TitleName style={{ fontSize: 23.69 }}>Client Payments</TitleName>
          <TitleService style={{ fontSize: 13.82, marginTop: 6 }}>
            Select a client to edit their payment details
          </TitleService>
        </TitleContainer>
        {/* <ClientPayments booking={booking} setClient={setClient} /> */}
        <TabContainer>
          {booking.clientsList.map((client:BookingClient.AsObject) =>
            <AccordianContainer key={client.clientId}>
              <AccordianEdit mayClick onClick={() => onClientSelect(client)}>
                <ButtonHead style={{ width: "50px" }}>
                  {client.firstName} {client.lastName}
                </ButtonHead>
                <Separator />
                <ButtonDetails style={{ fontWeight: "bold", width: "70px" }}>
                  {moneyToText(calculateTotal(booking, client), true)}
                </ButtonDetails>
                <Separator />
                <ButtonDetails>{client.statusMessage}</ButtonDetails>
                { client.paymentStatus === BookingClient.PaymentStatus.PENDING && 
                <OptionsContainer>
                  <DropdownSelect
                    options={[{
                      label: "Edit",
                      onClick: () => onClientSelect(client)
                    }, {
                      label: "Approve",
                      onClick: () => this.clientApproval(client)
                    }, {
                      label: "Reject",
                      onClick: () => this.clientReject(client)
                    }, {
                      label: "Process",
                      onClick: () => this.processBooking(client)
                    }
                    // , {
                    //   label: "Offerings",
                    //   onClick: () => this.processBooking(client)
                    // }
                  ]}
                  >
                    <NoSpacingIconButton>
                      <Options />
                    </NoSpacingIconButton>
                  </DropdownSelect>
                </OptionsContainer>
                }
              </AccordianEdit>
            </AccordianContainer>
          )}
        </TabContainer>
      </RightContent>
    );
  }
}
