import { ResourceTypes, ResourceLists } from "./definitions";

// ResourceDataStatus provides the status of the resources data within redux.
// Only reflective of an API reponse when fetching.
export const enum ResourceDataStatus {
  // NoData is used when the resource has not yet been fetched.
  NoData = "NoData",
  // Ok is used when the resource has been created or successfully fetched.
  Ok = "Ok",
  // Error is used when the resource failed to successfully fetch.
  Error = "Error"
}

// ResourceActionTypes provides the possible resource actions.
export const enum ResourceActionTypes {
  Fetch = "Fetch",
  Create = "Create",
  Update = "Update",
  Remove = "Remove"
}

// ResourceListActionTypes provides the possible resource actions.
export const enum ResourceListActionTypes {
  Fetch = "Fetch",
  Page = "Page"
}

// ResourceActionStatus provides the status of a given resource action.
export const enum ResourceActionStatus {
  Pending = "Pending",
  Ok = "Ok",
  Error = "Error"
}

// ResourceAction provides the data associated with a resource action.
export interface ResourceActionCommon {
  ID: string;
  Type: string;
  At: Date;                     // When action was fired.
  Status: ResourceActionStatus;
  Error: Error | null;          // Availible on Status === Error
  Data: any;
}

// ResourceMetadataCommon provides the common metadata used in resources
// and resource lists.
export interface ResourceMetadataCommon {
  DataStatus: ResourceDataStatus | null;
  DataLastSynced: Date | null;  // Availible on Status === Ok
  DataError: Error | null;      // Availible on Status === Error
}

// ResourceMetadata provides the metadata used in resources.
export interface ResourceMetadata extends ResourceMetadataCommon {
  Actions: ResourceActionCommon[];    // Actions ordered from oldest to newest.
  Type: ResourceTypes;
}

// Resource defines the default resource object stored in redux.
export interface Resource {
  $Metadata: ResourceMetadata;
  [key: string]: any;
}

// ResourceListMetadata provides the metadata used in resource lists.
export interface ResourceListMetadata extends ResourceMetadataCommon {
  Actions: ResourceActionCommon[];    // Actions ordered from oldest to newest.
  List: ResourceLists;
  Context: { [key: string]: any };
}

// ResourceList defines the default resource list object stored in redux.
export interface ResourceList {
  $Metadata: ResourceListMetadata;
  IDs: string[];
  SubFilteredIDs: { [key: string]: string[] };
}
