import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const sizeProps = {
  small: {
    width: "20",
    height: "20"
  },
  medium: {
    width: "24",
    height: "24"
  },
  large: {
    width: "28",
    height: "28"
  }
};

export default class Mobile extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"])
  };

  static defaultProps = {
    size: "medium"
  };

  render() {
    const { size, ...otherProps } = this.props;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        {...sizeProps[size]}
        {...otherProps}
      >
        <path fill="none" d="M0 0h24v24H0V0z"/>
        <path d="M17 1H7c-1.1 0-1.99.85-1.99 1.95v18C5.01 22.05 5.9 23 7 23h10c1.1 0 2-.95 2-2.05v-18C19 1.85 18.1 1 17 1zm0 18H7V5h10v14zm-4.2-5.76v1.75L16 12l-3.2-2.98v1.7c-3.11.43-4.35 2.56-4.8 4.7 1.11-1.5 2.58-2.18 4.8-2.18z"/>
      </svg>
    );
  }
}
