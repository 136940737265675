import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { Map, Marker } from "google-maps-react";
import { format, addDays, isSameDay } from "date-fns";
import qs from "qs";

import typography from "./../../util/typography";
import { breakpoints, colors, hexToRgba } from "./../../util/consts";

import { getResource, getResourceList } from "./../../redux/features/resources/helpers";

import ButtonBase from "./../../components/ButtonBase";
import DropdownSelect from "./../../components/DropdownSelect";
import Card from "./Landing/Card";
import Place from "../icons/Place";
import ViewComfy from "../icons/ViewComfy";
import ResourceListFetch from "./../../components/elements/ResourceListFetch";
// import ResourceListRender from "./../../components/elements/ResourceListRender";
// import ResourceRender from "./../../components/elements/ResourceRender";

class Providers extends Component {
  state = {
    filters: {
      date: "Next 7 days"
      // time: null,
      // other: null
    },
    mapView: false
  };

  toggleMapView = () => {
    this.setState((prevState) => ({ mapView: !prevState.mapView }));
  };

  render() {
    const { filters, mapView } = this.state;
    const { location, listData } = this.props;
    const { lat, lng, search, service } = qs.parse(location.search.substring(1));

    const resourceContext = {
      Lat: lat,
      Lng: lng,
      Cats: service,
      Search: search
    };

    switch (filters.date) {
      case "Today": {
        resourceContext.StartDate = format(new Date(), "yyyy-MM-dd");
        resourceContext.MaxDays = 1;
        break;
      }
      case "Tomorrow": {
        resourceContext.StartDate = format(addDays(new Date(), 1), "yyyy-MM-dd");
        resourceContext.MaxDays = 1;
        break;
      }
      default: {
        resourceContext.StartDate = format(new Date(), "yyyy-MM-dd");
        break;
      }
    }

    const newList = listData
      .map((resource) => {
        const { FreeTimes } = resource;

        const MAX_NUM_SESSIONS = 7;

        const Filter = FreeTimes.reduce(
          (accumulator, currentValue) => {
            const numRemainingSpaces = MAX_NUM_SESSIONS - accumulator.numSessions;

            if (numRemainingSpaces > 0 && currentValue.Times.length > 0) {
              const newSessions = {
                Date: currentValue.Date,
                Times: currentValue.Times.slice(0, numRemainingSpaces)
              };

              const returnValue = {
                ...accumulator,
                sessions: [...accumulator.sessions, newSessions],
                numSessions: accumulator.numSessions + newSessions.Times.length,
                totalSessions: accumulator.totalSessions + currentValue.Times.length
              };

              if (numRemainingSpaces === MAX_NUM_SESSIONS) {
                returnValue.NextAvailDate = new Date(
                  currentValue.Date + " " + currentValue.Times[0]
                );
                returnValue.NextAvailString = isSameDay(new Date(currentValue.Date), new Date()) // eslint-disable-line no-nested-ternary
                  ? "Today"
                  : isSameDay(new Date(currentValue.Date), addDays(new Date(), 1))
                  ? "Tomorrow"
                  : "Next 7 days";
              }

              return returnValue;
            }

            return {
              ...accumulator,
              totalSessions: accumulator.totalSessions + currentValue.Times.length
            };
          },
          {
            sessions: [],
            numSessions: 0,
            totalSessions: 0,
            NextAvailDate: null,
            NextAvailString: ""
          }
        );

        return {
          ...resource,
          Filter
        };
      })
      .filter((resource) => {
        const { Filter } = resource;

        if (Filter && (filters.date === "Next 7 days" || filters.date === Filter.NextAvailString)) {
          return true;
        }

        return false;
      });

    const firstItem = newList.find(
      (resource) =>
        resource && typeof resource !== "undefined" && resource.Address && resource.Address.Location
    );

    return (
      <>
        <FiltersHeader>
          <PageSizer>
            <FiltersHeaderContent>
              <div>
                <DropdownSelect
                  value={filters.date}
                  options={[
                    {
                      label: "Next 7 days",
                      onClick: () =>
                        this.setState((prevState) => ({
                          filters: { ...prevState.filters, date: "Next 7 days" }
                        }))
                    },
                    {
                      label: "Today",
                      onClick: () =>
                        this.setState((prevState) => ({
                          filters: { ...prevState.filters, date: "Today" }
                        }))
                    },
                    {
                      label: "Tomorrow",
                      onClick: () =>
                        this.setState((prevState) => ({
                          filters: { ...prevState.filters, date: "Tomorrow" }
                        }))
                    }
                  ]}
                  style={{
                    marginRight: 20
                  }}
                >
                  <FilterButton active={filters.date}>
                    <ButtonText>{filters.date || "Date"}</ButtonText>
                  </FilterButton>
                </DropdownSelect>
                {/* <DropdownSelect
                  value={filters.time}
                  options={[
                    {
                      label: "Any",
                      onClick: () =>
                        this.setState((prevState) => ({
                          filters: { ...prevState.filters, time: "Any" }
                        }))
                    },
                    {
                      label: "Am",
                      onClick: () =>
                        this.setState((prevState) => ({
                          filters: { ...prevState.filters, time: "Am" }
                        }))
                    },
                    {
                      label: "Pm",
                      onClick: () =>
                        this.setState((prevState) => ({
                          filters: { ...prevState.filters, time: "Pm" }
                        }))
                    }
                  ]}
                  style={{
                    marginRight: 20
                  }}
                >
                  <FilterButton active={filters.time}>
                    <ButtonText>{filters.time || "Time"}</ButtonText>
                  </FilterButton>
                </DropdownSelect>
                <DropdownSelect
                  value={filters.other}
                  options={[
                    {
                      label: "Bulk billed",
                      onClick: () =>
                        this.setState((prevState) => ({
                          filters: { ...prevState.filters, other: "Bulk billed" }
                        }))
                    }
                  ]}
                >
                  <FilterButton active={filters.other}>
                    <ButtonText>{filters.other || "Filters"}</ButtonText>
                  </FilterButton>
                </DropdownSelect> */}
              </div>
              <div>
                <OtherButton onClick={this.toggleMapView}>
                  {mapView ? (
                    <>
                      <ButtonText style={{ marginRight: 4 }}>Show List</ButtonText>
                      <ViewComfy color={colors.secondary.main} />
                    </>
                  ) : (
                    <>
                      <ButtonText style={{ marginRight: 4 }}>Show Maps</ButtonText>
                      <Place fill={colors.secondary.main} />
                    </>
                  )}
                </OtherButton>
              </div>
            </FiltersHeaderContent>
          </PageSizer>
        </FiltersHeader>
        <PageSizer>
          <Container>
            <LeftContainer mapView={mapView}>
              <div style={{ paddingBottom: 12 }}>
                <Title>Explore providers</Title>
              </div>
              <CardsContainer>
                <ResourceListFetch
                  noLoginReq={true}
                  type="SearchPageLocations"
                  listctx={resourceContext}
                  force
                />
                {newList.length > 0 ? (
                  newList.map(
                    (resource) =>
                      resource &&
                      typeof resource !== "undefined" && (
                        <Card
                          resource={resource}
                          key={resource.ID}
                          halfScreen={mapView}
                          filter={filters.date}
                        />
                      )
                  )
                ) : (
                  <Subtitle style={{ margin: 5 }}>No available providers...</Subtitle>
                )}
                {/* <ResourceListRender
                  type="SearchPageLocations"
                  compSuccess={({ list }) =>
                    list.IDs.map((id) => (
                      <ResourceRender
                        key={id}
                        type="Location"
                        context={resourceContext}
                        ids={id}
                        compSuccess={({ resource }) => (
                          <Card resource={resource} halfScreen={mapView} />
                        )}
                        denorm={true}
                      />
                    ))
                  }
                  compLoading={() => <div>loading</div>}
                  compError={() => <div>error</div>}
                /> */}
              </CardsContainer>
            </LeftContainer>
            <MapContainer mapView={mapView}>
              <Map
                google={window.google}
                center={
                  firstItem
                    ? {
                        lat: firstItem.Address.Location.Latitude,
                        lng: firstItem.Address.Location.Longitude
                      }
                    : {
                        lat: -37.81,
                        lng: 144.96
                      }
                }
                zoom={14}
              >
                {newList.map(
                  (resource) =>
                    resource &&
                    typeof resource !== "undefined" && (
                      <Marker
                        key={resource.ID}
                        name={resource.Name}
                        position={
                          resource.Address && resource.Address.Location
                            ? {
                                lat: resource.Address.Location.Latitude,
                                lng: resource.Address.Location.Longitude
                              }
                            : { lat: undefined, lng: undefined }
                        }
                      />
                    )
                )}
              </Map>
            </MapContainer>
          </Container>
        </PageSizer>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const { auth, lists, resources } = state;
  const list = getResourceList(lists, "SearchPageLocations", {});
  return {
    auth,
    list,
    listData: (list.IDs || []).map((lid) => getResource(resources, "Location", lid, true))
  };
}

export default connect(mapStateToProps)(Providers);

/* Shared */

const PageSizer = styled.div`
  height: 100%;
  max-width: 1440px;
  padding: 0 24px;
  margin: 0 auto;
`;

/* Filter/Second Header */

const FiltersHeader = styled.div`
  height: 60px;
  width: 100vw;
  border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
  background-color: ${colors.surface.light};

  position: sticky;
  top: 69px;
  left: 0;
  z-index: 1;

  ${breakpoints["phone-only"]} {
    display: none;
  }
`;

const FiltersHeaderContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterButton = styled(ButtonBase)`
  height: 30px;
  min-width: 124px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 15px;

  margin: 0 10px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${({ active }) =>
    (active
      ? `
          padding-top: 7px;
          padding-bottom: 7px;
          background-color: ${colors.primary.main};
          color: ${colors.whiteText.highEmphasis};

          transition: opacity 0.2s ease;
          &:hover:enabled {
            opacity: 0.8;
          }

          &:focus {
            opacity: 0.8;
          }
        `
      : `
          padding-top: 6px;
          padding-bottom: 6px;
          border: 1px solid ${colors.surfaceText.lowEmphasis};
          background-color: transparent;
          color: ${colors.surfaceText.lowEmphasis};

          transition: border-color 0.2s ease;
          &:hover:enabled {
            border-color: ${hexToRgba(colors.primary.main, 0.12)};
          }

          &:focus {
            border-color: ${hexToRgba(colors.primary.main, 0.12)};
          }
        `)}
`;

const OtherButton = styled(ButtonBase)`
  height: 30px;
  padding: 3px 16px;
  border-radius: 15px;
  display: flex;
  align-items: center;

  color: ${colors.surfaceText.lowEmphasis};
  background-color: transparent;
  transition: background-color 0.2s ease;

  &:hover:enabled {
    background-color: ${colors.primary[50]};
  }

  &:focus {
    background-color: ${colors.primary[50]};
  }
`;

const ButtonText = styled.div`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`;

/* Main Content */

const Container = styled.div`
  padding: 32px 0;
  display: flex;
`;

const Title = styled.span`
  ${typography.heading5};
  font-weight: bold;
  letter-spacing: 0.3px;
`;

const Subtitle = styled.span`
  ${typography.subtitle1};
  letter-spacing: 0.5px;
  line-height: 28px;
  opacity: 0.6;
`;

const CardsContainer = styled.div`
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
  flex-wrap: wrap;
`;

const LeftContainer = styled.div`
  width: 100%;

  ${breakpoints["tablet-up"]} {
    width: ${({ mapView }) => (mapView ? "calc(50% - 5px)" : "100%")};
  }
`;

const MapContainer = styled.div`
  display: ${({ mapView }) => (mapView ? "block" : "none")};
  width: ${({ mapView }) => (mapView ? "calc(50% + 5px)" : "0%")};
  height: calc(100vh - 193px);
  padding-left: 10px;
  position: sticky;
  top: 161px;
  border-radius: 4px;

  ${breakpoints["phone-only"]} {
    display: none;
  }
`;
