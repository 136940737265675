import React from "react";
import styled from "@emotion/styled";
import { breakpoints, colors, hexToRgba } from "./../../util/consts";
import typography from "./../../util/typography";
import Dialog from "./Dialog";
import { DialogProps } from "./DialogTypes";
import { rebookDataStore } from "../../mobx/stores/RebookDataStore";

interface Props extends DialogProps {
  left: React.ReactNode;
  right: React.ReactNode;

  // @deprecated
  leftRender?: React.ComponentType;
  // @deprecated
  rightRender?: React.ComponentType;
  // @deprecated
  forwardedProps?: any;
}

export class SplitDialog extends React.Component<Props> {
  render() {
    rebookDataStore.dialogID = this.props.dialogId;

    return (
      <Dialog
        dialogId={this.props.dialogId}
        ariaLabel="some-other-modal"
        trigger={this.props.data.trigger}
      >
        <Container aria-label="amodal">
          <Content>
            {this.props.leftRender && (
              <LeftContainer>
                {React.createElement(this.props.leftRender, this.props.forwardedProps)}
              </LeftContainer>
            )}
            {this.props.rightRender && (
              <RightContainer>
                {React.createElement(this.props.rightRender, this.props.forwardedProps)}
              </RightContainer>
            )}
            {this.props.left && <LeftContainer>{this.props.left}</LeftContainer>}
            {this.props.right && <RightContainer>{this.props.right}</RightContainer>}
          </Content>
        </Container>
      </Dialog>
    );
  }
}

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100%;
  max-height: 100vh;
  border-radius: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${colors.surface.light};

  ${breakpoints["laptop-up"]} {
    max-width: 1200px;
    height: 900px;
    max-height: calc(100vh - 96px);
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const LeftContainer = styled.div`
  position: relative;
  flex: 0 0 320px;
  display: flex;
  overflow: hidden;

  ${breakpoints["phone-only"]} {
    display: none;
  }

  ${breakpoints["laptop-up"]} {
    flex-basis: 380px;
  }
`;

const RightContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  background-color: #f4f8f9;
  height: 100%;
  overflow-y: auto;
`;

export const LeftContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 53px 30px;
  overflow-y: auto;
`;

export const RightContent = styled.div`
  position: absolute;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 50px;
  padding-left: 24px;
  padding-right: 24px;
  overflow-y: auto;

  ${breakpoints["tablet-up"]} {
    padding-left: 48px;
    padding-right: 48px;
  }

  ${breakpoints["laptop-up"]} {
    padding-left: 86px;
    padding-right: 86px;
  }

  ${breakpoints["phone-only"]} {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const Seperator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${hexToRgba(colors.primary.main, 0.1)};
  margin-top: 31.5px;
  margin-bottom: 19px;
`;

export const SideHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.23px;
  line-height: 24px;
  margin-bottom: 15px;
`;

export const SideLabel = styled.div`
  ${typography.subtitle1};
  opacity: 0.6;
  letter-spacing: 0.63px;
  line-height: 20px;
  margin-bottom: 9px;
  margin-top: 9px;
`;

export const SideValue = styled.div`
  ${typography.body1};
  letter-spacing: 0.63px;
  line-height: 20px;
  margin-bottom: 3px;
`;

export const RightPadding = styled.div`
  ${breakpoints["tablet-up"]} {
  }

  ${breakpoints["laptop-up"]} {
    margin: 28px 30px;
  }
`;

export const LeftFooterText = styled.div<{ fontWeight: number }>`
  ${typography.subtitle1};
  letter-spacing: 1.44px;
  font-weight: ${({ fontWeight }) => fontWeight};
`;

export const LeftSection = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
