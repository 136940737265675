import React, { PureComponent } from "react";

import { FundInvoice } from "./FundInvoice";
// import { ClientInvoiceComp } from "./../../../components/dialogs/ClientActionDialog/Invoices";

import { TabContainer, TabHeader } from "./index";
import { FundRequirements } from './../../../util/bookings';
import { AccItemStatus, AccountItem, AccItemType } from './../../../sdk/account_items_pb';
import { ClientInvoice } from '../ClientActionDialog/Invoices';

interface Props{
  invoices: Array<AccountItem.AsObject>;
}
export default class Failed extends PureComponent<Props>{
  render() {
    const { invoices } = this.props;

    const compPay = invoices.filter((v)=>v.status===AccItemStatus.ACC_ITEM_ERROR && v.type==AccItemType.ACC_ITEM_BOOKING_PAYMENT);
    const compFunds = invoices.filter((v)=>v.status===AccItemStatus.ACC_ITEM_ERROR && v.type==AccItemType.ACC_ITEM_FUND);
  
    return (
      <React.Fragment>
        {compPay.length > 0 &&
          <TabContainer>
            <TabHeader>Payments</TabHeader>
            {compPay.map((v: AccountItem.AsObject)=><ClientInvoice key={v.id} invoice={v} {...this.props} />)}
          </TabContainer>}

        {compFunds.length > 0 &&
          <TabContainer>
            <TabHeader>Fund Claims</TabHeader>
            {compFunds.map((v: AccountItem.AsObject)=><FundInvoice key={v.id} invoice={v} {...this.props} />)}
          </TabContainer>}
      </React.Fragment>
    );
  }
}
