import React, { Component } from "react";
import qs from "qs";
import { format } from "date-fns";

// Components
import PagesContainer, { Page } from "./../../components/elements/PagesContainer";
import ResourceRender from "./../../components/elements/ResourceRender";
import ResourceFetch from "./../../components/elements/ResourceFetch";

// Pages
import InitialPage from "./../../components/elements/IframeCmpts/Initial";
import FormPage from "./../../components/elements/IframeCmpts/Form";
import CompletePage from "./../../components/elements/IframeCmpts/Complete";

import LoadingPage from "./../../components/routes/LoadingPage";
import ResourcePageError from "./../../components/elements/ResourcePageError";

class IframeLocs extends Component {
  state = {
    date: (() => {
      const query = qs.parse(location.search.substring(1));
      return query.StartDate || format(new Date(), "yyyy-MM-dd");
    })()
  };

  setDate = (newDate) => {
    this.setState({ date: format(newDate, "yyyy-MM-dd") });
  };

  renderPage = React.memo((props) => (
    <PagesContainer sidePanel={false} extraProps={props} initialPage={"initial"}>
      <Page name="initial" component={InitialPage} />
      <Page name="form" component={FormPage} />
      <Page name="complete" component={CompletePage} />
    </PagesContainer>
  ));

  render() {
    const { date } = this.state;
    const { match } = this.props;

    const isIFrame = match.path.indexOf("iframe") >= 0;
    return (
      <>
        <ResourceFetch
          type="Location"
          ids={match.params.lid}
          extraData={{ StartDate: date }}
          noLoginReq={true}
          force
        />
        <ResourceRender
          type="Location"
          ids={match.params.lid}
          denorm={true}
          compSuccess={this.renderPage}
          compError={ResourcePageError}
          compLoading={LoadingPage}
          forwardedProps={{ ...this.props, date, setDate: this.setDate, isIFrame }}
          propsReq={["Services"]}
        />
      </>
    );
  }
}

export default IframeLocs;
