import { observable } from "mobx";

class PaymentModalStore {
  @observable
  private _isOpen: boolean = false;

  @observable
  private _isCreditCardSet: boolean = true;

  @observable
  private _isMedicareFundSet: boolean = true;

  public get isOpen(): boolean {
    return this._isOpen;
  }

  public set isOpen(value: boolean) {
    this._isOpen = value;
  }

  public get isCreditCardSet(): boolean {
    return this._isCreditCardSet;
  }

  public set isCreditCardSet(value: boolean) {
    this._isCreditCardSet = value;
  }

  public get isMedicareFundSet(): boolean {
    return this._isMedicareFundSet;
  }

  public set isMedicareFundSet(value: boolean) {
    this._isMedicareFundSet = value;
  }
}

export const paymentModalStore = new PaymentModalStore();
