import React, { Component } from "react";
import styled from "@emotion/styled";

// components
// import ButtonBase from "./../../../components/ButtonBase";

import LocationAccordian from "./LocationAccordian";

import { AddButton } from "./../../../components/elements/AccordianElements";

export default class Locations extends Component {
  state = {
    adding: false
  };
  addCard = (e) => {
    this.setState({ adding: true });
  };
  closeNew = () => {
    this.setState({ adding: false });
  };
  render() {
    const { orgUser } = this.props;
    return (
      <Container>
        <Header>Locations</Header>
        {orgUser.Locations.map((v) => (
          <LocationAccordian key={v.Location} id={v.Location.ID} locUser={v} orgUser={orgUser} />
        ))}
        {!this.state.adding ? null : (
          <LocationAccordian
            key="newloc"
            id="newuser"
            locUser={null}
            orgUser={orgUser}
            closeNew={this.closeNew}
          />
        )}
        {orgUser.Locations.length >= orgUser.Org.Locations.length || this.state.adding ? null : (
          <AddButton onClick={this.addCard}>ADD NEW LOCATION</AddButton>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  margin: 0 auto;
  padding: 1px;
`;

const Header = styled.div`
  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 15.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
`;
