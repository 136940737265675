import React from "react";
import { ThirdPartyInvoice } from "./../../sdk/third_party_invoices_pb";
import {
  AccordianContainer,
  AccordianEdit,
  ButtonHead,
  Separator,
  ButtonDetails,
  OptionsContainer,
  NoSpacingIconButton
} from "../elements/AccordianElements";
import { HoverToolTip } from "../elements/HoverToolTip";
import Email from "../icons/Email";
import Options from "../icons/Options";
import { tpiStatusToText, tpiTotalCharge } from "./../../util/third-party-invoices";
import { aiStatusToText } from "./../../util/account-items";
import { AccItemStatus } from "./../../sdk/account_items_pb";
import { observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { Booking, BookingClient } from "./../../sdk/bookings_pb";
import { EditThirdPartyForm } from "./EditThirdPartyForm";
import { CloseButton } from "../elements/CloseButton";
import { moneyToText } from './../../util/money';
import DropdownSelect from '../DropdownSelect';
import { ISOCurrencyName } from '../../sdk/currencies_pb';

interface Props {
  booking: Booking.AsObject;
  client: BookingClient.AsObject;
  invoice: ThirdPartyInvoice.AsObject;
  dfltCcy: ISOCurrencyName;
  onRemove?: () => void;
  setPaid?: (isPaid: boolean) => void;
  reading_only?: boolean;
}

@observer
export class ThirdPartyRow extends React.Component<Props> {
  @observable
  private isEditing = false;

  private editOptions() {
    const { invoice, reading_only } = this.props;
    if (reading_only){
      return [this.download];
    } else {
      switch (invoice.accountItem!.status) {
        case AccItemStatus.ACC_ITEM_CREATED:
          return [this.edit, this.download, this.remove];
        case AccItemStatus.ACC_ITEM_PROCESSING:
          return [this.setPaid, this.edit, this.download, this.remove];
        case AccItemStatus.ACC_ITEM_COMPLETED:
          return [this.setUnpaid, this.download];
        case AccItemStatus.ACC_ITEM_CANCELLED:
          return [this.download];
        case AccItemStatus.ACC_ITEM_ERROR:
          return [this.download];
        default:
          return [this.download];
      }
    }
  }

  setPaid = {
    label: "Set Paid",
    onClick: () => {
      this.props.setPaid!(true);
    }
  };
  setUnpaid = {
    label: "Set Unpaid",
    onClick: () => {
      this.props.setPaid!(false);
    }
  };

  edit = {
    label: "Edit",
    onClick: () => {
      this.isEditing = true;
    }
  };

  remove = {
    label: "Remove",
    onClick: () => {
      this.props.onRemove!();
    }
  };

  download = {
    label: "Download",
    onClick: () => {
      window.open(this.props.invoice.media!.downloadUrl, "_blank");
    }
  };

  render() {
    const { invoice } = this.props;
    return (
      <AccordianContainer>
        <AccordianEdit>
          <ButtonHead style={{width: "25%"}}>{invoice.details!.companyName}</ButtonHead>
          <Separator />
          <ButtonDetails style={{width: "15%"}}>{invoice.accountItem!.invoiceId}</ButtonDetails>
          <Separator />
          <ButtonDetails>{aiStatusToText(invoice.accountItem!.status)}</ButtonDetails>
          <Separator />
          <HoverToolTip tip={invoice.statusMessage ? invoice.statusMessage : "Email will be sent after PROCESS button is clicked" }>
            <Email />
          </HoverToolTip>
          <Separator />
          <ButtonDetails style={{ fontWeight: "bold", width: "70px" }}>
            {moneyToText(tpiTotalCharge(invoice), false)}
          </ButtonDetails>
          {this.isEditing ? (
            <CloseButton 
              style={{
                position: "relative",
                padding: "2px",
              }} 
            onClick={() => (this.isEditing = false)} />
          ) : (
            <OptionsContainer>
              <DropdownSelect options={this.editOptions()}>
                <NoSpacingIconButton>
                  <Options />
                </NoSpacingIconButton>
              </DropdownSelect>
            </OptionsContainer>
          )}
        </AccordianEdit>
        {this.isEditing && (
          <EditThirdPartyForm
            booking={this.props.booking}
            client={this.props.client}
            invoice={invoice}
            onRequestClose={() => {
              this.isEditing = false;
            }}
            dfltCcy={this.props.dfltCcy}
          />
        )}
      </AccordianContainer>
    );
  }
}
