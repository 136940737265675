import { Offering } from './../sdk/offerings_pb';

export function offeringTypeToText(offType: Offering.Type): string {
  switch (offType) {
    case Offering.Type.PRODUCT:
      return 'Product';
    case Offering.Type.SERVICE:
      return 'Service';
    default:
      return 'Unknown';
  }
}
