import apiService from "./../../../redux/services/api";
import {
  Resource,
  ResourceList
} from "./types";

import {
  resourceDefinitions,
  resourceListDefinitions
} from "./definitions";

import * as Actions from "./actions";

import { generateID } from "./../../../util";

export * from "./actions";

// Thunk Actions.
export function action(resource: Resource, actionType: string, actionData?: any, actionID: string|null = null) {
  return async (dispatch: any) => {
    // Check the action and type definitions are correct.
    const { $Metadata, ...resourceData } = resource;

    if (!$Metadata || !$Metadata.Type) {
      throw new Error("missing $Metadata or $Metadata.Type in resource");
    }
    if (!resourceDefinitions.hasOwnProperty($Metadata.Type)) {
      throw new Error(`invalid resource type ${$Metadata.Type}`);
    }

    const resourceTypeDef = resourceDefinitions[$Metadata.Type];

    if (!resourceTypeDef.actions.hasOwnProperty(actionType)) {
      throw new Error(`invalid resource action ${action} for type ${$Metadata.Type}`);
    }

    const actionDef = resourceTypeDef.actions[actionType](actionData, resourceData, $Metadata);
    if (actionDef === null) {
      throw new Error(`unsupported resource action ${action} for type ${$Metadata.Type}`);
    }

    // Generate the action id.
    const aid = actionID||generateID();

    // Dispatch the start action.
    dispatch(Actions.actionStart(resource, aid, actionType, actionData));

    // Perform the request, dispatching a success or fail action depending on
    // the result and then returning the result.
    try {
      let res = await apiService.performRequest({
        id: aid,
        method: actionDef.method,
        path: actionDef.path,
        data: actionDef.data,
        query: actionDef.query
      });

      dispatch(Actions.actionSucceeded(resource, aid, actionType, actionData, res));
      return Promise.resolve(res);
    } catch (err) {
      if (err instanceof Error) {
        dispatch(Actions.actionFailed(resource, aid, actionType, actionData, err));
      }
      throw err;
    }
  };
}

export function listAction(list: ResourceList, clearFirst: boolean, actionType: string, actionID: string|null = null) {
  return async (dispatch: any, getState: any) => {
    // Check the action and type definitions are correct.
    const { $Metadata } = list;

    if (!$Metadata || !$Metadata.List) {
      throw new Error("missing $Metadata or $Metadata.Type in resource");
    }
    if (!resourceListDefinitions.hasOwnProperty($Metadata.List)) {
      throw new Error(`invalid resource list ${$Metadata.List}`);
    }

    const resourceListDef = resourceListDefinitions[$Metadata.List];
    if (!resourceListDef.actions.hasOwnProperty(actionType)) {
      throw new Error(`invalid resource list action ${action} for list ${list}`);
    }

    const actionDef = resourceListDef.actions[actionType]($Metadata);
    if (actionDef === null) {
      throw new Error(`unsupported resource action ${action} for type ${list}`);
    }

    // Generate the action id.
    const aid = actionID||generateID();

    // Dispatch the start action.
    dispatch(Actions.listActionStart(list, clearFirst, aid, actionType));

    // Perform the request, dispatching a success or fail action depending on
    // the result and then returning the result.
    try {
      let res = await apiService.performRequest({
        id: aid,
        method: actionDef.method,
        path: actionDef.path,
        data: actionDef.data,
        query: actionDef.query
      });

      dispatch(Actions.listActionSucceeded(list, aid, actionType, res));
      return Promise.resolve(res);
    } catch (err) {
      if (err instanceof Error) {
        dispatch(Actions.listActionFailed(list, aid, actionType, err));
      }
      throw err;
    }
  };
}
