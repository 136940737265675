import React, { Component } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


interface Props {
  value?: string
  defaultCountry?: string
  name?: string
  id?:string
  required?: boolean
  disabled?: boolean
  onPhoneChange(phoneNumer: string): any
  onCountryChange(countryCode : string): any
}

export default class MobileInput extends Component<Props> {
 
    render() {
    const {
        value,
        defaultCountry,
        onCountryChange,
        onPhoneChange,
        disabled,
        name,
        id,
      } = this.props;

      function handleOnChange(value: string, data:any, event:any, formattedValue:any) {
        var str = value.slice(data.dialCode.length);
        if (str[0]== "0"){
          str = str.slice(1);
        }
        var chunks = str.match(/.{1,3}/g);
        if (chunks){
          var new_value = chunks!.join(" ");
        } else{
          new_value = ""
        }
        onCountryChange(data.countryCode.toUpperCase())
        onPhoneChange("+"+ data.dialCode + " " + new_value)
      }

    return(
        <PhoneInput
            autoFormat={false}
            country={defaultCountry ? defaultCountry : 'au'}
            countryCodeEditable={false}
            onlyCountries={['au', 'us']}
            value={value}
            disabled={disabled}
            onChange={handleOnChange}
            inputStyle={{width: '100%', height:'44px', lineHeight:'22px'}}
            inputProps={{
              name: name,
              id: id,
            }}
        />
    )
    }

}



