import React from 'react';
import styled from '@emotion/styled';
import * as fns from 'date-fns';
import { Booking, BookingClient, BookingType } from './../../../../sdk/bookings_pb';
import { UserBlock } from './../../../../components/elements/UserBlock';
import Avatar from './../../../../components/Avatar';
import Divider from './../../../../components/Divider';
import typography from './../../../../util/typography';
import { colors } from './../../../../util/consts';
import { toDate } from './../../../../util/timestamp';

interface Props {
  booking: Booking.AsObject;
  client?: BookingClient.AsObject;
}

export class BreakdownHeader extends React.Component<Props> {
  render() {
    const { booking, client } = this.props;
    const startTime = toDate(booking.startTime!);
    const endTime = fns.addSeconds(startTime, booking.duration!.seconds);
    
    return (
      <>
        <LeftHeader>
          {booking.type === BookingType.BOOKING_SINGLE ? (
            <UserBlock firstName={client!.firstName} lastName={client!.lastName} size="small" />
          ) : (
            <GBHeadContainer>
              <Avatar letters={`GB`} size={32} />
              <GBHeadTitle>Group Booking</GBHeadTitle>
            </GBHeadContainer>
          )}
        </LeftHeader>
        <Divider />
        <LeftHeader>
          <LeftHeaderTitle>{fns.format(startTime, "EEEE, dd/MM/yyyy")}</LeftHeaderTitle>
          <LeftHeaderBody>
            {fns.format(startTime, "hh:mma")} - {fns.format(endTime, "hh:mma")}
          </LeftHeaderBody>
          {/* <LeftHeaderBody>{booking.Location.Name}</LeftHeaderBody> */}
          <LeftHeaderBody>
            {booking.provider!.firstName} {booking.provider!.lastName}
          </LeftHeaderBody>
          <LeftHeaderBody>#{booking.friendlyId}</LeftHeaderBody>
        </LeftHeader>
      </>
    );
  }
}

const LeftHeader = styled.div`
  flex: 0 0 auto;
  padding: 32px 32px 22px 32px;
`;

const GBHeadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const GBHeadTitle = styled.div`
  font-size: 13.82px;
  font-weight: normal;
  letter-spacing: 0.09px;
  line-height: 19px;
  color: #2c2e3c;
  margin: 0;
  margin-left: 10px;
`;

const LeftHeaderTitle = styled.div`
  ${typography.subtitle1};
  letter-spacing: 1.44px;
  line-height: 20px;
  font-weight: 600;
  margin: 3px 0;
`;

const LeftHeaderBody = styled.div`
  ${typography.body1};
  letter-spacing: 1.44px;
  line-height: 20px;
  color: ${colors.surfaceText.medEmphasis};
  margin: 3px 0;
`;
