import React, { PureComponent } from "react";
import { withRouter, Link } from "react-router-dom";
import styled from "@emotion/styled";
// import qs from "qs";

// utils
import { colors, hexToRgba, breakpoints } from "./../../../util/consts";
import typography from "./../../../util/typography";

// components
import ButtonBase from "./../../../components/ButtonBase";
// import Dropdown from "./../../../components/Dropdown";
// import IconButton from "./../../../components/IconButton";
// import Clear from "./../../../components/icons/Clear";

class LandingCard extends PureComponent {
  state = {
    isDropdownOpen: false
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({ isDropdownOpen: !prevState.isDropdownOpen }));
  };

  closeDropdown = () => {
    this.setState({ isDropdownOpen: false });
  };

  render() {
    // const { isDropdownOpen } = this.state;
    const { resource, halfScreen } = this.props;

    return (
      <CardContainer halfScreen={halfScreen}>
        <ClickArea to={`/providers/${resource.ID}`}>
          <CardImage
            style={{
              backgroundImage: `url(${
                !resource.Banner ? resource.Org.Banner.DownloadURL : resource.Banner.DownloadURL
              })`
            }}
          />
          <div>
            <BusinessType>
              {resource.Categories.map(
                (c, i) => c && typeof c !== "undefined" && (i > 0 ? ", " + c.Value : c.Value)
              )}
            </BusinessType>
            <BusinessName>{resource.Name}</BusinessName>
            <BusinessAddress>{resource.Address.Raw}</BusinessAddress>
          </div>
        </ClickArea>
        <div>
          <NextDate>{resource.Filter.NextAvailString}</NextDate>
          <CardButton as={Link} to={`/bookings/locs/${this.props.resource.ID}`} target="_blank">
            {resource.Filter.NextAvailString === "Next 7 days"
              ? resource.Filter.totalSessions
              : resource.Filter.numSessions}{" "}
            available
          </CardButton>
        </div>
      </CardContainer>
    );
  }
}

export default withRouter(LandingCard);

const CardContainer = styled.div`
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;

  ${breakpoints["tablet-up"]} {
    width: ${({ halfScreen }) => (halfScreen ? "100" : "50")}%;
  }

  ${breakpoints["laptop-up"]} {
    width: ${({ halfScreen }) => (halfScreen ? "50" : "33.33")}%;
  }

  ${breakpoints["desktop-up"]} {
    width: ${({ halfScreen }) => (halfScreen ? "50" : "25")}%;
  }
`;

const ClickArea = styled(Link)`
  text-decoration: none;
`;

const CardImage = styled.div`
  overflow: hidden;
  padding-top: 66.67%;
  border-radius: 4px;
  background-size: cover;
  position: relative;
  filter: contrast(75%);
`;

const CardButton = styled(ButtonBase)`
  height: 22px;
  padding: 2px 14px;
  border-radius: 11px;
  color: ${colors.primary.main};
  background-color: ${hexToRgba(colors.primary.main, 0.15)};
  font-size: 10.8px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
`;

const BusinessType = styled.p`
  ${typography.body2};
  font-weight: bold;
  color: ${colors.secondary.main};
  margin: 5px 0;
  ${typography.elipse};
`;

const BusinessName = styled.p`
  ${typography.subtitle1};
  font-weight: bold;
  margin-bottom: 1px;
  ${typography.elipse};
`;

const BusinessAddress = styled.p`
  ${typography.body2};
  ${typography.lighter};
  margin-bottom: 6px;
  ${typography.elipse};
`;

const NextDate = styled.span`
  ${typography.caption};
  ${typography.lightest};
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 12px;
  ${typography.elipse};
`;

// DROPDOWN

/*
<Dropdown
  isOpen={isDropdownOpen}
  mobileFullscreen={true}
  onClickOutside={this.closeDropdown}
  render={({ styles, ref }) => (
    <DropdownContainer style={styles} ref={ref}>
      <div
        onClick={this.closeDropdown}
        style={{
          flex: "1 1 auto"
        }}
      />
      <div
        style={{
          marginTop: "auto",
          flex: "0 0 auto",
          backgroundColor: colors.surface.light
        }}
      >
        <DropdownHeader>
          <DropdownText>Book now</DropdownText>
          <IconButton mini onClick={this.closeDropdown}>
            <Clear />
          </IconButton>
        </DropdownHeader>
        <DropdownContent>
          <DropdownSectionContainer>
            <DropdownText style={{ marginBottom: 8 }}>Next Available</DropdownText>
            <div style={{ margin: "-4px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                onClick={() =>
                  this.goToBooking(resource.ID, {
                    StartDate: nextSessions.sessions[0].Date,
                    StartTime: nextSessions.sessions[0].Times[0]
                  })
                }
              >
                <DropdownButtonText>
                  {nextAvailableSessionString}{" "}
                  {format(nextAvailableSession, "hh:mmaaaaa")}m
                </DropdownButtonText>
              </DropdownButton>
            </div>
          </DropdownSectionContainer>
          {nextSessions.sessions.map((ftd, tfd_index) => (
            <DropdownSectionContainer key={ftd.Date}>
              <DropdownText style={{ marginBottom: 8 }}>
                {this.getDateString(new Date(ftd.Date))}
              </DropdownText>
              <div style={{ margin: "-4px" }}>
                {(tfd_index === 0 ? ftd.Times.slice(1) : ftd.Times).map((time) => (
                  <DropdownButton
                    key={time}
                    onClick={() =>
                      this.goToBooking(resource.ID, {
                        StartDate: ftd.Date,
                        StartTime: time
                      })
                    }
                  >
                    <DropdownButtonText>
                      {format(parse(time, "HH:mm:ss", new Date()), "hh:mmaaaaa")}m
                    </DropdownButtonText>
                  </DropdownButton>
                ))}
              </div>
            </DropdownSectionContainer>
          ))}
        </DropdownContent>
        <DropdownFinalButton onClick={() => this.goToBooking(resource.ID)}>
          <DropdownFinalButtonText>See All Appointments</DropdownFinalButtonText>
        </DropdownFinalButton>
      </div>
    </DropdownContainer>
  )}
>
  <CardButton onClick={this.toggleDropdown}>
    {nextSessions.totalSessions} available
  </CardButton>
</Dropdown>
*/

// const DropdownContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 300px;

//   ${breakpoints["phone-only"]} {
//     background-color: ${hexToRgba(colors.primary.main, 0.5)};
//   }

//   ${breakpoints["tablet-up"]} {
//     margin-top: 7px;
//     margin-bottom: 7px;
//     border: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
//     border-radius: 4px;
//     overflow: hidden;
//   }
// `;

// const DropdownHeader = styled.div`
//   height: 56px;
//   padding: 0 24px;
//   border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};

//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// `;

// const DropdownText = styled.div`
//   color: #2c2e3c;
//   font-size: 11.85px;
//   font-weight: 600;
//   letter-spacing: 0.16px;
//   line-height: 16px;
// `;

// const DropdownButton = styled(ButtonBase)`
//   padding: 6px 16px;
//   border-radius: 15px;
//   transition: opacity 0.2s ease;
//   color: ${colors.whiteText.highEmphasis};
//   background-color: ${colors.secondary.main};
//   height: 30px;
//   width: calc(33.33% - 8px);
//   margin: 4px;

//   &:hover:enabled {
//     opacity: 0.8;
//   }

//   &:focus {
//     opacity: 0.8;
//   }

//   &:disabled {
//     background-color: ${hexToRgba(colors.primary.main, 0.12)};
//   }
// `;

// const DropdownButtonText = styled.div`
//   font-size: 10.8px;
//   letter-spacing: 0.5px;
//   line-height: 18px;
//   text-align: center;
// `;

// const DropdownContent = styled.div`
//   width: 100%;
//   padding: 24px;
// `;

// const DropdownSectionContainer = styled.div`
//   margin-bottom: 22px;

//   &:last-of-type {
//     margin-bottom: 0;
//   }
// `;

// const DropdownFinalButton = styled(ButtonBase)`
//   padding: 6px 16px;
//   transition: opacity 0.2s ease;
//   color: ${colors.whiteText.highEmphasis};
//   background-color: ${colors.primary.main};
//   height: 48px;
//   width: 100%;

//   &:hover:enabled {
//     opacity: 0.8;
//   }

//   &:focus {
//     opacity: 0.8;
//   }

//   &:disabled {
//     background-color: ${hexToRgba(colors.primary.main, 0.12)};
//   }

//   &:first-of-type {
//     margin-left: 0;
//   }

//   &:last-of-type {
//     margin-right: 0;
//   }
// `;

// const DropdownFinalButtonText = styled.div`
//   font-size: 11.85px;
//   font-weight: 600;
//   letter-spacing: 0.5px;
//   line-height: 18px;
//   text-align: center;
// `;
