export const colors = {
  primary: {
    main: "#2C2E3C",
    900: "#2C2E3C",
    800: "#404254",
    700: "#515469",
    600: "#636780",
    500: "#717691",
    400: "#8689A2",
    300: "#9B9EB4",
    200: "#B7BACB",
    100: "#D2D5E3",
    50: "#EEEDF7",
  },
  secondary: {
    main: "#00B1B6",
    900: "#09645D",
    800: "#088786",
    700: "#069C9D",
    600: "#00B1B6",
    500: "#00C1C8",
    400: "#2CCBCD",
    300: "#52D4D4",
    200: "#83E1DF",
    100: "#B3EDEA",
    50: "#E0F8F7",
  },
  tertiary: {
    main: "#B7BACB",
  },
  quaternary: {
    main: "#f4f8f9"
  },
  transparent: {
    main: "transparent"
  },
  surface: {
    light: "#FFFFFF",
    dark: "#F5F5F5",
  },
  surfaceText: {
    highEmphasis: "rgba(44, 46, 60, 1)",
    medEmphasis: "rgba(44, 46, 60, 0.6)",
    lowEmphasis: "rgba(44, 46, 60, 0.38)",
  },
  whiteText: {
    highEmphasis: "rgba(255, 255, 255, 1)",
    medEmphasis: "rgba(255, 255, 255, 0.6)",
    lowEmphasis: "rgba(255, 255, 255, 0.38)",
  },
  blackText: {
    highEmphasis: "rgba(0, 0, 0, 0.87)",
    medEmphasis: "rgba(0, 0, 0, 0.6)",
    lowEmphasis: "rgba(0, 0, 0, 0.38)",
  }
};

export const breakpoints = {
  "phone-only": "@media (max-width: 743px)",
  "tablet-up": "@media (min-width: 744px)",
  "tablet-and-down": "@media (max-width: 969px)",
  "laptop-up": "@media (min-width: 970px)",
  "desktop-up": "@media (min-width: 1240px)",
  "big-desktop-up": "@media (min-width: 1510px)",
};

export function hexToRgba(hex, opacity) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? "rgba(" + parseInt(result[1], 16) + ", " + parseInt(result[2], 16) + ", " + parseInt(result[3], 16) + ", " + opacity + ")"
    : null;
}

export const FundType = {
  None: 'None',
  AUMedicare: 'AUMedicare', // idk why this is here. it's not a real fund type
  AUMedicareStd: 'AUMedicareStd',
  AUMedicareBulk: 'AUMedicareBulk',
  AUDVA: 'AUDVA',
  AUDVAStd: 'AUDVAStd',
  AUDVAAllied: 'AUDVAAllied',
  AUHICAPS: 'AUHICAPS',
  CreditCard: 'CreditCard',
  ThirdPartyInvoice: 'ThirdPartyInvoice',
}

export const availableFundTypes = [
  FundType.CreditCard,
  FundType.AUMedicareStd,
  FundType.AUMedicareBulk,
  FundType.AUHICAPS,
  FundType.AUDVAStd,
  FundType.AUDVAAllied,
  FundType.ThirdPartyInvoice,
]

export function mapFund(fund) {
  switch (fund) {
  case FundType.AUMedicare:
    return "Medicare";
  case FundType.AUDVA:
    return "DVA";
  case FundType.AUHICAPS:
    return "HICAPS/Medipass";
  case "AUVictoriaTAC":
    return "TAC";
  default:
    return "";
  }
}

export function offFundToText(fund) {
  switch (fund) {
  case "None":
    return  "No Fund";
  case "CreditCard":
    return  "Credit Card";
  case "ThirdPartyInvoice":
    return  "Third Party Invoice";
  case "AUMedicareStd":
    return  "Credit Card + Medicare Standard";
  case "AUMedicareBulk":
    return  "Medicare (Bulk Billing)";
  case "AUDVAStd":
    return  "DVA";
  case "AUDVAAllied":
    return  "DVA (Allied Health)";
  case "AUHICAPS":
    return  "HICAPS/Medipass";
  case "AUVictoriaTAC":
    return  "TAC";
  case "BookingPayment":
    return "Credit card";
  case "ThirdParty":
      return "Third Party Invoice";
  case "Multiple":
      return "Multiple Funds";
  default:
    return "";
  }
}

export function sortOfferings(offerings) {
  if (!offerings) {
    return []
  }
  return offerings.slice().sort((a, b) => {
    return new Date(a.CreatedAt) < new Date(b.CreatedAt);
  });
}
