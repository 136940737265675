import { ThirdPartyInvoice } from './../sdk/third_party_invoices_pb';
import { Money } from './../sdk/money_pb';
import { add, moneyToText } from './money';

export function tpiStatusToText(status: ThirdPartyInvoice.Status): string {
  switch (status) {
    case ThirdPartyInvoice.Status.WAITING:
      return 'Waiting';
    case ThirdPartyInvoice.Status.SENT:
      return 'Sent';
    default:
      return 'Unknown';
  }
}

export function tpiTotalCharge(tpi: ThirdPartyInvoice.AsObject): Money.AsObject {
  // add up all the charges for the items in the invoice
  return add(...tpi.details!.lineItemsList.map(item => add(item.charge!.amount!, item.charge!.tax!)));
}
