import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import styled from "@emotion/styled";

import typography from "./../../util/typography";
import * as ReduxDialogs from "./../../redux/features/dialogs";
import { getResource } from "./../../redux/features/resources/helpers";
import { breakpoints, FundType } from "./../../util/consts";
import Form from "./../../components/form/Form";
import SectionsContainer from "./../../components/form/SectionsContainer";
import FooterContainer from "./../../components/form/FooterContainer";
import Section from "./../../components/form/Section";
import Header from "./../../components/form/Header";
import Label from "./../../components/form/Label";
import Input from "./../../components/form/Input";
import Error from "./../../components/form/Error";
import Button from "./../../components/Button";
import Cards from "./../../components/dialogs/ClientActionDialog/Cards";
import Funds from "./../../components/dialogs/ClientActionDialog/Funds";
import Referrals from "./../../components/dialogs/ClientActionDialog/Referrals";
import ResourceFetch from "./../../components/elements/ResourceFetch";
import ResourceRender from "./../../components/elements/ResourceRender";

import Dialog from "./Dialog";


class ClientPaymentMethodsDialog extends Component {
  render() {
    const {
      client,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
    } = this.props;



    return (
      <Dialog dialogId={this.props.dialogId}>
        <Container>
          <Header title="Add Payment Options" />
          <Small>This is optional and can always be edited later</Small>
          <SectionsContainer>
            <ResourceFetch type="Client" ids={{ ID: client.ID }} />
            <ResourceRender
              type="Client"
              ids={{ ID: client.ID }}
              denorm={true}
              forwardedProps={this.props}
              compError={() => <div>error</div>}
              compSuccess={({ client }) => (
                <React.Fragment>
                  <Section>
                    <Cards client={client} />
                  </Section>
                  <Section>
                    <Funds client={client} />
                  </Section>
                  <Section>
                    <Referrals client={client} />
                  </Section>
                </React.Fragment>
              )}
            />
          </SectionsContainer>
          <FooterContainer>
            <Button
              style={{ minWidth: 150 }}
              variant="contained"
              color="secondary"
              type="submit"
              onClick={() => this.props.dispatch(ReduxDialogs.actions.close(this.props.dialogId))}
            >
              Done
            </Button>
          </FooterContainer>
        </Container>
      </Dialog>
    );
  }
}

function mapStateToProps(state, props) {
  const client = getResource(state.resources, "Client", {
    ID: props.data.client.ID,
  }, true);
  return { client };
}

export default compose(
  connect(mapStateToProps),
)(ClientPaymentMethodsDialog);

const Container = styled.div`
  width: 100vw;
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    padding: 64px 90px;
    max-width: 700px;
  }
`;

const Small = styled.div`
  ${typography.subtitle2};
  opacity: 0.6;
  letter-spacinh: 1.26px;
  margin-top: 1em;
`;
