enum DialogType {
  ActionDialog = "Action",
  InfoDialog = "Info",
  AccountDialog = "Account",
  QuickBookDialog = "QuickBook",
  SplitDialog = "Split",
  NewBookingDialog = "NewBooking",
  AppointmentDialog = "Appointment",
  ClientActionDialog = "ClientAction",
  StaffActionDialog = "StaffAction",
  OfferingDialog = "Offering",
  AddStaffDialog = "AddStaffDialog",
  AddClientDialog = "AddClientDialog",
  WelcomeDialog = "WelcomeDialog",
  CancelDialog = "CancelDialog",
  RejectDialog = "RejectDialog",
  BatchChargeDialog = "BatchChargeDialog",
  TimeOffDialog = "TimeOffDialog",
  ConsumerAptDialog = "ConsumerAptDialog",
  ExportTransactionsDialog = "ExportTransactionsDialog",
  PullServicesDialog = "PullServicesDialog",
  ClientPaymentMethodsDialog = "ClientPaymentMethodsDialog",
}

export default DialogType;
