import React, { Component } from "react";
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

import * as ReduxDialogs from "./../../../redux/features/dialogs";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { parseFormError } from "./../../../util/form";

// utils
import { colors } from "./../../../util/consts";

// import Label from "./../../../components/form/Label";
import Error from "./../../../components/form/Error";
import LoadingButton from "./../../../components/LoadingButton";
import Section from "./../../../components/form/Section";

import DropdownSelect from "./../../../components/DropdownSelect";
import LoadingIcon from "./../../../components/icons/Loading";

import {
  AccordianForm,
  AccordianContainer,
  // AccordianButton,
  AccordianEdit,
  NewHeader,
  Separator,
  OptionsContainer,
  ButtonHead,
  ButtonDetails,
  ButtonContainer,
  NoSpacingIconButton
} from "./../../../components/elements/AccordianElements";
import { SpecificLocationClientSelector } from "./../../../components/form/ResourceSelector";

// icons
import ClearIcon from "./../../../components/icons/Clear";
import Options from "./../../../components/icons/Options";
import { bookingStore } from "./../../../stores/booking-store";
import { Booking, BookingClient } from '../../../sdk/bookings_pb';
import { Client } from '../../../sdk/clients_pb';

function mapClientStatus(client) {
  switch (client.Status) {
    case "Created":
      return "Waiting for Approval";
    case "Approved":
      return "Approved";
    case "Rejected":
      return "Rejected";
    case "Cancelled":
      if (client.CancelledByUser) {
        return "Cancelled by User";
      } else {
        return "Cancelled by Provider";
      }
  }
  return "";
}

class ClientAccordian extends Component {
  state = {
    open: false,
    isLoading: false
  };

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  cancelClient = () => {
    const { dispatch, client, booking } = this.props;
    if (!client) {
      return;
    }
    dispatch(ReduxDialogs.openCancel(null, booking.ID, client.Client.ID, () => {}));
  };

  approveClient = async () => {
    const { booking, client, dispatch, toastManager } = this.props;
    if (!client) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      await dispatch(
        ResourceActions.action(booking, "ActionClient", {
          Client: client.Client.ID,
          Action: "ApproveBooking"
        })
      );
      toastManager.add("Client approved.", { appearance: "success" });
      this.setState({ isLoading: false });
    } catch (err) {
      parseFormError(err, {}, () => {}, this.props);
      this.setState({ isLoading: false });
    }
  };

  rejectClient = () => {
    const { booking, client, dispatch } = this.props;
    if (!client) {
      return;
    }
    // RejectDialog is in type, requiring a protobuff booking and client
    // it only uses ID now so we just set the ID. 
    //If it requires the complete obj, we can initialize manually, get it from the mobx store or call gRoc
    const bookingTyped = new Booking();
    bookingTyped.setId(booking.ID);
    const clientTyped = new BookingClient();
    clientTyped.setClientId(client.Client.ID)

    dispatch(
      ReduxDialogs.openReject(null, bookingTyped.toObject(), clientTyped.toObject(), ()=>{})
    );
  };

  rebookClient = async () => {
    // const { dispatch, client, booking } = this.props;
    // if (!client) {
    //   return;
    // }
    // try {
    //   await dispatch(ResourceActions.action(booking, "ActionClient", client.Client));
    // } catch (err) {
    //   parseFormError(err, {}, () => {}, this.props);
    // }
  };

  renderContent() {
    const {
      id,
      booking,
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit
    } = this.props;

    return (
      <AccordianForm
        id={`book-off-${id}-form`}
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <Section>
          {/* <Label htmlFor={`book-off-${id}`}>Service or Product</Label> */}
          <SpecificLocationClientSelector
            id={`book-off-${id}`}
            name="Client"
            placeholder="Select a client"
            location={booking.Location}
            value={values.Client}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.Client && errors.Client}
            org={booking.Location.Org}
          />
          {!!errors.Client && touched.Client && <Error>{errors.Client}</Error>}
        </Section>

        <ButtonContainer>
          <LoadingButton
            style={{
              minWidth: 150
            }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Save
          </LoadingButton>
        </ButtonContainer>
      </AccordianForm>
    );
  }

  render() {
    const { open, isLoading } = this.state;
    const { client, closeNew, setClient } = this.props;

    let editOptions;
    if (client) {
      switch (client.Status) {
        case "Created":
          editOptions = [
            {
              label: "Approve",
              onClick: this.approveClient
            },
            {
              label: "Reject",
              onClick: this.rejectClient
            }
          ];
          break;
        case "Approved":
          editOptions = [
            {
              label: "Cancel",
              onClick: this.cancelClient
            }
          ];
          break;
        case "Rejected":
          break;
        case "Cancelled":
          // editOptions = [
          //   {
          //     label: "Rebook",
          //     onClick: this.rebookClient
          //   }
          // ];
          break;
      }
    }

    return (
      <AccordianContainer>
        {!client ? null : (
          <AccordianEdit mayClick onClick={() => setClient(client.Client.ID)}>
            <ButtonHead>
              {client.Client.FirstName} {client.Client.LastName}
            </ButtonHead>
            <Separator />
            <ButtonDetails>{client.Client.MobileNumber || client.Client.Email}</ButtonDetails>
            <Separator />
            <ButtonDetails>{mapClientStatus(client)}</ButtonDetails>
            {isLoading ? (
              <OptionsContainer>
                <LoadingIcon width={16} height={16} color="#2c2e3c" />
              </OptionsContainer>
            ) : (
              <OptionsContainer>
                {open ? (
                  <NoSpacingIconButton onClick={this.toggleOpen}>
                    <ClearIcon fill={colors.primary.main} />
                  </NoSpacingIconButton>
                ) : (
                  <DropdownSelect options={editOptions}>
                    <NoSpacingIconButton>
                      <Options />
                    </NoSpacingIconButton>
                  </DropdownSelect>
                )}
              </OptionsContainer>
            )}
          </AccordianEdit>
        )}
        {(open || !client) && (
          <React.Fragment>
            {!client && (
              <NewHeader>
                <ButtonHead>Add Client</ButtonHead>
                <NoSpacingIconButton onClick={closeNew}>
                  <ClearIcon fill={colors.primary.main} />
                </NoSpacingIconButton>
              </NewHeader>
            )}
            {this.renderContent()}
          </React.Fragment>
        )}
      </AccordianContainer>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ client }) => ({
    Client: !client ? null : client.Client.ID
  }),
  validationSchema: Yup.object().shape({
    Client: Yup.object().required("Client is required")
  }),
  enableReinitialize: false,
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    const { dispatch, client, booking } = props;
    // Adding to funds.
    try {
      if (!client) {
        await dispatch(
          ResourceActions.action(booking, "AddClient", {
            Client: values.Client.ID
          })
        );
        props.closeNew();
        bookingStore.load(booking.ID);
      }
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "Client"
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(ClientAccordian);
