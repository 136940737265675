/** @jsx jsx */
import { Component, Fragment } from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import { format, parse } from "date-fns";

// Utils
import { colors, breakpoints, hexToRgba } from "./../../../util/consts";
import typography from "./../../../util/typography";
import { daysOfWeek } from "./../../../util";

// Components
import Card from "./Card";
import Divider from "./../../../components/Divider";
import PlaceIcon from "./../../../components/icons/Place";
import { ProviderSidebar } from "./Sidebar";
import ResourceFetch from "./../../../components/elements/ResourceFetch";
import ResourceRender from "./../../../components/elements/ResourceRender";

class Provider extends Component {
  render() {
    const id = this.props.match.params.pid;
    const today = new Date();
    const daysOfTheWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ];

    return (
      <div style={{ maxWidth: "100vw" }}>
        <ResourceFetch
          noLoginReq={true}
          ids={id}
          type="Location"
          extraData={{
            StartDate: format(new Date(), "yyyy-MM-dd")
          }}
          force
        />
        <ResourceRender
          ids={id}
          type="Location"
          compSuccess={({ resource }) => (
            <Fragment>
              <HeadlineImage
                css={css`
                  background-image: url(${!resource.Banner
                    ? resource.Org.Banner.DownloadURL
                    : resource.Banner.DownloadURL});
                `}
              />
              <Container>
                <Content>
                  <div>
                    <BusinessType>
                      {typeof resource.Categories[0] !== "undefined" &&
                        resource.Categories.map((c, i) => (i === 0 ? c.Value : ", " + c.Value))}
                    </BusinessType>
                    <BusinessName>{resource.Name}</BusinessName>
                    <CenterLeftContainer>
                      <PlaceIcon
                        css={css`
                          margin-right: 6px;
                        `}
                        fill={colors.secondary.main}
                      />
                      <MainText
                        css={css`
                          ${typography.elipse};
                        `}
                      >
                        {resource.Address.Raw}
                      </MainText>
                    </CenterLeftContainer>
                    <div
                      css={css`
                        display: flex;
                        margin-top: 24px;
                      `}
                    >
                      <CenterLeftContainer
                        css={css`
                          margin-right: 30px;
                        `}
                      >
                        <FakeProfile
                          css={css`
                            background-color: ${colors.primary.main};
                            margin-right: 6px;
                          `}
                        />
                        {(resource.Providers || []).length} Staff
                      </CenterLeftContainer>
                      <CenterLeftContainer>
                        <FakeProfile
                          css={css`
                            background-color: ${colors.primary.main};
                            margin-right: 6px;
                          `}
                        />
                        {(resource.Services || []).length} Services
                      </CenterLeftContainer>
                    </div>
                  </div>
                  <Divider style={{ margin: "36px 0" }} />
                  <div>
                    <SectionHeading>Bio</SectionHeading>
                    <MainText style={{ whiteSpace: "pre-wrap" }}>
                      {resource.Org.Description}
                    </MainText>
                  </div>
                  <Divider style={{ margin: "36px 0" }} />
                  <div
                    css={css`
                      ${breakpoints["tablet-and-down"]} {
                        margin-bottom: 60px;
                      }
                    `}
                  >
                    <SectionHeading>Services &amp; Special Interests</SectionHeading>
                    <div
                      css={css`
                        display: inline-flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        margin: 0 -12px;
                        max-height: 140px;
                      `}
                    >
                      {(resource.Services || []).map((s) => (
                        <MainText
                          css={css`
                            margin: 0 12px;
                          `}
                          key={s.ID}
                        >
                          {s.Name}
                        </MainText>
                      ))}
                    </div>
                  </div>
                  <Divider style={{ margin: "36px 0" }} />
                  <div>
                    <SectionHeading>Staff</SectionHeading>
                    <div
                      css={css`
                        display: flex;
                        flex-wrap: wrap;
                        margin: -4px;
                      `}
                    >
                      {(resource.Providers || []).map((p) => (
                        <Card resource={p} key={p.User.ID} />
                      ))}
                    </div>
                  </div>
                  <Divider style={{ margin: "36px 0" }} />
                  <div
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                      margin: -10px;
                    `}
                  >
                    <div
                      css={css`
                        flex: 0 0 auto;
                        margin: 10px;
                        margin-right: 60px;
                      `}
                    >
                      <SectionHeading>Opening hours</SectionHeading>
                      <div
                        css={css`
                          display: flex;
                        `}
                      >
                        <div
                          css={css`
                            margin-right: 42px;
                          `}
                        >
                          {daysOfTheWeek.map((d) => (
                            <MainText
                              style={{
                                fontWeight: d === daysOfWeek[today.getDay()] ? "bold" : "normal"
                              }}
                              key={d}
                            >
                              {d}:
                            </MainText>
                          ))}
                        </div>
                        <div>
                          {daysOfTheWeek.map((d) => (
                            <MainText
                              style={{
                                fontWeight: d === daysOfWeek[today.getDay()] ? "bold" : "normal"
                              }}
                              key={d}
                            >
                              {resource.Schedule[d].OpensAt && resource.Schedule[d].ClosesAt
                                ? format(
                                    parse(resource.Schedule[d].OpensAt, "HH:mm:ss", new Date()),
                                    "hh:mmaaaaa"
                                  ) +
                                  "m - " +
                                  format(
                                    parse(resource.Schedule[d].ClosesAt, "HH:mm:ss", new Date()),
                                    "hh:mmaaaaa"
                                  ) +
                                  "m"
                                : "Closed"}
                            </MainText>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div
                      css={css`
                        flex: 1 1 212px;
                        margin: 10px;
                      `}
                    >
                      <SectionHeading>Contact Details</SectionHeading>
                      <div>
                        <MainText>{resource.Address.Raw}</MainText>
                        <CenterLeftContainer>
                          <MainText>
                            Phone: <A href={`tel:${resource.Phone}`}>{resource.Phone}</A>
                          </MainText>
                        </CenterLeftContainer>
                      </div>
                    </div>
                  </div>
                </Content>
                <ProviderSidebar resource={resource} />
              </Container>
            </Fragment>
          )}
          compLoading={() => <div>loading</div>}
          compError={() => <div>error</div>}
          denorm={true}
        />
      </div>
    );
  }
}

export default Provider;

const HeadlineImage = styled.div`
  width: 100%;
  height: 210px;
  background-size: cover;
  background-position: center;

  ${breakpoints["laptop-up"]} {
    height: 420px;
  }
`;

const Container = styled.div`
  max-width: 1440px;
  padding-top: 36px;
  padding-bottom: 24px;
  padding-left: 80px;
  padding-right: 80px;
  margin-bottom: 60px;
  margin: 0 auto;
  display: flex;

  ${breakpoints["tablet-and-down"]} {
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
  }
`;

const Content = styled.div`
  flex: 1 1 auto;
  padding-bottom: 24px;

  ${breakpoints["laptop-up"]} {
    margin-right: 48px;
  }
`;

const BusinessType = styled.div`
  ${typography.overline};
  letter-spacing: 0.25px;
  font-weight: 600;
  color: ${colors.secondary.main};
  text-transform: uppercase;
`;

const BusinessName = styled.div`
  ${typography.heading4};
  font-weight: 700;
`;

const CenterLeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SectionHeading = styled.div`
  font-size: 17.8px;
  color: ${colors.primary.main};
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
`;

const MainText = styled.div`
  ${typography.subtitle1};
  color: ${hexToRgba(colors.primary.main, 0.6)};
  letter-spacing: 0.5px;
  line-height: 28px;
`;

const FakeProfile = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 7px;
`;

const A = styled.a`
  text-decoration: none;
  color: ${colors.surfaceText.medEmphasis};

  transition: color 150ms ease;
  &:hover {
    color: ${colors.surfaceText.highEmphasis};
  }
`;
