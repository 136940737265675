// LockGroup alows for delaying processing while another asynchronous task
// runs.
export default class LockGroup<T> {
  holds: ((d: T)=>void)[] = [];

  // waitHere returns a promise that resolves when release is called.
  waitHere(): Promise<T> {
    return new Promise((resolve: (rd: T)=>void)=>{
      this.holds.push(resolve);
    });
  }

  // release resolves the promises added by waitHere.
  release(d: T) {
    this.holds.forEach((cb)=>cb(d));
    this.holds = [];
  }
}
