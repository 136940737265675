import React, { Component } from "react";
import styled from "@emotion/styled";

// components
import ReferralAccordian from "./ReferralAccordian";
import LargeDottedButton from "./../../../components/LargeDottedButton";
import { TabContainer, TabHeader, TabDetail } from "./index";

import LoadingIcon from "./../../../components/icons/Loading";
import ResourceRender from './../../../components/elements/ResourceRender';
import ResourceFetch from './../../../components/elements/ResourceFetch';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { BookingClient } from './../../../sdk/bookings_pb';

interface Props {
  bkClient: BookingClient.AsObject;
  resource?: any;
  rightMessage?: any;
  rightMessageProps?: any;
  disallowNew?: boolean;
  isLoading?: any;
  selRef: string;
  onSelect?: (provId: string) => void;
}

@observer
class Referrals extends Component<Props> {
  @observable
  private adding = false;
  
  addCard = (e:any) => {
    this.adding = true;
  };
  closeNew = () => {
    this.adding = false;
  };

  render() {
    const { resource: client, rightMessage, disallowNew, onSelect, selRef, isLoading, bkClient } = this.props;
    return (
      <TabContainer>
        <TabHeader>
          <div>
            Client's Referrals
            {onSelect && <Small>click to enable/disable</Small>}
          </div>
          {!isLoading && rightMessage &&
            <TabDetail>{rightMessage}</TabDetail>
          }
          {!isLoading && !selRef && onSelect &&
            <TabDetail style={{ color: "#eb8634", fontWeight: "bold" }}>No referrals selected</TabDetail>
          }
          {isLoading && <TabDetail><LoadingIcon width={16} height={16} color="#2c2e3c" /></TabDetail>}
        </TabHeader>
        {client.Referrals.map((k:any) => (
          onSelect ? 
          <ReferralAccordian
            key={k.ID}
            id={k.ID}
            client={client}
            referral={k}
            selected={k.ID===selRef}
            onSelect={(provId:string) =>onSelect(provId)}
            bkClient={bkClient}
          /> 
          :
          <ReferralAccordian
            key={k.ID}
            id={k.ID}
            client={client}
            referral={k}
            selected={k.ID===selRef}
            bkClient={bkClient}
          /> 
        ))}
        {!this.adding ? null : (
          <ReferralAccordian
            key="newref"
            id="newref"
            client={client}
            referral={null}
            closeNew={this.closeNew}
            onSelect={onSelect ? (provId:string) =>onSelect(provId) : null}
            bkClient={bkClient}
          />
        )}
        {(this.adding || disallowNew) ? null : (
          <LargeDottedButton onClick={this.addCard}>ADD NEW REFERRAL</LargeDottedButton>
        )}
      </TabContainer>
    );
  }
}

interface ActualProps {
  client: any;
  bkClient?: BookingClient.AsObject;
  selRef?: any;
  onSelect?: (provId: string)=>void;
}

export default class Loader extends React.Component<ActualProps> {
  render() {
    return (
      <>
        <ResourceFetch type="Client" ids={this.props.client.ID} force />
        <ResourceRender
          type="Client"
          ids={this.props.client.ID}
          compSuccess={Referrals}
          forwardedProps={this.props}
          denorm={true}
        />
      </>
    );
  }
}

const Small = styled.span`
  font-size: 10px;
  opacity: 0.4;
  margin-left: 10px;
`;
