import styled from "@emotion/styled";

const Section = styled.div`
  width: 100%;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export default Section;
