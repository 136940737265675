import React, { PureComponent } from "react";

import { FundInvoice } from "./FundInvoice";

import { TabContainer, TabHeader } from "./index";
import { AccItemStatus, AccountItem, AccItemType } from './../../../sdk/account_items_pb';
import { toJS } from 'mobx';
import { ClientInvoice } from '../ClientActionDialog/Invoices';
import { ToastManager } from 'react-toast-notifications';

interface Props {
  invoices: Array<AccountItem.AsObject>;
  dispatch?: any;
  toastManager?: ToastManager;
}
export class ShowInvoices extends PureComponent<Props> {
  render() {
    const { invoices } = this.props;
    
    const compPay = invoices.filter(
      (v: AccountItem.AsObject)=>
      (v.status==AccItemStatus.ACC_ITEM_PROCESSING || v.status==AccItemStatus.ACC_ITEM_COMPLETED) &&
      v.type==AccItemType.ACC_ITEM_BOOKING_PAYMENT);
    const compFunds = invoices.filter(
      (v: AccountItem.AsObject)=>
      (v.status==AccItemStatus.ACC_ITEM_CREATED || v.status==AccItemStatus.ACC_ITEM_PROCESSING || v.status==AccItemStatus.ACC_ITEM_COMPLETED) &&
      v.type==AccItemType.ACC_ITEM_FUND);

    return (
      <>
        {compPay.length > 0 &&
          <TabContainer>
            <TabHeader>Payment Invoice</TabHeader>
            {compPay.map((v: AccountItem.AsObject)=><ClientInvoice key={v.id} invoice={v} {...this.props} />)}
          </TabContainer>}

        {compFunds.length > 0 &&
          <TabContainer>
            <TabHeader>Fund Claims</TabHeader>
            {compFunds.map((v: AccountItem.AsObject)=><FundInvoice key={v.id} invoice={v} {...this.props} />)}
          </TabContainer>}
      </>
    );
  }
}
