import { observable } from "mobx";

class RebookDataStore {
  @observable
  private _isOpen: boolean = false;

  @observable
  private _dialogID: string = "";

  @observable
  private _daysAfter: number = 0;

  @observable
  private _isRebooking: boolean = false;

  @observable
  private _bookingID: string = "";

  @observable
  private _clientId: string = "";

  @observable
  private _locationId: string = "";

  @observable
  private _startTime: any = undefined;

  @observable
  private _duration: any = undefined;

  @observable
  private _provider: any = undefined;

  @observable
  private _offering: any = undefined;

  public get isRebooking(): boolean {
    return this._isRebooking;
  }

  public set isRebooking(value: boolean) {
    this._isRebooking = value;
  }

  public get isOpen(): boolean {
    return this._isOpen;
  }

  public set isOpen(value: boolean) {
    this._isOpen = value;
  }

  public get dialogID(): string {
    return this._dialogID;
  }

  public set dialogID(value: string) {
    this._dialogID = value;
  }

  public get daysAfter(): number {
    return this._daysAfter;
  }

  public set daysAfter(value: number) {
    this._daysAfter = value;
  }

  public get bookingID(): string {
    return this._bookingID;
  }

  public set bookingID(value: string) {
    this._bookingID = value;
  }

  public get offering(): any {
    return this._offering;
  }

  public set offering(value: any) {
    this._offering = value;
  }

  public get clientId(): string {
    return this._clientId;
  }

  public set clientId(value: string) {
    this._clientId = value;
  }

  public get locationId(): string {
    return this._locationId;
  }

  public set locationId(value: string) {
    this._locationId = value;
  }

  public get provider(): any {
    return this._provider;
  }

  public set provider(value: any) {
    this._provider = value;
  }

  public get startTime(): any {
    return this._startTime;
  }

  public set startTime(value: any) {
    this._startTime = value;
  }

  public get duration(): any {
    return this._duration;
  }

  public set duration(value: any) {
    this._duration = value;
  }

  public reset() {
    this._isOpen = false;
    this._dialogID = "";
    this._daysAfter = 0;
    this._isRebooking = false;
    this._bookingID = "";
    this._clientId = "";
    this._locationId = "";
    this._provider = undefined;
    this._offering = undefined;
    this._startTime = undefined;
    this._duration = undefined;
  }
}

export const rebookDataStore = new RebookDataStore();
