import { AccItemStatus, AccItemType } from './../sdk/account_items_pb';
import { AccTableRowStatus } from "./../sdk/accounts_table_pb";

export function aiStatusToText(status: AccItemStatus): string {
  switch (status) {
    case AccItemStatus.ACC_ITEM_CREATED:
      return 'Created';
    case AccItemStatus.ACC_ITEM_PROCESSING:
      return 'Processing';
    case AccItemStatus.ACC_ITEM_ERROR:
      return 'Error';
    case AccItemStatus.ACC_ITEM_COMPLETED:
      return 'Completed';
    case AccItemStatus.ACC_ITEM_CANCELLED:
      return 'Cancelled';
    case AccItemStatus.ACC_ITEM_REFUNDED:
      return 'Refunded';
    default:
      return 'Unknown';
  }
}


export function aiTypeToText(type: AccItemType): string {
  switch (type) {
    case AccItemType.ACC_ITEM_BOOKING_PAYMENT:
      return 'Credit Card';
    case AccItemType.ACC_ITEM_FUND:
      return 'Fund';
    case AccItemType.ACC_ITEM_ORG_FUND_TOPUP:
      return 'Org Fund Topup';
    case AccItemType.ACC_ITEM_ORG_PAYOUT:
      return 'Org Payout';
    case AccItemType.ACC_ITEM_SUBSCRIPTION_FEE:
      return 'Subscription fee';
    case AccItemType.ACC_ITEM_THIRD_PARTY:
      return 'Third Party';
    default:
      return 'Unknown';
  }
}

export function aiTypeToInternalType(acc_type: string): AccItemType {
  switch (acc_type) {
    case 'Charges':
      return AccItemType.ACC_ITEM_BOOKING_PAYMENT;
    case 'Claims':
      return AccItemType.ACC_ITEM_FUND;
    case 'Org Fund Topup':
      return AccItemType.ACC_ITEM_ORG_FUND_TOPUP;
    case 'Org Payout':
      return AccItemType.ACC_ITEM_ORG_PAYOUT;
    case 'Subscription fee':
      return AccItemType.ACC_ITEM_SUBSCRIPTION_FEE;
    case 'Invoices':
      return AccItemType.ACC_ITEM_THIRD_PARTY;
    default:
      return AccItemType.ACC_ITEM_TYPE_UNSPECIFIED;
  }
}

export function statusAccRowToText(status: AccTableRowStatus): string {
  switch(status){
    case AccTableRowStatus.ACC_ROW_ALL:
      return "All"
    case AccTableRowStatus.ACC_ROW_UNPROCESSED:
      return "Unprocessed"
    case AccTableRowStatus.ACC_ROW_PROCESSING:
      return "Processing"
    case AccTableRowStatus.ACC_ROW_CREATED:
      return "Created"
    case AccTableRowStatus.ACC_ROW_COMPLETED:
      return "Completed"
    case AccTableRowStatus.ACC_ROW_CANCELLED:
      return "Cancelled"
    case AccTableRowStatus.ACC_ROW_PAYMENT_ERROR:
      return "Payment Error"
    case AccTableRowStatus.ACC_ROW_CLAIM_ERROR:
      return "Claim Error"
    case AccTableRowStatus.ACC_ROW_UNPAID_TPI:
      return "Unpaid (TPI)"
    case AccTableRowStatus.ACC_ROW_PAID_TPI:
      return "Paid (TPI)"
    default: 
      return "Unknown"
  }
}

export function statusAccRowToDTO(status: string): AccTableRowStatus {
  switch(status){
    case "All":
      return AccTableRowStatus.ACC_ROW_ALL;
    case "Unprocessed":
      return AccTableRowStatus.ACC_ROW_UNPROCESSED;
    case "Processing":
      return AccTableRowStatus.ACC_ROW_PROCESSING;
    case "Created":
      return AccTableRowStatus.ACC_ROW_CREATED;
    case "Completed":
      return AccTableRowStatus.ACC_ROW_COMPLETED;
    case "Cancelled":
      return AccTableRowStatus.ACC_ROW_CANCELLED;
    case "Payment Error":
      return AccTableRowStatus.ACC_ROW_PAYMENT_ERROR;
    case "Claim Error":
      return AccTableRowStatus.ACC_ROW_CLAIM_ERROR;
    case "Unpaid (TPI)":
      return AccTableRowStatus.ACC_ROW_UNPAID_TPI;
    case "Paid (TPI)":
      return AccTableRowStatus.ACC_ROW_PAID_TPI;
    default: 
      return AccTableRowStatus.ACC_ROW_UNKNOWN;
  }
}

export function accItemTypeToText(accType: AccItemType): string {
  switch(accType){
    case AccItemType.ACC_ITEM_BOOKING_PAYMENT:
      return "Charges";
    case AccItemType.ACC_ITEM_FUND:
      return "Claims";
    case AccItemType.ACC_ITEM_ORG_FUND_TOPUP:
      return "Fund TopUps ";
    case AccItemType.ACC_ITEM_ORG_PAYOUT:
      return "Org Payouts";
    case AccItemType.ACC_ITEM_SUBSCRIPTION_FEE:
      return "Subscription";
    case AccItemType.ACC_ITEM_THIRD_PARTY:
      return "Invoices";
    default:
      return "Unspecified";
  }
}

export function accItemTypeToInternal(accType: string): AccItemType {
  switch(accType){
    case "Charges":
      return AccItemType.ACC_ITEM_BOOKING_PAYMENT;
    case "Claims":
      return AccItemType.ACC_ITEM_FUND;
    case "Fund TopUps ":
      return AccItemType.ACC_ITEM_ORG_FUND_TOPUP;
    case "Org Payouts":
      return AccItemType.ACC_ITEM_ORG_PAYOUT;
    case "Subscription":
      return AccItemType.ACC_ITEM_SUBSCRIPTION_FEE;
    case "Invoices":
      return AccItemType.ACC_ITEM_THIRD_PARTY;
    case "Lyfe":
      return AccItemType.ACC_ITEM_LYFE;
    default:
      return AccItemType.ACC_ITEM_TYPE_UNSPECIFIED;
  }
}
