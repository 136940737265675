import { css } from "@emotion/core";

import { colors } from "./../util/consts";

// Base classes
const base = css`
  margin: 0;
  color: ${colors.surfaceText.highEmphasis};
`;

const heading1 = css`
  ${base};
  font-size: 94.77px;
  letter-spacing: -1.5px;
  line-height: 129px;
`;

const heading2 = css`
  ${base};
  font-size: 59.23px;
  letter-spacing: -0.5px;
  line-height: 80px;
`;

const heading3 = css`
  ${base};
  font-size: 47.39px;
  letter-spacing: 0px;
  line-height: 65px;
`;

const heading4 = css`
  ${base};
  font-size: 33.57px;
  letter-spacing: 0.25px;
  line-height: 46px;
`;

const heading5 = css`
  ${base};
  font-size: 23.69px;
  letter-spacing: 0px;
  line-height: 32px;
`;

const heading6 = css`
  ${base};
  font-size: 19.74px;
  letter-spacing: 0.25px;
  line-height: 24px;
`;

const body1 = css`
  ${base};
  font-size: 14.8px;
  letter-spacing: 0.5px;
  line-height: 28px;
`;

const body2 = css`
  ${base};
  font-size: 12.82px;
  letter-spacing: 0.25px;
  line-height: 16px;
`;

const subtitle1 = css`
  ${base};
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

const subtitle2 = css`
  ${base};
  font-size: 13.82px;
  letter-spacing: 0.1px;
  line-height: 24px;
`;

const caption = css`
  ${base};
  font-size: 10.85px;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const overline = css`
  ${base};
  font-size: 11.85px;
  letter-spacing: 0.5px;
  line-height: 16px;
`;

// Misc helpful classes
const lighter = css`
  opacity: 0.6;
`;

const lightest = css`
  opacity: 0.38;
`;

const elipse = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const tableSub = css`
  ${base};
  font-size: 10.82px;
  letter-spacing: 0.09px;
  line-height: 15px;
`;

export default {
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
  body1,
  body2,
  subtitle1,
  subtitle2,
  caption,
  overline,
  lighter,
  lightest,
  elipse,
  tableSub
};
