import React, { Component } from "react";
import * as dfns from "date-fns";
import styled from "@emotion/styled";

// components
import {
  SplitDialog,
  LeftContent,
  RightContent,
  Seperator,
  SideHeader,
  SideLabel,
  SideValue,
  RightPadding
} from "../SplitDialog";
import Tabs from "./../../../components/Tabs";
import ResourceRender from "./../../../components/elements/ResourceRender";
import UserBlockDeprecated from "./../../../components/elements/UserBlock";

// Modal Components
import Appointments from "./Appointments";

import { ClientNotes } from "./Notes/ClientNotes";
import Details from "./Details";

import { accItemStore } from "./../../../stores/acc_item-store";
import { bookingStore } from "./../../../stores/booking-store";
import { AccItemType } from "./../../../sdk/account_items_pb";
import { clientFundStore } from "./../../../stores/client_fund-store";
import { ShowInvoices } from "../AppointmentDialog/ShowInvoices";
import { colors } from "./../../../util/consts";
import { ThirdPartyRow } from "./../../../components/third-party-invoices/ThirdPartyRow";
import { thirdPartyInvStore } from "./../../../stores/third_party_inv-store";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { Client } from "./../../../sdk/clients_pb";
import { Booking } from "./../../../sdk/bookings_pb";

// mobx
import { tagDataStore } from "../../../mobx/stores/TagDataStore";
import { toastStore } from "./../../../stores/toast-store";
import PaymentsTab from './PaymentsTab';
import { PhysitrackButton } from './Physitrack/PhysitrackButton';
import { TokenAvailableResponse, TokenAvailableRequest } from '../../../sdk/physitrack_pb';

// grpc
import { rpc, metadata } from "../../../grpc";
import { ApplicationType } from '../../../sdk/applications_and_tokens_pb';


@observer
class ClientActionDialog extends Component {
  @observable
  client = new Client().toObject();
  @observable
  booking = new Booking().toObject();
  state = {
    tokenAndData: new TokenAvailableResponse().toObject()
  }

  async componentDidMount() {
    const orgId = this.props.data.item.Org.ID;
    const clientId = this.props.data.item.ID;
    const preBooking = this.props.data.item.PrevBooking;
    // save client id to mobx store
    tagDataStore.clientID = clientId;

    await accItemStore.loadByBookingClient(null, clientId);

    if (preBooking) {
      await bookingStore.load(preBooking.ID);
      this.booking = bookingStore.get(preBooking.ID);
      this.client = bookingStore.get(preBooking.ID).clientsList[0];
    } else {
      this.client.clientId = clientId;
    }
    await clientFundStore.load(clientId);
    await thirdPartyInvStore.load(null, clientId);

    try {
      // Get token and patient data for physitrack
      const req = new TokenAvailableRequest();
      req.setOrgid(orgId);
      req.setClientid(clientId);
      req.setType(ApplicationType.PHYSITRACK_LYFE);

      const res = await rpc.physitrackV1.tokenAvailable(req, metadata());
      this.setState({ tokenAndData: res.toObject() });
    } catch (err) {
      toastStore.grpcToastError(err)
    }
  }

  leftComponent = React.memo(({ resource }) => (
    <LeftContent>
      {resource.DateOfBirth ? (
        <UserBlockDeprecated
          type="Client"
          user={resource}
          alt={dfns.format(new Date(resource.DateOfBirth), "dd/MM/yyyy")}
        />
      ) : (
          <UserBlockDeprecated type="Client" user={resource} />
        )}
      <Seperator />
      {resource.Email && (
        <React.Fragment>
          <SideLabel>Email Address</SideLabel>
          <SideValue>{resource.Email}</SideValue>
        </React.Fragment>
      )}
      {resource.MobileNumber && (
        <React.Fragment>
          <SideLabel>Phone Number</SideLabel>
          <SideValue>{resource.MobileNumber}</SideValue>
        </React.Fragment>
      )}
      {resource.Address && (
        <React.Fragment>
          <SideLabel>Address</SideLabel>
          <SideValue>{resource.Address.Raw}</SideValue>
        </React.Fragment>
      )}
      <Seperator />
      <SideHeader>Medicare & Insurance Information</SideHeader>
      {resource.FundProperties && resource.FundProperties.AUMedicare && (
        <React.Fragment>
          <SideLabel>Medicare</SideLabel>
          <SideValue>
            {resource.FundProperties.AUMedicare.Number} -{" "}
            {resource.FundProperties.AUMedicare.Reference}
          </SideValue>
        </React.Fragment>
      )}
      {resource.FundProperties && resource.FundProperties.AUDVA && (
        <React.Fragment>
          <SideLabel>DVA</SideLabel>
          <SideValue>
            {resource.FundProperties.AUDVA.Number} - {resource.FundProperties.AUDVA.Disability}
          </SideValue>
        </React.Fragment>
      )}
      {resource.FundProperties && resource.FundProperties.AUHICAPS && (
        <React.Fragment>
          <SideLabel>HICAPS/Medipass</SideLabel>
          <SideValue>{resource.FundProperties.AUHICAPS.MemberID}</SideValue>
        </React.Fragment>
      )}
      {!resource.FundProperties ||
        (!resource.FundProperties.AUMedicare &&
          !resource.FundProperties.AUDVA &&
          !resource.FundProperties.AUHICAPS && <SideValue>None Added</SideValue>)}
    </LeftContent>
  ));

  invoiceTab = React.memo(({ client }) =>
    accItemStore.all().filter((ai) => ai.clientId === client.ID).length > 0 ||
      thirdPartyInvStore.all().filter((ai) => ai.accountItem.clientId === client.ID).length > 0 ? (
        <>
          <ShowInvoices
            invoices={accItemStore
              .all()
              .filter((ai) => ai.clientId === client.ID && ai.type !== AccItemType.ACC_ITEM_THIRD_PARTY)}
          />
          {thirdPartyInvStore
            .all()
            .filter((ai) => ai.accountItem.clientId === client.ID)
            .map((invoice) => (
              <>
                <TabHeader key={invoice.id + "tab"}>Third Party Invoices</TabHeader>
                <ThirdPartyRow
                  key={invoice.id}
                  booking={invoice.accountItem.booking}
                  client={invoice.accountItem.booking.clientsList[0]}
                  invoice={invoice}
                  reading_only={true}
                />
              </>
            ))}
        </>
      ) : (
        <NoInvoices>No Payments Processed Yet</NoInvoices>
      )
  );

  notesTab = React.memo(({ client }) => (
    // All notes of a client are shared across all bookings
    <ClientNotes client={this.client} clientID={client.ID || this.client.clientId} />
  ));

  rightComponent = React.memo(({ resource, dialogId }) => (
    <RightContent>
      <Tabs
        initialTab={this.props.data.initialTab}
        tabs={[
          {
            label: "Appointments",
            content: Appointments,
            props: { key: "tab-appointments", client: resource, dialogId }
          },
          {
            label: "Details",
            content: Details,
            props: { key: "tab-details", clientId: resource.ID, tokenAndData: this.state.tokenAndData }
          },
          {
            label: "Payment",
            content: PaymentsTab,
            props: { key: "tab-payment", client: resource }
          },
          {
            label: "Invoices",
            content: this.invoiceTab,
            props: { key: "tab-invoices", client: resource }
          },
          {
            label: "Notes",
            content: this.notesTab,
            props: { key: "tab-notes", client: resource }
          }
        ]}
      />
      <RightPadding />
    </RightContent>
  ));

  contentComponent = React.memo((props) => (
    <SplitDialog
      data={props.data}
      dialogId={props.dialogId}
      leftRender={this.leftComponent}
      rightRender={this.rightComponent}
      forwardedProps={props}
    />
  ));

  render() {
    const { data } = this.props;
    const ids = { ID: data.item.ID };

    return (
      <React.Fragment>
        <ResourceRender
          type="Client"
          ids={ids}
          compSuccess={this.contentComponent}
          forwardedProps={this.props}
          denorm={true}
        />
      </React.Fragment>
    );
  }
}

export default ClientActionDialog;

export const TabContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 100px;
`;

export const TabHeader = styled.div`
  margin-top: 30px;
  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 15.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
`;

export const TabDetail = styled.div`
  font-weight: 400;
`;

const NoInvoices = styled.div`
  height: 74px;
  border-radius: 4px;
  margin: 10px 0;
  background-color: ${colors.surface.light};
  overflow: hidden;
  width: 100%;
  padding: 8px 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.23px;
  color: ${colors.surfaceText.highEmphasis};
`;
