import React, { Component } from "react";
import styled from "@emotion/styled";

import Label from "./../../components/form/Label";
import Select from "./../../components/form/BaseSelect";

const mins = ["00", "15", "30", "45"];
const times = [];
for (let i = 0; i < 24 * mins.length; i++) {
  const hour = Math.floor(i / mins.length);
  times.push(`${hour < 10 ? "0" : ""}${hour}:${mins[i % mins.length]}:00`);
}

export default class WeekSchedule extends Component {
  onChange = (id, pid) => (e) => {
    const { name, value, onChange } = this.props;
    if (e.target.value === "") {
      onChange({
        target: {
          name,
          value: {
            ...value,
            [id]: {
              OpensAt: null,
              ClosesAt: null
            }
          }
        }
      });
    } else if (value[id][pid] === null) {
      onChange({
        target: {
          name,
          value: {
            ...value,
            [id]: {
              OpensAt: e.target.value,
              ClosesAt: times[Math.min(times.indexOf(e.target.value) + 1, times.length - 1)]
            }
          }
        }
      });
    } else {
      onChange({
        target: {
          name,
          value: {
            ...value,
            [id]: {
              ...value[id],
              [pid]: e.target.value
            }
          }
        }
      });
    }
  };
  onHandleBlur = (e) => {};

  renderItem(id) {
    const { value, error } = this.props;
    const opensAt = value && value[id] && value[id].OpensAt || "";
    const closesAt = value && value[id] && value[id].ClosesAt || "";
    return (
      <React.Fragment>
        <Row>
          <TimeLabel htmlFor={`sched-${id}`} width={150}>{`${id} Open:`}</TimeLabel>
          <TimeSelect
            id={`sched-${id}-open`}
            name={`${id}Open`}
            value={opensAt}
            onChange={this.onChange(id, "OpensAt")}
            onBlur={this.onHandleBlur}
            error={error}
          >
            <option key={""} value={""}>
              Closed
            </option>
            {times
              .filter((v) => !closesAt || v.localeCompare(closesAt) < 0)
              .map((tv) => (
                <option key={tv} value={tv}>
                  {tv.slice(0, 5)}
                </option>
              ))}
          </TimeSelect>
          <TimeLabel htmlFor={`sched-${id}-closes`} left={20}>
            Close:{" "}
          </TimeLabel>
          <TimeSelect
            id={`sched-${id}-closes`}
            name={`${id}Open`}
            value={closesAt || ""}
            onChange={this.onChange(id, "ClosesAt")}
            onBlur={this.onHandleBlur}
            error={error}
          >
            <option key={""} value={""}>
              Closed
            </option>
            {times
              .filter((v) => !opensAt || v.localeCompare(opensAt) > 0)
              .map((tv) => (
                <option key={tv} value={tv}>
                  {tv.slice(0, 5)}
                </option>
              ))}
          </TimeSelect>
        </Row>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderItem("Monday")}
        {this.renderItem("Tuesday")}
        {this.renderItem("Wednesday")}
        {this.renderItem("Thursday")}
        {this.renderItem("Friday")}
        {this.renderItem("Saturday")}
        {this.renderItem("Sunday")}
      </React.Fragment>
    );
  }
}

const TimeLabel = styled(Label)`
  margin-left: ${({ left }) => left || 0}px;
  margin-right: 20px;
  width: ${({ width }) => width}px;
`;

const TimeSelect = styled(Select)`
  width: 120px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`;
