import { denormalize } from "normalizr";
import { Resource, ResourceList, ResourceDataStatus } from "./types";
import { ResourcesReducerState, ResourceListsReducerState } from "./reducer";
import {
  ResourceTypes,
  resourceDefinitions,
  blankResource,
  ResourceLists,
  resourceListDefinitions,
  blankResourceList
} from "./definitions";
import { normalizrSchemas } from "./schema";

export function getResource(
  state: ResourcesReducerState,
  type: ResourceTypes,
  ids: any,
  denorm: boolean
): Resource {
  if (!resourceDefinitions.hasOwnProperty(type)) {
    throw new Error(`invalid resource type ${type}`);
  }

  const resourceTypeDef = resourceDefinitions[type];
  if (!resourceTypeDef.idFields) {
    throw new Error(`invalid resource type ${type}, missing idFields`);
  }
  let idStr: string;
  let idObj: { [key: string]: string } = {};
  if (typeof ids === "string") {
    const idArr = ids.split("$");
    resourceTypeDef.idFields.forEach((k, i) => {
      idObj[k] = idArr[i];
    });
    idStr = ids;
  } else {
    idObj = ids!;
    idStr = resourceTypeDef.idFields
      .map((k) => idObj[k])
      .filter((v) => typeof v !== "undefined")
      .join("$");
  }

  if (!state || !state[type] || !state[type][idStr]) {
    return {
      ...blankResource(type),
      ...idObj
    };
  }

  if (denorm) {
    return denormalize(state[type][idStr], normalizrSchemas[type], state);
  } else {
    return state[type][idStr];
  }
}

export function getResourceList(
  state: ResourceListsReducerState,
  type: ResourceLists,
  ctx: { [key: string]: any }
): ResourceList {
  if (!resourceListDefinitions.hasOwnProperty(type)) {
    throw new Error(`invalid resource list type ${type}`);
  }

  const listDef = resourceListDefinitions[type];
  const $Metadata = {
    List: type,
    Context: ctx,
    Actions: [],
    DataStatus: ResourceDataStatus.Ok,
    DataLastSynced: new Date(),
    DataError: null
  };
  const key = !listDef.key ? "primary" : listDef.key($Metadata);
  const listid = `${type}$${key}`;

  if (!state || !state[listid]) {
    return blankResourceList(type, ctx);
  }

  return state[listid];
}

export function resourceIsFetching(resource?: Resource): boolean {
  if (!resource || !resource.$Metadata) {
    return true;
  }
  const idx = resource.$Metadata.Actions.findIndex(
    (a) => a.Type === "Fetch" && a.Status === "Pending"
  );
  if (idx >= 0) {
    return true;
  }
  return false;
}

export function listIsFetching(list?: ResourceList): boolean {
  if (!list || !list.$Metadata) {
    return true;
  }
  const idx = list.$Metadata.Actions.findIndex((a) => a.Type === "Fetch" && a.Status === "Pending");
  if (idx >= 0) {
    return true;
  }
  return false;
}
