/** @jsx jsx */
import { PureComponent } from "react";
import { css, jsx } from "@emotion/core";

import ButtonBase from "./ButtonBase";
import { colors } from "./../util/consts";

const base = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 100px;
  margin: 8px;
  background-color: transparent;
  transition: background-color 0.2s ease;

  &:hover:enabled {
    background-color: ${colors.primary[50]};
  }

  &:focus {
    outline: none;
    background-color: ${colors.primary[50]};
  }
`;

class IconButton extends PureComponent {
  render() {
    const { mini = false, ...otherProps } = this.props;
    return (
      <ButtonBase
        css={css`
          ${base};
          padding: ${mini ? "6px" : "12px"};
          margin: ${mini ? "2px" : "8px"};
        `}
        {...otherProps}
      />
    );
  }
}

export default IconButton;
