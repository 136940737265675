import { ActionType, getType } from "typesafe-actions";
import * as fromActions from "./actions";
import DialogType from "./ids";

export type DialogActions = ActionType<typeof fromActions>;

interface Dialog {
  id: string;
  type: DialogType;
  data: any;
}

export interface DialogsReducerState {
  active: Dialog[];
}

export const initialDialogState: DialogsReducerState = {
  active: []
};

export interface DialogReduxStoreState {
  readonly dialogs: DialogsReducerState;
}

export default {
  dialogs: (state: DialogsReducerState = initialDialogState, action: DialogActions): DialogsReducerState => {
    switch (action.type) {
      case getType(fromActions.open):
        return {
          ...state,
          active: [
            ...state.active,
            {
              id: action.payload.id,
              type: action.payload.type,
              data: action.payload.data,
            }
          ]
        };
      case getType(fromActions.swap):
        return {
          ...state,
          active: [
            ...state.active.filter((ad)=>ad.id!==action.payload.closeID),
            {
              id: action.payload.id,
              type: action.payload.type,
              data: action.payload.data,
            }
          ]
        };
      case getType(fromActions.close):
        return {
          ...state,
          active: state.active.filter((ad)=>ad.id!==action.payload.id),
        };
      case getType(fromActions.setData):
        const idx = state.active.findIndex((ad)=>ad.id===action.payload.id);
        if (idx < 0) {
          return state;
        } else {
          const newActive = [...state.active];
          newActive[idx] = {
            ...newActive[idx],
            data: action.payload.data,
          };
          return {
            ...state,
            active: newActive
          };
        }
    }

    return state;
  }
}
