import { createAction } from "typesafe-actions";
import { AuthCreds } from "./../../../redux/services/api";

interface AuthPayloadClose {
}

// Actions
export const set = createAction("Auth/Set", (resolve) => {
  return (data: AuthCreds) => resolve(data as AuthCreds);
});

export const refresh = createAction("Auth/Refresh", (resolve) => {
  return (data: AuthCreds) => resolve(data as AuthCreds);
});

export const invalid = createAction("Auth/Invalid", (resolve) => {
  return (e: Error) => resolve(e);
});

export const clear = createAction("Auth/Clear", (resolve) => {
  return () => resolve({} as AuthPayloadClose);
});
