import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const sizeProps = {
  small: {
    width: "20",
    height: "20"
  },
  medium: {
    width: "24",
    height: "24"
  },
  large: {
    width: "28",
    height: "28"
  }
};

export default class Menu extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"])
  };

  static defaultProps = {
    size: "medium"
  };

  render() {
    const { size, ...otherProps } = this.props;
    return (
      <svg
        role="img"
        viewBox="0 0 24 24"
        {...sizeProps[size]}
        {...otherProps}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M2 17c0 .55.45 1 1 1h18c.55 0 1-.45 1-1s-.45-1-1-1H3c-.55 0-1 .45-1 1zm0-5c0 .55.45 1 1 1h18c.55 0 1-.45 1-1s-.45-1-1-1H3c-.55 0-1 .45-1 1zm0-5c0 .55.45 1 1 1h18c.55 0 1-.45 1-1s-.45-1-1-1H3c-.55 0-1 .45-1 1z" />
      </svg>
    );
  }
}
