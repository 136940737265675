import React, { PureComponent } from "react";
import styled from "@emotion/styled";

import AutoComplete from "./SearchInput";

const items = [
  {
    value: "#0000FF",
    label: "Blue",
    icon: (p) => <ColoredCircle {...p} color="#0000FF" />
  },
  {
    value: "#00FF00",
    label: "Green",
    icon: (p) => <ColoredCircle {...p} color="#00FF00" />
  },
  {
    value: "#FF0000",
    label: "Red",
    icon: (p) => <ColoredCircle {...p} color="#FF0000" />
  }
];

export default class CategoriesSelector extends PureComponent {
  render() {
    return <AutoComplete {...this.props} items={items} />;
  }
}

const ColoredCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;
