import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";

import * as ReduxDialogs from "./../../redux/features/dialogs";

import { colors, hexToRgba } from "./../../util/consts";
import typography from "./../../util/typography";

// Utils
import { breakpoints } from "./../../util/consts";

import Dialog from "./Dialog";
import ButtonBase from "./../../components/ButtonBase";
import CheckRadioBox from "./../../components/form/CheckRadioBox";

class ActionDialog extends React.Component {
  state = {
    checked: true
  };

  doAction = (a, v) => () => {
    const { checked } = this.state;
    const { dispatch, dialogId, data } = this.props;
    dispatch(ReduxDialogs.actions.close(dialogId));
    if (data.cb) {
      data.cb(a, v, checked);
    }
  };

  handleCheckChange = (e) => {
    this.setState(({ checked })=>({ checked: !checked }));
  }

  render() {
    const { checked } = this.state;
    const { data } = this.props;
    return (
      <Dialog
        dialogId={this.props.dialogId}
        overideCloseDialog={this.doAction(false, false)}
        trigger={data.trigger}
        noClose
      >
        <Container>
          <Row>
            <p>{data.message}</p><br/>
            {data.checkMessage &&
              <CheckRadioBox
                type="checkbox"
                checked={checked}
                onChange={this.handleCheckChange}
              >
                {data.checkMessage}
              </CheckRadioBox>}
          </Row>
          <ButtonContainer>
            <LargeBottomButton
              isPrimary={true}
              onClick={this.doAction(true, false)}
            >
              <LargeButtonText>{data.btn2}</LargeButtonText>
            </LargeBottomButton>
            <LargeBottomButton
              onClick={this.doAction(true, true)}
            >
              <LargeButtonText>{data.btn1}</LargeButtonText>
            </LargeBottomButton>
          </ButtonContainer>
        </Container>
      </Dialog>
    );
  }
}

const Container = styled.div`
  position: relative;
  padding: 24px;
  padding-bottom: 74px;

  ${breakpoints["tablet-up"]} {
    padding: 42px 90px;
    padding-bottom: 82px;
    max-width: 700px;
  }

  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
`;

const LargeButtonText = styled.span`
  ${typography.heading6};
  font-weight: 600;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: ${colors.whiteText.highEmphasis};
`;

const LargeBottomButton = styled(ButtonBase)`
  flex: 1 0 100px;
  height: 100%;
  background-color: ${({ isPrimary })=>(isPrimary ? colors.primary.main : colors.secondary.main)};
  transition: opacity 0.2s ease;

  &:hover:enabled {
    opacity: 0.95;
  }

  &:focus {
    opacity: 0.95;
  }

  &:disabled {
    background-color: ${hexToRgba(colors.secondary.main, 0.12)};
  }
`;

export default connect()(ActionDialog);
