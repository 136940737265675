/** @jsx jsx */
import { Component } from "react";
import { Link } from "react-router-dom";
import { css, jsx } from "@emotion/core";
import { withFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

// Redux
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import apiService from "./../../../redux/services/api";
import { colors } from "./../../../util/consts";

// Utils
import { parseFormError } from "./../../../util/form";

// Custom components
import Button from "./../../../components/Button";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import Header from "./../../../components/form/Header";
import Form from "./../../../components/form/Form";
import DayPickerInput from "./../../../components/form/DayPickerInput";
import FooterContainer from "./../../../components/form/FooterContainer";
import FooterText from "./../../../components/form/FooterText";

const IS_PHONE_REGEX = /^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1})?[-\s\./0-9]*$/; // eslint-disable-line no-useless-escape

class SignUp extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      closeDialog
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Header title="Get Started" subtitle="Create a personal account" />
        <SectionsContainer>
          <Section>
            <Label htmlFor="signup-EmailPhone">Email or Phone</Label>
            <Input
              id="signup-EmailPhone"
              placeholder="example@exail.com or 04********"
              type="text"
              name="EmailPhone"
              value={values.EmailPhone}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.EmailPhone && touched.EmailPhone && <Error>{errors.EmailPhone}</Error>}
          </Section>
          <Section>
            <Label htmlFor="signup-FirstName">First Name</Label>
            <Input
              id="signup-FirstName"
              placeholder="Joe"
              type="text"
              name="FirstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.FirstName}
            />
            {!!errors.FirstName && touched.FirstName && <Error>{errors.FirstName}</Error>}
          </Section>
          <Section>
            <Label htmlFor="signup-LastName">Last Name</Label>
            <Input
              id="signup-LastName"
              placeholder="Smith"
              type="text"
              name="LastName"
              value={values.LastName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.LastName && touched.LastName && <Error>{errors.LastName}</Error>}
          </Section>
          <Section>
            <Label htmlFor="signup-Password">Password</Label>
            <Input
              id="signup-Password"
              placeholder="8+ characters"
              type="password"
              name="Password"
              value={values.Password}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.Password && touched.Password && <Error>{errors.Password}</Error>}
          </Section>
          <Section>
            <Label htmlFor="signup-DateOfBirth">Date of Birth (optional) </Label>
            <DayPickerInput
              id="signup-DateOfBirth"
              name="DateOfBirth"
              placeholder="Select your date of birth"
              value={values.DateOfBirth}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.DateOfBirth && touched.DateOfBirth && <Error>{errors.DateOfBirth}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <Button
            css={css`
              width: 150px;
            `}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={
              (!!errors.EmailPhone,
              !!errors.FirstName,
              !!errors.LastName,
              !!errors.Password,
              // !!errors.DateOfBirth,
              isSubmitting)
            }
          >
            Continue
          </Button>
        </FooterContainer>
        <FooterContainer
          css={css`
            margin-top: 16px;
          `}
        >
          <FooterText
            css={css`
              text-align: center;
            `}
          >
            By clicking “Continue” I agree to Booklyfe’s{" "}
            <Link
              to="/"
              onClick={closeDialog}
              css={css`
                text-decoration: none;
                color: ${colors.secondary.main};
              `}
            >
              Terms of Service
            </Link>{" "}
            &amp;{" "}
            <Link
              to="/"
              onClick={closeDialog}
              css={css`
                text-decoration: none;
                color: ${colors.secondary.main};
              `}
            >
              Privacy Policy
            </Link>
          </FooterText>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: () => ({
    EmailPhone: "",
    FirstName: "",
    LastName: "",
    Password: "",
    DateOfBirth: null
  }),
  validationSchema: Yup.object().shape({
    EmailPhone: Yup.string().required("Please enter your email or mobile number"),
    FirstName: Yup.string().required("Please enter your first name"),
    LastName: Yup.string().required("Please enter your last name"),
    Password: Yup.string()
      .min(8, "Passwords have an 8 character minimum")
      .required("Please enter a password"),
      // DateOfBirth: Yup.date().required("Please enter your date of birth")
    }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    const EmailPhone = values.EmailPhone;
    const usePhone = IS_PHONE_REGEX.test(EmailPhone);

    try {
      await apiService.performRequest({
        method: "post",
        path: "/api/v1/users",
        data: {
          FirstName: values.FirstName.trim(),
          LastName: values.LastName.trim(),
          Email: usePhone ? null : EmailPhone.trim(),
          MobileNumber: usePhone ? EmailPhone : null,
          MobileCountryCode: usePhone ? "AU" : null,
          Password: values.Password,
          DateOfBirth: values.DateOfBirth,
          Role: "Standard"
        }
      });

      const logRes = await apiService.userLogin(
        usePhone ? null : EmailPhone,
        usePhone ? EmailPhone : null,
        usePhone ? "AU" : null,
        values.Password
      );

      const resource = { $Metadata: { Type: "User" }, ID: logRes.Payload.UserID };
      const res = await props.dispatch(ResourceActions.action(resource, "Fetch", {}));

      const mustVerifyEmail =
        res.Payload.Email && (res.Payload.ValidatedFields || []).indexOf("Email") < 0;
      const mustVerifyPhone =
        res.Payload.MobileNumber && (res.Payload.ValidatedFields || []).indexOf("MobileNumber") < 0;

        props.setPage("Verify");
        
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }

    setSubmitting(false);
  },
  displayName: "SignUpForm"
});

// export default connect()(formikEnhancer(SignUp));
export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(SignUp);
