/** @jsx jsx */
import React, { Component } from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import PlacesAutocomplete from "react-places-autocomplete";

import { colors, hexToRgba } from "./../../util/consts";
import typography from "./../../util/typography";

import LoadingIcon from "./../../components/icons/Loading";

const Input = styled.input`
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 22px;
  text-align: left;
  width: 100%;
  padding: 10px 16px;
  color: ${colors.surfaceText.highEmphasis};
  outline: none;
  border: none;
  border-radius: 4px;
  flex: 0 1 auto;
  &:disabled {
    background-color: white;
    border: none;
    padding: 0;
    height: 25px;
  }
  &::placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }
  &::placeholder-shown {
    color: ${colors.surfaceText.medEmphasis};
  }
  &::-webkit-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }
  &::-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }
  &:-ms-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }
  &:-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }
`;

class GMPlacesAutocomplete extends Component {
  handleChange = (value) => {
    const { id, name } = this.props;
    this.props.onChange({ target: { id, name, value } });
  };

  handleSelect = (value, placeID) => {
    const { id, name } = this.props;
    this.props.onChange({ target: { id, name, value } });
  };

  render() {
    const { value, onChange: _, disabled, inputComponent, ...otherProps } = this.props;
    return (
      <PlacesAutocomplete
        value={value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={{
          types: ["address"],
          componentRestrictions: {
            country: "au"
          }
        }}
        highlightFirstSuggestion={true}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Container
            css={css`
              ${suggestions.length > 0 && `border-radius: 4px 4px 0px 0px;`}
            `}
            disabled={disabled}
          >
            {React.createElement(inputComponent || Input, {
              ...getInputProps({
                placeholder: "Search Places ...",
                ...otherProps
              }),
              css: css`
                ${suggestions.length > 0 && `border-radius: 4px 4px 0px 0px;`};
              `,
              disabled
            })}
            {loading && (
              <LoadingContainer>
                <LoadingIcon color={colors.primary.main} size="small" />
              </LoadingContainer>
            )}
            {suggestions.length > 1 && (
              <SuggestionsList>
                {suggestions.map((suggestion) => (
                  <ListItem
                    {...getSuggestionItemProps(suggestion)}
                    key={suggestion.id}
                    css={css`
                      background-color: ${suggestion.active
                        ? hexToRgba(colors.primary.main, 0.05)
                        : null};
                    `}
                  >
                    <ListItemText>{suggestion.description}</ListItemText>
                  </ListItem>
                ))}
              </SuggestionsList>
            )}
          </Container>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default GMPlacesAutocomplete;

const Container = styled.div`
  position: relative;
  border: ${({ disabled }) => (!disabled ? `1px solid ${colors.primary[100]}` : "none")};
  border-radius: 4px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  height: ${({ disabled }) => (!disabled ? "44px" : "auto")};
  &:focus-within {
    border-color: ${colors.primary.main};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const SuggestionsList = styled.ul`
  position: absolute;
  z-index: 9;
  top: calc(100% + 1px);
  left: -1px;
  max-height: 260px;
  overflow-y: scroll;
  width: calc(100% + 2px);
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border-left: 1px solid ${colors.primary.main};
  border-right: 1px solid ${colors.primary.main};
  border-bottom: 1px solid ${colors.primary.main};
  border-radius: 0px 0px 4px 4px;
`;

const ListItem = styled.span`
  height: 40px;
  padding: 14px;
  align-items: center;
  display: flex;
`;

const ListItemText = styled.span`
  ${typography.overline};
  letter-spacing: inherit;
`;
