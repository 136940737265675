import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
import * as ResourceActions from "../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "../../../util/form";
import { colors } from "../../../util/consts";
import typography from "../../../util/typography";

// Components
import Form from "../../form/Form";
import SectionsContainer from "../../form/SectionsContainer";
import Section from "../../form/Section";
import Header from "../../form/Header";
import Label from "../../form/Label";
import Input from "../../form/Input";
import Error from "../../form/Error";
import FooterContainer from "../../form/FooterContainer";
import LoadingButton from "../../LoadingButton";
import CheckRadioBox from "../../form/CheckRadioBox";
import TextArea from "../../form/TextArea";
import Select from "../../../components/form/BaseSelect";
import { OrgDefCcyRequest, ISOCurrencyName } from "../../../sdk/currencies_pb";
import { rpc, metadata } from "../../../grpc";
import { toastStore } from "../../../stores/toast-store";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";
import { observer } from "mobx-react";
import { observable, toJS } from "mobx";

interface Props {
  org: Organisation;
  values: Values;
  errors: Values;
  touched: Values;
  dirty: boolean;
  handleChange: () => void;
  handleBlur: () => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
  pageData: { Name: string };
}

interface Organisation {
  Address: any;
  AuthUser: any;
  BankAccountDetails: any;
  Banner: any;
  ContactEmail: string;
  ContactPhone: string;
  CreatedAt: string;
  CreditCards: any[];
  CurrentFundPoolAmount: number;
  Description: string;
  FundPoolActive: true;
  ID: string;
  IFrameSrc: string;
  Integrations: any;
  LastFundPoolTopupAmount: number;
  LastModifiedAt: string;
  Locations: any[];
  Logo: any;
  Name: string;
  RolloverSubscriptionType: string;
  Settings: any;
  SubscriptionDetails: any;
  SubscriptionStartedAt: string;
  SubscriptionStatus: string;
  SubscriptionStatusMessage: string;
  SubscriptionType: string;
  TaxDetails: any;
}

interface Values {
  RequireBookingApproval: string;
  NotificationDaysBefore: string;
  PenaltyFeeFixed: number;
  PenaltyFeeVar: string;
  PenaltyTimePeriod: string;
  ClientPaysCCFee: string;
  ThirdPartyTACs: string;
  ThirdPartyInvoicePayableWithin: any;
  DefaultCurrency: string;
}

export const CcyIsoNameToString = (ccyIsoName: ISOCurrencyName): string => {
  switch (ccyIsoName) {
    case ISOCurrencyName.AUD:
      return "(AUD)";
    case ISOCurrencyName.USD:
      return "(USD)";
    case ISOCurrencyName.CAD:
      return "(CAD)";
    case ISOCurrencyName.EUR:
      return "(EUR)";
    default:
      return "";
  }
};

@observer
class OrgEditAccount_Settings extends Component<Props> {
  @observable
  private currenciesAvailable = new Array<string>();

  async componentWillMount() {
    const orgId = this.props.org.ID;
    const req = new OrgDefCcyRequest();
    req.setOrgId(orgId);

    try {
      const res = await rpc.currencies.getOrgDefaultCcy(req, metadata());
      this.props.values.DefaultCurrency = CcyIsoNameToString(res.toObject().currency!.ccyIsoName);
    } catch (err) {
      toastStore.grpcToastError(err);
    }

    try {
      const res = await rpc.currencies.getAvailableCcyByServerInstanceLoc(new Empty(), metadata());
      res.toObject().currenciesList.forEach((ccy, i) => {
        this.currenciesAvailable.push(CcyIsoNameToString(ccy.ccyIsoName));
      });
    } catch (err) {
      toastStore.grpcToastError(err);
    }
  }

  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;
    return (
      this.currenciesAvailable.length > 0 && (
        <Form
          onSubmit={
            isSubmitting
              ? (e) => {
                  e.preventDefault();
                  return false;
                }
              : handleSubmit
          }
        >
          <Header title="Bookings" subtitle="Set booking preferences for your organisation" />
          <SectionsContainer>
            <Section>
              <Label>Booking Approval</Label>
              <LabelSubText>
                Client initiated bookings require your approval or book in automatically if you
                choose not required.
              </LabelSubText>
              <FormSplitContainer>
                <FormSplitItem>
                  <CheckRadioBox
                    id="editorg-set-rba-yes"
                    name="RequireBookingApproval"
                    value={"true"}
                    type="radio"
                    checked={values.RequireBookingApproval === "true"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.RequireBookingApproval && errors.RequireBookingApproval}
                  >
                    Required
                  </CheckRadioBox>
                </FormSplitItem>
                <FormSplitItem>
                  <CheckRadioBox
                    id="editorg-set-rba-no"
                    name="RequireBookingApproval"
                    value={"false"}
                    type="radio"
                    checked={values.RequireBookingApproval === "false"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.RequireBookingApproval && errors.RequireBookingApproval}
                  >
                    Not Required
                  </CheckRadioBox>
                </FormSplitItem>
                {!!errors.RequireBookingApproval && touched.RequireBookingApproval && (
                  <Error>{errors.RequireBookingApproval}</Error>
                )}
              </FormSplitContainer>
            </Section>
          </SectionsContainer>
          <Header
            title="Notifications"
            subtitle="Enter the number of days prior to a booking you would like the SMS appointment reminder sent to your clients."
          />
          <SectionsContainer>
            <Section>
              <Label htmlFor="editorg-set-note">Notification Period (Days)</Label>
              <Input
                id="editorg-set-note"
                name="NotificationDaysBefore"
                type="number"
                placeholder="1"
                autoFocus={true}
                value={values.NotificationDaysBefore}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.NotificationDaysBefore && errors.NotificationDaysBefore}
              />
              {!!errors.NotificationDaysBefore && touched.NotificationDaysBefore && (
                <Error>{errors.NotificationDaysBefore}</Error>
              )}
            </Section>
          </SectionsContainer>
          <Header
            title="Cancellations"
            subtitle="Set the percentage of the service fee you would charge for cancellations then set the notice period required for a client to make a cancellation."
            popover="Cancellation fees are not auto-charged so you will be able to choose on a case by case basis whether or not you wish to charge a cancellation fee."
          />
          <SectionsContainer>
            <Section>
              <Label htmlFor="editorg-set-pfee">Cancellation Fee Percentage</Label>
              {/*  (? ) Set this to 50 to charge 50% of the service fee for bookings cancelled by a client within your cancellation window. */}
              <Input
                id="editorg-set-pfee"
                name="PenaltyFeeVar"
                type="number"
                placeholder="50"
                value={values.PenaltyFeeVar}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.PenaltyFeeVar && errors.PenaltyFeeVar}
              />
              {!!errors.PenaltyFeeVar && touched.PenaltyFeeVar && (
                <Error>{errors.PenaltyFeeVar}</Error>
              )}
            </Section>
            <Section>
              <Label htmlFor="editorg-phone">Required Cancellation Notice (Hours)</Label>
              {/* (? ) Set this to 24 to notify your clients that at least 24 hours’ notice is required to cancel a booking. */}
              <Input
                id="editorg-phone"
                name="PenaltyTimePeriod"
                type="number"
                placeholder="24"
                value={values.PenaltyTimePeriod}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.PenaltyTimePeriod && errors.PenaltyTimePeriod}
              />
              {!!errors.PenaltyTimePeriod && touched.PenaltyTimePeriod && (
                <Error>{errors.PenaltyTimePeriod}</Error>
              )}
            </Section>
          </SectionsContainer>
          <Header
            title="Payments &amp; Invoicing"
            subtitle="Set payment and invoicing preferences for your organisation"
          />
          <SectionsContainer>
            <Section>
              <Label>Credit Card Transaction Fee</Label>
              <LabelSubText>
                A 1.2% credit card transaction fee can be paid by your client or your business.
              </LabelSubText>
              {/* (? ) This is a common fee that all payment facilities charge to clients however we give you the choice to take on this cost if you choose. */}
              <FormSplitContainer>
                <FormSplitItem>
                  <CheckRadioBox
                    id="editorg-ccfee-true"
                    name="ClientPaysCCFee"
                    value={"true"}
                    type="radio"
                    checked={values.ClientPaysCCFee === "true"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ClientPaysCCFee && errors.ClientPaysCCFee}
                  >
                    Client Pays
                  </CheckRadioBox>
                </FormSplitItem>
                <FormSplitItem>
                  <CheckRadioBox
                    id="editorg-ccfee-false"
                    name="ClientPaysCCFee"
                    value={"false"}
                    type="radio"
                    checked={values.ClientPaysCCFee === "false"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ClientPaysCCFee && errors.ClientPaysCCFee}
                  >
                    Organisation Absorbs
                  </CheckRadioBox>
                </FormSplitItem>
                {!!errors.ClientPaysCCFee && touched.ClientPaysCCFee && (
                  <Error>{errors.ClientPaysCCFee}</Error>
                )}
              </FormSplitContainer>
            </Section>
            <Section>
              <Label>Third Party Terms and Conditions</Label>
              <TextArea
                id="editorg-thirdpartytacs"
                name="ThirdPartyTACs"
                type="text"
                placeholder="Enter your terms and conditions"
                value={values.ThirdPartyTACs}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.ThirdPartyTACs && errors.ThirdPartyTACs}
              />
              {!!errors.ThirdPartyTACs && touched.ThirdPartyTACs && (
                <Error>{errors.ThirdPartyTACs}</Error>
              )}
            </Section>
            <Section>
              <Label>Third Party Invoice Payable Within (days)</Label>
              <Input
                id="editorg-set-note"
                name="ThirdPartyInvoicePayableWithin"
                type="number"
                placeholder="E.g. 90 days (optional)"
                value={values.ThirdPartyInvoicePayableWithin}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.ThirdPartyInvoicePayableWithin && errors.ThirdPartyInvoicePayableWithin
                }
              />
              {!!errors.ThirdPartyInvoicePayableWithin &&
                touched.ThirdPartyInvoicePayableWithin && (
                  <Error>{errors.ThirdPartyInvoicePayableWithin}</Error>
                )}
            </Section>
            <Section>
              <Label>Default Currency</Label>
              <Select
                id="offering-Tax"
                name="Tax"
                value={values.DefaultCurrency}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={true}
              >
                {this.currenciesAvailable.map((ccy, id) => (
                  <option key={id} value={ccy}>
                    {ccy}
                  </option>
                ))}
              </Select>
              {!!errors.DefaultCurrency &&
                touched.DefaultCurrency && (
                  <Error>{errors.DefaultCurrency}</Error>
                )}
            </Section>
          </SectionsContainer>
          <FooterContainer>
            <LoadingButton
              style={{ width: 150 }}
              loading={isSubmitting}
              variant="contained"
              color="secondary"
              type="submit"
              disabled={!dirty && !pageData.Name}
            >
              Save
            </LoadingButton>
          </FooterContainer>
        </Form>
      )
    );
  }
}

function strToBool(b: string) {
  return !b ? "false" : "true";
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ org }: any) =>
    ({
      RequireBookingApproval:
        typeof org.Settings.RequireBookingApproval === "boolean"
          ? strToBool(org.Settings.RequireBookingApproval)
          : "true",
      NotificationDaysBefore: org.Settings.NotificationDaysBefore || "1",
      PenaltyFeeVar: org.Settings.PenaltyFeeVar / 100 || "50",
      PenaltyTimePeriod: org.Settings.PenaltyTimePeriod || "24",
      ClientPaysCCFee:
        typeof org.Settings.ClientPaysCCFee === "boolean"
          ? strToBool(org.Settings.ClientPaysCCFee)
          : "true",
      ThirdPartyTACs: org.Settings.ThirdPartyTACs || "",
      ThirdPartyInvoicePayableWithin: org.Settings.ThirdPartyInvoicePayableWithin,
      DefaultCurrency: org.Settings.Currency || ""
    } as Values),
  validationSchema: Yup.object().shape({
    RequireBookingApproval: Yup.string().required("Required."),
    NotificationDaysBefore: Yup.number()
      .min(1, "Must be greater than 0.")
      .max(30, "Must be less than 30.")
      .required("Required."),
    PenaltyFeeVar: Yup.number()
      .min(1, "Must be equal to or greater than 0.")
      .max(100, "Must be less than 100.")
      .required("Required."),
    PenaltyTimePeriod: Yup.number()
      .min(0, "Must be equal to or greater than 0.")
      .max(960, "Must be less than 960.")
      .required("Required."),
    ClientPaysCCFee: Yup.string().required("Required."),
    ThirdPartyTACs: Yup.string().notRequired(),
    ThirdPartyInvoicePayableWithin: Yup.number().notRequired(),
    Currency: Yup.array<String>().required("Required.")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      const updOrg = {
        ...props.org,
        Settings: {
          RequireBookingApproval: values.RequireBookingApproval === "true",
          NotificationDaysBefore: parseInt(values.NotificationDaysBefore),
          PenaltyFeeFixed: 0,
          PenaltyFeeVar: parseInt(values.PenaltyFeeVar) * 100,
          PenaltyTimePeriod: parseInt(values.PenaltyTimePeriod),
          ClientPaysCCFee: values.ClientPaysCCFee === "true",
          ThirdPartyTACs: values.ThirdPartyTACs || "",
          ThirdPartyInvoicePayableWithin: values.ThirdPartyInvoicePayableWithin
        }
      };

      await props.dispatch(ResourceActions.action(updOrg, "Update", {}));
      props.toastManager.add("Saved Successfully!", { appearance: "success" });
      resetForm(values);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "OrgEditAccountSettings"
});

export default compose(
  connect((state) => ({})),
  withToastManager,
  formikEnhancer
)(OrgEditAccount_Settings as any);

const FormSplitContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const FormSplitItem = styled.div`
  flex: 1 1 0;

  &:first-of-type {
    margin-right: 24px;
  }
`;

const LabelSubText = styled.div`
  ${typography.body2};
  color: ${colors.surfaceText.medEmphasis};
  letter-spacing: 0.09px;
  margin-bottom: 6px;
`;

const CurrencyLabel = styled.label``;
