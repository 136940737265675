import React, { Component } from "react";
import * as fns from "date-fns";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

import * as ReduxDialogs from "./../../redux/features/dialogs";
import * as ResourceActions from "./../../redux/features/resources/thunkactions";
import { parseFormError } from "./../../util/form";

// Utils
import { breakpoints } from "./../../util/consts";
import typography from "./../../util/typography";

// Custom components
import Dialog from "./Dialog";
import Button from "./../../components/Button";
import LoadingButton from "./../../components/LoadingButton";

import Form from "./../../components/form/Form";
import SectionsContainer from "./../../components/form/SectionsContainer";
import Section from "./../../components/form/Section";
import Label from "./../../components/form/Label";
import Input from "./../../components/form/Input";
import Error from "./../../components/form/Error";
import DayTimePickerInput from "./../../components/form/DayTimePickerInput";
import DayTimeRangePickerInput from "./../../components/form/DayTimeRangePickerInput";
import {
  LocationOfferingSelector,
  LocationProviderSelector,
  SpecificLocationClientSelector
} from "./../../components/form/ResourceSelector";
import FooterContainer from "./../../components/form/FooterContainer";
import CheckRadioBox from "./../../components/form/CheckRadioBox";
import apiService from '../../redux/services/api';
import { rebookDataStore } from '../../mobx/stores/RebookDataStore';

class NewBookingDialog extends Component {
  async componentDidMount() {
    this.props.setStatus({ Type: "Appointment" });
    // window.requestAnimationFrame(this.updateModalHeight);
        
    if (rebookDataStore.isRebooking) {
      const resClients = await apiService.performRequest({
        method: "get",
        path: `/api/v1/locations/`+ rebookDataStore.locationId + `/clients`,
      });
  
      const rebookedClient = resClients.Payload.find(client => client.ID === rebookDataStore.clientId);

      this.props.handleChange({
        target: { name: "Service", value: rebookDataStore.offering},
      })
      this.props.handleChange({
        target: { name: "Client", value: rebookedClient},
      })
      // this.props.handleChange({
      //   target: { name: "Provider", value: rebookDataStore.provider},
      // })
    }
  }

  handleServiceSelect = (offering) => {
    const { values, setFieldValue } = this.props;
    if (values.StartTime) {
      const bd = new Date();
      const stime = fns.parse(values.StartTime, "HH:mm:00", bd);
      if (fns.isDate(stime)) {
        const etime = fns.addMinutes(stime, offering.ServiceDuration);
        setFieldValue("EndTime", fns.format(etime, "HH:mm:00"));
      }
    }
  };
  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      // setFieldValue,
      isSubmitting,
      data
    } = this.props;

    return (
      <Dialog dialogId={this.props.dialogId} trigger={data.trigger}>
        <Container>
          <Form
            onSubmit={
              isSubmitting
                ? (e) => {
                    e.preventDefault();
                    return false;
                  }
                : handleSubmit
            }
          >
            <HeaderTitle>New Booking</HeaderTitle>
            <SectionsContainer>
              <Section style={{ display: "flex" }}>
                <Button
                  color="primary"
                  variant={values.Type === "Appointment" ? "contained" : "outlined"}
                  onClick={() =>
                    handleChange({
                      target: { name: "Type", value: "Appointment" }
                    })
                  }
                  style={{ marginRight: 12, flex: "1 1 50px" }}
                >
                  Appointment
                </Button>
                <Button
                  color="primary"
                  variant={values.Type === "TimeOff" ? "contained" : "outlined"}
                  onClick={() =>
                    handleChange({
                      target: { name: "Type", value: "TimeOff" }
                    })
                  }
                  style={{ marginLeft: 12, flex: "1 1 50px" }}
                >
                  Time Off
                </Button>
              </Section>
            </SectionsContainer>
            {values.Type === "Appointment" ? (
              <SectionsContainer>
                <Section>
                  <Label>Group Booking?</Label>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <CheckRadioBox
                        name="ServiceType"
                        value="Single"
                        type="radio"
                        checked={values.ServiceType === "Single"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.ServiceType && errors.ServiceType}
                      >
                        No
                      </CheckRadioBox>
                    </div>
                    <div style={{ width: "50%" }}>
                      <CheckRadioBox
                        name="ServiceType"
                        value="Group"
                        type="radio"
                        checked={values.ServiceType === "Group"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.ServiceType && errors.ServiceType}
                      >
                        Yes
                      </CheckRadioBox>
                    </div>
                  </div>
                </Section>
                <Section>
                  <Label htmlFor="newbook-service">Service</Label>
                  <LocationOfferingSelector
                    id="newbook-service"
                    name="Service"
                    placeholder="Select a provided service"
                    location={data.location}
                    onlyServices
                    value={values.Service}
                    onChange={handleChange}
                    onSelect={this.handleServiceSelect}
                    onBlur={handleBlur}
                    error={touched.Service && errors.Service}
                  />
                  {!!errors.Service && touched.Service && <Error>{errors.Service}</Error>}
                </Section>
                <Section>
                  <Label htmlFor="newbook-datetime">Date &amp; Time</Label>
                  <DayTimeRangePickerInput
                    id="newbook-datetime"
                    interval={5}
                    placeholder="Select the time"
                    dayName="Date"
                    dayValue={values.Date}
                    startName="StartTime"
                    startValue={values.StartTime}
                    endName="EndTime"
                    endValue={values.EndTime}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      (touched.Date && errors.Date) ||
                      (touched.StartTime && errors.StartTime) ||
                      (touched.EndTime && errors.EndTime)
                    }
                  />
                  {!!errors.Date && touched.Date && <Error>{errors.Date}</Error>}
                </Section>
                <Section>
                  <Label htmlFor="newbook-provider">Provider</Label>
                  <LocationProviderSelector
                    id="newbook-provider"
                    name="Provider"
                    placeholder="Select the provider"
                    location={data.location}
                    value={values.Provider}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Provider && errors.Provider}
                  />
                  {!!errors.Provider && touched.Provider && <Error>{errors.Provider}</Error>}
                </Section>
                {values.ServiceType === "Single" && (
                  <Section>
                    <Label htmlFor="newbook-client">Client</Label>
                    <SpecificLocationClientSelector
                      id="newbook-client"
                      name="Client"
                      placeholder="Select the client"
                      location={data.location}
                      org={data.location.Org}
                      value={values.Client}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.Client && errors.Client}
                    />
                    {!!errors.Client && touched.Client && <Error>{errors.Client}</Error>}
                  </Section>
                )}
                {values.ServiceType === "Group" && (
                  <React.Fragment>
                    <Section>
                      <Label htmlFor="newbook-maxgroupsize">Maximum Group Size</Label>
                      <Input
                        id="newbook-maxgroupsize"
                        name="MaxGroupSize"
                        type="number"
                        min={1}
                        max={100}
                        placeholder="Item code"
                        value={values.MaxGroupSize}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.MaxGroupSize && errors.MaxGroupSize}
                      />
                      {!!errors.MaxGroupSize && touched.MaxGroupSize && (
                        <Error>{errors.MaxGroupSize}</Error>
                      )}
                    </Section>
                  </React.Fragment>
                )}
              </SectionsContainer>
            ) : (
              <SectionsContainer>
                <Section>
                  <Label htmlFor="newbook-provider">Provider</Label>
                  <LocationProviderSelector
                    id="newbook-provider"
                    name="Provider"
                    placeholder="Select the provider"
                    location={data.location}
                    value={values.Provider}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Provider && errors.Provider}
                  />
                  {!!errors.Provider && touched.Provider && <Error>{errors.Provider}</Error>}
                </Section>
                <Section>
                  <Label htmlFor="newbook-start">Start Date and Time</Label>
                  <DayTimePickerInput
                    id="newbook-start"
                    name="FromDate"
                    step={900}
                    value={values.FromDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.FromDate && errors.FromDate}
                  />
                  {!!errors.FromDate && touched.FromDate && <Error>{errors.FromDate}</Error>}
                </Section>
                <Section>
                  <Label htmlFor="newbook-end">End Date and Time3</Label>
                  <DayTimePickerInput
                    id="newbook-end"
                    name="ToDate"
                    step={900}
                    value={values.ToDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ToDate && errors.ToDate}
                  />
                  {!!errors.ToDate && touched.ToDate && <Error>{errors.ToDate}</Error>}
                </Section>
              </SectionsContainer>
            )}
            <FooterContainer>
              <LoadingButton
                style={{
                  minWidth: 150,
                  marginTop: 10
                }}
                loading={isSubmitting}
                variant="contained"
                color="secondary"
                type="submit"
              >
                Save
              </LoadingButton>
            </FooterContainer>
          </Form>
        </Container>
      </Dialog>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: (props) => ({
    Type: "Appointment",
    Service: null,
    Provider: null,
    Client: null,
    Date: new Date(),
    StartTime: fns.format(
      fns.roundToNearestMinutes(fns.addMinutes(new Date(), 2.5), { nearestTo: 5 }),
      "HH:mm:00"
    ),
    EndTime: "",
    ServiceType: "Single",
    FromDate: fns.addHours(fns.startOfHour(new Date()), 1),
    ToDate: fns.addHours(fns.startOfHour(new Date()), 2),
    MaxGroupSize: 5
  }),
  validationSchema: Yup.lazy((v) => {
    if (v.Type === "Appointment" && v.ServiceType === "Single") {
      return Yup.object().shape({
        Service: Yup.object()
          .nullable(true)
          .required("A service is required"),
        Provider: Yup.object()
          .nullable(true)
          .required("A provider is required"),
        Client: Yup.object()
          .nullable(true)
          .required("A client is required"),
        Date: Yup.date().required("Day is required"),
        StartTime: Yup.string().required("Start time is required"),
        EndTime: Yup.string().required("End time is required")
      });
    } else if (v.Type === "Appointment") {
      return Yup.object().shape({
        Service: Yup.object().required("A service is required"),
        Provider: Yup.object().required("A provider is required"),
        Date: Yup.date().required("Day is required"),
        StartTime: Yup.string().required("Start time is required"),
        EndTime: Yup.string().required("End time is required"),
        MaxGroupSize: Yup.number()
          .min(1, "Must be one or more.")
          .max(100, "Must be less than one hundred.")
          .required("End time is required")
      });
    } else {
      return Yup.object().shape({
        Provider: Yup.object().required("A provider is required"),
        FromDate: Yup.date().required("From date is required"),
        ToDate: Yup.date().required("To date is required")
      });
    }
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    const { dispatch, dialogId } = props;
    if (values.Type === "Appointment") {
      try {
        // Create the booking.
        const newBooking = {
          $Metadata: { Type: "LocBooking" },
          Type: values.ServiceType,
          Provider: values.Provider.User.ID,
          StartDate: fns.format(values.Date, "yyyy-MM-dd"),
          EndDate: fns.format(values.Date, "yyyy-MM-dd"),
          StartTime: values.StartTime,
          EndTime: values.EndTime,
          MaxGroupSize: values.ServiceType === "Single" ? null : values.MaxGroupSize,
          Clients: values.ServiceType === "Single" ? [values.Client.ID] : [],
          Parent: rebookDataStore.isRebooking ? rebookDataStore.bookingID : null,
          Offerings: [
            {
              Offering: values.Service.ID,
              Client: values.ServiceType === "Single" ? values.Client.ID : null,
              Count: 1,
              FundType: "None",
            }
          ]
        };
        await dispatch(
          ResourceActions.action(newBooking, "Create", { Location: props.data.location.ID })
        );

        rebookDataStore.reset()

        props.data.cb && props.data.cb();
        await dispatch(ReduxDialogs.actions.close(dialogId));
        // dispatch(ReduxDialogs.actions.swap(
        //   dialogId,
        //   ReduxDialogs.DialogType.AppointmentDialog,
        //   {
        //     booking: res.Payload.ID
        //   }
        // ));
      } catch (err) {
        parseFormError(err, values, setFieldError, props);
      }
    } else {
      try {
        // Create the booking.
        const newBooking = {
          $Metadata: { Type: "LocBooking" },
          Type: "TimeOff",
          Provider: values.Provider.User.ID,
          StartDate: fns.format(values.FromDate, "yyyy-MM-dd"),
          EndDate: fns.format(values.ToDate, "yyyy-MM-dd"),
          StartTime: fns.format(values.FromDate, "HH:mm:00"),
          EndTime: fns.format(values.ToDate, "HH:mm:00"),
          Clients: [],
          Offerings: []
        };
        await dispatch(
          ResourceActions.action(newBooking, "Create", {
            Location: props.data.location.ID
          })
        );

        rebookDataStore.reset()

        props.data.cb && props.data.cb();
        dispatch(ReduxDialogs.actions.close(dialogId));
      } catch (err) {
        parseFormError(err, values, setFieldError, props);
      }
    }

    setSubmitting(false);
  },
  displayName: "NewBooking"
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(NewBookingDialog);

const Container = styled.div`
  width: 100vw;
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    padding: 64px 90px;
    max-width: 700px;
  }
`;

const HeaderTitle = styled.span`
  ${typography.heading5};
`;
