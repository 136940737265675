import { HTTPMethod } from "./../../../redux/services/api";
import {
  Resource,
  ResourceList,
  ResourceDataStatus,
  ResourceActionTypes,
  ResourceListActionTypes,
  ResourceMetadata,
  ResourceListMetadata
} from "./types";

// ResourceTypes defines all of the possible resource types.
export enum ResourceTypes {
  User = "User",
  Client = "Client",
  Organisation = "Organisation",
  Location = "Location",
  OrganisationUser = "OrganisationUser",
  LocationUser = "LocationUser",
  Media = "Media",
  Address = "Address",
  CreditCard = "CreditCard",
  Taxonomy = "Taxonomy",
  Offering = "Offering",
  AUMedicareService = "AUMedicareService",
  Note = "Note",
  LocBookingClient = "LocBookingClient",
  LocBookingOffering = "LocBookingOffering",
  LocBooking = "LocBooking",
  LocDashboard = "LocDashboard",
  // ConsumerProvider = "ConsumerProvider",
  AccountItem = "AccountItem",
  Locality = "Locality",
  UserBooking = "UserBooking"
}

interface ResourceMethod {
  method: HTTPMethod;
  path: string;
  query?: any;
  data?: any;
  processResponse?: (cr: any, nr: any) => any;
}

interface ResourceDefinition {
  idFields: string[];
  preProcess?: (rp: any, ctx: any) => any;
  edges: { [key: string]: ResourceTypes | [ResourceTypes] };
  actions: { [key: string]: (ad: any, r: any, m: ResourceMetadata) => ResourceMethod };
}

function makeNullOnEmpty(cr: any, nr: any, fields: string[]): any {
  const newr = { ...cr, ...nr };
  fields.forEach((f: string) => {
    if (cr[f] && !nr[f]) {
      newr[f] = null;
    }
  });
  return newr;
}

function withFields(r: any, fields: string[]): any {
  const obj = {};
  Object.keys(r)
    .filter((f) => fields.findIndex((v) => v === f) >= 0)
    .forEach((f) => {
      obj[f] = r[f];
    });
  return obj;
}

function withoutFields(r: any, fields: string[]): any {
  const obj = {};
  Object.keys(r)
    .filter((f) => fields.findIndex((v) => v === f) < 0)
    .forEach((f) => {
      obj[f] = r[f];
    });
  return obj;
}

function mapEdges(r: any, rd: ResourceDefinition): any {
  const nr = { ...r };
  Object.keys(rd.edges).forEach((edge) => {
    if (!nr[edge]) {
      return;
    } else if (Array.isArray(rd.edges[edge])) {
      if (!Array.isArray(nr[edge])) {
        return;
      }
      nr[edge] = nr[edge].map((ev: any) => {
        if (typeof ev === "string") {
          return ev || null;
        } else {
          const ids = getIdsFromResource(nr[edge]);
          if (!ids) {
            return null;
          } else {
            return getIdsFromResource(ev);
          }
        }
      });
    } else {
      if (typeof nr[edge] === "string") {
        nr[edge] = nr[edge] || null;
      } else {
        const ids = getIdsFromResource(nr[edge]);
        if (!ids) {
          nr[edge] = null;
        } else {
          nr[edge] = ids.join("$");
        }
      }
    }
  });
  return nr;
}

const resourceDefinitions: { [key in keyof typeof ResourceTypes]: ResourceDefinition } = {
  [ResourceTypes.User]: {
    idFields: ["ID"],
    edges: {
      Photo: ResourceTypes.Media,
      Address: ResourceTypes.Address,
      CreditCards: [ResourceTypes.CreditCard]
    },
    actions: {
      [ResourceActionTypes.Create]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/users`,
        data: r
      }),
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/users/${r.ID}`
      }),
      [ResourceActionTypes.Update]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/users/${r.ID}`,
        data: mapEdges(
          withoutFields(r, [
            "ID",
            "CreatedAt",
            "LastModifiedAt",
            "JoinedAt",
            "Status",
            "ValidatedFields",
            "PasswordExpiry",
            "CreditCards",
            "Address"
          ]),
          resourceDefinitions[ResourceTypes.User]
        ),
        processResponse: (cr: any, nr: any) =>
          makeNullOnEmpty(cr, nr, ["Photo", "Address", "PasswordExpiry"])
      }),
      [ResourceActionTypes.Remove]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/users/${r.ID}`
      })
    }
  },
  [ResourceTypes.Client]: {
    idFields: ["ID"],
    edges: {
      Org: ResourceTypes.Organisation,
      Address: ResourceTypes.Address,
      CreditCards: [ResourceTypes.CreditCard]
    },
    preProcess: (r: any, ctx: any) => {
      switch (ctx) {
        case ResourceLists.ClientAppointmentTab:
          return withoutFields(r, ["CreditCards", "Referrals"]);
        default:
          return r;
      }
    },
    actions: {
      [ResourceActionTypes.Create]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/clients`,
        data: r
      }),
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/clients/${r.ID}`
      }),
      [ResourceActionTypes.Update]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/clients/${r.ID}`,
        data: mapEdges(
          withoutFields(r, [
            "ID",
            "CreatedAt",
            "LastModifiedAt",
            "CreditCards",
            "Address",
            "NextBooking",
            "PrevBooking"
          ]),
          resourceDefinitions[ResourceTypes.Client]
        ),
        processResponse: (cr: any, nr: any) => makeNullOnEmpty(cr, nr, ["Address"])
      }),
      [ResourceActionTypes.Remove]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/clients/${r.ID}`
      })
    }
  },
  [ResourceTypes.Organisation]: {
    idFields: ["ID"],
    edges: {
      Address: ResourceTypes.Address,
      Logo: ResourceTypes.Media,
      Banner: ResourceTypes.Media,
      CreditCards: [ResourceTypes.CreditCard],
      Locations: [ResourceTypes.Location],
      AuthUser: ResourceTypes.OrganisationUser
    },
    actions: {
      [ResourceActionTypes.Create]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/orgs`,
        data: r
      }),
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/orgs/${r.ID}`,
        query: ad
      }),
      [ResourceActionTypes.Update]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/orgs/${r.ID}`,
        data: mapEdges(
          withoutFields(r, [
            "ID",
            "CreatedAt",
            "LastModifiedAt",
            "SubscriptionStartedAt",
            "SubscriptionStatus",
            "SubscriptionStatusMessage",
            "SubscriptionDetails",
            "TrialCompleted",
            "CreditCards",
            "Locations",
            "Address",
            "FundPoolActive",
            "CurrentFundPoolAmount",
            "LastFundPoolTopupAmount",
            "Users",
            "AuthUser",
            "IFrameSrc"
          ]),
          resourceDefinitions[ResourceTypes.Organisation]
        ),
        processResponse: (cr: any, nr: any) =>
          makeNullOnEmpty(cr, nr, ["Address", "Logo", "Banner"])
      }),
      [ResourceActionTypes.Remove]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/orgs/${r.ID}`
      }),
      Action: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/orgs/${r.ID}/action`,
        data: ad
      })
    }
  },
  [ResourceTypes.Taxonomy]: {
    idFields: ["ID"],
    edges: {
      Parent: ResourceTypes.Taxonomy,
      PrimaryMedia: ResourceTypes.Media,
      SecondayMedia: ResourceTypes.Media
    },
    actions: {
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/taxonomies/${ad.Type}/${r.ID}`
      })
    }
  },
  [ResourceTypes.Location]: {
    idFields: ["ID"],
    edges: {
      Categories: [ResourceTypes.Taxonomy],
      Org: ResourceTypes.Organisation,
      Address: ResourceTypes.Address,
      Banner: ResourceTypes.Media,
      AuthUser: ResourceTypes.LocationUser,
      Providers: [ResourceTypes.LocationUser],
    },
    actions: {
      [ResourceActionTypes.Create]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/locations`,
        data: r
      }),
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${r.ID}`,
        query: ad
      }),
      [ResourceActionTypes.Update]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/locations/${r.ID}`,
        data: mapEdges(
          withoutFields(r, [
            "ID",
            "CreatedAt",
            "LastModifiedAt",
            "Org",
            "Status",
            "AuthUser",
            "Address",
            "Categories",
            "IFrameSrc",
            "Providers",
            "FreeTimes",
            "GroupTimes",
            "Services"
          ]),
          resourceDefinitions[ResourceTypes.Location]
        ),
        processResponse: (cr: any, nr: any) => makeNullOnEmpty(cr, nr, ["Address", "Banner"])
      }),
      [ResourceActionTypes.Remove]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/locations/${r.ID}`
      }),
      Action: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/locations/${r.ID}/action`,
        data: ad
      })
    }
  },
  [ResourceTypes.OrganisationUser]: {
    idFields: ["User", "Org"],
    edges: {
      User: ResourceTypes.User,
      Org: ResourceTypes.Organisation,
      Locations: [ResourceTypes.LocationUser],
      AuthLocations: [ResourceTypes.LocationUser]
    },
    actions: {
      [ResourceActionTypes.Create]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/orgs/${ad.org}/users`,
        data: r
      }),
      [ResourceActionTypes.Update]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/orgs/${r.Org}/users/${r.User.ID}`,
        data: withoutFields(
          {
            ...r,
            User: mapEdges(
              withoutFields(r.User, ["ID", "CreatedAt", "LastModifiedAt"]),
              resourceDefinitions[ResourceTypes.User]
            )
          },
          ["Org", "CreatedAt", "LastModifiedAt", "Locations", "AuthLocations"]
        )
      }),
      [ResourceActionTypes.Remove]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/orgs/${r.Org}/users/${r.User.ID}`
      }),
      AddLocation: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/locations/${ad.Location}`,
        data: withoutFields(ad, ["Location"])
      }),
      UpdateLocation: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/locations/${ad.Location}/users/${r.User.ID}`,
        data: withoutFields(ad, ["Location"])
      })
    }
  },
  [ResourceTypes.LocationUser]: {
    idFields: ["User", "Location"],
    edges: {
      User: ResourceTypes.User,
      Location: ResourceTypes.Location,
      ProviderCategories: [ResourceTypes.Taxonomy]
    },
    actions: {
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${r.Location}/users/${r.User}`
      }),
      [ResourceActionTypes.Create]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/locations/${ad.org}/users`,
        data: r
      }),
      [ResourceActionTypes.Update]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/locations/${r.Location.ID}/users/${r.User.ID}`,
        data: withoutFields(r, ["User", "Location", "CreatedAt", "LastModifiedAt"])
      }),
      [ResourceActionTypes.Remove]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/locations/${r.Location}/users/${r.User.ID}`
      })
    }
  },
  [ResourceTypes.Address]: {
    idFields: ["ID"],
    edges: {},
    actions: {
      [ResourceActionTypes.Create]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/addresses`,
        data: r
      }),
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/addresses/${r.ID}`
      }),
      [ResourceActionTypes.Update]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/addresses/${r.ID}`,
        data: withoutFields(r, ["ID", "CreatedAt", "LastModifiedAt", "Timezone"])
      }),
      [ResourceActionTypes.Remove]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/addresses/${r.ID}`
      })
    }
  },
  [ResourceTypes.Media]: {
    idFields: ["ID"],
    edges: {},
    actions: {
      [ResourceActionTypes.Create]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/media`,
        data: r
      }),
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/media/${r.ID}`
      }),
      [ResourceActionTypes.Update]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/media/${r.ID}`,
        data: withFields(r, ["Name", "Description"])
      }),
      [ResourceActionTypes.Remove]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/media/${r.ID}`
      })
    }
  },
  [ResourceTypes.CreditCard]: {
    idFields: ["ID"],
    edges: {},
    actions: {
      [ResourceActionTypes.Create]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/ccards`,
        data: r
      }),
      [ResourceActionTypes.Update]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/ccards/${r.ID}`,
        data: withFields(r, ["IsPrimary"])
      }),
      [ResourceActionTypes.Remove]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/ccards/${r.ID}`,
        data: null
      })
    }
  },
  [ResourceTypes.Offering]: {
    idFields: ["ID"],
    edges: {},
    actions: {
      [ResourceActionTypes.Create]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/offerings`,
        data: r
      }),
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/offerings/${r.ID}`
      }),
      [ResourceActionTypes.Update]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/offerings/${r.ID}`,
        data: withoutFields(r, ["ID", "CreatedAt", "LastModifiedAt", "Status"])
      }),
      [ResourceActionTypes.Remove]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/offerings/${r.ID}`
      })
    }
  },
  [ResourceTypes.AUMedicareService]: {
    idFields: ["Code"],
    edges: {},
    actions: {}
  },
  [ResourceTypes.Note]: {
    idFields: ["ID"],
    edges: {
      Client: ResourceTypes.Client,
      Media: [ResourceTypes.Media]
    },
    actions: {
      [ResourceActionTypes.Create]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/notes`,
        data: r
      }),
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/notes/${r.ID}`
      }),
      [ResourceActionTypes.Update]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/notes/${r.ID}`,
        data: mapEdges(
          withoutFields(r, ["ID", "CreatedAt", "LastModifiedAt", "Status", "WrittenBy"]),
          resourceDefinitions[ResourceTypes.Note]
        )
      }),
      [ResourceActionTypes.Remove]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/notes/${r.ID}`
      })
    }
  },
  [ResourceTypes.LocBookingOffering]: {
    idFields: ["ID"],
    edges: {
      Booking: ResourceTypes.LocBooking,
      Client: ResourceTypes.Client,
      Offering: ResourceTypes.Offering
    },
    actions: {}
  },
  [ResourceTypes.LocBookingClient]: {
    idFields: ["Booking", "Client"],
    edges: {
      Booking: ResourceTypes.LocBooking,
      Client: ResourceTypes.Client,
      Offerings: [ResourceTypes.LocBookingOffering],
      PaymentInvoices: [ResourceTypes.AccountItem],
      FundInvoices: [ResourceTypes.AccountItem],
      ThirdPartyInvoices: [ResourceTypes.AccountItem]
    },
    actions: {}
  },
  [ResourceTypes.LocBooking]: {
    idFields: ["ID"],
    edges: {
      Provider: ResourceTypes.User,
      Location: ResourceTypes.Location,
      Clients: [ResourceTypes.LocBookingClient],
      GroupOfferings: [ResourceTypes.LocBookingOffering]
    },
    actions: {
      [ResourceActionTypes.Create]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/locations/${ad.Location}/bookings`,
        data: r
      }),
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/bookings/${r.ID}`
      }),
      [ResourceActionTypes.Update]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/locations/${r.Location.ID}/bookings/${r.ID}`,
        data: mapEdges(
          withoutFields(r, [
            "ID",
            "CreatedAt",
            "LastModifiedAt",
            "Status",
            "Clients",
            "GroupOfferings",
            "CancellationMessage",
            "FriendlyID",
            "Location",
            "CancelledAt",
            "StartDateTimeTZ",
            "EndDateTimeTZ",
            "FundRebate",
            "FundTax",
            "SubtotalCharge",
            "SubtotalTax",
            "LyfeCreditCardFee",
            "LyfeCreditCardTax",
            "TotalCharge",
            "TotalTax",
            "MayChargeCancellationFee",
            "Timezone",
            // exclude ProviderFundProperties as it is newly introduced and should not be changed 
            "ProviderFundProperties",
          ]),
          resourceDefinitions[ResourceTypes.LocBooking]
        )
      }),
      [ResourceActionTypes.Remove]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/bookings/${r.ID}`
      }),
      Action: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/locations/${r.Location.ID}/bookings/${r.ID}/action`,
        data: ad
      }),
      AddOffering: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/locations/${r.Location.ID}/bookings/${r.ID}/offerings`,
        data: ad
      }),
      UpdateOffering: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/locations/${r.Location.ID}/bookings/${r.ID}/offerings/${ad.ID}`,
        data: withFields(ad, ["Offering", "Client", "Count", "FundType"])
      }),
      RemoveOffering: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/locations/${r.Location.ID}/bookings/${r.ID}/offerings/${ad.ID}`
      }),
      AddClient: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/locations/${r.Location.ID}/bookings/${r.ID}/clients`,
        data: ad
      }),
      ActionClient: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/locations/${r.Location.ID}/bookings/${r.ID}/clients/${ad.Client}/action`,
        data: withoutFields(ad, ["Client"])
      }),
      AddThirdPartyInvoice: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/locations/${r.Location.ID}/bookings/${r.ID}/clients/${ad.Client}/invoices`,
        data: withoutFields(ad, ["Client"])
      }),
      UpdateThirdPartyInvoice: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Put,
        path: `/api/v1/locations/${r.Location.ID}/bookings/${r.ID}/clients/${ad.Client}/invoices/${
          ad.ID
        }`,
        data: withoutFields(ad, ["Client", "ID"])
      }),
      ActionThirdPartyInvoice: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/locations/${r.Location.ID}/bookings/${r.ID}/clients/${ad.Client}/invoices/${
          ad.ID
        }/action`,
        data: withoutFields(ad, ["Client", "ID"])
      })
    }
  },
  [ResourceTypes.LocDashboard]: {
    idFields: ["Location"],
    edges: {},
    actions: {
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${r.Location}/dashboard`,
        query: ad,
      }),
    }
  },
  // [ResourceTypes.ConsumerLocation]: {
  //   idFields: ["ID"],
  //   edges: {
  //     Org: ResourceTypes.Organisation,
  //     Address: ResourceTypes.Address,
  //     Banner: ResourceTypes.Media,
  //     Providers: [ResourceTypes.LocationUser],
  //     Categories: [ResourceTypes.Taxonomy]
  //     // Services: [ResourceTypes.Offering],
  //   },
  //   actions: {
  //     [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
  //       method: HTTPMethod.Get,
  //       path: `/api/v1/locations/${r.ID}`,
  //       query: ad
  //     })
  //   }
  // },
  [ResourceTypes.AccountItem]: {
    idFields: ["ID"],
    edges: {
      Parent: ResourceTypes.AccountItem,
      Organisation: ResourceTypes.Organisation,
      Location: ResourceTypes.Location,
      Booking: ResourceTypes.LocBooking,
      Client: ResourceTypes.Client,
      Offering: ResourceTypes.Offering,
      Invoice: ResourceTypes.Media
    },
    actions: {
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/accountitems/${r.ID}`
      }),
      [ResourceActionTypes.Remove]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Delete,
        path: `/api/v1/accountitems/${r.ID}`
      }),
      Action: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/accountitems/${r.ID}/action`,
        data: ad
      }),
    }
  },
  [ResourceTypes.Locality]: {
    idFields: ["ID"],
    edges: {},
    actions: {
      [ResourceActionTypes.Fetch]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/localities/${r.ID}`
      })
    }
  },
  [ResourceTypes.UserBooking]: {
    idFields: ["ID"],
    edges: {},
    actions: {
      [ResourceActionTypes.Create]: (ad: any, r: any, m: ResourceMetadata) => ({
        method: HTTPMethod.Post,
        path: `/api/v1/bookings`,
        data: r
      }),
    }
  }
};

export { resourceDefinitions };

// getIdsFromResource returns the array of ids from resource or if unable to
// extract one or more of the id fields null is returned.
export function getIdsFromResource(resource: Resource): string[] | null {
  const { $Metadata, ...resourceData } = resource;

  if (!$Metadata || !$Metadata) {
    throw new Error("missing $Metadata or $Metadata.Type in resource");
  }
  if (!resourceDefinitions.hasOwnProperty($Metadata.Type as any)) {
    throw new Error(`invalid resource type ${$Metadata.Type}`);
  }

  const resourceTypeDef = resourceDefinitions[$Metadata.Type as any];

  if (!resourceTypeDef.idFields) {
    throw new Error(`invalid resource type ${$Metadata.Type}, missing idFields`);
  }

  const ids = resourceTypeDef.idFields
    .map((k: any) => {
      if (resourceTypeDef.edges.hasOwnProperty(k) && typeof resource[k] !== "string") {
        const rids = getIdsFromResource({
          $Metadata: { Type: resourceTypeDef.edges[k] },
          ...resource[k]
        });
        return (rids || []).join("$");
      } else {
        return resourceData[k];
      }
    })
    .filter((v: any) => typeof v !== "undefined");

  if (ids.length !== resourceTypeDef.idFields.length) {
    return null;
  }
  return ids;
}

export function blankResource(type: ResourceTypes): Resource {
  return {
    $Metadata: {
      Type: type,
      Actions: [],
      DataStatus: ResourceDataStatus.NoData,
      DataLastSynced: null,
      DataError: null
    }
  };
}

// ResourceLists defines all of the possible resource lists.
export enum ResourceLists {
  Categories = "Categories",
  AuthUserOrgs = "AuthUserOrgs",
  OrgansationLocationsPage = "OrgansationLocationsPage",
  LocationBookingListPage = "LocationBookingListPage",
  LocationBookingCalendarPage = "LocationBookingCalendarPage",
  LocationProviderBookingCalendarPage = "LocationProviderBookingCalendarPage",
  LocationOfferingsPage = "LocationOfferingsPage",
  LocationUsersPage = "LocationUsersPage",
  OrganisationUsersPage = "OrganisationUsersPage",
  OrganisationAccountsPage = "OrganisationAccountsPage",
  OrganisationClientsPage = "OrganisationClientsPage",
  LocationAccountItemsPage = "LocationAccountItemsPage",
  ClientNotesTab = "ClientNotesTab",
  AppointmentProvidersPage = "AppointmentProvidersPage",
  ProviderAppointmentTab = "ProviderAppointmentTab",
  ClientAppointmentTab = "ClientAppointmentTab",
  ClientInvoicesTab = "ClientInvoicesTab",
  LocationProvidersSelector = "LocationProvidersSelector",
  LocationOfferingsSelector = "LocationOfferingsSelector",
  LocationClientsSelector = "LocationClientsSelector",
  AUMedicareServiceSelector = "AUMedicareServiceSelector",
  LandingPageLocations = "LandingPageLocations",
  SearchPageLocations = "SearchPageLocations",
  // ConsumerProviders = "ConsumerProviders",
  LocationUpcomingBookings = "LocationUpcomingBookings",
  LocationPastProcessingBookings = "LocationPastProcessingBookings",
  SearchLocalities = "SearchLocalities",
  UserBookings = "UserBookings"
}

interface ResourceListDefinition {
  entity: ResourceTypes;
  key?: (m: ResourceListMetadata) => string;
  actions: { [key: string]: (m: ResourceListMetadata) => ResourceMethod | null };
  subFilters: { [key: string]: (r: any) => boolean };
}

const resourceListDefinitions: { [key in keyof typeof ResourceLists]: ResourceListDefinition } = {
  [ResourceLists.Categories]: {
    entity: ResourceTypes.Taxonomy,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/taxonomies/Categories/items`,
        query: m.Context
      })
      // [ResourceListActionTypes.Page]: (m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {
      Primary: (r: any) => !r.Parent
    }
  },
  [ResourceLists.AuthUserOrgs]: {
    entity: ResourceTypes.OrganisationUser,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/orgs`,
        query: m.Context
      })
      // [ResourceListActionTypes.Page]: (ad: any, m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {}
  },
  [ResourceLists.OrgansationLocationsPage]: {
    entity: ResourceTypes.Organisation,
    actions: {
      // [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`,
      //   query: ad,
      // }),
      // [ResourceListActionTypes.Page]: (m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {
      ActiveUsers: (r: any) => r.status === "Active"
    }
  },
  [ResourceLists.LocationOfferingsPage]: {
    entity: ResourceTypes.Offering,
    key: (m: ResourceListMetadata) => m.Context.Location,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${m.Context.Location}/offerings`,
        query: withoutFields(m.Context, ["Location"])
      })
      // [ResourceListActionTypes.Page]: (ad: any, m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {}
  },
  [ResourceLists.LocationBookingListPage]: {
    entity: ResourceTypes.LocBooking,
    key: (m: ResourceListMetadata) => m.Context.Location,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${m.Context.Location}/bookings`,
        query: withoutFields(m.Context, ["Location"])
      })
      // [ResourceListActionTypes.Page]: (ad: any, m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {}
  },
  [ResourceLists.LocationBookingCalendarPage]: {
    entity: ResourceTypes.LocBooking,
    key: (m: ResourceListMetadata) => m.Context.Location,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${m.Context.Location}/bookings`,
        query: withoutFields(m.Context, ["Location"])
      })
    },
    subFilters: {}
  },
  [ResourceLists.LocationUpcomingBookings]: {
    entity: ResourceTypes.LocBooking,
    key: (m: ResourceListMetadata) => m.Context.Location,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${m.Context.Location}/bookings`,
        query: { ...withoutFields(m.Context, ["Location"]), Selector: "Upcoming" }
      })
    },
    subFilters: {}
  },
  [ResourceLists.LocationPastProcessingBookings]: {
    entity: ResourceTypes.LocBooking,
    key: (m: ResourceListMetadata) => m.Context.Location,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${m.Context.Location}/bookings`,
        query: { ...withoutFields(m.Context, ["Location"]), Selector: "Past", Status: "Processing" }
      })
    },
    subFilters: {}
  },
  [ResourceLists.LocationProviderBookingCalendarPage]: {
    entity: ResourceTypes.LocBooking,
    key: (m: ResourceListMetadata) => `${m.Context.Location}$${m.Context.Provider}`,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${m.Context.Location}/provider/${m.Context.Provider}/bookings`,
        query: withoutFields(m.Context, ["Location", "Provider"])
      })
    },
    subFilters: {}
  },
  [ResourceLists.LocationUsersPage]: {
    entity: ResourceTypes.OrganisationUser,
    key: (m: ResourceListMetadata) => m.Context.Location,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${m.Context.Location}/users`,
        query: withoutFields(m.Context, ["Location"])
      })
      // [ResourceListActionTypes.Page]: (m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {}
  },
  [ResourceLists.OrganisationUsersPage]: {
    entity: ResourceTypes.OrganisationUser,
    key: (m: ResourceListMetadata) => m.Context.Organistaion,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/orgs/${m.Context.Organisation}/users`,
        query: withoutFields(m.Context, ["Organisation"])
      })
      // [ResourceListActionTypes.Page]: (m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {}
  },
  [ResourceLists.OrganisationAccountsPage]: {
    entity: ResourceTypes.AccountItem,
    key: (m: ResourceListMetadata) => m.Context.Organistaion,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/orgs/${m.Context.Organistaion}/accountitems`,
        query: withoutFields(m.Context, ["Organistaion"])
      })
      // [ResourceListActionTypes.Page]: (m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {}
  },
  [ResourceLists.OrganisationClientsPage]: {
    entity: ResourceTypes.Client,
    key: (m: ResourceListMetadata) => m.Context.Org,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/orgs/${m.Context.Org}/clients`,
        query: withoutFields(m.Context, ["Org"])
      })
      // [ResourceListActionTypes.Page]: (m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {}
  },
  [ResourceLists.LocationAccountItemsPage]: {
    entity: ResourceTypes.AccountItem,
    key: (m: ResourceListMetadata) => m.Context.Location,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${m.Context.Location}/accountitems`,
        query: withoutFields(m.Context, ["Location"])
      })
      // [ResourceListActionTypes.Page]: (ad: any, m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {}
  },
  [ResourceLists.ClientNotesTab]: {
    entity: ResourceTypes.Note,
    key: (m: ResourceListMetadata) => `${m.Context.Client}$${m.Context.Booking || "all"}`,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/clients/${m.Context.Client}/notes`,
        query: withoutFields(m.Context, ["Client"])
      })
      // [ResourceListActionTypes.Page]: (ad: any, m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {}
  },
  [ResourceLists.AppointmentProvidersPage]: {
    entity: ResourceTypes.OrganisationUser,
    key: (m: ResourceListMetadata) => m.Context.Location,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${m.Context.Location}/users`,
        query: {
          FilterRole: "Provider"
        }
      })
    },
    subFilters: {}
  },
  // [ResourceLists.LocationProviderPage]: {
  //   entity: ResourceTypes.LocationUser,
  //   actions: {
  //     [ResourceActionTypes.Fetch]: (ad: any, m: ResourceMetadata) => ({
  //       method: HTTPMethod.Get,
  //       path: `api/v1/locations/${ad.Location}/users/${ad.User}`,
  //       query: ad
  //     })
  //   }
  // },
  // subFilters: {}
  [ResourceLists.ProviderAppointmentTab]: {
    entity: ResourceTypes.LocBooking,
    key: (m: ResourceListMetadata) =>
      `${m.Context.Org}$${m.Context.Provider}$${m.Context.Selector}`,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/orgs/${m.Context.Org}/providers/${m.Context.Provider}/bookings`,
        query: withoutFields(m.Context, ["Org", "Provider"])
      })
    },
    subFilters: {}
  },
  [ResourceLists.ClientAppointmentTab]: {
    entity: ResourceTypes.LocBooking,
    key: (m: ResourceListMetadata) => `${m.Context.Org}$${m.Context.Client}$${m.Context.Selector}`,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/orgs/${m.Context.Org}/clients/${m.Context.Client}/bookings`,
        query: withoutFields(m.Context, ["Org", "Client"])
      })
    },
    subFilters: {}
  },
  [ResourceLists.ClientInvoicesTab]: {
    entity: ResourceTypes.AccountItem,
    key: (m: ResourceListMetadata) => `${m.Context.Org}$${m.Context.Client}`,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/orgs/${m.Context.Org}/clients/${m.Context.Client}/invoices`,
        query: withoutFields(m.Context, ["Org", "Client"])
      })
    },
    subFilters: {}
  },
  [ResourceLists.LocationProvidersSelector]: {
    entity: ResourceTypes.OrganisationUser,
    key: (m: ResourceListMetadata) => `${m.Context.Location}$${m.Context.Name}`,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${m.Context.Location}/users`,
        query: withoutFields(m.Context, ["Location", "Name"])
      })
      // [ResourceListActionTypes.Page]: (ad: any, m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {}
  },
  [ResourceLists.LocationOfferingsSelector]: {
    entity: ResourceTypes.Offering,
    key: (m: ResourceListMetadata) => `${m.Context.Location}$${m.Context.Name}`,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${m.Context.Location}/offerings`,
        query: withoutFields(m.Context, ["Location", "Name"])
      })
      // [ResourceListActionTypes.Page]: (ad: any, m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {}
  },
  [ResourceLists.LocationClientsSelector]: {
    entity: ResourceTypes.Client,
    key: (m: ResourceListMetadata) => `${m.Context.Location}$${m.Context.Name}`,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/locations/${m.Context.Location}/clients`,
        query: withoutFields(m.Context, ["Location", "Name"])
      })
      // [ResourceListActionTypes.Page]: (ad: any, m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {}
  },
  [ResourceLists.AUMedicareServiceSelector]: {
    entity: ResourceTypes.AUMedicareService,
    key: (m: ResourceListMetadata) => m.Context.Name,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/funds/aumedicare/services`,
        query: withoutFields(m.Context, ["Name"])
      })
      // [ResourceListActionTypes.Page]: (ad: any, m: ResourceListMetadata) => ({
      //   method: HTTPMethod.Get,
      //   path: `/api/v1/stores`
      // }),
    },
    subFilters: {}
  },
  [ResourceLists.LandingPageLocations]: {
    entity: ResourceTypes.Location,
    key: (m: ResourceListMetadata) => `all`,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/search`,
        query: m.Context
      })
    },
    subFilters: {}
  },
  [ResourceLists.SearchPageLocations]: {
    entity: ResourceTypes.Location,
    key: (m: ResourceListMetadata) => `all`,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/search`,
        query: m.Context
      })
    },
    subFilters: {}
  },
  [ResourceLists.SearchLocalities]: {
    entity: ResourceTypes.Locality,
    key: (m: ResourceListMetadata) => `all`,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/localities`,
        query: m.Context
      })
    },
    subFilters: {}
  },
  [ResourceLists.UserBookings]: {
    entity: ResourceTypes.UserBooking,
    key: (m: ResourceListMetadata) => m.Context.User,
    actions: {
      [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
        method: HTTPMethod.Get,
        path: `/api/v1/users/${m.Context.User}/bookings`,
        query: withoutFields(m.Context, ["User"])
      })
    },
    subFilters: {}
  }
  // [ResourceLists.ConsumerProviders]: {
  //   entity: ResourceTypes.ConsumerProvider,
  //   key: (m: ResourceListMetadata) => `all`,
  //   actions: {
  //     [ResourceListActionTypes.Fetch]: (m: ResourceListMetadata) => ({
  //       method: HTTPMethod.Get,
  //       path: `/api/v1/offerings`,
  //       query: m.Context
  //     })
  //   },
  //   subFilters: {}
  // }
};

// ResourceTypes.OrganisationUser
export { resourceListDefinitions };

export function blankResourceList(list: ResourceLists, ctx: { [key: string]: any }): ResourceList {
  return {
    $Metadata: {
      List: list,
      Context: ctx,
      Actions: [],
      DataStatus: ResourceDataStatus.NoData,
      DataLastSynced: null,
      DataError: null
    },
    IDs: [],
    SubFilteredIDs: Object.keys(resourceListDefinitions[list].subFilters).reduce(
      (a, k) => ({ ...a, [k]: [] }),
      {}
    )
  };
}
