import React from 'react';

interface Props {
  required?: boolean;
}

export class Label extends React.Component<Props> {
  render() {
    return (
      <label>
        <span>{this.props.children}</span>
        {this.props.required &&
          <span style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}> *</span>
        }
      </label>
    )
  }
}
