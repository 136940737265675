import * as actions from "./actions";
import reducers, { AuthReduxStoreState, AuthActions } from "./reducer";

export {
  actions,
  reducers
};

// This is to get around the isolated modules as required by Babel.
export type AuthReduxStoreState = AuthReduxStoreState;
export type AuthActions = AuthActions;
