import React from 'react';
import { observable, computed, isObservable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { SplitDialog } from '../SplitDialog';
import { DialogID } from '../DialogTypes';
import { BookingBreakdown } from './BookingBreakdown/BookingBreakdown';
import BookingWizard from './BookingWizard/BookingWizard';
import { isGroupBooking } from './../../../util/bookings';
import { bookingStore } from './../../../stores/booking-store';
import { accTableStore } from './../../../stores/acc_table-store';

interface Props {
  dialogId: DialogID;
  data: {
    booking: string; // a booking ID
  };
}

@observer
export class AppointmentDialog extends React.Component<Props> {
  
  @computed
  get booking() {
    return bookingStore.get(this.props.data.booking);
  }

  @computed
  get selectedClient() {
    if (!isGroupBooking(this.booking!)) {
      // we only have a single client for non-group bookings.
      return this.booking!.clientsList[0];
    }
    return undefined;
  }

  async componentDidMount() {
    await bookingStore.load(this.props.data.booking);
  }

  async componentWillUnmount() {
    await accTableStore.loadFromBooking(this.props.data.booking);
  }

  render() {
    if (!this.booking){
      return null;
    }

    return (
      <SplitDialog
        data={this.props.data}
        dialogId={this.props.dialogId}
        left={<BookingBreakdown booking={this.booking} client={this.selectedClient} />}
        right={<BookingWizard booking={this.booking} client={this.selectedClient} />}
      />
    );
  }
}
