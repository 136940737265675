import { Component } from "react";
import { connect } from "react-redux";

import * as GlobalActions from "./../../redux/features/global/actions";

class SetLocInUserContext extends Component {
  componentDidMount() {
    const { dispatch, userContext, match } = this.props;
    dispatch(GlobalActions.setUserContext(userContext.OrgID, match.params.lid));
  }
  render() {
    return null;
  }
}

export default connect(
  (state) => ({
    userContext: state.global.UserContext
  })
)(SetLocInUserContext);
