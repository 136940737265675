import React from 'react';
import { default as BaseSelect } from './BaseSelect';

interface SelectOption<T> {
  label: string;
  value: T;
}

interface Props<T> {
  value: T;
  values: SelectOption<T>[];
  style?: React.CSSProperties;
  onChange: (value: SelectOption<T>) => void;
}

export class Select<T> extends React.Component<Props<T>> {

  private onChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const index = event.currentTarget.selectedIndex;
    this.props.onChange(this.props.values[index]);
  }

  render() {
    const selectedIndex = this.props.values.findIndex(option => option.value === this.props.value);

    return (
      <BaseSelect onChange={this.onChange} value={selectedIndex} style={this.props.style} > 
        {this.props.values.map((option, i) =>
          <option key={i} value={i}>{option.label}</option>
        )}
      </BaseSelect>
    )
  }
}
