import React, { createElement, PureComponent } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Downshift, { StateChangeOptions, DownshiftState } from "downshift";

// Utils
import typography from "./../../util/typography";
import { colors, hexToRgba } from "./../../util/consts";
import IconButton from "../IconButton";
import Clear from "../icons/Clear";

interface AutoCompleteItem<T> {
  label: string;
  value: T;
  icon?: any; // to be refactored
}

interface Props<T> {
  name?: string;
  items: Array<AutoCompleteItem<T>>;
  selectedItem?: AutoCompleteItem<T>;
  inputValue?: string;
  value?: any; // not sure what this is was
  containerStyle?: any;
  inputStyle?: React.CSSProperties;
  hasClearButton?: boolean;
  placeholder?: string;
  allowFreeText?: boolean;
  setFieldValue(name: string | undefined, value: T | undefined): void;
  onInputValueChange?(value: string): void;
}

class SearchInput<T> extends PureComponent<Props<T>> {

  handleDownshiftSelect = (selectedItem: AutoCompleteItem<T>) => {
    const { name, setFieldValue } = this.props;
    setFieldValue(name, selectedItem ? selectedItem.value : undefined);
  };

  stateReducer = (state: DownshiftState<any>, changes: StateChangeOptions<any>): Partial<StateChangeOptions<any>> => {
    if (this.props.allowFreeText) {
      if (
        changes.type === Downshift.stateChangeTypes.blurInput
        || changes.type === Downshift.stateChangeTypes.mouseUp
        || changes.type === Downshift.stateChangeTypes.touchEnd
      ) {
        return {
          ...changes,
          inputValue: this.props.inputValue,
        }
      }
    }
    return changes;
  }

  render() {
    const {
      items,
      setFieldValue: _,
      value,
      containerStyle,
      inputStyle,
      hasClearButton,
      onInputValueChange,
      ...otherProps
    } = this.props;

    return (
      <Downshift
        inputValue={this.props.inputValue}
        onInputValueChange={this.props.onInputValueChange}
        onSelect={this.handleDownshiftSelect}
        itemToString={(item) => (item ? item.label || String(item.value) : "")}
        initialSelectedItem={items.find((i) => i.value === value)}
        stateReducer={this.stateReducer}
        {...otherProps}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          getRootProps,
          isOpen,
          inputValue,
          highlightedIndex,
          openMenu,
          clearSelection
        }) => {
          const relevantItems = items.filter(
            (item) =>
              !inputValue ||
              item.label.toLowerCase().includes(inputValue.toLowerCase())
          );
          const isTrulyOpen = isOpen && relevantItems.length > 0;
          return (
            <SearchRoot
              {...getRootProps({ style: containerStyle } as any)}
              css={css`
                ${isTrulyOpen && `border-radius: 4px 4px 0px 0px;`} &:focus-within {
                  box-shadow: 0 2px 4px 0 rgba(44, 46, 60, 0.15);
                }
              `}
            >
              <Input
                {...getInputProps({
                  ...otherProps,
                  style: inputStyle,
                  onFocus: openMenu,
                  type: "text"
                })}
                css={css`
                  ${isTrulyOpen && `border-radius: 4px 4px 0px 0px;`};
                `}
              />
              {hasClearButton && inputValue !== "" && (
                <IconButton
                  style={{ display: 'block', position: "absolute", right: 2, top: '50%', transform: 'translateY(-50%)' }}
                  mini
                  type="button"
                  onClick={clearSelection}
                >
                  <Clear size="small" />
                </IconButton>
              )}
              {isTrulyOpen && (
                <List {...getMenuProps()}>
                  {relevantItems.map((item, index) => (
                    <DropItem
                      key={index}
                      css={css`
                        background-color: ${highlightedIndex === index
                          ? hexToRgba(colors.primary.main, 0.05)
                          : null};
                      `}
                      {...getItemProps({ index, item })}
                    >
                      {Boolean(item.icon) &&
                        createElement(item.icon, {
                          style: { marginRight: 12 }
                        })}
                      <Itemtext>{item.label || String(item.value)}</Itemtext>
                    </DropItem>
                  ))}
                </List>
              )}
            </SearchRoot>
          );
        }}
      </Downshift>
    );
  }
}

export default SearchInput;

const SearchRoot = styled.div`
  position: relative;
  border: 1px solid ${colors.primary[100]};
  border-radius: 4px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  height: 44px;

  &:focus-within {
    border-color: ${colors.primary.main};
  }
`;

const Input = styled.input`
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 22px;
  width: 100%;
  height: 100%;
  padding: 10px 16px;
  color: ${colors.surfaceText.highEmphasis};
  outline: none;
  border: none;
  border-radius: 4px;
  flex: 0 1 auto;

  &::placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::placeholder-shown {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-webkit-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-ms-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }
`;

const List = styled.ul`
  position: absolute;
  z-index: 11;
  top: calc(100% + 1px);
  left: -1px;
  max-height: 260px;
  overflow-y: scroll;
  width: calc(100% + 2px);
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border-left: 1px solid ${colors.primary.main};
  border-right: 1px solid ${colors.primary.main};
  border-bottom: 1px solid ${colors.primary.main};
  border-radius: 0px 0px 4px 4px;
`;

const DropItem = styled.li`
  position: relative;
  height: 40px;
  padding: 14px;
  align-items: center;
  cursor: pointer;
  display: flex;
  align-items: center;

  :hover {
    background-color: ${hexToRgba(colors.primary.main, 0.05)};
  }
`;


const Itemtext = styled.span`
  ${typography.overline};
  letter-spacing: inherit;
  ${typography.elipse};
`;
