import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { parseFormError } from "./../../../util/form";

// utils
import { colors } from "./../../../util/consts";

import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import Error from "./../../../components/form/Error";
import LoadingButton from "./../../../components/LoadingButton";
import Section from "./../../../components/form/Section";
import DayPickerInput from "./../../../components/form/DayPickerInput";
import CheckRadioBox from "./../../../components/form/CheckRadioBox";
import DropdownSelect from "./../../../components/DropdownSelect";
import TagsDropdown from '../../elements/Dropdown/TagsDropdown';

// grpc
import { rpc, metadata } from "../../../grpc";
import { ListTagRequest, ReplaceReferralTagsRequest } from './../../../sdk/tag_pb';

// mobx
import { observer } from "mobx-react";
import { observable, toJS } from 'mobx';
import { referralTagStore } from './../../../stores/referral_tag-store';

import {
  AccordianForm,
  AccordianContainer,
  // AccordianButton,
  AccordianEdit,
  NewHeader,
  Separator,
  OptionsContainer,
  ButtonHead,
  ButtonDetails,
  ButtonContainer,
  NoSpacingIconButton
} from "./../../../components/elements/AccordianElements";

// icons
import ClearIcon from "./../../../components/icons/Clear";
import Options from "./../../../components/icons/Options";
import Tooltip from "./../../../components/elements/Tooltip";
import Check from "./../../../components/icons/Check";
import { Row } from "./../../../components/layout/flexbox/Row";
import { Gutter } from "./../../../components/layout/flexbox/FlexProps";

@observer
class ReferralAccordian extends Component {
  @observable
  tagOptions = [];
  @observable
  selectedTags = [];

  componentDidMount = async () => {
    const resTagOptions = await this.getTags();

    // set tag options based on organisation tag
    await this.onChangeTagOptions(resTagOptions);

    // clear referral tag store
    await referralTagStore.deleteAll();
  }

  onChangeTagOptions = (tagOptions) => {
    this.tagOptions = tagOptions;
  }

  onChangeSelectedTags = (selectedTags, selectedTag, action) => {
    // change selected tags to be shown in react-select
    this.selectedTags = selectedTags;

    // change selected tags in mobx store
    if (this.props.id === "newref") {
      if (action === "select-option") {
        referralTagStore.add("tempRefId", selectedTag);
      }
      else if (action === "deselect-option") {
        referralTagStore.delete("tempRefId", selectedTag);
      }
    }
    else {
      const currentTags = referralTagStore.get(this.props.values.ProviderID);
      if (currentTags) {
        if (action === "select-option") {
          referralTagStore.add(this.props.values.ProviderID, selectedTag);
        }
        else if (action === "deselect-option") {
          referralTagStore.delete(this.props.values.ProviderID, selectedTag);
        }
      }
    }
  }

  getTags = async () => {
    // request
    const req = new ListTagRequest();
    req.setOrganisationId(this.props.client.Org.ID);

    // response
    const res = await rpc.freeFormTag.list(req, metadata());

    return res.toObject().tagsList;
  }

  getReferralTags = async () => {

    // clear referral tag store
    await referralTagStore.deleteAll();

    // load referral's selected tags 
    await referralTagStore.load(this.props.referral.ProviderID, this.props.client.Org.ID);

    // set referral's selected tags
    const referralTags = referralTagStore.get(this.props.referral.ProviderID);
    if (referralTags) {
      this.selectedTags = referralTags;
    }
  }

  toggleOpen = async () => {
    const { values, setFieldValue } = this.props;
    this.getReferralTags();
    await setFieldValue("IsOpen", !values.IsOpen);
  };

  toggleClose = () => {
    const { values, setFieldValue } = this.props;
    setFieldValue("IsOpen", !values.IsOpen);
  }

  removeItem = async () => {
    const { dispatch, client, referral, bkClient } = this.props;
    if (!referral) {
      return;
    }
    // Adding to funds.
    try {
      const updClient = {
        ...client,
        Org: client.Org.ID,
        Referrals: client.Referrals.filter((k) => k.ID !== referral.ID)
      };
      await dispatch(ResourceActions.action(updClient, "Update", {}));
      // clear the referral id from the booking store
      if (bkClient.activeReferralId === referral.ID) {
        bkClient.activeReferralId = "";
      }
    } catch (err) {
      parseFormError(err, {}, () => { }, this.props);
    }
  };

  handleSelect = () => {
    const { referral, onSelect, values } = this.props;
    onSelect && !values.IsOpen && onSelect(referral.ID);
  };

  referralNoRequired = () => {
    const {values, setFieldValue, closeNew, referral, onSelect} = this.props;
    if(closeNew){
      closeNew();
    } 
    if(referral){
      onSelect && onSelect(referral.ID);
    }
    setFieldValue("IsOpen", !values.IsOpen);
  }

  editOptions = [
    {
      label: "Edit",
      onClick: this.toggleOpen
    },
    {
      label: "Remove",
      onClick: this.removeItem
    }
  ];

  renderContent() {
    const {
      id,
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      client
    } = this.props;

    return (
      <AccordianForm
        id={`addref-${id}-form`}
        onSubmit={
          isSubmitting
            ? (e) => {
              e.preventDefault();
              return false;
            }
            : handleSubmit
        }
      >
        <Section>
          <Label htmlFor={`addref-${id}-ProviderType`}>Attach Referral</Label>
          <FormSplitContainer>
            <FormSplitItem>
              <CheckRadioBox
                id={`addref-${id}-ProviderType-Specialist`}
                name="ProviderType"
                value="Specialist"
                type="radio"
                checked={values.ProviderType === "Specialist"}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.ProviderType && errors.ProviderType}
              >
                Yes (I’m Allied / Specialist)
              </CheckRadioBox>
            </FormSplitItem>
            <FormSplitItem>
              <CheckRadioBox
                id={`addref-${id}-ProviderType-GP`}
                name="ProviderType"
                value="GP"
                type="radio"
                checked={values.ProviderType === "GP"}
                onChange={this.referralNoRequired}
                onBlur={handleBlur}
                error={touched.ProviderType && errors.ProviderType}
              >
                Not Required (I’m a G.P)
              </CheckRadioBox>
            </FormSplitItem>
          </FormSplitContainer>
        </Section>

        <Section>
          <Label htmlFor={`addref-${id}-ProviderName`}>Referring Provider Name</Label>
          <Input
            id={`addref-${id}-ProviderName`}
            name="ProviderName"
            type="text"
            placeholder="Name of the referring provider"
            value={values.ProviderName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.ProviderName && errors.ProviderName}
          />
          {!!errors.ProviderName && touched.ProviderName && <Error>{errors.ProviderName}</Error>}
        </Section>

        <Section>
          <Label htmlFor={`addref-${id}-ProviderID`}>Referring Provider ID</Label>
          <Input
            id={`addref-${id}-ProviderID`}
            name="ProviderID"
            type="text"
            placeholder="ID of the referring provider"
            value={values.ProviderID}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.ProviderID && errors.ProviderID}
          />
          {!!errors.ProviderID && touched.ProviderID && <Error>{errors.ProviderID}</Error>}
        </Section>

        <Section>
          <Label htmlFor={`addref-${id}-Date`}>Referral Date</Label>
          <DayPickerInput
            id="signup-Date"
            name="Date"
            placeholder="Select the date of the referral"
            value={values.Date}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!errors.Date && touched.Date && <Error>{errors.Date}</Error>}
        </Section>

        <Section>
          <Label htmlFor={`addref-${id}-RequestType`}>Request Type</Label>
          <FormSplitContainer>
            <FormSplitItem>
              <CheckRadioBox
                id={`addref-${id}-RequestType-Pathology`}
                name="RequestType"
                value="Pathology"
                type="radio"
                checked={values.RequestType === "Pathology"}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.RequestType && errors.RequestType}
              >
                General
              </CheckRadioBox>
            </FormSplitItem>
            <FormSplitItem>
              <CheckRadioBox
                id={`addref-${id}-RequestType-DiagnosticImaging`}
                name="RequestType"
                value="DiagnosticImaging"
                type="radio"
                checked={values.RequestType === "DiagnosticImaging"}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.RequestType && errors.RequestType}
              >
                Diagnostic Imaging
              </CheckRadioBox>
            </FormSplitItem>
          </FormSplitContainer>
        </Section>

        <Section>
          <Label htmlFor={`addref-${id}-PeriodType`}>Period Type</Label>
          <FormSplitContainer>
            <FormSplitItem>
              <CheckRadioBox
                id={`addref-${id}-PeriodType-Standard`}
                name="PeriodType"
                value="Standard"
                type="radio"
                checked={values.PeriodType === "Standard"}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.PeriodType && errors.PeriodType}
              >
                Standard
              </CheckRadioBox>
            </FormSplitItem>
            <FormSplitItem>
              <CheckRadioBox
                id={`addref-${id}-PeriodType-NonStandard`}
                name="PeriodType"
                value="NonStandard"
                type="radio"
                checked={values.PeriodType === "NonStandard"}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.PeriodType && errors.PeriodType}
              >
                Non Standard
              </CheckRadioBox>
            </FormSplitItem>
          </FormSplitContainer>
          <FormSplitContainer>
            <FormSplitItem>
              <CheckRadioBox
                id={`addref-${id}-PeriodType-Indefinite`}
                name="PeriodType"
                value="Indefinite"
                type="radio"
                checked={values.PeriodType === "Indefinite"}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.PeriodType && errors.PeriodType}
              >
                Indefinite
              </CheckRadioBox>
            </FormSplitItem>
            <FormSplitItem />
          </FormSplitContainer>
        </Section>

        {values.PeriodType === "NonStandard" && (
          <Section>
            <Label htmlFor={`addref-${id}-Period`}>Period</Label>
            <Input
              id={`addref-${id}-Period`}
              name="Period"
              type="text"
              placeholder="Period in months"
              value={values.Period}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Period && errors.Period}
              min={1}
              max={12}
            />
            {!!errors.Period && touched.Period && <Error>{errors.Period}</Error>}
          </Section>
        )}

        <Section>
          <Label htmlFor={`addref-${id}-Used`}>
            Used Count{" "}
            <Tooltip>
              The number of times this referral was used by your client. This number starts at zero
              and counts up after each claim processed. You can manually start the count at a higher
              number if the client informs youthat they have used this referral at another practice.
            </Tooltip>
          </Label>
          {/* (?) The number of times this referral was used by your client. This number starts at zero and counts up after each claim processed. You can manually start the count at a higher number if the client informs youthat they have used this referral at another practice. */}
          <Input
            id={`addref-${id}-Used`}
            name="Used"
            type="text"
            placeholder="000"
            value={values.Used}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.Used && errors.Used}
          />
          {!!errors.Used && touched.Used && <Error>{errors.Used}</Error>}
        </Section>

        <Section style={{ display: 'flex', flexDirection: 'column' }}>
          <Label>Tags</Label>
          <TagsDropdown
            orgId={client.Org.ID}
            tagOptions={this.tagOptions}
            selectedTags={this.selectedTags}
            onChangeSelectedTags={this.onChangeSelectedTags}
            onChangeTagOptions={this.onChangeTagOptions}
            getTags={this.getTags}
            isDisabled={false}
            isEditingClient={false}
            {...this.props}
          />

          {/* need to handle errors later */}
          {/* {!!errors.preferred_fund_type && touched.preferred_fund_type && <Error>{errors.preferred_fund_type}</Error>} */}
        </Section>

        <ButtonContainer>
          <LoadingButton
            style={{
              minWidth: 150
            }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Save
          </LoadingButton>
        </ButtonContainer>
      </AccordianForm>
    );
  }

  render() {
    const { values, referral, closeNew, selected, onSelect } = this.props;

    return (
      <AccordianContainer selected={selected} onClick={this.handleSelect} maySelect={onSelect}>
        {!referral ? null : (
          <AccordianEdit>
            {onSelect &&
              <React.Fragment>
                <ButtonHead>
                  {selected ? (
                    <Row alignItems="center" gutter={Gutter.small}><Check color="green" /> Selected</Row>
                  ) : (
                      <span>Not Selected</span>
                    )}
                </ButtonHead>
                <Separator />
              </React.Fragment>
            }
            <ButtonHead>{referral.ProviderName}</ButtonHead>
            <Separator />
            <ButtonDetails>{referral.ProviderID}</ButtonDetails>
            <Separator />
            <ButtonDetails>{referral.Date}</ButtonDetails>
            <OptionsContainer>
              {!values.IsOpen ? (
                <DropdownSelect options={this.editOptions}>
                  <NoSpacingIconButton>
                    <Options />
                  </NoSpacingIconButton>
                </DropdownSelect>
              ) : (
                  <NoSpacingIconButton onClick={this.toggleClose}>
                    <ClearIcon fill={colors.primary.main} />
                  </NoSpacingIconButton>
                )}
            </OptionsContainer>
          </AccordianEdit>
        )}
        {(values.IsOpen || !referral) && (
          <React.Fragment>
            {referral ? null : (
              <NewHeader>
                <ButtonHead>New Referral</ButtonHead>
                <NoSpacingIconButton onClick={closeNew}>
                  <ClearIcon fill={colors.primary.main} />
                </NoSpacingIconButton>
              </NewHeader>
            )}
            {this.renderContent()}
          </React.Fragment>
        )}
      </AccordianContainer>
    );
  }
}

const yupPeriodMonth = Yup.number()
  .min(1, "Period must be between 1 and 12 inclusive")
  .max(12, "Period must be between 1 and 12 inclusive");

const formikEnhancer = withFormik({
  mapPropsToValues: ({ client, referral }) => ({
    IsOpen: !referral,
    ProviderName: !referral ? "" : referral.ProviderName,
    ProviderID: !referral ? "" : referral.ProviderID,
    ProviderType: !referral ? "Specialist" : referral.ProviderType,
    Date: !referral ? "" : referral.Date,
    RequestType: !referral ? "Pathology" : referral.RequestType,
    PeriodType: !referral ? "Standard" : referral.PeriodType,
    Period: !referral ? "" : referral.Period,
    Used: !referral ? "0" : referral.Used
  }),
  validationSchema: Yup.lazy((v) =>
    Yup.object().shape(
        {
          ProviderName: Yup.string().required("Provider name is required"),
          ProviderID: Yup.string().required("Provider ID is required"),
          ProviderType: Yup.string().required("Provider type is required"),
          Date: Yup.string().required("Date is required"),
          RequestType: Yup.string().required("Request type is required"),
          PeriodType: Yup.string().required("Period type is required"),
          Period:
            v.PeriodType === "NonStandard"
              ? yupPeriodMonth.required("Period is required")
              : Yup.string().notRequired(),
          Used: Yup.number().required("Referral used count is required")
        }
    )
  ),
  enableReinitialize: false,
  handleSubmit: async (values, { setSubmitting, setFieldError, setFieldValue, props }) => {
    const { dispatch, client, referral, onSelect } = props;
    // Adding to funds.
    try {
      const refData = {
        ProviderName: values.ProviderName.trim(),
        ProviderID: values.ProviderID.trim(),
        ProviderType: values.ProviderType,
        Date: values.Date,
        RequestType: values.RequestType,
        PeriodType: values.PeriodType,
        Period: values.PeriodType === "NonStandard" ? parseInt(values.Period) : null,
        Used: parseInt(values.Used)
      };

      const refs = [...client.Referrals];
      if (!referral) {
        refs.push(refData);
      } else {
        const refIdx = refs.findIndex((v) => v.ID === referral.ID);
        refs[refIdx] = refData;
      }
      const updClient = {
        ...client,
        Org: client.Org.ID,
        Referrals: refs
      };

      const organisationId = updClient.Org;
      const referralId = refData.ProviderID;
      const selectedTagIds = [];
      if (props.id === "newref") {
        const selectedTags = referralTagStore.get("tempRefId");

        if (selectedTags) {
          referralTagStore.get("tempRefId").map(selectedTag => selectedTagIds.push(selectedTag.id));
        }
      }
      else {
        if (referral && referral.ProviderID != referralId) {
          // The referral ID has been edited so use the old ID to get the tags from the store.
          referralTagStore.get(referral.ProviderID).map(selectedTag => selectedTagIds.push(selectedTag.id));
        } else {
          referralTagStore.get(referralId).map(selectedTag => selectedTagIds.push(selectedTag.id));
        }

      }

      // request
      const req = new ReplaceReferralTagsRequest();
      req.setOrganisationId(organisationId);
      req.setReferralId(referralId);
      req.setTagsIdList(selectedTagIds);

      // response
      await rpc.freeFormTag.replaceReferralTags(req, metadata());

      const res = await dispatch(ResourceActions.action(updClient, "Update", {}));
      const newRefId = res.Payload.Referrals[res.Payload.Referrals.length - 1].ID;

      if (onSelect) {
        // automatically select new referral
        onSelect(newRefId);
      }
      !referral && props.closeNew();
      referral && setFieldValue("IsOpen", false);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "Referral"
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(ReferralAccordian);

const FormSplitContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const FormSplitItem = styled.div`
  flex: 1 1 0;

  &:first-of-type {
    margin-right: 24px;
  }
`;
