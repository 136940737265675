import styled from "@emotion/styled";

import { colors, hexToRgba } from "./../util/consts";

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${hexToRgba(colors.primary.main, 0.1)};
`;

export default Divider;
