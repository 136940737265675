import React, { useState } from "react";
import * as Yup from "yup";

export type Errors<T> = {
  [key in keyof T]: string;
};

type Touched<T> = {
  [key in keyof T]: unknown; // we only care about the presence of a key
};

export interface FormHook<T> {
  fields: Partial<T>;
  errors: Partial<Errors<T>>;
  updateField: (change: Partial<T>) => void;
  handleBlur: () => void;
  isValid: (fields: Partial<T>) => fields is T;
}

function mapValidationError(error: Yup.ValidationError) {
  return { [error.path]: error.message };
}

export function useForm<T extends object>(
  schema: Yup.ObjectSchema<T>,
  initial?: Partial<T>
): FormHook<T> {
  let [fields, setFields] = useState<Partial<T>>(initial || {});
  const [errors, setErrors] = useState<Partial<Errors<T>>>({});
  const [touched, setTouched] = useState<Partial<T>>({});
  const validate = (allowEmpty: boolean) => {
    try {
      schema.validateSync(fields, { abortEarly: false });
      setErrors({});
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        if (allowEmpty && !(error.path in touched)) {
          return;
        }
        const nextErrors = error.inner.reduce((acc, curr) => {
          return Object.assign(acc, mapValidationError(curr));
        }, mapValidationError(error));
        setErrors(nextErrors as any);
      }
    }
  };

  return {
    get fields() {
      return fields;
    },
    errors: errors,
    updateField: (change: Partial<T>) => {
      fields = Object.assign({}, fields, change);
      setFields(fields);
      setTouched(Object.assign({}, touched, change));
      validate(true);
    },
    handleBlur: () => {},
    isValid: (value: Partial<T>): value is T => {
      validate(false);
      return schema.isValidSync(value);
    }
  };
}

interface Props<T extends object> {
  schema: Yup.ObjectSchema<T>;
  initial?: Partial<T>;
  onSubmit?: (value: T) => void;
  children: (form: FormHook<T>) => React.ReactNode;
  // this can be passed to use a different form element as the root
  component?: React.ReactType<React.HTMLAttributes<HTMLFormElement>>;
}

function Form<T extends object>(props: Props<T>) {
  const form = useForm<T>(props.schema, props.initial);
  return React.createElement(
    props.component || "form",
    {
      onSubmit: (event: React.FormEvent) => {
        event.preventDefault();
        if (props.onSubmit && form.isValid(form.fields)) {
          props.onSubmit(form.fields);
        }
      }
    },
    props.children(form)
  );
}

export function createForm<T extends object>(): React.FC<Props<T>> {
  return Form as any;
}

export function addArrayItem<T>(values: T[] | undefined, value: T): T[] {
  if (!values) {
    return [value];
  }
  return [...values, value];
}

export function updateArrayItem<T>(values: T[] | undefined, index: number, value: T): T[] {
  if (!values) {
    return [value];
  }
  const next = values.slice();
  next[index] = value;
  return next;
}

export function removeArrayItem<T>(values: T[] | undefined, index: number): T[] {
  if (!values) {
    return [];
  }
  const next = values.slice();
  next.splice(index, 1);
  return next;
}
