import { EntityStore } from "./entity-store";
import { Booking, GetBookingRequest, ListBookingByLocationRequest } from "./../sdk/bookings_pb";
import { rpc, metadata } from "./../grpc";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { toastStore } from "./toast-store";

class BookingStore extends EntityStore<Booking.AsObject> {
  async load(bookingId: string) {
    const req = new GetBookingRequest();
    req.setId(bookingId);
    try {
      const res = await rpc.bookings.get(req, metadata());
      this.add(res.toObject());
    } catch (err) {
      toastStore.grpcToastError(err);
    }
  }

  async loadByLocation(locationId: string, startDate?: Timestamp, endDate?: Timestamp) {
    const req = new ListBookingByLocationRequest();
    req.setLocationId(locationId);
    req.setBookingStartDate(startDate);
    req.setBookingEndDate(endDate);
    try {
      const res = await rpc.bookings.listByLocation(req, metadata());
      res.toObject().bookingsList.map((booking) => this.add(booking));
    } catch (err) {
      toastStore.grpcToastError(err);
    }
  }
}

export const bookingStore = new BookingStore();
