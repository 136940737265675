import React, { PureComponent } from "react";
import { connect } from "react-redux";

// redux
import { getResource } from "./../../../redux/features/resources/helpers";

// Components
import PagesContainer, { PageGroup, Page } from "./../../../components/elements/PagesContainer";

// Pages
import General from "./General";
import Settings from "./Settings";
import Cards from "./Cards";
import Funds from "./Funds";

class userEditAccount extends PureComponent {
  render() {
    const { user } = this.props;
    return (
      <PagesContainer sidePanel={true} queryp="page" blockNotSeen={false} extraProps={{ user }}>
        <PageGroup label="Profile">
          <Page name="general" label="General" component={General} />
        </PageGroup>
        <PageGroup label="Settings">
          <Page name="account" label="Account" component={Settings} />
        </PageGroup>
        <PageGroup label="Payments">
          <Page name="ccards" label="Credit Cards" component={Cards} />
          <Page name="funds" label="Funds" component={Funds} />
        </PageGroup>
      </PagesContainer>
    );
  }
}

export default connect((state) => ({
  user: getResource(state.resources, "User", state.auth.UserID, true)
}))(userEditAccount);
