import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

// redux
import apiService from "./../../../redux/services/api";
import { getResource } from "./../../../redux/features/resources/helpers";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// utils
import { breakpoints, colors, hexToRgba, FundType } from "./../../../util/consts";
import typography from "./../../../util/typography";

// components
import ButtonBase from "./../../../components/ButtonBase";
import Button from "./../../../components/Button";
import Header from "./../../../components/form/Header";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import FooterText from "./../../../components/form/FooterText";
import DayPickerInput from "./../../../components/form/DayPickerInput";
import MobileInput from "./../../../components/form/MobileInput";
import { format, parse } from "date-fns";

import * as ReduxDialogs from "./../../../redux/features/dialogs";

import LoadingIcon from "./../../../components/icons/Loading";
import MobileIcon from "./../../../components/icons/Mobile";
import EmailIcon from "./../../../components/icons/Email";


import { parseFormError } from "./../../../util/form";

// grpc
import { rpc, metadata } from "../../../grpc";
import { HubspotResponse, HubspotRequest } from '../../../sdk/hubspot_pb';
import { GetUserRequest } from '../../../sdk/user_pb';

class FormPage extends Component {
  state = {
    doesHaveLyfeAccount: false,
    isLoading: false,
    emailInput: true
  };

  forgotYourPassword = () => {
    const { dispatch } = this.props;
    dispatch(ReduxDialogs.openAccount(null, "forgotpass", { closeOnBack: true }, () => true));
  };

  handleSubmit = async (values, { setSubmitting, setFieldError, setStatus }) => {
    const { doesHaveLyfeAccount, isLoading } = this.state;
    const { pageData, history, Offering, Location, Provider, Booking, isIFrame, setPage } = this.props;
    if (isLoading) {
      setSubmitting(false);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const newBooking = {
        $Metadata: { Type: "UserBooking" },
        Booking: {
          Booking: pageData.Booking ? pageData.Booking : undefined,
          Location: Location.ID,
          Offering: Offering.ID,
          Provider: Provider.User.ID,
          Date: pageData.Date,
          StartTime: pageData.Time
        },
        User: {}
      };

      if (doesHaveLyfeAccount) {
        // login
        const EmailPhone = values.EmailPhone
        const usePhone = EmailPhone.indexOf("@") < 0;

        const loginResponse = await apiService.userLogin(
          usePhone ? null : EmailPhone.trim(),
          usePhone ? EmailPhone : null,
          usePhone ? values.MobileCountryCode : null,
          values.Password
        );
        newBooking.User.User = loginResponse.Payload.UserID;
        this.getUser(loginResponse.Payload.UserID).then(response =>{
          this.addClientToHubspot(response, Location)
        }).catch(console.log('failed to get user'))
      } else {
        newBooking.User.FirstName = values.FirstName.trim();
        newBooking.User.LastName = values.LastName.trim();
        newBooking.User.Email = values.Email.trim();
        newBooking.User.MobileNumber = values.MobileNumber;
        newBooking.User.MobileCountryCode = values.MobileCountryCode;
        newBooking.User.DateOfBirth = values.DateOfBirth;
        this.addClientToHubspot(newBooking.User, Location)
      }
      await this.props.dispatch(ResourceActions.action(newBooking, "Create", {}));

      // If in an iframe then set complete appointment.
      if (isIFrame) {
        setPage("complete");
      } else {
        this.props.toastManager.add("You have successfully booked the appointment", {
          appearance: "success"
        });
        if (doesHaveLyfeAccount) {
          history.push("/users/appointments");
        } else {
          history.push("/");
        }
      }
    } catch (e) {
      parseFormError(e, values, setFieldError, this.props);
    }
    setSubmitting(false);
    this.setState({ isLoading: false });
  };

  handleLoggedInSubmit = async (values, { setSubmitting, setFieldError, setStatus }) => {
    const { isLoading } = this.state;
    const {
      pageData,
      history,
      Offering,
      Location,
      Provider,
      authUser,
      pageData: { Booking },
      isIFrame,
      setPage
    } = this.props;

    if (isLoading) {
      setSubmitting(false);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const newBooking = {
        $Metadata: { Type: "UserBooking" },
        Booking: {
          Booking: Booking ? Booking : undefined,
          Location: Location.ID,
          Offering: Offering.ID,
          Provider: Provider.User.ID,
          Date: pageData.Date,
          StartTime: pageData.Time
        },
        User: {
          User: authUser.ID,
          FirstName: authUser.FirstName,
          LastName: authUser.LastName,
          Email: authUser.Email,
          MobileNumber: authUser.MobileNumber,
          MobileCountryCode: authUser.MobileCountryCode,
          DateOfBirth: authUser.DateOfBirth
        }
      };
      this.getUser(authUser.ID).then(response =>{
        this.addClientToHubspot(response, Location)
      }).catch(console.log('failed to get user'))

      await this.props.dispatch(ResourceActions.action(newBooking, "Create", {}));

      this.props.toastManager.add("You have successfully booked the appointment", {
        appearance: "success"
      });

      if (isIFrame) {
        setPage("complete");
      } else {
        history.push("/users/appointments");
      }
    } catch (e) {
      parseFormError(e, values, setFieldError, this.props);
    }
    setSubmitting(false);
    this.setState({ isLoading: false });
  };

  getPercentCompletion = (values, errors) => {
    const { doesHaveLyfeAccount } = this.state;

    let acc = 0;

    const isEmailPhoneValid = values.EmailPhone && !errors.EmailPhone;
    const isEmailValid = values.Email && !errors.Email;
    const isPhoneValid = values.MobileNumber && !errors.MobileNumber;
    const isFirstNameValid = values.FirstName && !errors.FirstName;
    const isLastNameValid = values.LastName && !errors.LastName;
    const isPasswordValid = values.Password && !errors.Password;
    const isDateOfBirthValid = values.DateOfBirth && !errors.DateOfBirth;

    if (doesHaveLyfeAccount) {
      acc += isEmailPhoneValid ? 1 : 0;
      acc += isPasswordValid ? 1 : 0;

      return Math.round(100 * (acc / 2));
    } else {
      acc += isEmailValid ? 1 : 0;
      acc += isPhoneValid ? 1 : 0;
      acc += isFirstNameValid ? 1 : 0;
      acc += isLastNameValid ? 1 : 0;
      acc += isDateOfBirthValid ? 1 : 0;

      return Math.round(100 * (acc / 5));
    }
  };

  addClientToHubspot = (newBooking, Location) =>{
    this.getHubspot(Location.Org.ID).then(data=>{
      if (data.isAvailable){
        try{
          // Add client to Hubspot
          fetch(
            "https://api.hsforms.com/submissions/v3/integration/submit/"+data.orgHubspot.portalid+"/"+data.orgHubspot.formid,
            {
              method: "POST",
              body: JSON.stringify({
                fields: [
                  {
                    name: "firstname",
                    value: newBooking.FirstName.trim()
                  },
                  {
                    name: "email",
                    value: newBooking.Email
                  },
                  {
                    name: "lastname",
                    value: newBooking.LastName.trim()
                  },
                  {
                    name: "phone",
                    value: newBooking.MobileNumber
                  },
                  {
                    name: "date_of_birth",
                    value: newBooking.DateOfBirth
                  },
                  {
                    name: "location",
                    value: Location.Name
                  },
                ],
                legalConsentOptions: {
                  consent: {
                    consentToProcess: true,
                    text: "I agree to allow Example Company to store and process my personal data.",
                    communications: [
                      {
                        value: true,
                        subscriptionTypeId: 999,
                        text: "I agree to receive marketing communications from Example Company."
                      }
                    ]
                  }
                }
              }),
              headers: {
                "Content-Type": "application/json"
              }
            })
            .then((res) => res.json())
            .then((response) => console.log("Success:", "hubspot", JSON.stringify(response)))
            .catch((error) => console.error("Error:", "hubspot", error));
        }
        catch{
          console.log("Hubspot Failed to send")
        }
      }
  })
  }

  async getHubspot(org) {
    let hubspotResponse = {}
    try{
    const req = new HubspotRequest();
    req.setOrgid(org)
    const res = await rpc.hubspotV1.getFormTokens(req)
    hubspotResponse = res.toObject()
    }
    catch{
      console.log('failed to get hubspot keys')
    }
    return hubspotResponse
  }

  async getUser(userId){
    const req = new GetUserRequest();
    let formattedResponse = {}
    req.setUserId(userId)
    try{
      const res = await rpc.user.getUser(req)
      let clientResponse = res.toObject()

      formattedResponse = {
        FirstName: clientResponse.user.firstName,
        LastName: clientResponse.user.lastName,
        MobileNumber: clientResponse.user.mobileNumber,
        DateOfBirth: clientResponse.user.dateOfBirth,
        Email: clientResponse.user.email
      }
    }catch{
      console.log('falied to get user details')
    }

    return formattedResponse
  }


  render() {
    const { doesHaveLyfeAccount, isLoading } = this.state;
    const {
      setPage,
      Offering,
      Location,
      Provider,
      pageData: { Booking },
      auth,
      authUser
    } = this.props;
    const isLoggedIn = auth.Type === "user" && typeof authUser.ID !== "undefined";

    return (
      <Container
        isBookingPage={this.props.location.pathname.split("/").find((el) => el === "bookings")}
      >
        <LeftContainer>
          <LeftContent>
            <LocationOffering style={{ marginBottom: 7 }}>{Offering.Name}</LocationOffering>
            <LocationName style={{ marginBottom: 7 }}>{Location.Name}</LocationName>
            <LocationAddress>{Location.Address.Raw}</LocationAddress>
            <Divider />
            <BookingDate style={{ fontWeight: 600, marginBottom: 6 }}>
              {Provider.User.FirstName + " " + Provider.User.LastName}
              {typeof Booking !== undefined && Boolean(Booking) && " - Group Booking"}
            </BookingDate>
            <BookingDate style={{ marginBottom: 6 }}>
              {this.props.pageData.Date} at{" "}
              {format(parse(this.props.pageData.Time, "HH:mm:ss", new Date()), "hh:mmaaaaa")}m
            </BookingDate>
            <div>
              <FooterText style={{ cursor: "pointer" }} onClick={() => setPage("initial")}>
                Change
              </FooterText>
            </div>
          </LeftContent>
        </LeftContainer>
        <RightContainer>
          {isLoggedIn ? (
            <Formik onSubmit={this.handleLoggedInSubmit}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <RightContent>
                    <RightSection>
                      <HeaderTitle style={{ marginBottom: 6 }}>Logged in as:</HeaderTitle>
                      <HeaderSubtitle style={{ marginBottom: 36 }}>
                        {authUser.FirstName} {authUser.LastName} ({authUser.Email})
                      </HeaderSubtitle>
                      <HeaderTitle>
                        To finalise your booking please click the book appointment button below, if
                        this is not you please logout.
                      </HeaderTitle>
                    </RightSection>
                  </RightContent>
                  <LargeButton type="submit">
                    {!isLoading ? (
                      <LargeButtonText>Book Appointment</LargeButtonText>
                    ) : (
                      <LoadingIcon width={16} height={16} color={colors.secondary.main} />
                    )}
                  </LargeButton>
                </form>
              )}
            </Formik>
          ) : (
            <Formik
              onSubmit={this.handleSubmit}
              initialValues={{
                EmailPhone: "",
                Email: "",
                MobileNumber: "",
                MobileCountryCode:"",
                FirstName: "",
                LastName: "",
                Password: "",
                DateOfBirth: null
              }}
              validationSchema={Yup.object().shape(
                doesHaveLyfeAccount
                  ? {
                      EmailPhone: Yup.string().required(
                        "Please enter your email or mobile number."
                      ),
                      Password: Yup.string()
                        .min(8, "Passwords have an 8 character minimum.")
                        .required("Please enter a password.")
                    }
                  : {
                      FirstName: Yup.string().required("Please enter your first name"),
                      LastName: Yup.string().required("Please enter your last name"),
                      Email: Yup.string()
                        .email("Not a valid email")
                        .required("Please enter your email"),
                      MobileNumber: Yup.string().required("Please enter your mobile number"),
                      DateOfBirth: Yup.date().required("Please enter your date of birth")
                    }
              )}
            >
              {({ values, touched, errors, handleBlur, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit} style={{ width: "100%", height: "100%" }}>
                  <ScrollContainer>
                    <RightContent>
                      <RightSection>
                        <HeaderTitle style={{ marginBottom: 6 }}>Appointment type</HeaderTitle>
                        <HeaderSubtitle>Select the appropriate details</HeaderSubtitle>
                      </RightSection>
                      <RightSection>
                        <SectionLabel>Do you have a Lyfe account?</SectionLabel>
                        <HalfContainer>
                          <HalfButton
                            onClick={() => this.setState({ doesHaveLyfeAccount: true })}
                            isActive={doesHaveLyfeAccount}
                          >
                            <HalfButtonText>Yes</HalfButtonText>
                          </HalfButton>
                          <HalfButton
                            onClick={() => this.setState({ doesHaveLyfeAccount: false })}
                            isActive={!doesHaveLyfeAccount}
                          >
                            <HalfButtonText>No</HalfButtonText>
                          </HalfButton>
                        </HalfContainer>
                      </RightSection>
                      {doesHaveLyfeAccount ? (
                        <Header
                          title="Sign in to BookLyfe"
                          subtitle="Enter either your email or mobile number below"
                        />
                      ) : (
                        <Header title="Get Started" subtitle="A personalised health experience" />
                      )}
                      <SectionsContainer>
                        {doesHaveLyfeAccount ? (
                          <>
                                  <Section>
                              <div style={{ textAlignLast: 'center'}}>
                            <Button
                                css={css`
                                  width: 150px;
                                  height: 30px;
                                  background-color: ${this.state.emailInput ? colors.secondary.main : colors.primary[300]};
                                  margin-right: 5px;
                                `}
                                variant="contained"
                                color="secondary"
                                type="button"
                                onClick={()=>{this.setState({emailInput: true}), values.EmailPhone = ""}}

                              >
                                <div style={{display: 'flex', justifyContent: 'center', color:'white'}}><EmailIcon size="small" fill="white" style={{marginRight:'5px'}}/>Email </div> 
                              </Button>
                              <Button
                                css={css`
                                  width: 150px;
                                  height: 30px;
                                  background-color: ${this.state.emailInput ? colors.primary[300] : colors.secondary.main};
                                  margin-left: 5px;
                                `}
                                variant="contained"
                                type="button"
                                onClick={()=>{this.setState({emailInput: false}), values.EmailPhone=""}}
                              >
                                <div style={{display: 'flex', justifyContent: 'center', color:'white'}}><MobileIcon size="small" fill="white" style={{marginRight:'5px'}}/>Mobile</div> 
                              </Button> </div>
                              </Section>
                          { this.state.emailInput ?
                            <Section>
                              <Label htmlFor="signup-EmailPhone">Email</Label>
                              <Input
                                id="signup-EmailPhone"
                                placeholder="example@example.com"
                                type="text"
                                name="EmailPhone"
                                value={values.EmailPhone}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              {!!errors.EmailPhone && touched.EmailPhone && (
                                <Error>{errors.EmailPhone}</Error>
                              )}
                            </Section> :
                          <Section>
                          <Label htmlFor="login-EmailPhone">Mobile</Label>
                          <MobileInput
                                id="login-EmailPhone"
                                name="EmailPhone"
                                onCountryChange={(e)=>{values.MobileCountryCode = e}}
                                onPhoneChange={(e)=>{values.EmailPhone = e}}
                              /> 
                          {!!errors.EmailPhone && touched.EmailPhone && <Error>{errors.EmailPhone}</Error>}
                        </Section> 
                        }
                            <Section>
                              <LabelSpacer>
                                <Label htmlFor="login-Password">Password</Label>
                                <Label
                                  htmlFor="login-Password"
                                  onClick={this.forgotYourPassword}
                                  style={{ cursor: "pointer", userSelect: "none" }}
                                >
                                  <FooterText>Forgot?</FooterText>
                                </Label>
                              </LabelSpacer>

                              <Input
                                id="signup-Password"
                                placeholder="8+ characters"
                                type="password"
                                name="Password"
                                value={values.Password}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              {!!errors.Password && touched.Password && (
                                <Error>{errors.Password}</Error>
                              )}
                            </Section>
                          </>
                        ) : (
                          <>
                            <Section>
                              <Label htmlFor="signup-Email">Email</Label>
                              <Input
                                id="signup-Email"
                                placeholder="example@example.com"
                                type="text"
                                name="Email"
                                value={values.Email}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              {!!errors.Email && touched.Email && <Error>{errors.Email}</Error>}
                            </Section>
                            <Section>
                              <Label htmlFor="signup-MobileNumber">Mobile Number</Label>
                              <MobileInput
                                id="signup-MobileNumber"
                                name="MobileNumber"
                                onCountryChange={(e)=>{values.MobileCountryCode = e}}
                                onPhoneChange={(e)=>{values.MobileNumber = e}}
                               /> 
                              {!!errors.MobileNumber && touched.MobileNumber && (
                                <Error>{errors.MobileNumber}</Error>
                              )}
                            </Section>
                            <Section>
                              <Label htmlFor="signup-FirstName">First Name</Label>
                              <Input
                                id="signup-FirstName"
                                placeholder="Joe"
                                type="text"
                                name="FirstName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.FirstName}
                              />
                              {!!errors.FirstName && touched.FirstName && (
                                <Error>{errors.FirstName}</Error>
                              )}
                            </Section>
                            <Section>
                              <Label htmlFor="signup-LastName">Last Name</Label>
                              <Input
                                id="signup-LastName"
                                placeholder="Smith"
                                type="text"
                                name="LastName"
                                value={values.LastName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              {!!errors.LastName && touched.LastName && (
                                <Error>{errors.LastName}</Error>
                              )}
                            </Section>
                            <Section>
                              <Label htmlFor="signup-DateOfBirth">Date of Birth</Label>
                              <DayPickerInput
                                id="signup-DateOfBirth"
                                name="DateOfBirth"
                                placeholder="Select your date of birth"
                                value={values.DateOfBirth}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              {!!errors.DateOfBirth && touched.DateOfBirth && (
                                <Error>{errors.DateOfBirth}</Error>
                              )}
                            </Section>
                          </>
                        )}
                      </SectionsContainer>
                    </RightContent>
                  </ScrollContainer>
                  <LargeButton
                    percent={this.getPercentCompletion(values, errors)}
                    disabled={this.getPercentCompletion(values, errors) !== 100}
                    type="submit"
                  >
                    {!isLoading ? (
                      <LargeButtonText>Book Appointment</LargeButtonText>
                    ) : (
                      <LoadingIcon width={16} height={16} color={colors.secondary.main} />
                    )}
                  </LargeButton>
                </form>
              )}
            </Formik>
          )}
        </RightContainer>
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  const { pageData } = props;
  const { auth, resources } = state;

  return {
    auth,
    authUser: state.auth ? getResource(state.resources, "User", { ID: state.auth.UserID }) : null,
    Offering: getResource(resources, "Offering", pageData.Offering, true),
    Location: getResource(resources, "Location", pageData.Location, true),
    Provider: getResource(
      resources,
      "LocationUser",
      { Location: pageData.Location, User: pageData.Provider },
      true
    )
  };
}

export default compose(
  connect(mapStateToProps),
  withToastManager,
  withRouter
)(FormPage);

const Container = styled.div`
  position: relative;
  width: 100%;
  height: ${({ isBookingPage }) => (isBookingPage ? "calc(100vh - 69px)" : "100vh")};
  background-color: ${colors.surface.light};
  display: flex;
`;

/* LEFT */

const LeftContainer = styled.div`
  position: relative;
  flex: 0 0 320px;
  overflow: hidden;

  ${breakpoints["phone-only"]} {
    display: none;
  }

  ${breakpoints["laptop-up"]} {
    flex-basis: 380px;
  }
`;

const LeftContent = styled.div`
  padding: 48px 36px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${hexToRgba(colors.primary.main, 0.1)};
  margin-top: 33px;
  margin-bottom: 26px;
`;

const LocationOffering = styled.div`
  ${typography.overline};
  color: ${colors.secondary.main};
  font-weight: 600;
  text-transform: uppercase;
`;

const LocationName = styled.div`
  ${typography.heading6};
  font-weight: bold;
`;

const LocationAddress = styled.div`
  ${typography.overline};
  color: ${colors.surfaceText.medEmphasis};
`;

const BookingDate = styled.div`
  ${typography.body1};
  letter-spacing: 0.63px;
  line-height: 20px;
`;

/* RIGHT */

const RightContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  background-color: #f4f8f9;
  height: 100%;
`;

const ScrollContainer = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  margin-bottom: 70px;
  overflow-y: auto;
`;

const RightContent = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 48px 24px;

  ${breakpoints["tablet-up"]} {
    padding: 48px 36px;
  }
`;

const LargeButton = styled(ButtonBase)`
  position: absolute;
  bottom: 0;
  height: 70px;
  left: 0;
  width: 100%;
  background: linear-gradient(
    to right,
    ${colors.secondary.main} ${({ percent }) => percent || 0}%,
    ${hexToRgba(colors.secondary.main, 0.6)} ${({ percent }) => percent || 0}%
  );
`;

const LargeButtonText = styled.span`
  color: ${colors.whiteText.highEmphasis};
  font-size: 19.82px;
  font-weight: 600;
  letter-spacing: 1.8px;
  text-align: center;
  text-transform: uppercase;
`;

const RightSection = styled.div`
  width: 100%;
  margin: 24px 0;

  &:first-of-type {
    margin-top: 0px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const SectionLabel = styled.div`
  color: ${colors.primary.main};
  font-size: 17.69px;
  line-height: 24px;
  margin-bottom: 28px;
`;

const HalfContainer = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
`;

const HalfButtonText = styled.span`
  text-align: center;
  font-size: 15.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  text-align: center;
`;

const HalfButton = styled(ButtonBase)`
  width: 50%;
  height: 100%;

  ${({ isActive }) =>
    (isActive
      ? `background-color: ${colors.primary.main};

    & > ${HalfButtonText} {
      color: ${colors.whiteText.highEmphasis};
    }`
      : `background-color: ${colors.surface.light};

    & > ${HalfButtonText} {
      color: ${colors.surfaceText.highEmphasis};
    }`)}
`;

const HeaderTitle = styled.div`
  ${typography.heading5};
  font-weight: 600;
  text-align: center;
`;

const HeaderSubtitle = styled.div`
  ${typography.subtitle1};
  color: ${colors.surfaceText.medEmphasis};
  letter-spacing: 1.26px;
  line-height: 19px;
  text-align: center;
`;

const LabelSpacer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

/* BOTH */
