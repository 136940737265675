import React, { Component } from "react";

// components
import CardAccordian from "./CardAccordian";
import LargeDottedButton from "./../../../components/LargeDottedButton";
import Header from "./../../../components/form/Header";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import FundAccordian from './FundAccordian'

export default class Cards extends Component {
  state = {
    adding: false
  };
  addCard = (e) => {
    this.setState({ adding: true });
  };
  closeNew = () => {
    this.setState({ adding: false });
  };
  render() {
    const { user } = this.props;
    console.log('user', user)
    return (
      <React.Fragment>
        <Header
          title="Funds"
          subtitle="Please modify your primary payment methods below"
        />
        <SectionsContainer></SectionsContainer>
        {/* {!this.state.adding ? null : (
          <CardAccordian
            key="newcard"
            id="newcard"
            user={user}
            ccard={null}
            closeNew={this.closeNew}
          />
        )} */}
        {Object.keys(user.FundProperties).map((k) => (
        <FundAccordian key={k} id={k} client={user} fund={k} />
        ))}
        {!this.state.adding ? null : (
        <FundAccordian
        key="newref"
        id="newref"
        client={user}
        fund={null}
        closeNew={this.closeNew}
        />
        )}
          {this.state.adding ? null : (
        <LargeDottedButton onClick={this.addCard}>ADD NEW FUND</LargeDottedButton>
        )}
      </React.Fragment>
    );
  }
}
