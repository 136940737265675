/** @jsx jsx */
import React, { Component } from "react";
import { css, jsx } from "@emotion/core";
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";
import CreditCardInput from "react-credit-card-input";

import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { parseFormError } from "./../../../util/form";

// utils
import { colors } from "./../../../util/consts";
import { getYearFromTwo } from "./../../../util";

import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import Error from "./../../../components/form/Error";
import LoadingButton from "./../../../components/LoadingButton";
import Section from "./../../../components/form/Section";

import DropdownSelect from "./../../../components/DropdownSelect";

import {
  AccordianForm,
  AccordianContainer,
  // AccordianButton,
  AccordianEdit,
  NewHeader,
  Separator,
  OptionsContainer,
  ButtonHead,
  ButtonDetails,
  ButtonContainer,
  NoSpacingIconButton
} from "./../../../components/elements/AccordianElements";

// icons
import ClearIcon from "./../../../components/icons/Clear";
import Options from "./../../../components/icons/Options";

import { getCardToken, getCardType } from "./../../../util/ccard";

class CardAccordian extends Component {
  state = {
    open: false
  };

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  setPrimary = async () => {
    const { dispatch, user, ccard } = this.props;
    if (!ccard) { return; }
    // Adding to funds.
    try {
      await dispatch(ResourceActions.action({
        ...ccard,
        IsPrimary: true
      }, "Update", {}));
      await dispatch(ResourceActions.action(user, "Fetch", {}));
    } catch (err) {
      parseFormError(err, {}, ()=>{}, this.props);
    }
  }

  removeItem = async () => {
    const { dispatch, user, ccard } = this.props;
    if (!ccard) { return; }
    // Adding to funds.
    try {
      await dispatch(ResourceActions.action(ccard, "Remove", {}));
      await dispatch(ResourceActions.action(user, "Fetch", {}));
    } catch (err) {
      parseFormError(err, {}, ()=>{}, this.props);
    }
  }

  editOptions = [
    {
      label: "Set As Primary",
      onClick: this.setPrimary
    },
    {
      label: "Remove",
      onClick: this.removeItem
    }
  ];

  renderContent() {
    const {
      id,
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit
    } = this.props;

    return (
      <AccordianForm
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <Section>
          <Label htmlFor={`addref-${id}-CardName`}>Card Name</Label>
          <Input
            id={`addref-${id}-CardName`}
            name="CardName"
            type="text"
            placeholder="Please input card name"
            value={values.CardName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.CardName && errors.CardName}
          />
          {!!errors.CardName && touched.CardName && (
            <Error>{errors.CardName}</Error>
          )}
        </Section>

        <Section>
          <CreditCardInput
            cardNumberInputProps={{
              name: "Number",
              value: values.Number,
              onChange: handleChange,
              onBlur: handleBlur
            }}
            cardExpiryInputProps={{
              name: "Expiry",
              value: values.Expiry,
              onChange: handleChange,
              onBlur: handleBlur
            }}
            cardCVCInputProps={{
              name: "CVC",
              value: values.CVC,
              onChange: handleChange,
              onBlur: handleBlur
            }}
            containerStyle={{
              width: "100%"
            }}
            fieldStyle={css`
              font-size: 15.8px;
              -webkit-letter-spacing: 0.15px;
              -moz-letter-spacing: 0.15px;
              -ms-letter-spacing: 0.15px;
              letter-spacing: 0.15px;
              text-align: left;
              height: 44px;
              width: 100%;
              padding: 10px 16px;
              border: 1px solid #d2d5e3;
              border-radius: 4px;
              outline: none;
              color: rgba(44, 46, 60, 1);
              -webkit-transition: border-color 0.2s ease;
              transition: border-color 0.2s ease;
            `}
          />
          {!!errors.Number && touched.Number && (
            <Error>{errors.Number}</Error>
          )}
          {!!errors.Expiry && touched.Expiry && (
            <Error>{errors.Expiry}</Error>
          )}
          {!!errors.CVC && touched.CVC && (
            <Error>{errors.CVC}</Error>
          )}
        </Section>

        <ButtonContainer>
          <LoadingButton
            style={{
              minWidth: 150
            }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Save
          </LoadingButton>
        </ButtonContainer>
      </AccordianForm>
    );
  }

  render() {
    const { open } = this.state;
    const { ccard, closeNew } = this.props;

    return (
      <AccordianContainer>
        {!ccard ? null : (
          <AccordianEdit>
            <ButtonHead>{(window.innerWidth<700)?`…`:`•••• •••• •••• `}{`${ccard.Last4}`}</ButtonHead>
            <Separator />
            <ButtonDetails>{`${ccard.ExpiryMonth}/${ccard.ExpiryYear%100}`}</ButtonDetails>
            <Separator />
            <ButtonDetails>{ccard.IsPrimary ? "Primary" : ""}</ButtonDetails>
            <OptionsContainer>
              <DropdownSelect options={this.editOptions}>
                <NoSpacingIconButton>
                  <Options />
                </NoSpacingIconButton>
              </DropdownSelect>
            </OptionsContainer>
          </AccordianEdit>
        )}
        {(open || !ccard) && (
          <React.Fragment>
            {ccard ? null :
              <NewHeader>
                <ButtonHead>New Card</ButtonHead>
                <NoSpacingIconButton onClick={closeNew}>
                  <ClearIcon fill={colors.primary.main} />
                </NoSpacingIconButton>
              </NewHeader>}
            {this.renderContent()}
          </React.Fragment>
        )}
      </AccordianContainer>
    );
  }
}


const formikEnhancer = withFormik({
  mapPropsToValues: ({ ccard, refIdx }) => ({
    CardName: "",
    Number: "",
    Expiry: "",
    CVC: ""
  }),
  validationSchema: Yup.object().shape({
    CardName: Yup.string().required("Card name is required"),
    Number: Yup.string().required("Number is required."),
    Expiry: Yup.string().required("Expiry is required."),
    CVC: Yup.string().required("CVC is required."),
  }),
  enableReinitialize: false,
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    const { dispatch, user, ccard } = props;
    // Adding to funds.
    try {
      const expVals = values.Expiry.split("/");
      const newCardToken = await getCardToken(
        values.Number,
        values.CardName,
        values.CVC,
        parseInt(expVals[0].trim()),
        getYearFromTwo(parseInt(expVals[1].trim())),
      );

      const newCard = {
        $Metadata: { Type: "CreditCard" },
        OwnerType: "User",
        Owner: user.ID,
        Token: newCardToken,
        Type: getCardType(values.Number),
        Last4: values.Number.slice(values.Number.length-4),
        IsPrimary: true,
        ExpiryMonth: parseInt(expVals[0].trim()),
        ExpiryYear: getYearFromTwo(parseInt(expVals[1].trim())),
        Merchant: "WebDosh"
      };

      await dispatch(ResourceActions.action(newCard, "Create", {}));
      await dispatch(ResourceActions.action(user, "Fetch", {}));

      !ccard && props.closeNew();
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "Card"
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(CardAccordian);
