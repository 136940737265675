/** @jsx jsx */
import { Component } from "react";
import { css, jsx } from "@emotion/core";
import { withFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

// Utils
import { parseFormError } from "./../../../util/form";

// grpc
import { rpc, metadata } from "../../../grpc";
import { UpdateUserRequest, UpdateUserResponse, User } from '../../../sdk/user_pb';

// Redux
import apiService from "./../../../redux/services/api";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import * as GlobalActions from "./../../../redux/features/global/actions";

// Custom components
import Button from "./../../../components/Button";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import Header from "./../../../components/form/Header";
import Form from "./../../../components/form/Form";
import FooterContainer from "./../../../components/form/FooterContainer";
import { GenderSelect } from "./../../../components/form/BaseSelect";
import DayPickerInput from "./../../../components/form/DayPickerInput";
import MobileInput from "./../../../components/form/MobileInput";

import { getAuthenticatedUser } from "./../../../components/elements/AuthGetUser";

const IS_PHONE_REGEX = /^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1})?[-\s\./0-9]*$/; // eslint-disable-line no-useless-escape

let history;

class NewPassword extends Component {
  constructor(Props) {
    super(Props);
  }

  componentDidMount() {
    this.history = this.props.historys
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Header
          title="Reset Your Password"
          subtitle="Please reset your password below before continuing."
        />
        <SectionsContainer>
        <Section>
            <Label htmlFor="login-First-Name">First Name</Label>
            <Input
              id="login-First-Name"
              placeholder="First Name"
              type="text"
              name="FirstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.FirstName}
            />
            {!!errors.FirstName && touched.FirstName && <Error>{errors.FirstName}</Error>}
          </Section>
          <Section>
            <Label htmlFor="login-Last-Name">Last Name</Label>
            <Input
              id="login-Last-Name"
              placeholder="Last Name"
              type="text"
              name="LastName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.LastName}
            />
            {!!errors.LastName && touched.LastName && <Error>{errors.LastName}</Error>}
          </Section>
          { this.props.pageData.Email ? 
          <Section>
            <Label htmlFor="login-EmailPhone" style={{color:'grey'}}>{this.props.pageData.Email ? 'Email' : 'Phone'}</Label>
            <Input
              id="login-EmailPhone"
              disabled={true}
              placeholder={this.props.pageData.Email ? this.props.pageData.Email : this.props.pageData.MobileNumber}
              value={this.props.pageData.Email ? this.props.pageData.Email : this.props.pageData.MobileNumber}
            />
          </Section>
          :
          <Section>
          <Label htmlFor="addclient-Phone">Phone Number</Label>
          <MobileInput
            onCountryChange={(e)=>{values.MobileCountryCode = e}}
            onPhoneChange={(e)=>{values.MobileNumber = e}}
            disabled
            value={this.props.pageData.Email ? this.props.pageData.Email : this.props.pageData.MobileNumber}
            /> 
          </Section>}
          { this.props.pageData.Email ?
          <Section>
          <Label htmlFor="addclient-Phone">Phone Number</Label>
          <MobileInput
            onCountryChange={(e)=>{values.MobileCountryCode = e}}
            onPhoneChange={(e)=>{values.MobileNumber = e}}
            value={values.MobileNumber}
            /> 
          </Section>
          :
          <Section>
            <Label htmlFor="login-Email">Email</Label>
            <Input
              id="login-Email"
              name="Email"
              onChange={handleChange}
              placeholder={this.props.pageData.Email}
              value={values.Email}
              // value={this.props.pageData.Email ? this.props.pageData.MobileNumber: this.props.pageData.Email }
            />
          </Section>}
          {!!errors.PhoneEmail && touched.PhoneEmail && <Error>{errors.PhoneEmail}</Error>}
          <Section>
                <Label htmlFor="addclient-Gender">Gender (optional)</Label>
                <GenderSelect
                  id="login-Gender"
                  name="Gender"
                  value={values.Gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {!!errors.Gender && touched.Gender && <Error>{errors.Gender}</Error>}
              </Section>
          <Section>
            <Label htmlFor="addclient-DateOfBirth">Date of Birth (optional)</Label>
            <DayPickerInput
              id="login-DateOfBirth"
              name="DateOfBirth"
              placeholder="Select a date of birth"
              value={values.DateOfBirth}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.DateOfBirth && errors.DateOfBirth}
            />
            {!!errors.DateOfBirth && touched.DateOfBirth && <Error>{errors.DateOfBirth}</Error>}
          </Section>
          <Section>
            <Label htmlFor="login-Password">New Password</Label>
            <Input
              id="login-Password"
              placeholder="8+ characters"
              type="password"
              name="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Password}
            />
            {!!errors.Password && touched.Password && <Error>{errors.Password}</Error>}
          </Section>
          <Section>
            <Label htmlFor="login-PasswordCheck">Retype New Password</Label>
            <Input
              id="login-PasswordCheck"
              placeholder="8+ characters"
              type="password"
              name="PasswordCheck"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.PasswordCheck}
            />
            {!!errors.PasswordCheck && touched.PasswordCheck && <Error>{errors.PasswordCheck}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <Button
            css={css`
              width: 200px;
            `}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isSubmitting}
          >
            Sign Up
          </Button>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({pageData}) => ({
    FirstName: pageData.FirstName,
    LastName: pageData.LastName,
    EmailPhone: pageData.EmailPhone,
    PhoneEmail: (pageData.Email ? pageData.MobileNumber : pageData.Email),
    Email: pageData.Email,
    MobileNumber: pageData.MobileNumber,
    MobileCountryCode: pageData.MobileCountryCode,
    Gender: pageData.Gender,
    DateOfBirth: null,
    Password: "",
    PasswordCheck: ""
  }),
  validationSchema: Yup.object().shape({
    Password: Yup.string()
      .min(8, "Passwords have an 8 character minimum.")
      .required("Please enter your new password."),
    PasswordCheck: Yup.string()
      .min(8, "Passwords have an 8 character minimum.")
      .required("Please enter a matching password.")
      .oneOf([Yup.ref('Password'), null], 'Passwords does not match')
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      let updateUserResponse = {}
      const req = new UpdateUserRequest();
      const user = new User();
        user.setFirstName(values.FirstName.trim())
        user.setLastName(values.LastName.trim())
        user.setMobileNumber(values.MobileNumber ? values.MobileNumber :  null)
        user.setMobileCountryCode(values.MobileCountryCode ? values.MobileCountryCode : null)
        user.setEmail(values.Email ? values.Email : null)
        user.setDateOfBirth(values.DateOfBirth)
        user.setRole("Standard")
        user.setGender(values.Gender)
        user.setId(props.pageData.ID)

      req.setUser(user)
      req.setPassword(values.Password)
      const res = await rpc.user.updateUser(req, metadata())
      updateUserResponse = res.toObject()
      console.log('working', updateUserResponse)

    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }

    try{
      const logRes = await apiService.userLogin(
        (props.pageData.Email == "") ? null : props.pageData.Email,
        (props.pageData.Email == "") ? props.pageData.MobileNumber : null,
        (props.pageData.Email == "") ? props.pageData.MobileCountryCode : null,
        values.Password
      );
    }
    catch(err){
      parseFormError(err, values, setFieldError, props);
    }

    //TODO:Update Client with new information as well

    props.setPage('ccards')
    setSubmitting(false);

  },
  displayName: "LoginForm"
});



export default compose(
  connect((state)=>({ authUser: getAuthenticatedUser(state) })),
  withToastManager,
  formikEnhancer
)(NewPassword);
