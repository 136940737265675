import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

import * as ReduxDialogs from "./../../redux/features/dialogs";
import { parseFormError } from "./../../util/form";

import { colors, hexToRgba } from "./../../util/consts";
import typography from "./../../util/typography";

import * as ResourceActions from "./../../redux/features/resources/thunkactions";

// Utils
import { breakpoints } from "./../../util/consts";

import Dialog from "./Dialog";
import ButtonBase from "./../../components/ButtonBase";
import Label from "./../../components/form/Label";
import Section from "./../../components/form/Section";
import Select from "./../../components/form/BaseSelect";

import LoadingIcon from "./../../components/icons/Loading";

class PullServicesDialog extends React.Component {
  closeDialog = () => {
    const { dispatch, dialogId } = this.props;
    dispatch(ReduxDialogs.actions.close(dialogId));
  }

  pullProductsAndServices = async () => {
    const { dispatch, dialogId, toastManager, data: { loc, cb } } = this.props;
    const { fromLoc } = this.state;

    if (!fromLoc) {
      toastManager.add("You must specify a location to pull other services from", { appearance: "error" });
      return;
    }

    if (this.state.isLoading || !fromLoc) { return; }

    this.setState({ isLoading: true });
    try {
      await dispatch(
        ResourceActions.action(loc, "Action", {
          Action: "PullServicesFromAnotherLocation",
          Location: fromLoc
        })
      );

      toastManager.add("Successfully pulled all products and services!", { appearance: "success" });
      this.setState({ isLoading: false });
      if (cb) {
        await cb();
      }
      dispatch(ReduxDialogs.actions.close(dialogId));
    } catch (err) {
      parseFormError(err, {}, () => {}, this.props);
      this.setState({ isLoading: false });
    }
  }

  handleFromLocChange = (e) => {
    this.setState({ fromLoc: e.target.value });
  }

  constructor(props) {
    super(props);
    const locs = props.data.org.Locations.filter((v)=>v.ID!==props.data.loc.ID);
    this.state = {
      fromLoc: locs.length > 0 ? locs[0].ID : "",
      isLoading: false
    };
  }

  render() {
    const { isLoading, fromLoc } = this.state;
    const { data: { trigger, org, loc } } = this.props;
    return (
      <Dialog
        dialogId={this.props.dialogId}
        trigger={trigger}
        overideCloseDialog={this.closeDialog}
        noClose
      >
        <Container>
          <FormSections>
            <Section>
              <Label htmlFor="pullservices-FromLoc">Location to Copy From</Label>
              <LabelSubText>
                Choose the location you wish to copy product and services from.
                Any product or service with the same internal code will be updated.
              </LabelSubText>
              <Select
                id="pullservices-FromLoc"
                name="FromLoc"
                value={fromLoc}
                onChange={this.handleFromLocChange}
                disabled={isLoading}
              >
                {org.Locations.filter((v)=>v.ID!==loc.ID).map((v)=>
                  <option key={v.ID} value={v.ID}>{v.Name}</option>
                )}
              </Select>
            </Section>
          </FormSections>

          <ButtonContainer>
            <LargeBottomButton onClick={this.pullProductsAndServices}>
              {isLoading ?
                <LoadingIcon
                  width={16}
                  height={16}
                  color="#FFF"
                /> :
                <LargeButtonText>Pull Services/Products</LargeButtonText>}
            </LargeBottomButton>
          </ButtonContainer>
        </Container>
      </Dialog>
    );
  }
}

const Container = styled.div`
  position: relative;
  padding: 24px;
  padding-bottom: 74px;

  ${breakpoints["tablet-up"]} {
    padding: 42px 90px;
    padding-bottom: 82px;
    max-width: 500px;
  }

  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const FormSections = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
`;

const LargeButtonText = styled.span`
  ${typography.heading6};
  font-weight: 600;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: ${colors.whiteText.highEmphasis};
`;

const LargeBottomButton = styled(ButtonBase)`
  flex: 1 0 100px;
  height: 100%;
  background-color: ${({ isPrimary })=>(isPrimary ? colors.primary.main : colors.secondary.main)};
  transition: opacity 0.2s ease;

  &:hover:enabled {
    opacity: 0.95;
  }

  &:focus {
    opacity: 0.95;
  }

  &:disabled {
    background-color: ${hexToRgba(colors.secondary.main, 0.12)};
  }
`;

const LabelSubText = styled.div`
  ${typography.body2};
  color: ${colors.surfaceText.medEmphasis};
  letter-spacing: 0.09px;
  margin-bottom: 20px;
`;

export default compose(
  connect(),
  withToastManager
)(PullServicesDialog);
