/** @jsx jsx */
import { Component } from "react";
import { Link } from "react-router-dom";
import { css, jsx } from "@emotion/core";
import { withFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

// Redux
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import apiService from "./../../../redux/services/api";
import { colors } from "./../../../util/consts";

// MobX
import { signUpDataStore } from "./../../../mobx/stores/SignUpDataStore";

// Utils
import { parseFormError } from "./../../../util/form";
import { findPlaceDetails, getAddressResourceFromPlaceDetails } from "./../../../util/address";

// Custom components
import Button from "./../../../components/Button";
import GMPlacesAutocomplete from "./../../../components/form/GMPlacesAutocomplete";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import Header from "./../../../components/form/Header";
import Form from "./../../../components/form/Form";
import DayPickerInput from "./../../../components/form/DayPickerInput";
import FooterContainer from "./../../../components/form/FooterContainer";
import FooterText from "./../../../components/form/FooterText";

const IS_PHONE_REGEX = /^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1})?[-\s\./0-9]*$/; // eslint-disable-line no-useless-escape

class SignUpOrg extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      closeDialog
    } = this.props;

    this.props.toggleSignedUp;
    return (
      <Form onSubmit={handleSubmit}>
        <Header title="Get Started" subtitle="Create a business account" />
        <SectionsContainer>
          <Section>
            <Label htmlFor="signup-EmailPhone">Email</Label>
            <Input
              id="signup-EmailPhone"
              placeholder="example@email.com"
              type="text"
              name="EmailPhone"
              value={values.EmailPhone}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.EmailPhone && touched.EmailPhone && <Error>{errors.EmailPhone}</Error>}
          </Section>
          <Section>
            <Label htmlFor="createorg-phone">Contact Phone</Label>
            <Input
              id="createorg-phone"
              name="ContactPhone"
              type="text"
              placeholder="03 1234 3210"
              value={values.ContactPhone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.ContactPhone && errors.ContactPhone}
            />
            {!!errors.ContactPhone && touched.ContactPhone && <Error>{errors.ContactPhone}</Error>}
          </Section>
          <Section>
            <Label htmlFor="signup-FirstName">Business Name</Label>
            <Input
              id="signup-FirstName"
              placeholder="ABC Business"
              type="text"
              name="FirstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.FirstName}
            />
            {!!errors.FirstName && touched.FirstName && <Error>{errors.FirstName}</Error>}
          </Section>
          <Section css={{ marginBottom: "30px !important" }}>
            <Label htmlFor="signupextra-Address">Business Address</Label>
            <GMPlacesAutocomplete
              id="signupextra-Address"
              name="Address"
              type="text"
              placeholder="Address"
              value={values.Address}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {!!errors.Address && touched.Address && <Error>{errors.Address}</Error>}
          </Section>
          <Section>
            <Label htmlFor="signup-Password">Password</Label>
            <Input
              id="signup-Password"
              placeholder="8+ characters"
              type="password"
              name="Password"
              value={values.Password}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.Password && touched.Password && <Error>{errors.Password}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <Button
            css={css`
              width: 150px;
            `}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={
              (!!errors.EmailPhone,
                !!errors.ContactPhone,
              !!errors.FirstName,
              !!errors.Password,
              // !!errors.DateOfBirth,
              isSubmitting)
            }
          >
            Continue
          </Button>
        </FooterContainer>
        <FooterContainer
          css={css`
            margin-top: 16px;
          `}
        >
          <FooterText
            css={css`
              text-align: center;
            `}
          >
            By clicking “Continue” I agree to Booklyfe’s{" "}
            <Link
              to="/"
              onClick={closeDialog}
              css={css`
                text-decoration: none;
                color: ${colors.secondary.main};
              `}
            >
              Terms of Service
            </Link>{" "}
            &amp;{" "}
            <Link
              to="/"
              onClick={closeDialog}
              css={css`
                text-decoration: none;
                color: ${colors.secondary.main};
              `}
            >
              Privacy Policy
            </Link>
          </FooterText>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: () => ({
    EmailPhone: "",
    ContactPhone: "",
    FirstName: "",
    Password: "",
  }),
  validationSchema: Yup.object().shape({
    EmailPhone: Yup.string().required("Please enter your business email"),
    ContactPhone: Yup.string().required("Business phone is required."),
    FirstName: Yup.string().required("Please enter your business name"),
    Address: Yup.string().required("Please enter your business address"),
    Password: Yup.string()
      .min(8, "Passwords have an 8 character minimum")
      .required("Please enter a password"),
    }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    const EmailPhone = values.EmailPhone;
    const usePhone = false;

    try {

      // check address
      if (props.pageData.Address !== values.Address) {
        const prDet = await findPlaceDetails(values.Address, "Address");
        const prAddRes = {
          OwnerType: "Organisation",
          ...getAddressResourceFromPlaceDetails(prDet, values.Address)
        };
      } 
      // end
      
      await apiService.performRequest({
        method: "post",
        path: "/api/v1/users",
        data: {
          FirstName: values.FirstName.trim(),
          LastName: "",
          Email: values.EmailPhone.trim(),
          // MobileNumber: values.ContactPhone,
          // MobileCountryCode: "AU",
          Password: values.Password,
          DateOfBirth: null,
          Role: "Standard"
        }
      });

      const logRes = await apiService.userLogin(
        usePhone ? null : EmailPhone,
        null,
        null,
        values.Password
      );

      const resource = { $Metadata: { Type: "User" }, ID: logRes.Payload.UserID };
      const res = await props.dispatch(ResourceActions.action(resource, "Fetch", {}));

      const mustVerifyEmail =
        res.Payload.Email && (res.Payload.ValidatedFields || []).indexOf("Email") < 0;

      signUpDataStore._loggedIn = true // keep the registration section on form active

        props.setPage("Verify", {
          Name: values.FirstName.trim(),
          ContactEmail: values.EmailPhone,
          ContactPhone: values.ContactPhone,
          Address: values.Address,
        });

    // Add client to Hubspot
    fetch(
      "https://api.hsforms.com/submissions/v3/integration/submit/4603694/34d176f9-1fdd-46db-8bfc-5a86837d949e",
      {
        method: "POST",
        body: JSON.stringify({
          fields: [
            {
              name: "firstname",
              value: values.FirstName.trim()
            },
            {
              name: "email",
              value: values.EmailPhone
            },
            {
              name: "company",
              value: values.FirstName.trim()
            },
            {
              name: "phone",
              value: values.ContactPhone
            },
            {
              name: "address",
              value: values.Address
            }
          ],
          legalConsentOptions: {
            consent: {
              consentToProcess: true,
              text: "I agree to allow Example Company to store and process my personal data.",
              communications: [
                {
                  value: true,
                  subscriptionTypeId: 999,
                  text: "I agree to receive marketing communications from Example Company."
                }
              ]
            }
          }
        }),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then((res) => res.json())
      .then((response) => console.log("Success:", "hubspot", JSON.stringify(response)))
      .catch((error) => console.error("Error:", "hubspot", error));
    // end of hubspot integretation

    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }

    setSubmitting(false);
  },
  displayName: "SignUp"
});

// export default connect()(formikEnhancer(SignUpOrg));
export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(SignUpOrg);
